import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog, DLButton } from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import styled from "styled-components"
import { HcMgmtUserDialogType as DialogType } from "../../store/data-models/hard-copy-user-model"

export default observer(function SuspendOrRemoveUserDialog({
	i18n,
	partialStore, // hcMgmtStore > config
}: {
	i18n: DLI18nProps
	partialStore: any
}) {
	const actionName = "suspendUser"

	const handleAction = () => {
		// 		userStore.suspendUser(userStore.selectedUser.id)
	}

	const actionBtn =
		partialStore.dialogType === DialogType.suspend
			? i18n.twSuspend || "Suspend"
			: i18n.twRemove || "Remove"

	return (
		<DLDialog
			eleTestId={`${partialStore.dialogType}-dialog`}
			isOpen={partialStore.openSuspendOrRemoveUserDialog}
			setIsOpen={partialStore.setOpenSuspendOrRemoveUserDialog}
			handleAction={handleAction}
			showCloseBtn={true}
			dialogTitle={`${partialStore.dialogType} User`}
			dialogContents={
				partialStore.dialogType === DialogType.suspend ? (
					<>
						Do you really want to suspend this user? After this, the
						user cannot rent any item but able to see the hard copy
						list.
					</>
				) : (
					<>
						Do you really want to remove this user from the hard
						copy access list? After this, the user cannot read of
						the hard copy list.
					</>
				)
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionBtn={actionBtn}
			maxWidth="sm"
			fullWidth={true}
			actionReady={true}
			// 			showSpinner={}
			// 			cannotUpdate={userStore.responses.getActionStatus(actionName) === "LOADING"}
		/>
	)
})
