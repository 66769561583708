import { ConsoleLog } from "../../../../../components/basic-elements"

const GroupRelatedViews = (self: any) => ({
	currentGroupInfo() {
		if (self.currentPath[0]) {
			return self.orgGroupFlatList.find(
				(item: any) => item.id === self.currentPath[0]
			)
		} else {
			ConsoleLog("No selected group")
		}
	},
	get rightClickTargetInfo() {
		const target = self.orgGroupFlatList.find(
			(item: any) => item.id === self.rightClickTarget
		)
		if (target) {
			// ConsoleLog("target", target)
			return target
		} else {
			return undefined
		}
	},
})

export default GroupRelatedViews
