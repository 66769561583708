// ---------- common models
import {
    ActionStatus,
    MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
    idToString,
    IdType,
} from "../../../../../../library/converters/id-converter"

/**
 * This API has {} types responses
 * 1. Success
 * 2. Fail
 * 3. Error
 */

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const GetArchivedProjects = (self: any) => ({
    getArchivedProjects({
        year,
        pageSize,
        pageIndex,
        searchText,
    }: {
        year: "All Years" | string
        pageSize?: number
        pageIndex?: number
        searchText?: string
    }) {
        // 0.
        const actionName = "getArchivedProjects"
        console.time()
        // 1.
        // 2.
        self.responses.setResponse(actionName, {
            actionName,
            status: ActionStatus.loading,
        })
        // 3.call API
        self.readAmArchivedProjectList(year, pageSize, pageIndex, searchText)
            .then((response: any) => {
                // if success
                if (response.status === 200 && response.data.Status === 1) {
                    // 1) initialize existing stored list
                    self.resetStoredList()
                    // 2) Re-organize data from the server
                    const existingAmArchivedProjectList =
                        response.data.ArchivedClientList
                    if (existingAmArchivedProjectList === null) {
                    } else {
                        existingAmArchivedProjectList.map(
                            (archivedProject: any) => {
                                // 2-1) convert epUserIdList by string
                                let epUsers: any = []
                                if (archivedProject.EpUsers !== "") {
                                    archivedProject.EpUsers.split(",").map(
                                        (user: string, i: number) => {
                                            epUsers.push({
                                                id: archivedProject.EPUserIDs[i]
                                                    ? idToString(
                                                          archivedProject
                                                              .EPUserIDs[i],
                                                          IdType.user
                                                      )
                                                    : "xxx",
                                                aliasId: "xxx",
                                                name: user,
                                            })
                                        }
                                    )
                                }
                                // 2-2) convert qcUserIdList by string
                                let qcUsers: any = []
                                if (archivedProject.QCUsers !== "") {
                                    archivedProject.QCUsers.split(",").map(
                                        (user: string, i: number) => {
                                            qcUsers.push({
                                                id: archivedProject.QCUserIDs[i]
                                                    ? idToString(
                                                          archivedProject
                                                              .QCUserIDs[i],
                                                          IdType.user
                                                      )
                                                    : "xxx",
                                                aliasId: "xxx",
                                                name: user,
                                            })
                                        }
                                    )
                                }
                                // 2-3) re-organize the project information
                                let archivedBy = {
                                    id: "",
                                    aliasId: "",
                                    name: "",
                                }

                                if (
                                    archivedProject.ArchivedBy !== 0 &&
                                    archivedProject.ArchivedBy !== null
                                ) {
                                    archivedBy = {
                                        id: idToString(
                                            archivedProject.ArchivedBy,
                                            IdType.user
                                        ),
                                        aliasId: "xxx",
                                        name: archivedProject.ArchivedByUser,
                                    }
                                }
                                let unarchivedBy = null
                                if (
                                    archivedProject.UnArchivedBy !== 0 &&
                                    archivedProject.UnArchivedBy !== null
                                ) {
                                    unarchivedBy = {
                                        id: idToString(
                                            archivedProject.UnArchivedBy,
                                            IdType.user
                                        ),
                                        aliasId: "xxx",
                                        name: archivedProject.UnArchivedByUser,
                                    }
                                }
                                let lastAccessBy = null
                                if (archivedProject.LastAccessedBy !== null) {
                                    lastAccessBy = {
                                        id: "xxx", // Not getting from backend
                                        aliasId: "xxx",
                                        name: archivedProject.LastAccessBy,
                                    }
                                }
                                const reOrganizedArchivedProject = {
                                    //
                                    // * 1. location info: group, cabinet
                                    groupId: "xxx",
                                    groupName: archivedProject.GroupName, // [GroupName] ex) "2019 TEST"
                                    cabinetId: archivedProject.CabinetId
                                        ? idToString(
                                              archivedProject.CabinetId,
                                              IdType.cabinet
                                          )
                                        : "?", // [CabinetId] ex) 400
                                    cabinetName: "",
                                    //
                                    //
                                    // * 2. client info
                                    clientId: "xxx",
                                    clientName: archivedProject.ClientName, // [ClientName] ex) "CJ"
                                    clientAliasId: "", // archivedProject.clientShortName,  // NOTE: clientShortName = project aliasId
                                    //
                                    //
                                    // * 3. project basic info
                                    id: archivedProject.ProjectId
                                        ? idToString(
                                              archivedProject.ProjectId,
                                              IdType.project
                                          )
                                        : "?", // [ProjectId] ex) 1238
                                    aliasId: archivedProject.clientShortName,
                                    title: archivedProject.ProjectName, // [ProjectName] ex) "01-07 11:07:53"
                                    //
                                    engTypeId: "xxx",
                                    engTypeName: archivedProject.EngType, // [EngType] ex) "Engagement A"
                                    //
                                    raId: "xxx",
                                    raName:
                                        archivedProject.RiskAssessmentName ===
                                        null
                                            ? "null"
                                            : archivedProject.RiskAssessmentName,
                                    //
                                    //
                                    // * 4. project status info
                                    statusId: "xxx",
                                    statusName: "xxx",
                                    periodId: "xxx",
                                    periodName: archivedProject.Period, // [Period] ex) "Full year"
                                    archiveDatePolicy: "xxx",
                                    //
                                    //
                                    replicaStatus:
                                        archivedProject.ReplicaStatus === "Yes"
                                            ? true
                                            : false,
                                    archiveZipStatus:
                                        archivedProject.ArchiveZipStatus ===
                                        "Yes"
                                            ? true
                                            : false,
                                    unarchiveStatus:
                                        archivedProject.UnArchiveStatus ===
                                        "Yes"
                                            ? true
                                            : false,
                                    //
                                    version: parseInt(
                                        archivedProject.Version.split(" v")[1]
                                    ), // [Version] ex) "Archived v1"
                                    isArchived: true, // Not getting from backend
                                    isLocked: true, // Not getting from backend
                                    //
                                    //
                                    // * 5. access info
                                    roleId: "xxx",
                                    roleName: "xxx",
                                    accessible:
                                        archivedProject.IsOpenaccessright, // [IsOpenaccessright] ex) false --> ??? Where to use
                                    //
                                    //
                                    // * 6. schedule info
                                    financialYear: archivedProject.Year, // [Year] ex) 2020
                                    periodEndDate:
                                        archivedProject.FinancialYear, // [FinancialYear] ex) "2020-01-05T19:00:00+00:00"
                                    //
                                    expectedReportDate:
                                        archivedProject.ExpectedReportDate ||
                                        "",
                                    finalReportDate:
                                        archivedProject.FinalReportDate || "",
                                    //
                                    //
                                    expectedArchiveDate:
                                        archivedProject.ExpectedArchiveDeadlineDate ||
                                        "",
                                    finalArchiveDeadlineDate:
                                        archivedProject.FinalArchiveDeadlineDate ||
                                        "",
                                    //
                                    //
                                    // * 7. history info
                                    // archOrUnArchBy:
                                    // 	archivedProject.ArchOrUnAchivedBy, // [ArchOrUnAchivedBy] ex) "cskim"
                                    // archOrUnArchAt:
                                    // 	archivedProject.ArchOrUnArchivedOn, // [ArchOrUnArchivedOn] ex) "2020-01-10T17:58:02.994666+00:00"
                                    createdBy: archivedProject.CreatedBy
                                        ? {
                                              id: idToString(
                                                  archivedProject.CreatedBy,
                                                  IdType.user
                                              ),
                                              aliasId: "",
                                              name: archivedProject.CreatedByUser,
                                          }
                                        : null,
                                    createdAt:
                                        archivedProject.CreatedDate ?? null,
                                    //
                                    archivedBy,
                                    archivedAt: archivedProject.ArchivedDate
                                        ? archivedProject.ArchivedDate
                                        : "", // [ArchivedDate], ex) null
                                    //
                                    unarchivedBy,
                                    unarchivedAt: archivedProject.UnarchivedDate
                                        ? archivedProject.UnarchivedDate
                                        : "", // [UnarchivedDate], ex) null
                                    //
                                    lastAccessBy, // [LastAccessBy] ex) "cskim"
                                    lastAccessAt:
                                        archivedProject.LastAccessDate, // [LastAccessDate] ex) "2020-01-10T17:57:25.614208+00:00"
                                    //
                                    //
                                    // * 8. assigned users
                                    epUsers, // [EpUsers] ex) "김철수" // TODO: check the case with multiple ep users
                                    qcUsers, // [QCUsers] ex) "" // TODO: check the case with multiple qc users
                                    //
                                    //
                                    // * 9. extras

                                    //
                                    // * + speciall part
                                    hasExportPermission:
                                        archivedProject.IsExportPermission, // [IsExportPermission] ex) false
                                    //
                                    // * ???
                                    // epUserIdList: epUserIdList, // [EPUserIDs] ex) [16]
                                    // qcUserIdList: qcUserIdList, // [QCUserIDs] ex) []
                                    // archOrUnArchDate:
                                    // 	archivedProject.ArchUnarchDate, // [ArchUnarchDate] ex) "2020-01-10T17:58:02.994666+00:00"
                                    // ---- related with signed-in user's permission  // TODO: @Prudhvi: Need to check. Is this client? or project?
                                    // canVisit: archivedProject.canVisit, // [canVisit] ex) true
                                }

                                self.pushItemToList(reOrganizedArchivedProject)
                            }
                        )
                    }

                    // success case response
                    self.setNeedRefresh(false)
                    console.timeEnd()
                    self.handleResponse({
                        actionName,
                        status: ActionStatus.success,
                        code: 200, // temp
                        color: MessageColorType.blue,
                        open: true,
                        autoHide: true,
                    })
                } else {
                    self.handleResponse({
                        actionName,
                        status: ActionStatus.fail,
                        code: 200, // temp
                        color: MessageColorType.orange,
                        open: true,
                        // autoHide: true,
                    })
                }
            })
            .catch((error: Error) => {
                self.handleViewModelError({
                    error,
                    actionName,
                    open: false,
                })
            })
    },
})

export default GetArchivedProjects
