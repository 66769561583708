import { DLObjectFormat } from "../../../../common-models/enumerations/common-enums"
import { initialDLFile } from "../../data-models/initial-file"
import { IdType, idToString } from "../../../../library/converters/id-converter"
import {
	convertAttention,
	convertCommentStatus,
	convertSignOffs,
} from "../../proj-files-shared-functions"
import { findProjRoleInfo } from "../../../../temporary-data/project-side/find-proj-role"
import { RoleModelProps } from "../../data-models/file.data-model"
import {
	convertExtension,
	convertSourceFormat,
	isCustomFile,
} from "../../../../library/converters/source-format-converter"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { CommentStatus } from "../../../../screens/project-side/communications/proj-comments/store/data-models/comments.data-model"
import { formatFileSize } from "@datalobby/common/lib/format-file-size"

export const reorganizeFile = ({
	fetchedFile,
	index,
	menuId,
	folderId,
	expanded = false,
}: {
	fetchedFile: any
	index: number
	menuId: DLProjSubMenus | DLOrgSubMenus
	folderId: string
	expanded?: boolean
}) => {
	const {
		WPRID,
		LastModifiedBy,
		RolesList,
		MediaType,
		EngagementPartner,
		ConcurringPartner,
		QualityControl,
		WPEF,
		WPDesc,
		Final,
		Comments,
		Followup,
		PreparerList,
		ReviewerList,
		IsPrepared,
		IsReviewed,
		Extension,
		MediaID,
		FileSize,
		ActualFilePath,
		url,
		IsOpen,
		OpenedBy,
	} = fetchedFile

	const fileId = idToString(WPRID, IdType.file)
	// ? in the old file-add code.. I added 'TemplateId' for ID like this... but cannot remember why
	// id: idToString(
	//     fetchedInfo.WPRID | fetchedInfo.TemplateId,
	//     IdType.file
	// ),
	//
	let modifiedBy = {
		userAliasId: "",
		userName:
			LastModifiedBy.userName === null ? "" : LastModifiedBy.userName,
		userId: "",
		//
		roleId: "",
		roleShortName:
			LastModifiedBy.roleName === null ? "" : LastModifiedBy.roleName,
	}

	let assignedRoles: RoleModelProps[] = []
	// when add file, RolesList is null
	RolesList?.length >= 1 &&
		RolesList[0].Roles.map((role: any) => {
			const roleInfo = findProjRoleInfo(role)
			if (roleInfo) {
				assignedRoles.push({
					id: roleInfo.roleId,
					name: roleInfo.name,
					shortName: roleInfo.shortName,
					color: roleInfo.color,
				})
			} else {
				assignedRoles.push({
					id: "roleId" + role,
					name: "(...)",
					shortName: "(...)",
					color: "lightgray",
				})
			}
		})

	// NOTE: file.Extension = ".png" / MediaType: "Others"
	const where =
		"util-reorganize-file-" +
		WPEF +
		"-" +
		WPDesc +
		"-" +
		MediaType +
		"-" +
		Extension
	const srcFormat = convertSourceFormat(MediaType, "", where)
	const objectFormat = isCustomFile(srcFormat)
		? DLObjectFormat.customFile
		: DLObjectFormat.normalFile

	//
	let epReviewListWithId: any[] = []
	if (EngagementPartner) {
		epReviewListWithId = [...EngagementPartner]
		epReviewListWithId.map((item) => {
			item.roleId = 9
		})
	}

	//
	let cpReviewListWithId: any[] = []
	if (ConcurringPartner) {
		cpReviewListWithId = [...ConcurringPartner]
		cpReviewListWithId.map((item) => {
			item.roleId = 6
		})
	}

	//
	let qcReviewListWithId: any[] = []
	if (QualityControl) {
		qcReviewListWithId = [...QualityControl]
		qcReviewListWithId.map((item) => {
			item.roleId = 8
		})
	}
	
	const formatSize = (FileSize: string) => {
		const kb = FileSize.split("K")[0]
		const bytes = +kb * 1000
		
		return formatFileSize(bytes) === "0 bytes" ? "0KB" : formatFileSize(bytes)
	}

	//
	const reOrganizedFile = {
		...initialDLFile,
		id: fileId,
		menuId,
		parentId: folderId,
		aliasId: WPEF,
		title: WPDesc,
		objectFormat,
		expanded, // for checking the needs of expandAll
		//
		status: convertAttention(Final),
		reviewCommentStatus: Comments
			? convertCommentStatus(Comments)
			: CommentStatus.notExist,
		qcCommentStatus: Followup
			? convertCommentStatus(Followup)
			: CommentStatus.notExist,
		//
		//
		//
		prepareList: PreparerList ? convertSignOffs(PreparerList) : [],
		reviewList: ReviewerList ? convertSignOffs(ReviewerList) : [],
		//
		//
		epReviewList: convertSignOffs(
			// EngagementPartner --> This list return 0 for roleId, so need to update that part as correct id...
			epReviewListWithId
		),
		cpReviewList: convertSignOffs(
			// ConcurringPartner
			cpReviewListWithId
		),
		qcReviewList: convertSignOffs(
			// QualityControl
			qcReviewListWithId
		),
		//
		// NOTE: Even there are some sign-offs already, if the file is modified or related sign off is modified, this attribute be false
		isPrepared: IsPrepared,
		isReviewed: IsReviewed,
		//
		lastModifiedBy: modifiedBy,
		lastModifiedAt: LastModifiedBy.Date ? LastModifiedBy.Date : "",
		//
		srcFormat,
		extension: convertExtension(Extension, srcFormat),
		srcId: MediaID ? idToString(MediaID, IdType.src) : "",
		size: FileSize ? formatSize(FileSize) : "-", // @Noah: need to check: Files which are imported from library has null size
		// NOTE: Server returns string value always, like 1288KB (=1.3Mb)
		path: ActualFilePath, // NOTE: What is different of FilePath and Actual File Path...
		url: url,
		//
		isOpen: IsOpen ? IsOpen : false,
		openedByName: OpenedBy ? OpenedBy : null,

		assignedRoles,
		index,
	}
	return reOrganizedFile
}

/** CreateWPR response
{
    AJE: false
    ActualFilePath: ""
    Archive: false
    ClientID: 5743
    Comment: 1
    CreationMode: 0
    Extension: ".xlsx"
    FilePath: "Documents/30303039_949_5743_28022021144909499.xlsx"
    FileSize: null
    Final: "Completed"
    FinancialYear: "0001-01-01T00:00:00"
    Followup: 1
    Index: 0
    IsLocked: true
    IsModified: false
    IsOpen: false
    IsPrepared: true
    IsQualityControl: false
    IsReviewed: true
    LastModified: null
    LastModifiedBy: {roleName: null, userName: null, Date: null}
    LockedBy: 0
    MediaID: 304489
    MediaName: null
    MediaType: "Excel"
    Message: "Workpaper is created successfully"
    OpenedBy: null
    OpenedByUser: null
    PAJE: false
    ParentMediaID: 303403
    PdfExists: false
    PdfPath: null
    PreparerList: {UserID: 949, SignOff: null,…}
    ReviewProcessID: 0
    ReviewerList: {UserID: 949, SignOff: null,…}
    RolesList: null
    SignOff: null
    Stage: "Notstarted"
    Status: 1
    TemplateID: 0
    UserID: 0
    WPDesc: "title----"
    WPEF: "30303039"
    WPRID: 184733
    file: null
    signOffType: null
    url: ""
    wprList: null
}
*/

/**
CheckWPRFldFiles > folder in response
{
    AJE: false
    ActualFilePath: null
    Archive: false
    ClientID: 0
    CreationMode: 0
    Extension: null
    FilePath: null
    FileSize: null
    FinancialYear: "0001-01-01T00:00:00"
    Index: 0
    IsLocked: false
    IsModified: false
    IsOpen: false
    IsQualityControl: false
    LastModified: null
    LastModifiedBy: null
    LockedBy: 0
    MediaID: 0
    MediaName: null
    MediaType: null
    Message: null
    OpenedBy: null
    OpenedByUser: null
    PAJE: false
    ParentMediaID: 303403
    PdfExists: false
    PdfPath: null
    ReviewProcessID: 0
    RolesList: null
    Status: 0
    TemplateID: 0
    UserID: 0
    WPDesc: "dummy folder"
    WPEF: null
    WPRID: 0
    file: null
    signOffType: null
    url: null
    wprList: null
}
 */

/**
  * * CreateWPR response
{
    AJE: false
    ActualFilePath: ""
    Archive: false
    ClientID: 5743
    Comment: 1
    CreationMode: 0
    Extension: ".xlsx"
    FilePath: "Documents/kkk_933_5743_01032021030331331.xlsx"
    FileSize: null
    Final: "Completed"
    FinancialYear: "0001-01-01T00:00:00"
    Followup: 1
    Index: 0
    IsLocked: false
    IsModified: false
    IsOpen: false
    IsPrepared: true
    IsQualityControl: false
    IsReviewed: false
    LastModified: null
    LastModifiedBy: {roleName: null, userName: null, Date: null}
    LockedBy: 0
    MediaID: 304494
    MediaName: null
    MediaType: "Excel"
    Message: "Workpaper is created successfully"
    OpenedBy: null
    OpenedByUser: null
    PAJE: false
    ParentMediaID: 303403
    PdfExists: false
    PdfPath: null
    PreparerList: {UserID: 933, SignOff: null,…}
    ReviewProcessID: 0
    ReviewerList: null
    RolesList: null
    SignOff: null
    Stage: "Notstarted"
    Status: 1
    TemplateID: 0
    UserID: 0
    WPDesc: "jjjjj"
    WPEF: "kkk"
    WPRID: 184738
    file: null
    signOffType: null
    url: ""
    wprList: null
}
  */
