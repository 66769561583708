import { flow } from "mobx-state-tree"
import { HardCopyMgmtApiProps } from "../hg-mgmt-hard-copy.apis"

const UpdateHardCopyStatus = (
	self: any,
	updateHardCopyStatus: HardCopyMgmtApiProps["apiUpdateHardCopyStatus"]
) => ({
	updateHardCopyStatus: flow(function* (data: any) {
		const actionName = "updateHardCopyStatus"
		try {
			const response = yield updateHardCopyStatus(data)
			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default UpdateHardCopyStatus
