import { flow } from "mobx-state-tree"

const ReadHardCopyList = (self: any, read: any) => ({
	readHardCopyList: flow(function* (orgId, year) {
		const actionName = "readHardCopyList"
		try {
			const response = yield read(orgId, year)

			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default ReadHardCopyList
