import React, { useEffect } from "react"
import History from "./History"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { useHcMgmtStore } from "../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"
import { HardCopyMgmtHistorySourceEnum } from "./store/data-models/history-model"
import { observer } from "mobx-react-lite"

const HistoryController = observer(() => {
	const orgStore = useOrgStore()
	const hcMgmtStore = useHcMgmtStore()
	const menuId = DLOrgSubMenus.hard_copy_history

	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	// 	const needRefresh = hcMgmtStore.hardCopy.needRefresh
	const permission = orgStore.checkin.getActionsAsObject(menuId)
	
	useEffect(() => {
		const orgId = orgStore.checkin.orgInfo.orgId.split("Id").join("")
		hcMgmtStore.history.getHcMgmtHistories({ orgId, source: HardCopyMgmtHistorySourceEnum.hardCopy })
	},[])

	return (
		<>
			{menuAccess ? (
				<History
					partialStore={hcMgmtStore.history}
					permission={permission}
					fetchingStatus={hcMgmtStore.history.getActionStatus('getHcMgmtHistories')}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

export default HistoryController
