import { RationaleType, Rationale } from "../data-models/rationales.data-models"
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

/**
*
* @param self
* @returns
*
* * i18n records
* - success, fail
*
*/

const GetRationales = (self: any) => ({
	getRationales() {
		// 0.
		const actionName = "getRationales"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		self.readRationaleList()
			.then((response: any) => {
				const dbFinalReportDate = response.data.FinalIssuanceDate
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					// 0) check final report date
					self.setFinalReportDate(dbFinalReportDate) // TODO: variable name need update
					self.setModifiedFilesCount(response.data.TotalModifiedCount)
					self.setRationalesCount(
						response.data.TotalRationaleFilesCount
					)

					// 1) reset the stored list
					self.resetList()
					// 2) Re-organize: this API returns Wp, Pf rationales individually. Let's merge them below...
					// 2-1) re-organize the Wp rationales
					const existingWpRationaleList =
						response.data.WorkpaperFilesList
					existingWpRationaleList.map((rationale: any) => {
						const reOrganizedwpRationale = {
							type: RationaleType.wp,
							podId: rationale.WorkpaperID.toString(), // [WorkpaperID] ex) 4505
							aliasId: rationale.Reference, // [Reference] ex) "sdf"
							description: rationale.Description, // [Description] ex) "sfsdfa"
							modifiedAt: rationale.ModifiedDate, // [ModifiedDate] ex) "2020-01-11T12:45:20.188312+00:00"
							modifiedBy: rationale.ModifiedBy, // [ModifiedBy] ex) "cskim"
							rationaleDescription:
								rationale.RationaleDescription, // [RationaleDescription] ex) ""
							rationaleAt: rationale.RationaleDate, // [RationaleDate] ex) null
							rationaleBy: rationale.RationaleBY, // [RationaleBY] ex) ""
							rationaleId: rationale.RationaleID.toString(), // [RationaleID] ex) 0
							isModifiedAfterRationale:
								rationale.IsModifiedAfterRationale, // [IsModifiedAfterRationale] ex) false
						}
						// push the item to the list
						self.pushItemToList(reOrganizedwpRationale)
					})
					// 2-2) re-organize the Pf rationales
					const existingPfRationaleList =
						response.data.PermanentFilesList
					existingPfRationaleList.map((rationale: any) => {
						const reOrganizedPfRationale = {
							type: RationaleType.pf,
							podId: rationale.WorkpaperID.toString(), // [WorkpaperID] ex) 4505
							aliasId: rationale.Reference, // [Reference] ex) "sdf"
							description: rationale.Description, // [Description] ex) "sfsdfa"
							modifiedAt: rationale.ModifiedDate, // [ModifiedDate] ex) "2020-01-11T12:45:20.188312+00:00"
							modifiedBy: rationale.ModifiedBy, // [ModifiedBy] ex) "cskim"
							rationaleDescription:
								rationale.RationaleDescription, // [RationaleDescription] ex) ""
							rationaleAt: rationale.RationaleDate, // [RationaleDate] ex) null
							rationaleBy: rationale.RationaleBY, // [RationaleBY] ex) ""
							rationaleId: rationale.RationaleID.toString(), // [RationaleID] ex) 0
							isModifiedAfterRationale:
								rationale.IsModifiedAfterRationale, // [IsModifiedAfterRationale] ex) false
						}
						// push the item to the list
						self.pushItemToList(reOrganizedPfRationale)
					})
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else if (dbFinalReportDate === null) {
					// TODO: variable name need update
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 999, // temp
						message: response.data.Message,
						customMessage: "Please fill out the final report date",
						color: MessageColorType.orange,
						open: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						message: response.data.Message,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetRationales
