import React from "react"
import Icon from "@mdi/react"
import {
	mdiPackageVariant,
	mdiPackageVariantClosed,
	mdiShieldPlusOutline,
	mdiShieldLockOutline,
	mdiShieldAlertOutline,
	mdiHelpCircle,
} from "@mdi/js"
import styled from "styled-components"
import { DLTooltip } from "@datalobby/components"
import { ProjectStatus } from "../../../common-models/enumerations/project-related-enums"

interface CommonIconProps {
	/** default size = 1 */
	size?: number | string
}

export const NormalProjectIcon = ({ size = 1 }: CommonIconProps) => {
	return (
		<StyledProjectIcon
			className="FR AC proj-icon normal"
			data-testid="normal-project-icon"
		>
			<DLTooltip title="Normal Project">
				<Icon path={mdiPackageVariant} size={size ? size : 1} />
			</DLTooltip>
		</StyledProjectIcon>
	)
}

export const NormalLockedProjectIcon = ({ size = 1 }: CommonIconProps) => {
	return (
		<StyledProjectIcon
			className="FR AC proj-icon normal locked"
			data-testid="normal-locked-project-icon"
		>
			<DLTooltip title="Locked Project">
				<Icon path={mdiPackageVariantClosed} size={size ? size : 1} />
			</DLTooltip>
		</StyledProjectIcon>
	)
}

export const ReplicaProjectIcon = ({ size = 1 }: CommonIconProps) => {
	return (
		<StyledProjectIcon
			className="FR AC proj-icon replica"
			data-testid="replica-project-icon"
		>
			<DLTooltip title="Replica Project">
				<Icon path={mdiShieldPlusOutline} size={size ? size : 1} />
			</DLTooltip>
		</StyledProjectIcon>
	)
}

export const ArchivedProjectIcon = ({ size = 1 }: CommonIconProps) => {
	return (
		<StyledProjectIcon
			className="FR AC proj-icon archived"
			data-testid="archived-project-icon"
		>
			<DLTooltip title="Archived Project">
				<Icon path={mdiShieldLockOutline} size={size ? size : 1} />
			</DLTooltip>
		</StyledProjectIcon>
	)
}

export const UnarchivedProjectIcon = ({ size = 1 }: CommonIconProps) => {
	return (
		<StyledProjectIcon
			className="FR AC proj-icon unarchived"
			data-testid="unarchived-project-icon"
		>
			<DLTooltip title="Unarchived Project">
				<Icon path={mdiShieldAlertOutline} size={size ? size : 1} />
			</DLTooltip>
		</StyledProjectIcon>
	)
}

export function getProjectIcon(
	projectStatus: ProjectStatus,
	size?: number | string
) {
	let iconSize = size ? size : 0.8

	if (projectStatus === ProjectStatus.normal) {
		return <NormalProjectIcon size={iconSize} />
	}
	if (projectStatus === ProjectStatus.normalLocked) {
		return <NormalLockedProjectIcon size={iconSize} />
	}
	if (projectStatus === ProjectStatus.replica) {
		return <ReplicaProjectIcon size={iconSize} />
	}
	if (projectStatus === ProjectStatus.archived) {
		return <ArchivedProjectIcon size={iconSize} />
	}
	if (projectStatus === ProjectStatus.unarchived) {
		return <UnarchivedProjectIcon size={iconSize} />
	}
	if (projectStatus === ProjectStatus.unknown) {
		return <Icon path={mdiHelpCircle} size={iconSize} />
	}
}

const StyledProjectIcon = styled.div`
	&.normal {
		svg {
			fill: ${(props) => props.theme.normalProject};
			color: ${(props) => props.theme.normalProject};
		}
	}
	&.normal.locked {
		svg {
			fill: ${(props) => props.theme.normalLockedProject};
			color: ${(props) => props.theme.normalLockedProject};
		}
	}
	&.replica {
		svg {
			fill: ${(props) => props.theme.replicaProject};
			color: ${(props) => props.theme.replicaProject};
		}
	}
	&.archived {
		svg {
			fill: ${(props) => props.theme.archivedProject};
			color: ${(props) => props.theme.archivedProject};
		}
	}
	&.unarchived {
		svg {
			fill: ${(props) => props.theme.unarchivedProject};
			color: ${(props) => props.theme.unarchivedProject};
		}
	}
`
