import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { time, timeEnd } from "console"

const GetHardCopyUsers = (self: any) => ({
	getHardCopyUsers() {
		// 0.
		const actionName = "getHardCopyUsers"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.request API call
		self.readHardCopyUsers()
			.then((response: any) => {
				console.log(response)
				// if (response.status === 200 && response.data.Status === 1) {
					self.setHardCopyUsers(response)
					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				// } else {
				// 	self.handleResponse({
				// 		actionName,
				// 		status: ActionStatus.fail,
				// 		code: 200, // temp
				// 		color: MessageColorType.orange,
				// 		open: true,
				// 		autoHide: true,
				// 	})
				// }
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetHardCopyUsers
