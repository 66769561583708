import React, { useEffect, useState, useCallback } from "react"
import { Link } from "react-router-dom"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLSingleSelect,
	DLIconButton,
	DLSpinnerCenterAligned,
	DLTreeListByTreeData,
	DLInputField,
	ConsoleLog,
} from "../../../components/basic-elements"
import { DLTooltip } from "@datalobby/components"
import Icon from "@mdi/react"
import { mdiChevronRight, mdiAutorenew, mdiMagnify, mdiLibrary } from "@mdi/js"
// WARNING:
import Drawer from "@material-ui/core/Drawer"
import styled from "styled-components"
import {
	ActionStatus,
	DLObjectFormat,
} from "../../../common-models/enumerations/common-enums"
import { InputAdornment } from "@material-ui/core"
import { FolderRow, FileRow } from "./sub-components"
import { LibHierarchy } from "./store/lib-in-proj.data-model"

export default observer(function LibraryDrawerInProject() {
	const store = useRootStore()
	const projStore = useProjStore()
	const actionName = "getLibrariesInProj"
	ConsoleLog(" ----- LibraryDrawer rendered ----- ")

	const orgId = localStorage.getItem("orgId")
	const open = projStore.lib.drawerOpen
	const setOpen = projStore.lib.setDrawerOpen

	useEffect(() => {
		if (orgId !== null && projStore.lib.flatList.length === 0) {
			// NOTE: Do not call if there are existing stored data because lib is not updated many time
			// NOTE: If the user want to get the latest list, should click the refresh button
			ConsoleLog("calling lib data from userEffect in lib drawer")
			open && projStore.lib.getLibrariesInProj(orgId)
		}
	}, [open])

	const [selectedType, setSelectedType] = useState<"all" | DLObjectFormat>(
		"all"
	)
	const handleSelectFileType = (event: any) => {
		setSelectedType(event.target.value)
	}

	const handleQuery = (e: any) => {
		ConsoleLog(["query", e.target.value])
		projStore.lib.setQuery(e.target.value)
	}

	const handleAddFileFromLib = useCallback(() => {
		ConsoleLog("clicked handleAddFilefromflib.....")
	}, [])

	const drawerPadding = store.ui.isPageToolbarOpen ? "9rem" : "6rem"
	const openStatus = projStore.lib.drawerOpenStatus
	ConsoleLog(["lib ------- selectedType", selectedType])
	const treeData = projStore.lib.treeByFileTypeAndQuery(
		selectedType,
		projStore.lib.viewQuery
	)
	ConsoleLog(["lib ------- treeData", treeData.length])

	// WARNING: NEED TO CUSTOMIZE THIS DRAWER !!!!
	return (
		<StyledLibraryDrawer
			paddingTop={drawerPadding}
			data-testid="lib-drawer-in-project"
		>
			<Drawer
				className="drawer-root"
				variant="persistent"
				anchor="right"
				open={openStatus}
			>
				<div className="drawer-container">
					<div className="drawer-header FR AC JSB">
						<div className="FR AC">
							<DLIconButton
								variant="iconOnly"
								size="large"
								align="left"
								clickHandler={() => setOpen(false)}
								eleTestId="library drawer close button"
							>
								<DLTooltip title="Close the drawer">
									<Icon path={mdiChevronRight} size={1} />
								</DLTooltip>
							</DLIconButton>
							File Library
							<DLIconButton
								eleTestId="file-lib-refresh-btn"
								tooltipText="Refresh"
								clickHandler={() => {
									orgId &&
										projStore.lib.refreshInDrawer(orgId)
								}}
							>
								<Icon path={mdiAutorenew} size={0.7} />
							</DLIconButton>
						</div>
						<Link to="/organization/templates-and-library/library">
							<DLIconButton eleTestId="move-to-library-main-page">
								<DLTooltip title="Move to Organization File Library">
									<Icon path={mdiLibrary} size={0.8} />
								</DLTooltip>
							</DLIconButton>
						</Link>
					</div>
					<div className="drawer-body">
						<div className="lib-file-type-select">
							<DLSingleSelect
								eleTestId="lib-file-type-selection"
								placeholder="Select file type"
								eleOnChange={handleSelectFileType}
								eleValue={selectedType}
								eleFullWidth
								optionList={[
									{
										name: "All",
										value: "all",
									},
									{
										name: "Audit Program Files Only",
										value: DLObjectFormat.customFile, // TODO: @Noah --> Must specify as APG (DLSrcFormat)
									},
									{
										name: "Normal Files Only",
										value: DLObjectFormat.normalFile,
									},
								]}
							/>
						</div>
						<div className="lib-searchbar">
							<DLInputField
								eleType="search"
								eleClassName="search-field"
								elePlaceholder="Search library"
								eleTestId="tree-search-input-field"
								eleValue={projStore.lib.viewQuery}
								eleHandleChange={handleQuery}
								eleStartAdornment={
									<InputAdornment position="start">
										<Icon path={mdiMagnify} size={0.8} />
									</InputAdornment>
								}
							/>
						</div>
						<div className="lib-count">
							Total Library:{" "}
							<span data-testid="total-lib-count">
								{treeData.length}
							</span>
						</div>
						<div className="lib-contents">
							{projStore.lib.getActionStatus(actionName) ===
								ActionStatus.loading && (
								<DLSpinnerCenterAligned />
							)}
							{projStore.lib.getActionStatus(actionName) ===
								ActionStatus.success && (
								<DLTreeListByTreeData
									treeData={treeData}
									eleRenderNode={LibDrawerInProjRowRenderer}
									canDrag={false}
									eleWidth={280}
									eleHeight={450}
									indentWidth={20}
									rowHeight="medium"
									sortBy="title"
									externalNodeType="dlFileNode"
								/>
							)}
						</div>
					</div>
				</div>
			</Drawer>
		</StyledLibraryDrawer>
	)
})

const LibDrawerInProjRowRenderer = ({
	node,
	handleExpandFolder,
}: {
	node: any
	handleExpandFolder: (props: any) => void
}) => {
	return (
		<>
			{node.hierarchy === LibHierarchy.file ? (
				<FileRow node={node} />
			) : (
				<FolderRow node={node} />
			)}
		</>
	)
}

interface StyledLibraryDrawerProps {
	paddingTop: any
}

const StyledLibraryDrawer = styled.div<StyledLibraryDrawerProps>`
	transition: 0.2s;
	.drawer-root > div {
		box-shadow: -2px 10px 10px 1px ${(props) => props.theme.shadowColorDeep};
		background-color: ${(props) => props.theme.secondaryDeep};
		border-left: none;
		margin-top: ${(props) => props.paddingTop};
		border-top-left-radius: 4px;
		.drawer-container {
			width: 22rem;
			height: 100%;
			.drawer-header {
				padding-right: 1rem;
			}
			.drawer-body {
				padding: 0 2rem;
				.lib-select {
				}
				.lib-file-type-select {
					margin-bottom: 0.4rem;
				}
				.lib-searchbar {
					margin-bottom: 0.4rem;
				}
				.lib-count {
					margin-bottom: 0.4rem;
				}
				.lib-contents {
				}
			}
		}
	}
`
