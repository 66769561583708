import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog } from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { mdiLogout } from "@mdi/js"
import { Icon } from "@mdi/react"
import { useRootStore } from "../../../../../../stores/root-store/root-store.provider"
import RentHardCopyDialogContent from "./rent-hard-copy-dialog/RentHardCopyDialogContent"
import { useHcMgmtStore } from "../../../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"

export type RentInputsType = {
    rentedBy: { id: string; alias: string; name: string }
    description?: string
    dueDate: string
    files: any[]
}

const initialInputs = {
    rentedBy: { id: "", alias: "", name: "" },
    description: "",
    dueDate: "",
    files: [],
}

export default observer(function RentHardCopyDialog({
    i18n,
    partialStore,
    rentalStore,
}: {
    i18n: DLI18nProps
    partialStore: any
    rentalStore: any
}) {
    const store = useRootStore()
    const orgStore = useOrgStore()
    const hcMgmtStore = useHcMgmtStore()
    const configStore = hcMgmtStore.config
    const archiveMgmtStore = orgStore.archiveMgmt

    useEffect(() => {
        archiveMgmtStore.getFilteredUsers("internal")
    }, [])

    const [inputs, setInputs] = useState<RentInputsType>(initialInputs)

    const { id, pk, hasSoftCopy, title } = partialStore.viewSelectedItem()

    const handleAction = () => {
        const customerId = process.env.REACT_APP_CUSTOMER_ID
        const orgId = orgStore.checkin.orgInfo.orgId.split("Id").join("")
        const userInfo = {
            id: orgStore.checkin.orgInfo.userId.split("Id").join(""),
            alias: orgStore.checkin.orgInfo.userAliasId,
            name: orgStore.checkin.orgInfo.userName,
        }

        const params = {
            customerId,
            orgId,
            hardCopyPk: pk,
            hardCopyId: id,
            hardCopy: {
                pk,
                id,
                hasSoftCopy,
                title,
            },
            rentedBy: inputs.rentedBy,
            createdBy: userInfo,
            dueDate: inputs.dueDate,
            files: inputs.files,
        }

        const postAction = (data: any) =>
            partialStore.reflectRentalToHardCopy(data)

        if (inputs.files.length <= 0) {
            let proceed = window.confirm(
                "Do you want to proceed without any attachements?"
            )
            if (!proceed) {
                return
            } else {
                rentalStore.rentHardCopy({ params, postAction })
            }
        } else {
            /**
             * 1. get signed url
             * 2. upload the file
             * 3. proceed rent
             */
            rentalStore.getSignedUrl({ params, postAction })
        }
    }

    const dntFormat = store.global.getDntFormat
    return (
        <>
            <DLDialog
                eleTestId="rent-hard-copy-dialog"
                isOpen={rentalStore.openRentHardCopyDialog}
                setIsOpen={rentalStore.setOpenRentHardCopyDialog}
                showCloseBtn={true}
                dialogTitle={
                    <div className="FR AC">
                        <Icon
                            path={mdiLogout}
                            size={1}
                            style={{ marginRight: 8 }}
                        />
                        Rent Hard Copy
                    </div>
                }
                dialogContents={
                    <RentHardCopyDialogContent
                        inputs={inputs}
                        setInputs={setInputs}
                        userOptions={archiveMgmtStore.viewSearchAccessMgmtUserList()}
                        dateFormat={dntFormat.dateFormat}
                        hardCopyDetails={partialStore
                            .viewHardCopyList(dntFormat)
                            .find(
                                (item: any) =>
                                    item.id === partialStore.selectedId
                            )}
                        customColumns={configStore.viewCustomColumns}
                    />
                }
                actionReady={true}
                handleAction={handleAction}
                maxWidth="md"
                actionBtn="Rent"
                cancelBtnText={i18n.twCancel || "Cancel"}
                // showSpinner={
                // 	archiveMgmtStore.getActionStatus("getUsers") !== "SUCCESS"
                // }
                // cannotUpdate={
                // 	archiveMgmtStore.getActionStatus("getUsers") !== "SUCCESS"
                // }
            />
        </>
    )
})
