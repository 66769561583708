import React, { useEffect, useState } from "react"
import styled from "styled-components"

export const DoesHaveProjectContent = ({
	handleHaveProject,
}: {
	handleHaveProject: any
}) => {
	return (
		<div className="FC JC AC">
			Do you have a soft copy in Audit Lobby for the hard copy?
			<div className="FR">
				<StyledCard
					className="FR JC AC disabled"
					onClick={() => handleHaveProject("Yes")}
				>
					<p>
						Yes, I have.
						<br />I want to search the project.
					</p>
				</StyledCard>
				<StyledCard
					className="FR JC AC disabled"
					onClick={() => handleHaveProject("No")}
				>
					<p>
						No, I don't.
						<br />I want to submit a project.
					</p>
				</StyledCard>
			</div>
		</div>
	)
}

const StyledCard = styled.div`
	width: 220px;
	height: 150px;
	box-shadow: 0px 2px 6px 1px rgba(192, 203, 220, 0.3);
	border: 1px solid ${(props) => props.theme.shade10};
	border-radius: 4px;
	margin: 1rem;
	overflow: hidden;
	cursor: pointer;
	transition: 0.4s;
	padding: 2rem;
	text-align: center;
	:hover {
		/* box-shadow: 0px 2px 8px 1px rgba(192, 203, 220, 0.6); */
		box-shadow: 0px 2px 8px 1px ${(props) => props.theme.primaryLight};
		border: 1px solid ${(props) => props.theme.primaryLightDeep};
	}
	&.disabled {
		opacity: 0.3;
		pointer-events: none;
	}
`
