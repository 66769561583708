import { ConsoleLog } from "../../../../../../components/basic-elements"
import { HardCopyStatusEnum } from "../data-models/hard-copy-list.data-models"

const ReflectHardCopyUpdateActions = (self: {
	hardCopyList: any
	[x: string]: any
}) => ({
	reflectHardCopyCreation(newHardCopy: any) {
		console.log("reflectHardCopyCreation / newHardCopy", newHardCopy)
		self.hardCopyList.push(newHardCopy)
	},
	reflectHardCopyStatusUpdate({
		id,
		status,
	}: {
		id: string
		status: HardCopyStatusEnum
	}) {
		const target = self.hardCopyList.find((item: any) => item.id === id)
		console.log(status, "rentalAvailable")
		if (target) {
			target.status = status
			if (status !== HardCopyStatusEnum.available) {
				target.rentalAvailable = false
			} else {
				target.rentalAvailable = true
			}
		} else {
			ConsoleLog("(reflectHardCopyStatusUpdate) cannot find the target")
		}
	},
	reflectHcWithoutScDetailUpdate({
		id,
		title,
		description,
		year,
		customColumns,
	}: {
		id: string
		title: string
		description?: string
		year: number
		customColumns: any
	}) {
		const target = self.hardCopyList.find((item: any) => item.id === id)
		if (target) {
			target.title = title
			target.description = description
			target.year = year
			target.customColumns = customColumns
		} else {
			ConsoleLog(
				"(reflectHcWithoutScDetailUpdate) cannot find the target"
			)
		}
	},
	reflectMoveToTrashHardCopy(id: string) {
		const targetIndex = self.hardCopyList.findIndex(
			(item: any) => item.id === id
		)
		if (targetIndex !== -1) {
			self.hardCopyList.splice(targetIndex, 1)
		} else {
			ConsoleLog("(reflectMoveToTrashHardCopy) cannot find the target")
		}
	},
	reflectHardCopyRent({ id, rentalInfo }: { id: string; rentalInfo: any }) {
		const target = self.hardCopyList.find((item: any) => item.id === id)
		if (target) {
			// TODO:
		} else {
			ConsoleLog("(reflectHardCopyRent) cannot find the target")
		}
	},
	reflectHardCopyRentalExtend({
		id,
		extensionInfo,
	}: {
		id: string
		extensionInfo: any
	}) {
		const target = self.hardCopyList.find((item: any) => item.id === id)
		if (target) {
			// TODO:
		} else {
			ConsoleLog("(reflectHardCopyRentalExtend) cannot find the target")
		}
	},
	reflectHardCopyReturn({ id, returnInfo }: { id: string; returnInfo: any }) {
		const target = self.hardCopyList.find((item: any) => item.id === id)
		if (target) {
			// TODO:
		} else {
			ConsoleLog("(reflectHardCopyReturn) cannot find the target")
		}
	},
})

export default ReflectHardCopyUpdateActions
