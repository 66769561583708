import React from "react"
import { PageContainer } from "../../../components/app-frame-elements"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import { DLButton } from "../../../components/basic-elements"
import PBCActionBtns from "./sub-components/PBCActionBtns"
import PBCTableColumns, {
	filterComponent,
} from "./sub-components/PBCTableColumns"
import { DLI18nProps } from "../../../common-models/types/common-props"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import styled from "styled-components"
import ReactTableV8 from "../../../components/basic-elements/tables/DLReactTable"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

export default observer(function PreparedByClient({
	partialStore,
	contentsHeight,
	fetchingStatus,
	i18n,
}: {
	partialStore: any
	contentsHeight: any
	fetchingStatus: ActionStatus
	i18n: DLI18nProps
}) {
	const store = useRootStore()

	const dateFormat = store.global.dateFormat.value

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			isLoading={fetchingStatus === "LOADING"}
		>
			<StyledPBC>
				<PBCActionBtns partialStore={partialStore} />
				<div className="table-container">
					<ReactTableV8
						tableColumns={PBCTableColumns(partialStore, i18n)}
						customFilters={(props: any) =>
							filterComponent({ ...props, dateFormat })
						}
						data={[]}
						menuId={DLProjSubMenus.pbc}
						showPageSetting={false}
						hasPagination={false}
						height={contentsHeight - 30}
					/>
				</div>
				<div className="FR JR">
					<DLButton
						eleTestId="preview-btn"
						// clickHandler={() =>}
					>
						Preview Changes
					</DLButton>
					<DLButton
						eleTestId="save-btn"
						// clickHandler={() =>}
					>
						Save Changes
					</DLButton>
					<DLButton
						eleTestId="cancel-btn"
						// clickHandler={() =>}
					>
						Cancel Changes
					</DLButton>
				</div>
			</StyledPBC>
		</PageContainer>
	)
})

const StyledPBC = styled.div`
	.table-container {
		margin-top: 1rem;
	}
	.rt-th {
		border-rigth: 1px solid !important;
	}
	table > thead > tr:first-child {
		border: 1px solid black !important;
		th {
			padding: 0;
			.header {
				min-height: 2rem;
				padding-left: 8px;
				border-left: 1px solid #c2c4c6;
				border-right: 1px solid #c2c4c6;
				justify-content: center;
				div {
					justify-content: center;
				}
			}
		}
	}
`
