import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	DLButton,
	DLIconButton,
	DLInputField,
	DLTextField,
} from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import styled from "styled-components"
import { mdiClose, mdiPlusCircle, mdiPencil, mdiDelete } from "@mdi/js"
import { Icon } from "@mdi/react"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { useHcMgmtStore } from "../../../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"
import ReactTableV8 from "../../../../../../components/basic-elements/tables/DLReactTable"
import CreateUpdateCustomColumn from "./CreateUpdateCustomColumn"
import { PageContainer } from "../../../../../../components/app-frame-elements"

const CustomColumnSettingTable = observer(() => {
		const orgStore = useOrgStore()
		const hcMgmtStore = useHcMgmtStore()
		const configStore = hcMgmtStore.config
	
		const [open, setOpen] = useState(false)
		const [dialogType, setDialogType] = useState<"Create" | "Update">(
			"Create"
		)
		const [selectedId, setSelectedId] = useState(null)

		const handleDelete = (rowInfo: any) => {
			const { pk, id } = rowInfo
			let proceed = window.confirm(
				"Do you really want this custom column to move to trash?"
			)
			if (!proceed) return
			const payload = {
				pk,
				id,
				by: userInfo,
			}
			configStore.removeCustomColumn(payload)
		}

		const handleEdit = (rowInfo: any) => {
			const { id } = rowInfo
			setDialogType("Update")
			setOpen(true)
			setSelectedId(id)
		}

		const handleAdd = () => {
			setDialogType("Create")
			setOpen(true)
			setSelectedId(null)
		}
		
		const userInfo = {
			id: orgStore.checkin.orgInfo.userId.split("Id").join(""),
			alias: orgStore.checkin.orgInfo.userAliasId,
			name: orgStore.checkin.orgInfo.userName,
		}
		
		useEffect(() => {
			const orgId = orgStore.checkin.orgInfo.orgId.split("Id").join("")
			hcMgmtStore.config.getCustomColumns(orgId)
		}, [])

		return (
			<PageContainer
				pageTools={<div />}
				hasToolbar={false}
				fullWidth
				fixedHeight
				isLoading={hcMgmtStore.config.getActionStatus('getCustomColumns') === "LOADING"}
			>
				<ReactTableV8
					tableColumns={[
						{
							header: "Label",
							accessorKey: "label",
						},
						{
							header: "Description",
							accessorKey: "description",
						},
						{
							header: "Help Text",
							accessorKey: "helpText",
						},
						{
							header: "Moved to Trash",
							accessorKey: "movedToTrash",
						},
						{
							header: "Actions",
							cell: (cellInfo: any) => (
								<div className="FR JSA">
									<DLIconButton
										eleTestId="edit-custom-column"
										tooltipText="Edit"
										clickHandler={() =>
											handleEdit(cellInfo.row.original)
										}
									>
										<Icon path={mdiPencil} size={0.8} />
									</DLIconButton>
									<DLIconButton
										eleTestId="delete-custom-column"
										tooltipText="Move to trash"
										clickHandler={() =>
											handleDelete(cellInfo.row.original)
										}
									>
										<Icon path={mdiDelete} size={0.75} />
									</DLIconButton>
								</div>
							),
						},
					]}
					data={configStore.viewCustomColumnsList()}
					showPageSetting={false}
					pageHeader={() => (
						<DLIconButton
							eleTestId="add-custom-column"
							size="medium"
							color="primary"
							clickHandler={handleAdd}
						>
							<Icon path={mdiPlusCircle} size={1} />
						</DLIconButton>
					)}
					menuId="CustomColumnDialog"
				/>
				{open && (
					<CreateUpdateCustomColumn
						open={open}
						setOpen={setOpen}
						configStore={configStore}
						dialogType={dialogType}
						selectedId={selectedId}
					/>
				)}
			</PageContainer>
		)
	}
)

export default CustomColumnSettingTable

const StyledContent = styled.div`
	.row {
		margin-bottom: 8px;
		.MuiOutlinedInput-input {
			margin-top: 0px;
			min-width: 26rem;
			padding: 8px;
		}
	}
	.add-column {
		margin-top: 8px;
	}
`
