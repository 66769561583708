import { DLCommentType } from "../../../common-models/enumerations/common-enums"
import { ProgressStatus } from "../../../common-models/enumerations/common-enums"
import { ItemListForSortProps } from "./file-folder/reorder-children"
import { OldSignOffType } from "../data-models/sign-off.data-model"
import { RoleModelProps } from "../data-models/file.data-model"
import { findProjRoleInfo } from "../../../temporary-data/project-side/find-proj-role"
import { ReflectReplaceFileProps } from "./file/replace-file"
import { CommentStatus } from "../../../screens/project-side/communications/proj-comments/store/data-models/comments.data-model"
import { ConsoleLog } from "../../../components/basic-elements"
import { getSnapshot } from "mobx-state-tree"
import {
	changeAllNextSignOffsToRed,
	changeSignOffsToRed,
	changeCurrLevelSignOffsToBlack,
	changeSingleSignOffToBlack,
	pushSignOffIfNotExisted,
	removeSignOffFromList,
} from "../../../library/file-utils/sign-off-actions"
import { sortFoldersNFiles } from "../../../library/file-utils/file-utils"

const FlatListActions = (self: any) => ({
	setStructureLock(lockStatus: boolean) {
		self.structureLock = lockStatus
	},
	setTotalFileCount(count: string) {
		self.totalFileCount = count
	},
	// flat list control
	pushFlatItem(item: any) {
		const prevItemIndex = self.flatList.findIndex(
			(prevItem: any) => prevItem.id === item.id
		)
		if (prevItemIndex !== -1) {
			// NOTE: without splice, views cannot catch the sign off list update
			self.flatList.splice(prevItemIndex, 1)
			self.flatList.push(item)
		} else {
			self.flatList.push(item)
		}
		self.flatList = sortFoldersNFiles(self.flatList)
	},
	updateFlatList(newList: any) {
		newList.map((item: any) => {
			const prevItemIndex = self.flatList.findIndex(
				(prevItem: any) => prevItem.id === item.id
			)
			if (item.isParent) {
				if (prevItemIndex !== -1) {
					const prevItem = self.flatList.find(
						(prevItem: any) => prevItem.id === item.id
					)
					self.flatList.splice(prevItemIndex, 1)
					self.flatList.push({ ...item, expanded: prevItem.expanded })
				} else {
					self.flatList.push(item)
				}
			} else {
				if (prevItemIndex !== -1) {
					self.flatList.splice(prevItemIndex, 1)
					self.flatList.push(item)
				} else {
					self.flatList.push(item)
				}
			}
		})
		// self.flatList = sortFoldersNFiles(self.flatList)
	},
	resetPartialFlatList(parentId: string) {
		const targets = self.flatList.filter(
			(item: any) => item.parentId === parentId
		)
		targets.map((target: any) => {
			const index = self.flatList.findIndex(
				(item: any) => item.id === target.id
			)
			if (index !== -1) {
				self.flatList.splice(index, 1)
			}
		})
	},
	resetFlatList() {
		self.flatList.length = 0
	},
	setFlatList(newList: any) {
		self.flatList = newList
	},
	setSearchResults(results: any) {
		self.searchResults.length = 0
		console.log("for setSearchResults:", results)
		self.searchResults = results
	},
	resetSearchResults() {
		self.searchResults.length = 0
	},
	toggleExpanded(itemId: string, expanded?: boolean) {
		const target = self.flatList.find((item: any) => item.id === itemId)
		if (target) {
			if (expanded !== undefined) {
				target.expanded = expanded
			} else {
				target.expanded = !target.expanded
			}
		}
	},
	expandAll(expand: boolean) {
		self.flatList.map((item: any) => (item.expanded = expand))
	},
	//
	//
	// direct reflection
	reflectFileCheckin(fileId: string) {
		const target = self.flatList.find((item: any) => item.id === fileId)
		if (target) {
			target.isOpen = false
			target.openedByName = null
		} else {
			ConsoleLog("(reflectFileCheckin) cannot find target: " + fileId)
		}
	},
	reflectFileCheckout(fileId: string) {
		const target = self.flatList.find((item: any) => item.id === fileId)
		if (target) {
			target.isOpen = true
			target.openedByName = "me" // TODO: update is required
		} else {
			ConsoleLog("(reflectFileCheckout) cannot find target: " + fileId)
		}
	},
	reflectRemoveItem(itemId: string) {
		const targetIndex = self.flatList.findIndex(
			(item: any) => item.id === itemId
		)
		if (targetIndex !== -1) {
			self.flatList.splice(targetIndex, 1)
		} else {
			console.error("(reflectRemoveItem) No matched Item")
		}
	},
	reflectFolderName(folderId: string, folderName: string) {
		self.flatList.map((item: any) => {
			if (item.id === folderId) {
				item.title = folderName
			}
		})
		self.flatList = sortFoldersNFiles(self.flatList)
	},
	reflectFileInfoUpdate({
		fileId,
		aliasId,
		title,
	}: {
		fileId: string
		aliasId: string
		title: string
	}) {
		const target = self.flatList.find((item: any) => item.id === fileId)
		if (target) {
			// let newItem = { ...target }
			// newItem.aliasId = aliasId
			// newItem.title = title
			// ConsoleLog("newItem", newItem)
			// self.pushFlatItem(newItem)
			target.aliasId = aliasId
			target.title = title
			self.flatList = sortFoldersNFiles(self.flatList)
		} else {
			alert(
				`(reflectFileInfoUpdate) No matched target. ${fileId} | ${aliasId} | ${title}`
			)
		}
	},
	reflectFileReplace({
		fileId,
		size,
		srcFormat,
		prepared,
		reviewed,
		signOff,
		url,
		extension,
	}: ReflectReplaceFileProps) {
		const target = self.flatList.find((item: any) => item.id === fileId)
		// reflect file info
		if (target) {
			target.size = size
			target.srcFormat = srcFormat
			target.url = url !== "" ? url : null
			target.extension = extension
		} else {
			alert(
				`(reflectFileReplace) No matched target. ${fileId} | ${size} | ${srcFormat}`
			)
		}
		// reflect signoff info
		if (signOff) {
			let newSignoff = {
				userId: "blah",
				userName: signOff.userName,
				//
				roleShortName: signOff.roleName,
				signOffType: "",
				//
				date: signOff.Date,
				signedAt: signOff.Date,
				//
				signOffModified: false,
				srcModified: false,
			}
			if (prepared) {
				const signoff1 = { ...newSignoff }
				signoff1["signOffType"] = OldSignOffType.prepare
				target.prepareList.push(signoff1)
				// NOTE:  WARNING: @Noah-signoff
				target.isPrepared = false
				// ConsoleLog("reflect prepare----", signoff1)
			}
			if (reviewed) {
				const signoff2 = { ...newSignoff }
				signoff2["signOffType"] = OldSignOffType.review
				target.reviewList.push(signoff2)
				// NOTE:  WARNING: @Noah-signoff
				target.isReviewed = false
				if (signOff.roleName === "EP") {
					target.epReviewList.push(signoff2)
				}
				if (signOff.roleName === "CP") {
					target.cpReviewList.push(signoff2)
				}
				if (signOff.roleName === "QC") {
					target.qcReviewList.push(signoff2)
				}
				// ConsoleLog("reflect review----", signoff2)
			}
		}
	},
	reflectSignOff({
		userId,
		fileId,
		type,
		signOff,
	}: {
		userId: string
		fileId: string
		type: OldSignOffType
		signOff: {
			roleName: string
			userName: string
			at: string
		}
	}) {
		// ConsoleLog("reflectSignOff", userId, fileId, type, signOff)

		const targetFile = self.flatList.find((item: any) => item.id === fileId)
		// ConsoleLog("targetFile", targetFile)
		const newSignOff = {
			userId,
			userName: signOff.userName,
			//
			roleShortName: signOff.roleName,
			signOffType: type,
			// roleName: signOff.roleName,
			//
			date: signOff.at,
			signedAt: signOff.at,
			//
			signOffModified: false,
			srcModified: false,
		}
		//
		/**
		 *
		 * * 1. when the sign off type is prepare
		 * * 2. when the sign off type is review
		 * -- 2-1. roleName is EP
		 * -- 2-2. roleName is CP
		 * -- 2-3. roleName is QC
		 *
		 */
		if (type === OldSignOffType.prepare) {
			targetFile.isPrepared = true
			if (targetFile.prepareList.length === 0) {
				targetFile.prepareList.push(newSignOff)
			} else {
				changeCurrLevelSignOffsToBlack("PREPARE", targetFile)
				pushSignOffIfNotExisted(
					"PREPARE",
					targetFile.prepareList,
					newSignOff
				)
			}
			changeAllNextSignOffsToRed(targetFile)
		} else if (type === OldSignOffType.review) {
			targetFile.isReviewed = true
			// if targetFile.reviewList.length === 0, push the sign off
			if (targetFile.reviewList.length === 0) {
				targetFile.reviewList.push(newSignOff)
			}
			// if targetFile.reviewList.length > 0
			if (targetFile.reviewList.length > 0) {
				// check if the user already reviewed
				pushSignOffIfNotExisted(
					"REVIEW",
					targetFile.reviewList,
					newSignOff
				)
				targetFile.reviewList = targetFile.reviewList.map(
					(review: any) => {
						switch (signOff.roleName) {
							// if logged-in user is EP, change CP & QC Review to red, and others to false
							case "EP":
								switch (review.roleShortName) {
									case "CP":
									case "QC":
										review.signOffModified = true
										return review
									default:
										return changeSingleSignOffToBlack(
											review
										)
								}
							// if logged-in user is CP, change QC Review to red, EP to as is, CP to false, and others to false
							case "CP":
								switch (review.roleShortName) {
									case "EP":
										return review
									case "CP":
										review.signOffModified = false
										review.srcModified = false
										return review
									case "QC":
										review.signOffModified = true
										return review
									default:
										return changeSingleSignOffToBlack(
											review
										)
								}
							// if logged-in user is QC, change QC to false, EP & CP to as is, and others to false
							case "QC":
								switch (review.roleShortName) {
									case "QC":
										review.signOffModified = false
										review.srcModified = false
										return review
									case "EP":
									case "CP":
										return review
									default:
										return changeSingleSignOffToBlack(
											review
										)
								}
							// if logged-in user is others (JS/ SS/ M/ D/ P), change EP, CP, QC to red, and others to false
							default:
								switch (review.roleShortName) {
									case "EP":
									case "CP":
									case "QC":
										review.signOffModified = true
										changeSignOffsToRed("EP", targetFile)
										changeSignOffsToRed("CP", targetFile)
										changeSignOffsToRed("QC", targetFile)
										return review
									default:
										return changeSingleSignOffToBlack(
											review
										)
								}
						}
					}
				)
			}
			// ConsoleLog("push sign off as EP review")
			if (signOff.roleName === "EP") {
				if (targetFile.epReviewList.length === 0) {
					targetFile.epReviewList.push(newSignOff)
				} else if (targetFile.epReviewList.length > 0) {
					changeCurrLevelSignOffsToBlack("EP", targetFile)
					pushSignOffIfNotExisted(
						"EP",
						targetFile.epReviewList,
						newSignOff
					)
				}
				changeSignOffsToRed("CP", targetFile)
				changeSignOffsToRed("QC", targetFile)
				ConsoleLog(getSnapshot(targetFile.epReviewList))
			}

			if (signOff.roleName === "CP") {
				// ConsoleLog("push sign off as CP review")
				if (targetFile.cpReviewList.length === 0) {
					targetFile.cpReviewList.push(newSignOff)
				} else if (targetFile.cpReviewList.length > 0) {
					changeCurrLevelSignOffsToBlack("CP", targetFile)
					pushSignOffIfNotExisted(
						"CP",
						targetFile.cpReviewList,
						newSignOff
					)
				}
				changeSignOffsToRed("QC", targetFile)
				ConsoleLog(targetFile.cpReviewList)
			}
			if (signOff.roleName === "QC") {
				// ConsoleLog("push sign off as QC review")
				if (targetFile.qcReviewList.length === 0) {
					targetFile.qcReviewList.push(newSignOff)
				} else if (targetFile.qcReviewList.length > 0) {
					changeCurrLevelSignOffsToBlack("QC", targetFile)
					pushSignOffIfNotExisted(
						"QC",
						targetFile.qcReviewList,
						newSignOff
					)
				}
				ConsoleLog(targetFile.qcReviewList)
			}
		}
	},
	reflectUnSignOff({
		fileId,
		type,
		userId,
		roleId,
	}: // userRole
	{
		fileId: string
		type: OldSignOffType
		userId: string
		roleId: string
	}) {
		const targetFile = self.flatList.find((item: any) => item.id === fileId)
		/**
		 *
		 * * 1. when the sign off type is prepare
		 * * 2. when the sign off type is review
		 * -- 2-1. roleName is EP
		 * -- 2-2. roleName is CP
		 * -- 2-3. roleName is QC
		 *
		 */
		if (type === OldSignOffType.prepare) {
			targetFile.isPrepared = false
			removeSignOffFromList("PREPARE", targetFile.prepareList, userId)
			changeAllNextSignOffsToRed(targetFile)
		}
		// **** UN-REVIEW
		else if (type === OldSignOffType.review) {
			targetFile.isReviewed = false
			const targetSignOffIndex = targetFile.reviewList.findIndex(
				(item: any) => item.userId === userId
			)
			targetFile.reviewList.splice(targetSignOffIndex, 1)
			// Update signOffModified=true for next level sign-offs
			targetFile.reviewList = targetFile.reviewList.map((review: any) => {
				// roleId === "id_ep"
				switch (roleId) {
					case "id_ep":
						if (
							review.roleShortName === "CP" ||
							review.roleShortName === "QC"
						) {
							review.signOffModified = true
						}
						return review
					case "id_cp":
						if (review.roleShortName === "QC") {
							review.signOffModified = true
						}
						return review
					case "id_qc":
						return review
					default:
						if (
							review.roleShortName === "EP" ||
							review.roleShortName === "CP" ||
							review.roleShortName === "QC"
						) {
							review.signOffModified = true
							changeSignOffsToRed("EP", targetFile)
							changeSignOffsToRed("CP", targetFile)
							changeSignOffsToRed("QC", targetFile)
						}
						return review
				}
			})
			if (roleId === "id_ep") {
				removeSignOffFromList("EP", targetFile.epReviewList, userId)
				changeSignOffsToRed("CP", targetFile)
				changeSignOffsToRed("QC", targetFile)
			}
			if (roleId === "id_cp") {
				removeSignOffFromList("CP", targetFile.cpReviewList, userId)
				changeSignOffsToRed("QC", targetFile)
			}
			if (roleId === "id_qc") {
				removeSignOffFromList("QC", targetFile.qcReviewList, userId)
			}
		}
	},
	reflectAssign(fileId: string, roleId: string, type: "assign" | "unassign") {
		const target = self.flatList.find((item: any) => item.id === fileId)
		if (target === undefined) {
			console.error(
				`(reflectAssign) Cannot find target file. fileId: ${fileId} | roleId: ${roleId}`
			)
		} else {
			const prevRoles = target.assignedRoles
			ConsoleLog(["target", target])
			ConsoleLog(["prevRoles", prevRoles])
			if (prevRoles === undefined) {
				target.assignedRoles = []
			}
			if (type === "assign") {
				const duplicateCheck = prevRoles.findIndex(
					(item: RoleModelProps) => item.id === roleId
				)
				if (duplicateCheck !== -1) {
					return
				} else {
					const newRoleInfo = findProjRoleInfo(roleId)
					if (newRoleInfo !== undefined) {
						prevRoles.push({
							id: roleId,
							name: newRoleInfo.name,
							shortName: newRoleInfo.shortName,
							color: newRoleInfo.color,
						})
					} else {
						prevRoles.push({
							id: roleId,
							name: roleId,
							shortName: "(?)",
							color: "lightgray",
						})
					}
				}
			} else {
				const targetIndex = prevRoles.findIndex(
					(item: RoleModelProps) => item.id === roleId
				)
				prevRoles.splice(targetIndex, 1)
			}
		}
	},
	reflectStatus(fileId: string, status: ProgressStatus) {
		const target = self.flatList.find((item: any) => item.id === fileId)
		target.status = status
	},
	reflectReorder(folderId: string, fileList: ItemListForSortProps) {
		let fileIdList: string[] = []
		fileList.map((file: any) => fileIdList.push(file.id))
		self.flatList.map((item: any) => {
			if (fileIdList.includes(item.id)) {
				const newIndex = fileList.findIndex(
					(file: any) => file.id === item.id
				)
				item.parentId = folderId

				item.index = newIndex
			}
		})
	},
	reflectCommentAdd(fileId: string, commentType: string) {
		const targetFile = self.flatList.find((item: any) => item.id === fileId)
		if (targetFile) {
			if (commentType === DLCommentType.normal) {
				targetFile.reviewCommentStatus = CommentStatus.todo
			} else if (commentType === DLCommentType.qc) {
				targetFile.qcCommentStatus = CommentStatus.todo
			} else {
				ConsoleLog("TODO: Unexpected case")
			}
		} else {
			alert("(reflectCommentAdd) Matched file does not exist")
		}
	},
	emptyTheFlatList() {
		// for project template deletion
		// remove the store data only (not real act)
		self.flatList.length = 0
	},
	setFileInfo(fileInfo: any) {
		self.fileInfo = fileInfo
	},
})

export default FlatListActions
