import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog, DLButton } from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import styled from "styled-components"
import { useHcMgmtStore } from "../../../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"
import { HardCopyMgmtHistorySourceEnum } from "../../../hcmgmt-history/store/data-models/history-model"
import HistoryTable from "../../../hcmgmt-history/sub-components/HistoryTable"
import { PermissionAsObjectProps } from "../../../../../../common-models/permission"

export default observer(function HardCopyHistoryDialog({
	i18n,
	orgId,
	selectedId,
	permission
}: {
	i18n: DLI18nProps
	orgId: string
	selectedId: string
	permission: PermissionAsObjectProps
}) {
	const hcMgmtStore = useHcMgmtStore()
	const historyStore = hcMgmtStore.history
	
	useEffect(() => {
		console.log(selectedId)
		historyStore.getHcMgmtHistories({
			orgId,
			source: HardCopyMgmtHistorySourceEnum.hardCopy,
			targetId: selectedId
		})
	},[])
	
	return (
		<DLDialog
			eleTestId="hard-copy-history-dialog"
			isOpen={historyStore.openHardCopyHistoryDialog}
			setIsOpen={historyStore.setOpenHardCopyHistoryDialog}
			showCloseBtn={true}
			dialogTitle="Hard Copy History"
			dialogContents={<HardCopyHistoryDialogContent historyStore={historyStore} permission={permission}/>}
			// actionReady={isReady || userEmail === inputs.email.value}
			maxWidth="md"
			// actionBtn="Update"
			cancelBtnText={i18n.twCancel || "Cancel"}
			showSpinner={historyStore.getActionStatus("getHcMgmtHistories") !== "SUCCESS"}
			cannotUpdate={historyStore.getActionStatus("getHcMgmtHistories") !== "SUCCESS"}
		/>
	)
})

const HardCopyHistoryDialogContent = ({ historyStore, permission }: { historyStore: any, permission: PermissionAsObjectProps }) => {
	return(
		<HistoryTable 
			partialStore={historyStore}
			handleContextMenu={() => {}}
			menuId="HistoryDailog"
			rederingFrom="Dialog"
			permission={permission}
		/>
	)
}