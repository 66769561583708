import React from "react"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { PageContainer } from "../../../../components/app-frame-elements"
import styled from "styled-components"
import PieChartCard from "./sub-components/PieChartCard"

export default observer(function Dashboard({
	partialStore,
	fetchingStatus,
	permission,
	i18n,
}: {
	partialStore: any
	fetchingStatus: ActionStatus
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) {
    return (
        <PageContainer
			pageTools={<div />}
			hasToolbar={false}
			fullWidth
			fixedHeight
			isLoading={fetchingStatus === "LOADING"}
		>
			<StyledDashboard>
			    <div>
			        <PieChartCard />
			    </div>
			    <div>
			        
			    </div>
			</StyledDashboard>
		</PageContainer>
    )
})

const StyledDashboard = styled.div``