import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	DLButton,
	InputWithLabel,
	DLComboBox,
} from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import styled from "styled-components"
import { HardCopyStatusEnum } from "../../store/data-models/hard-copy-list.data-models"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { useHcMgmtStore } from "../../../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"

export default observer(function EditHardCopyStatusDialog({
	i18n,
	partialStore,
}: {
	i18n: DLI18nProps
	partialStore: any
}) {
	const orgStore = useOrgStore()
	const hcMgmtStore = useHcMgmtStore()
	const hcStore = hcMgmtStore.hardCopy

	const hardCopyStatus = hcStore.getHardCopyStatus()
	const [status, setStatus] = useState<HardCopyStatusEnum>(
		HardCopyStatusEnum.available
	)

	useEffect(() => {
		if (hardCopyStatus) {
			setStatus(hardCopyStatus)
		}
	}, [])

	const statusOptions = Object.values(HardCopyStatusEnum)
	const hardCopy = hcStore.getHardCopyInfo()

	const userId = orgStore.checkin.orgInfo.userId.split("Id").join("")
	const userAlias = orgStore.checkin.orgInfo.userAliasId
	const userName = orgStore.checkin.orgInfo.userName

	const handleSubmit = () => {
		if (hardCopy) {
			hcStore.editHardCopyStatus({
				id: hardCopy.id,
				pk: hardCopy.pk,
				by: {
					id: userId,
					alias: userAlias,
					name: userName,
				},
				status,
			})
		} else {
			console.log("Cannot find the hard copy information in the store")
		}
	}

	return (
		<DLDialog
			eleTestId="edit-hard-copy-status-dialog"
			isOpen={partialStore.openEditHardCopyStatusDialog}
			setIsOpen={partialStore.setOpenEditHardCopyStatusDialog}
			showCloseBtn={true}
			dialogTitle="Edit Hard Copy Status"
			dialogContents={
				<>
					<InputWithLabel
						direction="vertical"
						required
						label="Status"
					>
						{/*<DLInputField
						autoFocus
						eleTestId="group-input"
						eleFullWidth
						eleName="group"
						eleValue={input.group}
						eleHandleChange={handleInput}
						eleRequired
						variant="outlined"
					/>*/}
						<DLComboBox
							eleTestId="status-input"
							withoutLabel
							onChange={(value: any) => setStatus(value)}
							defaultValue={status}
							getOptionLabel={(option: any) => option}
							options={statusOptions}
						/>
					</InputWithLabel>
				</>
			}
			actionReady={hardCopyStatus !== status}
			maxWidth="sm"
			actionBtn="Update"
			handleAction={handleSubmit}
			cancelBtnText={i18n.twCancel || "Cancel"}
			// 			showSpinner={archiveMgmtStore.getActionStatus("getUsers") !== "SUCCESS"}
			// 			cannotUpdate={archiveMgmtStore.getActionStatus("getUsers") !== "SUCCESS"}
		/>
	)
})
