import React, { useCallback, useEffect } from "react"
import { Link } from "react-router-dom"
import { observer } from "mobx-react-lite"
import {
	SortingIconHeader,
	DLButton,
	DLIconButton,
} from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import { mdiDotsVertical, mdiPlus } from "@mdi/js"
import { Icon } from "@mdi/react"
import ReactTableV8 from "../../../../../../components/basic-elements/tables/DLReactTable"
import { Column, ColumnDef, Table } from "@tanstack/react-table"
import { DLOrgSubMenus } from "../../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { useHcMgmtStore } from "../../../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"

export default observer(function HardCopyAccessCtrlTable({
	handleContextMenu,
}: {
	handleContextMenu: (event: any, targetInfo: any) => void
}) {
	const hcMgmtStore = useHcMgmtStore()
	const configStore = hcMgmtStore.config
	
	const pageHeader = (instance: Table<any>) => {
		const totalUsers =  instance.getPreFilteredRowModel().flatRows.length
		return (
			<div className="FR JSB" style={{ width: "100%" }}>
				<div>Total Users: {totalUsers}</div>
				<DLButton
					variant="text"
					eleTestId="add-org-user-btn"
					startIcon={<Icon path={mdiPlus} size={1} />}
					clickHandler={() => configStore.setOpenAddUserDialog(true)}
					color="primary"
				>
					Add User
				</DLButton>
			</div>
		)
	}

	return (
		<ReactTableV8
			tableColumns={columns(handleContextMenu)}
			data={configStore.hardCopyUsers}
			handleContextMenu={handleContextMenu}
			menuId={DLOrgSubMenus.hard_copy_config}
			pageHeader={pageHeader}
		/>
	)
})

const columns = (handleContextMenu: (event: any, targetInfo: any) => void) => [
	{
		header: "Role",
		accessorKey: "role",
	},
	{
		header: "User Name",
		accessorKey: "userName",
	},
	{
		header: "Alias",
		accessorKey: "alias",
	},
	{
		header: "Status",
		accessorKey: "status",
	},
	{
		header: "Rental Available",
		accessorKey: "isRentalAvailable",
	},
	{
		header: "Added Date",
		accessorKey: "addedDate",
	},
	{
		header: "Modified Date",
		accessorKey: "modifiedDate",
	},
	{
		header: "Modified By",
		accessorKey: "modifiedBy",
	},
	{
		header: "Action",
		accessorKey: "pending",
		enableColumnFilter: false,
		cell: (props: any) => (
			<DLIconButton
				variant="iconOnly"
				aria-label="more"
				clickHandler={(event: any) =>
					handleContextMenu(event, props.row.original)
				}
				eleTestId="hard-copy-ctx-btn-on-row"
			>
				<Icon path={mdiDotsVertical} size={0.8} />
			</DLIconButton>
		),
	},
]
