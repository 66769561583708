import { DnTFormatProps } from "../../../../common-models/types/common-props"
import { ProjScreenType } from "../../../../components/combined-elements/projects-table/projects-table-props"
import {
    formattedDateWithTz2,
    getTzDate,
} from "../../../../library/converters/date-utc-converter"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { differenceInCalendarDays } from "date-fns"

const OrgProjectsFormattingViews = (self: any) => ({
    formattedProjList(
        dntFormat: DnTFormatProps,
        projType: ProjScreenType,
        menuId?: DLOrgSubMenus
    ) {
        const { dateFormat, timeFormat, timeZone } = dntFormat
        let selectedProj
        if (projType === ProjScreenType.archived) {
            selectedProj = self.archivedProjList
        } else if (projType === ProjScreenType.unarchived) {
            selectedProj = self.unarchivedProjList
        } else if (projType === ProjScreenType.normal) {
            selectedProj = self.normalProjList
        } else if (projType === ProjScreenType.replica) {
            selectedProj = self.replicaProjList
        } else if (projType === ProjScreenType.roamArchived) {
            selectedProj = self.roamArchivedProjList
        }

        let dateFormattedList: any[] = []
        function formatDate(date: string | null) {
            if (date) {
                return getTzDate({
                    date,
                    timeZone,
                    dateFormat,
                })
            } else {
                return ""
            }
        }

        function formatTime(date: string | null) {
            if (date) {
                return formattedDateWithTz2({
                    date,
                    timeZone,
                    timeFormat,
                    dateFormat,
                })
            }
            return ""
        }

        selectedProj.map((project: any) => {
            const formattedPeriodEndDate = formatDate(project.periodEndDate)
            const formattedLastAccess = formatTime(project.lastAccessAt)

            const formattedCreatedAt = formatDate(project.createdAt)
            //
            const formattedArchived = formatTime(project.archivedAt)
            const formattedUnArchivedAt = formatDate(project.unarchivedAt)
            const formattedFinalReportDate = formatDate(project.finalReportDate)
            const formattedExpectedReportDate = formatDate(
                project.expectedReportDate
            )
            const formattedExpectedArchiveDate = formatDate(
                project.expectedArchiveDate
            )
            const formattedFinalArchiveDeadlineDate = formatDate(
                project.finalArchiveDeadlineDate
            )
            // console.log(project.expectedArchiveDate, new Date(), getTzDateAndTime2({ date: project.expectedArchiveDate, dntFormat }))
            // console.log(new Date(formattedFinalArchiveDeadlineDate), utcToZonedTime(new Date(), timeZone), differenceInCalendarDays(new Date(formattedFinalArchiveDeadlineDate), utcToZonedTime(new Date(), timeZone)), project.title)
            let dueDays: any = ""
            if (
                menuId === DLOrgSubMenus.remind_report ||
                menuId === DLOrgSubMenus.admin_remind_report
            ) {
                dueDays = formattedFinalReportDate
                    ? "N/A"
                    : differenceInCalendarDays(
                          new Date(formattedExpectedReportDate),
                          new Date()
                      )
            } else if (
                menuId === DLOrgSubMenus.remind_archiving ||
                menuId === DLOrgSubMenus.admin_remind_archiving
            ) {
                if (
                    formattedExpectedArchiveDate &&
                    !formattedFinalArchiveDeadlineDate
                ) {
                    dueDays = differenceInCalendarDays(
                        new Date(formattedExpectedArchiveDate),
                        new Date()
                    )
                } else {
                    dueDays = differenceInCalendarDays(
                        new Date(formattedFinalArchiveDeadlineDate),
                        new Date()
                    )
                }
            }

            dateFormattedList.push({
                ...project,
                archivedDate: formattedArchived ? formattedArchived.date : "",
                archivedTime: formattedArchived ? formattedArchived.time : "",
                unarchivedAt: formattedUnArchivedAt,
                periodEndDate: formattedPeriodEndDate,
                lastAccessDate: formattedLastAccess
                    ? formattedLastAccess.date
                    : "",
                lastAccessTime: formattedLastAccess
                    ? formattedLastAccess.time
                    : "",
                finalReportDate: formattedFinalReportDate, // TODO: variable name need update
                expectedReportDate: formattedExpectedReportDate,
                finalArchiveDeadlineDate: formattedFinalArchiveDeadlineDate,
                expectedArchiveDate: formattedExpectedArchiveDate,
                createdAt: formattedCreatedAt,
                dueDays,
            })
        })

        if (
            menuId === DLOrgSubMenus.remind_report ||
            menuId === DLOrgSubMenus.admin_remind_report ||
            menuId === DLOrgSubMenus.remind_archiving ||
            menuId === DLOrgSubMenus.admin_remind_archiving
        ) {
            return dateFormattedList.sort((a, b) => {
                // If either dueDays is not a number, move it to the end
                if (isNaN(a.dueDays)) return 1
                if (isNaN(b.dueDays)) return -1
                // First, compare dueDays
                if (a.dueDays < b.dueDays) return -1
                if (a.dueDays > b.dueDays) return 1
                // second, compare year
                if (a.financialYear < b.financialYear) return -1
                if (a.financialYear > b.financialYear) return 1
                // compare clientNames
                if (a.clientName < b.clientName) return -1
                if (a.clientName > b.clientName) return 1
                // If both are equal, return 0
                return 0
            })
        }

        if (
            menuId === DLOrgSubMenus.assignedArchived ||
            menuId === DLOrgSubMenus.archived ||
            menuId === DLOrgSubMenus.assignedArchivedReadonlyProjects
        ) {
            return dateFormattedList.sort(
                (a, b) =>
                    new Date(b.archivedAt).getTime() -
                    new Date(a.archivedAt).getTime()
            )
        }

        if (
            menuId === DLOrgSubMenus.assignedUnarchived ||
            menuId === DLOrgSubMenus.unarchived
        ) {
            return dateFormattedList.sort(
                (a, b) =>
                    new Date(b.unarchivedAt).getTime() -
                    new Date(a.unarchivedAt).getTime()
            )
        }

        return dateFormattedList
    },

    getTodayDateWithOrgTZ(dntFormat: DnTFormatProps) {
        return getTzDate({
            date: new Date(),
            timeZone: dntFormat.timeZone,
            dateFormat: dntFormat.dateFormat,
        })
    },
})

export default OrgProjectsFormattingViews
