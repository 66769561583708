import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
import {
	ReadHardCopyList,
	ReadMyRentalList,
	_MoveToTrashHardCopy,
	DeleteCustomColumn,
	CreateHardCopy,
	UpdateHardCopyDetail,
	UpdateHardCopyStatus,
} from "./model-actions"
import { HardCopyMgmtApiProps } from "./hg-mgmt-hard-copy.apis"

const HcMgmtHardCopyModel = ({
	apiMoveToTrashHardCopy,
	apiCreateHardCopy,
	apiReadHardCopies,
	apiUpdateHardCopyDetail,
	apiUpdateHardCopyStatus,
	apiRemoveCustomColumn,
}: HardCopyMgmtApiProps) =>
	types
		.model({})
		.actions((self) => ReadHardCopyList(self, apiReadHardCopies))
		.actions((self, read = () => {}) => ReadMyRentalList(self, read))
		.actions((self) => DeleteCustomColumn(self, apiRemoveCustomColumn))
		.actions((self) => _MoveToTrashHardCopy(self, apiMoveToTrashHardCopy))
		.actions((self) => CreateHardCopy(self, apiCreateHardCopy))
		.actions((self) => UpdateHardCopyDetail(self, apiUpdateHardCopyDetail))
		.actions((self) => UpdateHardCopyStatus(self, apiUpdateHardCopyStatus))

		// model common actions
		.actions(CommonModelActions)

export default HcMgmtHardCopyModel
