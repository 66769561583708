import React from "react"
import { observer } from "mobx-react-lite"
import { InputWithLabel, DLComboBox, ConsoleLog } from "../../basic-elements"
import { CreateProjectSectionRightProps } from "./create-project.comp-props"
import Icon from "@mdi/react"
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js"
import { createFilterOptions } from "@material-ui/lab/Autocomplete"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"
import OtherUsersComponent from "./OtherUsersComponent"
import { CreateProjectMethod } from "./create-project.data-props"

export default observer(function CreateProjectSectionRight({
	path,
	userList,
	projRoleList,
	handleEpUser,
	handleCreatorRole,
	creatorRole,
	createMethod,
	epUserId,
	projRoleSet,
	projRoleSetList,
	handleProjRoleSet,
	userId,
	i18n,
	//
	sectionOn,
	handleSectionOn,
	highlight,
	creatorInfo,
	otherUsers,
	setInputs
}: CreateProjectSectionRightProps) {
	const pathLength = path.length

	return (
		<div
			className={`right-area select-users ${
				sectionOn ? "section-on" : ""
			} FC`}
		>
			{/* <div className="dialog-section-header heading3 FR AC JSB">
					{sectionOn ? "3. Users" : "3"}

					<DLIconButton
						eleTestId="section3-on-off"
						clickHandler={() => handleSectionOn("section3")}
						size="small"
					>
						{sectionOn ? (
							<Icon path={mdiChevronLeft} size={0.8} />
						) : (
							<Icon path={mdiChevronRight} size={0.8} />
						)}
					</DLIconButton>
				</div> */}
			{pathLength <= 1 ? (
				<div className="request-required-field note">
					Please set location first
				</div>
			) : (
				<>
					<div className={`input-section FR`}>
						<InputWithLabel
							label="Engagement Partner"
							required
							eleClassName="with-label"
							highlight={highlight && epUserId === ""}
						>
							<DLComboBox
								eleTestId="ep-select-field"
								withoutLabel
								placeholder={"Please select Engagement Partner"}
								options={userList}
								filterOptions={createFilterOptions({
									// matchFrom: 'start',
									stringify: (option: any) =>
										option.name +
										option.aliasId +
										option.email,
								})}
								getOptionDisabled={(option: any) =>
									option.isGrayed
								}
								renderOption={(
									option: any,
									{ inputValue }: { inputValue: any }
								) => {
									const matches_name = match(
										option.name,
										inputValue
									)
									const parts_name = parse(
										option.name,
										matches_name
									)
									const matches_aliasId = match(
										option.aliasId,
										inputValue
									)
									const parts_aliasId = parse(
										option.aliasId,
										matches_aliasId
									)
									const matches_email = match(
										option.email,
										inputValue
									)
									const parts_email = parse(
										option.email,
										matches_email
									)
									return (
										<div className="FR AC">
											<div
												style={{
													marginRight: "0.4rem",
												}}
											>
												{option.isGrayed &&
													"(Suspended) "}
												{parts_name.map((part, i) => (
													<span
														key={i}
														style={{
															fontWeight:
																part.highlight
																	? 700
																	: 400,
														}}
													>
														{part.text}
													</span>
												))}
											</div>

											<div
												style={{
													marginRight: "1rem",
													opacity: 0.8,
												}}
											>
												(
												{parts_aliasId.map(
													(part, i) => (
														<span
															key={i}
															style={{
																fontWeight:
																	part.highlight
																		? 700
																		: 400,
															}}
														>
															{part.text}
														</span>
													)
												)}
												)
											</div>

											<div
												style={{
													fontSize: "0.8rem",
												}}
											>
												{parts_email.map((part, i) => (
													<span
														key={i}
														style={{
															fontWeight:
																part.highlight
																	? 700
																	: 400,
														}}
													>
														{part.text}
													</span>
												))}
											</div>
										</div>
									)
								}}
								onChange={(value: any) => handleEpUser(value)}
								defaultValue={userList.find(
									(user: any) => user.id === epUserId
								)}
							/>
						</InputWithLabel>
					</div>
					{/* <div>
						<InputWithLabel
							label="Project Role Set"
							required
							eleClassName="with-label"
							highlight={highlight && projRoleSet === ""}
						>
							<DLComboBox
								eleTestId="proj-roleset-select"
								withoutLabel
								// placeholder={"Please select Project Role Set"}
								options={projRoleSetList}
								onChange={(value: any) =>
									handleProjRoleSet(value)
								}
								defaultValue="default-proj-role-set"
							/>
						</InputWithLabel>
					</div> */}
					{epUserId !== userId && (
						<div className={`input-section FR`}>
							<InputWithLabel
								label="Creator's role"
								required
								eleClassName="with-label"
								highlight={highlight && creatorRole === ""}
							>
								<div className="FR">
									<div className="creator-info">
										<div>Created By</div>
										<div>Name: {creatorInfo.name}</div>
										<div>
											AliasId: {creatorInfo.aliasId}
										</div>
										<div>Email: {creatorInfo.email}</div>
									</div>
									<DLComboBox
										eleTestId="role-of-creator-field"
										eleClassName="select-creator-roll"
										withoutLabel
										placeholder={
											"Please select your role in the project"
										} // TODO: Is it required multiple select?
										options={projRoleList}
										getOptionLabel={(option: any) =>
											option.name
										}
										onChange={(value: any) =>
											handleCreatorRole(value)
										}
										defaultValue={projRoleList.find(
											(role: any) =>
												role.roleId === creatorRole
										)}
									/>
								</div>
							</InputWithLabel>
						</div>
					)}
					{createMethod === CreateProjectMethod.byHardCopyOnly && (
						<div className={`input-section FR`}>
							<InputWithLabel
								label="Other users"
								required
								eleClassName="with-label"
							>
								<OtherUsersComponent otherUsers={otherUsers} setInputs={setInputs}/>
							</InputWithLabel>
						</div>
					)}
				</>
			)}
		</div>
	)
})
