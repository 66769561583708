import React from "react"
//
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { DLButton } from "../../../../../components/basic-elements"
import { Link } from "react-router-dom"
import Icon from "@mdi/react"
import { mdiArrowLeft } from "@mdi/js"
import { useHcMgmtStore } from "../../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"
import ProjectInfoDetailsContent from "./ProjectInfoDetailsContent"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import ReactMarkdown from "react-markdown"

export default observer(function HardCopyDetailsPage() {
	const store = useRootStore()
	const hcMgmtStore = useHcMgmtStore()
	
	const dntFormat = store.global.getDntFormat

	const selectedItem = hcMgmtStore.hardCopy.viewHardCopyList(dntFormat).find(
		(item: any) => item.id === hcMgmtStore.hardCopy.selectedId
	)
	const customColumns = hcMgmtStore.config.viewCustomColumns
	
	const { 
		hasSoftCopy,
		title,
		description,
		projectAlias,
		projectName,
		qcUsers,
		epUsers,
		groupId,
		clientAlias,
		clientName,
		year 
	} = selectedItem
	
	
	const customColumnsInfo = customColumns.reduce(function(r: any, e: any) {
	  r[e.label] = selectedItem[e.id];
	  return r;
	}, {});
	
	const projInfo = {
		year,
		// description,
		...( hasSoftCopy === "Yes" ? {
			projectAlias,
			projectName,
			qcUsers,
			epUsers,
			groupId,
			clientAlias,
			clientName,
		} : customColumnsInfo)
	}

	return (
		<StyledPage>
			<div className="FR JSB">
				<Link to="/organization/hard-copy-management/hard-copy-list">
					<DLButton
						eleTestId="back-to-list"
						startIcon={<Icon path={mdiArrowLeft} size={0.8} />}
					>
						Back to List
					</DLButton>
				</Link>
				<DLButton eleTestId="rental-history" color="primary">
					Rental History
				</DLButton>
			</div>
			<br />
			<div className="title">{selectedItem.title}</div>
			<div className="FR AC description">
				<div style={{ minWidth: "10rem" }}>Description</div>
				<ReactMarkdown
					children={description}
					className="md-area"
				/>
			</div>
			<ProjectInfoDetailsContent projInfo={projInfo} />
		</StyledPage>
	)
})


const StyledPage = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.title{
		font-size: 1.2rem;
		font-weight: bold;
		color: ${(props) => props.theme.emphasis};
	}
	.description{
		padding-left: 20px;
		font-size: 1rem;
		font-weight: bold;
	}
	.proj-info-details {
		padding: 20px;
		.row-item {
			margin-bottom: 20px;
		}
		.label {
			font-size: 1rem;
			font-weight: bold;
			min-width: 15rem;
		}
	}
`
