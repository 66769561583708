import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { HardCopyStatusEnum } from "../data-models/hard-copy-list.data-models"

const EditHardCopyStatus = (self: any) => ({
	editHardCopyStatus(payload: {
		id: string
		pk: string
		status: HardCopyStatusEnum
		by: { id: string; alias: string; name: string }
	}) {
		// 0.
		const actionName = "editHardCopyStatus"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		// 3.request API call
		self.updateHardCopyStatus(payload)
			.then((response: any) => {
				console.log(response)

				self.setOpenEditHardCopyStatusDialog(false)
				self.reflectHardCopyStatusUpdate({
					id: payload.id,
					status: payload.status,
				})
				if (response.status === 200) {
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
					// TODO: edit the hard copy item in the stored list
					// TODO: Close the dialog
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200, // temp
						color: MessageColorType.orange,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default EditHardCopyStatus
