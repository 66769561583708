import React, { useEffect } from "react"
// for store
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
// for input area
import { DLDialog, DLButton } from "../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { getProjectIcon } from "../../../../../components/basic-elements/icons/common-project-icons"
import BasicCounter from "../../../../../components/app-frame-elements/sub-components/BasicCounter"
import { handleExport } from "../../../../../components/basic-elements/export/ExportXLSX"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"

const AssignedProjectsDialog = observer(({ i18n }: { i18n: DLI18nProps }) => {
	const orgStore = useOrgStore()
	const actionaName = "precheckSuspend"

	useEffect(() => {
		orgStore.setupUsers.precheckSuspend(orgStore.setupUsers.selectedUser.id)
	}, [])

	const isLoading =
		orgStore.setupUsers.getActionStatus(actionaName) === "LOADING"

	return (
		<DLDialog
			eleTestId="assigned-projects-dialog"
			draggable
			isOpen={orgStore.setupUsers.assignedProjectsDialogOpen}
			setIsOpen={orgStore.setupUsers.setAssignedProjectsDialogOpen}
			showCloseBtn={true}
			dialogTitle={"Assigned Projects"}
			dialogContents={
				!isLoading && (
					<AssignedProjectsDialogContainer
						partialStore={orgStore.setupUsers}
						i18n={i18n}
					/>
				)
			}
			maxWidth="md"
			fullWidth={true}
			showSpinner={isLoading}
			cannotUpdate={isLoading}
		/>
	)
})

export const AssignedProjectsDialogContainer = observer(
	({ partialStore, i18n }: { partialStore: any; i18n: any }) => {
		const data = partialStore.assignedProjList
		return (
			<div className="user-assigned-projects">
				<div className="FR JSB">
					<BasicCounter label="Total Projects" value={data.length} />
					<DLButton
						eleTestId="export-assigned-projects"
						size="thin"
						color="primary"
						clickHandler={() =>
							handleExport(
								partialStore.exportAssignedProjects(),
								"AssignedProjects"
							)
						}
					>
						{i18n.twExport}
					</DLButton>
				</div>
				<ReactTableV8
					tableColumns={Columns(i18n)}
					data={data}
					hasPagination={false}
					showPageSetting={false}
					i18n={i18n}
					menuId="AssignedProjectsDialog"
				/>
			</div>
		)
	}
)

const Columns = (i18n: DLI18nProps) => [
	{
		header: i18n.twStatus,
		accessorKey: "statusName",
		cell: (props: any) => {
			const projStatus = props.getValue()
			const cellId = props.cell.column.id + "-cell"
			return (
				<div data-testid={cellId} className="FR JSB">
					{getProjectIcon(projStatus, 1)} {projStatus}
				</div>
			)
		},
	},
	{
		header: i18n.twFinancialYear || "Financial Year",
		accessorKey: "financialYear",
		cell: (props: any) => {
			return (
				<div data-testid="financial-year-cell">{props.getValue()}</div>
			)
		},
	},
	{
		header: i18n.twPeriodType,
		accessorKey: "periodName",
		minWidth: 50,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return <div data-testid={cellId}>{props.getValue()}</div>
		},
	},
	{
		header: i18n.twClient,
		accessorKey: "clientName",
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return <div data-testid={cellId}>{props.getValue()}</div>
		},
	},
	{
		header: i18n.twProjectTitle,
		accessorKey: "title",
	},
	{
		header: i18n.twVersion,
		accessorKey: "version",
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			const version =
				props.getValue() === 0 ? "-" : "v." + props.getValue()
			return <div data-testid={cellId}>{version}</div>
		},
	},
	{
		header: i18n.twRoleName || "Role Name",
		accessorKey: "roleName",
	},
	{
		header: i18n.twCreatedBy,
		accessorKey: "createdBy",
		accessorFn: (props: any) => props.createdBy.name,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return <div data-testid={cellId}>{props.getValue()}</div>
		},
	},
]

export default AssignedProjectsDialog
