import { flow } from "mobx-state-tree"
import { HardCopyMgmtHistorySourceEnum } from "../data-models/history-model"

const ReadHcMgmtHistories = (self: any, readHistories: any) => ({
	readHcMgmtHistories: flow(function* (params: {
		orgId: string
		source: HardCopyMgmtHistorySourceEnum
		targetId?: string
	}) {
		const actionName = "readHcMgmtHistories"
		try {
			const response = yield readHistories(params)

			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default ReadHcMgmtHistories
