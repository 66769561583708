import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { DLButton } from "../../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../../common-models/types/common-props"
import styled from "styled-components"
import DLReactEditableTable from "../../../../../../../components/basic-elements/tables/DLReactEditableTable"
import { Icon } from "@mdi/react"
import { mdiArrowDown, mdiArrowUp, mdiAlertCircle } from "@mdi/js"
import { ActionStatus } from "@datalobby/types/lib"
import { getTableColumns } from "./get-table-columns"
import { handleFile } from "./handle-file"
import { handleDownloadExcelTemplate } from "../../../../../../../library/bulk-import-utils/handle-download-template"
import { useHcMgmtStore } from "../../../../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"

export const BulkAddHcWithoutScDialogContents = observer(
	({
		partialStore,
		i18n,
		handleRemoveRow,
		orgIdForHardCopy,
		customColumnsDataForBulkImport,
	}: {
		partialStore: any
		i18n: DLI18nProps
		handleRemoveRow: any
		orgIdForHardCopy: string
		customColumnsDataForBulkImport: {
			forTemplate: any
			forColumns: any
			ccHeadersObjForBulkImport: { [x: string]: any }
		}
	}) => {
		const [localFileName, setLocalFileName] = useState("")

		const handleSelectFileFromLocal = (e: any) => {
			const localFile = e.target.files[0]
			setLocalFileName(localFile.name)
			handleFile({
				event: e,
				ccHeadersObjForBulkImport:
					customColumnsDataForBulkImport.ccHeadersObjForBulkImport,
				setList: partialStore.setBulkHardCopyList,
				setFileReadStatus: partialStore.setBulkHardCopyFileReadStatus,
			})
			partialStore.setBulkHardCopyFileReadStatus(ActionStatus.loading)
		}

		const multipleUsersStatus = partialStore.getActionStatus(
			"createMultipleHardCopies"
		)

		const customColumns = customColumnsDataForBulkImport.forTemplate

		const tableColumns = getTableColumns({
			handleRemoveRow,
			// renderEditableCell,
			customColumnsData: customColumnsDataForBulkImport.forColumns,
			bulkHardCopyList: partialStore.viewBulkHardCopyList,
			updateSingleItemInput: partialStore.updateBulkAddHardCopyItem,
		})
		const tableData = JSON.parse(
			JSON.stringify(partialStore.viewBulkHardCopyList)
		)

		// console.log("---- tableColumns", tableColumns)
		// console.log("---- tableData", tableData)

		return (
			<StyledContent>
				<div className="FR JSB">
					{multipleUsersStatus !== ActionStatus.success && (
						<div className="FR JSB">
							<DLButton
								eleTestId="download-template"
								startIcon={
									<Icon path={mdiArrowDown} size={0.8} />
								}
								clickHandler={() =>
									handleDownloadExcelTemplate([
										{
											title: "",
											status: "",
											year: 0,
											description: "",
											...customColumns.columnsEmptyObject,
										},
									], "HardCopiesTemplate")
								}
							>
								Download Template
							</DLButton>
							<DLButton
								eleTestId="choose-files-btn"
								startIcon={
									<Icon path={mdiArrowUp} size={0.8} />
								}
								color="primary"
							>
								<label className="file-upload-btn-wrapper">
									Choose A file
									<input
										type="file"
										name="file"
										onChange={handleSelectFileFromLocal}
										data-testid="file-input"
										accept=".xlsx,.xls"
									/>
								</label>
							</DLButton>
							<div className="FR AC">
								<div className="FR AC JC">
									<Icon
										path={mdiAlertCircle}
										size={0.8}
										color="red"
									/>
								</div>
								<div>
									<span
										style={{
											color: "red",
											fontWeight: "700",
										}}
									>
										NOTE
									</span>
									: DO NOT EDIT the COLUMN HEADERS and SHEET
									NAME
								</div>
							</div>
						</div>
					)}
					{/*{multipleUsersStatus !== ActionStatus.success && (
					<div className="FR JSB">
						<DuplicatedUserList
							inputTotal={users.length}
							dupItems={duplicatedItems}
							dupItemsInSheet={duplicatedItemsInSheet}
						/>
					</div>
				)}*/}
				</div>
				<DLReactEditableTable
					tableColumns={tableColumns}
					data={tableData}
				/>
			</StyledContent>
		)
	}
)

const StyledContent = styled.div`
	input[type="file"] {
		display: none;
	}
`
