import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

export default observer(function LeftSideHardCopyConfigNav({
	tab,
	setTab,
	tabs,
}: {
	tab: any
	setTab: any
	tabs: { id: string; label: string }[]
}) {
	const store = useRootStore()
	let contentsAreaHeight = store.ui.contentsAreaHeight
	let contentsAreaWidth = store.ui.contentsAreaWidth
	const navWidthCheck = contentsAreaWidth / 8
	console.log("navWidthCheck", navWidthCheck, contentsAreaWidth)
	const minNavWidth = 180
	const maxNavWidth = 200
	let subNavWidth =
		navWidthCheck < minNavWidth
			? minNavWidth
			: navWidthCheck > maxNavWidth
			? maxNavWidth
			: navWidthCheck

	return (
		<StyledLeftSideHardCopyConfigNav
			contentsAreaHeight={contentsAreaHeight}
			subNavWidth={subNavWidth}
		>
			{tabs.map((item) => {
				return (
					<div
						onClick={() => setTab(item.id)}
						className={`tab ${tab === item.id && "selected"}`}
					>
						{item.label}
					</div>
				)
			})}
		</StyledLeftSideHardCopyConfigNav>
	)
})

interface StyledLeftSideHardCopyConfigNavProps {
	contentsAreaHeight: number
	subNavWidth: number
}

// background-color: ${(props) => props.theme.shade30};
const StyledLeftSideHardCopyConfigNav: any = styled.div<StyledLeftSideHardCopyConfigNavProps>`
	width: ${(props) => props.subNavWidth}px;
	min-width: ${(props) => props.subNavWidth}px;
	height: ${(props) => props.contentsAreaHeight}px;
	border-right: 1px solid gray;
	.tab {
		padding: 1rem;
		&.selected {
			font-weight: 700;
		}
	}
`
