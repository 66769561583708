import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
import { ReadCustomColumns, ReadHardCopyUsers, DeleteCustomColumn, CreateCustomColumn, UpdateCustomColumn } from "./model-actions"
import { HcMgmtConfigApiProps } from "./hc-mgmt-config.apis"

const HcMgmtConfigModel = ({ apiReadCustomColumns, apiRemoveCustomColumn, apiAddCustomColumn, apiEditCustomColumn }: HcMgmtConfigApiProps) =>
	types
		.model({})
		.actions((self, read = apiReadCustomColumns) =>
			ReadCustomColumns(self, read)
		)
		.actions((self, create = apiAddCustomColumn) =>
			CreateCustomColumn(self, create)
		)
		.actions((self, update = apiEditCustomColumn) =>
			UpdateCustomColumn(self, update)
		)
		.actions((self, remove = apiRemoveCustomColumn) =>
			DeleteCustomColumn(self, remove)
		)
		.actions((self, read = () => {}) => ReadHardCopyUsers(self, read))
		// model common actions
		.actions(CommonModelActions)

export default HcMgmtConfigModel
