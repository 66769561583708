import React from "react"
import {
	BrowserRouter as Router,
	Route,
	NavLink,
	Switch,
} from "react-router-dom"
import DateAndTimeFormatController from "./date-and-time-format-setting/DateAndTimeFormatController"
import EmailNotificationController from "./email-notification/EmailNotificationController"
import styled from "styled-components"
import OtherSettingsController from "./others/OtherSettingsController"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"

export default function OrgSetupSysSettings() {
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.setup_sys
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	const tabList = [
		{
			value: "dnt-formats",
			label: "Date & Time Setting",
			url: "/organization/organization-setup/system/date-and-time-formats",
			component: <DateAndTimeFormatController />,
		},
		{
			value: "email-notification",
			label: "Email Notification",
			url: "/organization/organization-setup/system/email-notification",
			component: <EmailNotificationController />,
		},
		{
			value: "others",
			label: "Others",
			url: "/organization/organization-setup/system/other-settings",
			component: <OtherSettingsController />,
		},
	]

	return (
		<>
			{menuAccess ? (
				<Router>
					<StyledSystemSettings className="contents-container FR">
						<div className="vertical-tabs FC">
							{tabList.map((tab) => {
								return (
									<NavLink
										key={tab.value}
										className="org-setup-system-settings-tab"
										data-testid={
											"sys-setting-tab-" + tab.value
										}
										activeClassName="selected-tab"
										to={tab.url}
									>
										{tab.label}
									</NavLink>
								)
							})}
						</div>
						<div className="tab-panel-container">
							<Switch>
								{tabList.map((tab) => {
									return (
										<Route exact path={tab.url}>
											{tab.component}
										</Route>
									)
								})}
							</Switch>
						</div>
					</StyledSystemSettings>
				</Router>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
}

const StyledSystemSettings = styled.div`
	height: 100vh;
	.vertical-tabs {
		width: 12rem;
		height: 100%;
		background-color: ${(props) => props.theme.secondary};
		.org-setup-system-settings-tab {
			display: flex;
			align-items: center;
			height: ${(props) => props.theme.shared.baseUnit};
			/* border: 1px solid pink; */
			padding: 0 2rem 0 1rem;
			&.selected-tab {
				font-weight: 900;
				background-color: ${(props) => props.theme.shade10};
				color: ${(props) => props.theme.primaryMainDeep};
				border-right: 3px solid
					${(props) => props.theme.primaryMainDeep};
			}
		}
	}
	.tab-panel-container {
		padding: 1rem;
		width: calc(100% - 12rem);
	}
`
