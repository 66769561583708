import { types } from "mobx-state-tree"
// model & view-model
import HcMgmtTrashModel from "./hc-mgmt-trash.model"
import HcMgmtTrashViewModel from "./hc-mgmt-trash.view-model"
import { initialClickPoint } from "../../../../../common-models/types/dialog.props"
import { HcMgmtTrashApiProps, readTrashItems } from "./hc-mgmt-trash.apis"

export const HcMgmtTrashStore = ({ apiReadTrashItems }: HcMgmtTrashApiProps) =>
	types
		.compose(
			HcMgmtTrashModel({
				apiReadTrashItems,
			}),
			HcMgmtTrashViewModel
		)
		.named("HcMgmtTrashStore")

export const initialStore = {
	clickPoint: initialClickPoint,
	// viewModel related parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const HcMgmtTrashStoreInstance = HcMgmtTrashStore({
	apiReadTrashItems: readTrashItems,
}).create(initialStore)

export default HcMgmtTrashStoreInstance
