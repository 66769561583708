import React, { useState } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import ArchiveConditionCard from "./sub-components/ArchiveConditionCard"
import ArchiveConditionDetailDialogContents from "./sub-components/ArchiveConditionDetailDialogContents"
import {
    DLSpinner,
    DLDialog,
    DLExpansionPanelSingle,
    DLIconButton,
} from "../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiHelpCircleOutline } from "@mdi/js"
import styled from "styled-components"
import RationaleDialog from "./sub-components/dialogs/RationaleDialog"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import ArchMainActionBtns from "./sub-components/ArchMainActionBtns"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import OpenedFileListDialog from "../../opened-files/OpenedFilesDialog"
import ArchivePolicyDetailDialog from "./sub-components/dialogs/ArchivePolicyDetailDialog"
import ArchConditionHelpDialog from "../../../organization-side/org-setup/org-archive-policies/sub-components/dialog/ArchConditionHelpDialog"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import ArchiveChecklist from "./sub-components/ArchiveChecklist"

export default observer(function ArchiveDiagnosis({
    partialStore,
    fetchingStatus,
    permission,
    i18n,
}: {
    partialStore: any
    fetchingStatus: ActionStatus
    permission: PermissionAsObjectProps
    i18n: DLI18nProps
}) {
    const store = useRootStore()
    const projStore = useProjStore()
    const [detailDialogOpen, setDetailDialogOpen] = useState(false)

    const handleClick = () => {
        partialStore.setOpenArchivePolicyDetailDialog(true)
    }

    const projectStatus = projStore.projInfo.projInfo.statusName
    const projectId = projStore.checkin.projId

    // NOTE: screenWidth must be in the observer
    const screenWidth = store.ui.contentsAreaWidth
    return (
        <PageContainer
            pageTools={<div />}
            hasToolbar={false}
            fullWidth
            isLoading={fetchingStatus === "LOADING"}
        >
            {fetchingStatus === "SUCCESS" && (
                <StyledArchiveDiagnosis screenWidth={screenWidth}>
                    <div className="condition-list-container">
                        {/* <div className="container-header FR JSB AC"> */}
                        <ArchiveChecklist
                            projStore={projStore.projInfo}
                            partialStore={partialStore}
                        />
                        <hr />
                        <div className="container-header FC">
                            <div className="left-side FR AC">
                                <h3>Archive Diagnosis</h3>
                                <div className="FR AC policy-name">
                                    based on the {partialStore.title}
                                    <div className="policy-help-icon FR AC">
                                        <DLIconButton
                                            eleTestId={`archive-policy-info`}
                                            clickHandler={handleClick}
                                        >
                                            <Icon
                                                path={mdiHelpCircleOutline}
                                                size={0.6}
                                            />
                                        </DLIconButton>
                                    </div>
                                </div>
                            </div>
                            <div className="right-side">
                                <span data-testid="condition-counter">
                                    <span data-testid="completed-mandatory-count">
                                        {partialStore.getCompletedCount(
                                            "mandatory"
                                        )}
                                    </span>
                                    <span
                                        style={{
                                            marginRight: 8,
                                            marginLeft: 8,
                                        }}
                                    >
                                        /
                                    </span>
                                    <span data-testid="total-mandatory-count">
                                        {partialStore.getMandatoryList.length}
                                    </span>{" "}
                                </span>
                                Archive conditions have been completed
                            </div>
                            <span className="proj-size">
                                <b>Project Size: </b>
                                {partialStore.projectSize}
                            </span>
                        </div>

                        <DLExpansionPanelSingle
                            eleTestId="arch-diagnosis-mandatory-list-expansion"
                            eleClassName="list-title"
                            title={
                                <div className="FR AC">
                                    <h4>Mandatory List</h4>
                                    <p className="completed-ratio">
                                        (
                                        {partialStore.getCompletedCount(
                                            "mandatory"
                                        )}
                                        /{partialStore.getMandatoryList.length}{" "}
                                        completed)
                                    </p>
                                </div>
                            }
                            defaultExpanded
                        >
                            <div
                                className="condition-list"
                                data-testid="mandotary-list"
                            >
                                {partialStore.archiveConditionList &&
                                    partialStore.getMandatoryList.map(
                                        (item: any) => {
                                            return (
                                                <ArchiveConditionCard
                                                    conditionDetail={item}
                                                    openDetailDialog={
                                                        setDetailDialogOpen
                                                    }
                                                    key={item.id}
                                                />
                                            )
                                        }
                                    )}
                            </div>
                        </DLExpansionPanelSingle>
                        <div style={{ height: "1rem" }} />
                        <DLExpansionPanelSingle
                            eleTestId="arch-diagnosis-non-mandatory-list-expansion"
                            eleClassName="list-title"
                            title={
                                <div className="FR AC">
                                    <h4>Non-Mandatory List</h4>{" "}
                                    <p className="completed-ratio">
                                        (
                                        {partialStore.getCompletedCount(
                                            "non-mandatory"
                                        )}
                                        /
                                        {
                                            partialStore.getNonMandatoryList
                                                .length
                                        }{" "}
                                        completed)
                                    </p>
                                </div>
                            }
                        >
                            <div
                                className="condition-list"
                                data-testid="non-mandotary-list"
                            >
                                {partialStore.archiveConditionList &&
                                    partialStore.getNonMandatoryList.map(
                                        (item: any) => {
                                            return (
                                                <ArchiveConditionCard
                                                    conditionDetail={item}
                                                    openDetailDialog={
                                                        setDetailDialogOpen
                                                    }
                                                    key={item.id}
                                                />
                                            )
                                        }
                                    )}
                            </div>
                        </DLExpansionPanelSingle>
                    </div>
                    <ArchMainActionBtns
                        permission={permission}
                        partialStore={partialStore}
                        projectStatus={projectStatus}
                        projectId={projectId}
                    />
                    {/* ----- Keep Dialogs ----- */}
                    <DLDialog
                        eleTestId="view-detail-dialog"
                        isOpen={detailDialogOpen}
                        showCloseBtn
                        cancelBtnText="Close"
                        setIsOpen={setDetailDialogOpen}
                        dialogTitle="Archive Condition Detail"
                        dialogContents={
                            <ArchiveConditionDetailDialogContents />
                        }
                        draggable
                    />
                    {partialStore.rationaleDialogOpen && <RationaleDialog />}
                    {/* TODO: WARNING: Why this opened file part use partial store??? */}
                    {projStore.openedFiles.openedFilesDialogOpen && (
                        <OpenedFileListDialog menuId={partialStore.storeName} />
                    )}
                    {partialStore.openArchConditionHelpDialog && (
                        <ArchConditionHelpDialog
                            conditionId={projStore.archive.selectedConditionId}
                            isOpen={
                                projStore.archive.openArchConditionHelpDialog
                            }
                            setIsOpen={() => {
                                projStore.archive.setOpenArchConditionHelpDialog(
                                    projStore.archive.selectedConditionId,
                                    !projStore.archive
                                        .openArchConditionHelpDialog
                                )
                            }}
                        />
                    )}
                    {partialStore.openArchivePolicyDetailDialog && (
                        <ArchivePolicyDetailDialog i18n={i18n} />
                    )}
                    {/* response snackbar -- can be removed or commented in the future */}
                </StyledArchiveDiagnosis>
            )}
        </PageContainer>
    )
})

type StyledProps = {
    screenWidth: any
}

const StyledArchiveDiagnosis = styled.div<StyledProps>`
    height: 100%;
    display: flex;
    flex-direction: column;

    .condition-list-container {
        height: 80vh;
        overflow-y: scroll;
        padding: 0 2rem 1rem;
        margin-bottom: 3rem;
        .container-header {
            .left-side {
                .policy-help-icon {
                    margin-left: 0.3rem;
                }
                .policy-name {
                    margin-left: 1rem;
                }
            }
            .right-side {
                margin-top: 1rem;
                font-size: 1.6rem;
                font-weight: 100;
                span {
                    font-weight: 800;
                    font-size: 2rem;
                }
            }
        }
        .list-title {
            background-color: ${(props) => props.theme.secondaryDeep};
            .completed-ratio {
                margin-left: 0.5rem;
            }
        }
        .condition-list {
            width: 100%;
        }
    }

    .proj-size {
        padding: 4px;
        font-size: 13px;
    }
    .archive-action-area {
        position: absolute;
        background-color: ${(props) => props.theme.secondaryDeep};
        border-top: 1px solid white;
        width: ${(props) => props.screenWidth}px;
        padding: 1rem 2rem;
        bottom: 0;
        > div {
            margin-right: 1rem;
        }
    }
`
