import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

const AddHardCopy = (self: any) => ({
	addHardCopy(data: any) {
		// 0.
		const actionName = "addHardCopy"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.request API call
		self.createHardCopy(data)
			.then((response: any) => {
				console.log(response)

				if (response.status === 200) {
					const newHardCopy = response.data.data.newHardCopy
					console.log("newHardCopy:", newHardCopy)

					self.setOpenAddHardCopyDialog(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
					// TODO: Add the hard copy to the list
					// TODO: Close the dialog
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200, // temp
						color: MessageColorType.orange,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
	addHardCopies(hardCopies: any[], index: number) {
		const actionName = "addHardCopies"

		const target = {
			...hardCopies[index],
		}
		// 			self.updateBulkUserStatus(
		// 				"inprogress...",
		// 				ActionStatus.loading,
		// 				target.userId
		// 			)
		self.createHardCopy(target)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// 		self.updateBulkUserStatus(
					// 			response.data.Message,
					// 			ActionStatus.success,
					// 			target.userId,
					// 			idToString(response.data.UserID, IdType.user)
					// 		)
					if (index !== hardCopies.length - 1) {
						self.addHardCopies(hardCopies, index + 1)
					}
				} else {
					// 		self.updateBulkUserStatus(
					// 			response.data.Message,
					// 			ActionStatus.fail,
					// 			target.userId
					// 		)
					if (index !== hardCopies.length - 1) {
						self.addHardCopies(hardCopies, index + 1)
					}
				}
				if (index === hardCopies.length - 1) {
					// self.setUserList(successUsers)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						message: response.data.Message,
						customMessage:
							"Successfully created multiple hard copies",
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				if (index !== hardCopies.length - 1) {
					self.addHardCopies(hardCopies, index + 1)
				}
			})
		// WARNING: Do not place the next calling in the outside of the response status condition to take sequentiall process
	},
})

export default AddHardCopy
