import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import { PageContainer } from "../../../../components/app-frame-elements"
import styled from "styled-components"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { ProjScreenType } from "../../../../components/combined-elements/projects-table/projects-table-props"
import SharedProjectTableV8 from "../../../../components/combined-elements/projects-table/SharedProjectTableV8"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"
import {
    getPageSettings,
    savePageSetting,
} from "../../../../library/get-page-settings"

const RemindReportController = observer(
    ({ menuId }: { menuId: DLOrgSubMenus }) => {
        const store = useRootStore()
        const orgStore = useOrgStore()
        // const menuId = DLOrgSubMenus.remind_report
        const projStatus = ProjectStatus.normal // What will be the proj status
        const actionName = "getOrgProjectList_" + projStatus
        //
        const menuAccess = orgStore.checkin.checkAccess(menuId)
        const permission = orgStore.checkin.getActionsAsObject(menuId)
        const i18nSheet = OrgI18n.projects
        const needRefresh = orgStore.projects.needRefreshForAssignedNormal

        useEffect(() => {
            if (menuAccess && needRefresh) {
                // const defaultYear = getDefaultFinancialYear(menuId)
                const pageSetting = getPageSettings(menuId)
                if (pageSetting?.columnFilters) {
                    const value = pageSetting?.columnFilters.filter(
                        (item: any) => item.id !== "financialYear"
                    )
                    savePageSetting(
                        "columnFilters",
                        value,
                        `pageSetting_${menuId}`
                    )
                }
                orgStore.projects.getOrgProjectList({
                    projStatus,
                    year: "All Years",
                    PTMAssignedOnly: menuId === DLOrgSubMenus.remind_report,
                })
            }
        }, [menuAccess, needRefresh])

        const loadingStatus = orgStore.projects.getActionStatus(actionName)
        const i18n = store.i18n.combineI18n(i18nSheet)

        return (
            <>
                {menuAccess ? (
                    <PageContainer
                        pageTools={<div />}
                        hasToolbar={false}
                        fullWidth
                        fixedHeight
                        isLoading={loadingStatus === "LOADING"}
                    >
                        <StyledRemindReport>
                            {loadingStatus === "SUCCESS" && (
                                <SharedProjectTableV8
                                    partialStore={orgStore.projects}
                                    i18n={i18n}
                                    // common part 2
                                    projScreenType={ProjScreenType.normal}
                                    menuId={menuId}
                                    projectStatus={ProjectStatus.normal}
                                    permission={permission}
                                    hasDueInFilter
                                />
                            )}
                        </StyledRemindReport>
                    </PageContainer>
                ) : (
                    <OrgNoPermissionOnMenu menuId={menuId} />
                )}
            </>
        )
    }
)

const StyledRemindReport = styled.div`
    padding: 0 1rem;
`

export default RemindReportController
