import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { PageContainer } from "../../../components/app-frame-elements"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import ProjBasicCtxMenus from "../../../components/combined-elements/projects-table/ProjBasicCtxMenus"
import DeleteProjectDialog from "./sub-components/DeleteProjectDialog"
import { OrgI18n } from "../../../common-models/enumerations/translation-sheets"
import { CreateProjectMethod } from "../../../components/combined-elements/create-project/create-project.data-props"
import { ProjScreenType } from "../../../components/combined-elements/projects-table/projects-table-props"
import { ProjectStatus } from "../../../common-models/enumerations/project-related-enums"
import OrgNoPermissionOnMenu from "../org-access-control/OrgNoPermissionOnMenu"
import {
	getPageSettings,
	savePageSetting,
} from "../../../library/get-page-settings"
import { StyledSharedProjectsTable } from "../../../components/combined-elements/projects-table/StyledSharedProjectsTable"
import styled from "styled-components"
import SharedProjectTableV8 from "../../../components/combined-elements/projects-table/SharedProjectTableV8"
import MoveGroupItemDialog from "../groups/sub-components/dialogs/MoveGroupItemDialog"
import ProjLocationDetailsDialog from "../../project-side/project-information/sub-components/ProjLocationDetailsDialog"

export default observer(function NormalProjectsController() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.normal_projs
	const projStatus = ProjectStatus.normal
	const actionName = "getOrgProjectList_" + projStatus
	const i18nSheet = OrgI18n.projects
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)
	const needRefresh = orgStore.projects.needRefreshForNormal
	//

	useEffect(() => {
		if (menuAccess && needRefresh) {
			// const defaultYear = getDefaultFinancialYear(menuId)
			const pageSetting = getPageSettings(menuId)
			if (pageSetting?.columnFilters) {
				const value = pageSetting?.columnFilters.filter(
					(item: any) => item.id !== "financialYear"
				)
				savePageSetting("columnFilters", value, `pageSetting_${menuId}`)
			}
			orgStore.projects.getOrgProjectList({
				projStatus: ProjectStatus.normal,
				year: "All Years",
				PTMAssignedOnly: false,
				ForROAM: false,
			})
		}
	}, [menuAccess, needRefresh])

	const handleCtxMenuClose = () => {
		orgStore.projects.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}
	//
	const handleOpenCreateProjectDialog = () => {
		orgStore.projects.setCreateProjectMethod(
			CreateProjectMethod.byUndefined
		)
		orgStore.projects.setCreateProjectPresetInputs(undefined)
		orgStore.projects.setOpenCreateProjDialog(true)
	}
	const partialStore = orgStore.projects
	const loadingStatus = orgStore.projects.getActionStatus(actionName)

	const clickPoint = {
		mouseX: orgStore.projects.clickPoint.mouseX,
		mouseY: orgStore.projects.clickPoint.mouseY,
	}

	const i18n = store.i18n.combineI18n(i18nSheet)
	const userRole = orgStore.checkin.assignedRole.shortName
	const clickPointExist = partialStore.clickPoint.mouseX !== null

	return (
		<>
			{menuAccess ? (
				<PageContainer
					pageTools={<div />}
					hasToolbar={false}
					fullWidth
					fixedHeight
					isLoading={loadingStatus === "LOADING"}
				>
					<StyledSharedProjectsTable>
						<SharedProjectTableV8
							// NOTE: Need to compare with dashboard table for consistency
							// common part 1
							partialStore={partialStore}
							i18n={i18n}
							// common part 2
							projScreenType={ProjScreenType.normal}
							menuId={DLOrgSubMenus.normal_projs}
							projectStatus={ProjectStatus.normal}
							permission={permission}
						/>
					</StyledSharedProjectsTable>
					{clickPointExist && (
						<ProjBasicCtxMenus
							projStatus={ProjectStatus.normal}
							partialStore={partialStore}
							permission={permission}
							clickPoint={clickPoint}
							handleClose={handleCtxMenuClose}
							userRole={userRole}
						/>
					)}
					{partialStore.openDeleteProjDialog && (
						<DeleteProjectDialog
							projStatus={ProjectStatus.normal}
							partialStore={partialStore}
							i18n={i18n}
						/>
					)}

					{orgStore.groups.openMoveDialog && (
						<MoveGroupItemDialog
							i18n={i18n}
							targetItemId={partialStore.selectedProj}
							currentMenu={menuId}
						/>
					)}

					{orgStore.projects.openProjLocationDialog && (
						<ProjLocationDetailsDialog
							partialStore={orgStore.projects}
							view={store.global.sidebarViewState}
						/>
					)}
				</PageContainer>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

const StyledDeadlineShortcut = styled.div`
	&.deadline-filter-container {
		.deadline-type-select {
			min-height: 0px !important;
		}
		span {
			margin: 0 0.6rem;
		}
		.shortcut-days {
			min-width: 1rem;
			max-width: 2rem;
			input {
				font-size: ${(props) => props.theme.shared.textLg};
			}
		}
	}
`
