import React from "react"
import { observer } from "mobx-react-lite"
import { DLButton } from "../../../../../components/basic-elements"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { Column, Table } from "@tanstack/react-table"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import DLYearpicker from "../../../../../components/basic-elements/date-picker/DLYearpicker"
import { columns } from "./HistoryTableColumns"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"

export default observer(function HistoryTable({
	partialStore,
	handleContextMenu,
	menuId,
	rederingFrom = "Page",
	permission
}: {
	partialStore: any
	handleContextMenu: (event: any, targetInfo: any) => void
	menuId: string
	rederingFrom: "Page" | "Dialog"
	permission: PermissionAsObjectProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const dntFormat = store.global.getDntFormat	
	
	const handleSourceChange = (source: string) => {
		if(rederingFrom === "Page"){
			const orgId = orgStore.checkin.orgInfo.orgId.split("Id").join("")
			partialStore.getHcMgmtHistories({ orgId, source })
		}
	}
	
	return (
		<ReactTableV8
			// TODO: height setting
			height={830}
			tableColumns={columns(partialStore, handleContextMenu)}
			data={partialStore.viewHistories(dntFormat)}
			handleContextMenu={handleContextMenu}
			menuId={menuId}
			customFilters={(props: any) =>
				filterComponent({ ...props, handleSourceChange })
			}
			showPageSetting={rederingFrom === "Page"}
			permission={permission}
		/>
	)
})

const filterComponent = ({
	column,
	table,
	handleSourceChange,
}: {
	column: Column<any>
	table: Table<any>
	handleSourceChange: any
}) => {
	switch (column.id) {
		case "source":
			return (
				<select
					onChange={(event: any) => {
						column.setFilterValue(event.target.value)
						handleSourceChange(event.target.value)
					}}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "hard-copy") as string}
					data-testid="source-select"
				>
					<option value="HARD_COPY">Hard Copy</option>
					<option value="CUSTOM_COLUMN">Custom Column</option>
					<option value="RENTAL">Rental</option>
				</select>
			)

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}
