import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
import { ReadTrashItems } from "./model-actions"
import { HcMgmtTrashApiProps } from "./hc-mgmt-trash.apis"

const HcMgmtTrashModel = ({ apiReadTrashItems }: HcMgmtTrashApiProps) =>
	types
		.model({})
		.actions((self) => ReadTrashItems(self, apiReadTrashItems))
		// model common actions
		.actions(CommonModelActions)

export default HcMgmtTrashModel
