import { types } from "mobx-state-tree"

export const CustomColumnItem = types.model({
	pk: types.string,
	id: types.string,
	orgId: types.string,
	//
	label: types.string,
	createdBy: types.string,
	createdDate: types.string,
	//
	description: types.union(types.undefined, types.null, types.string),
	helpText: types.union(types.undefined, types.null, types.string),
})
