import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
// Dev side customers
import accKiwi220429 from "../../../../../admin-static-data/customer-table/developments/accKiwi220429.json"
// Production side customers
import accDaejoo211016 from "../../../../../admin-static-data/customer-table/productions/accDaejoo211016.json"
import accDasan200914 from "../../../../../admin-static-data/customer-table/productions/accDasan200914.json"
import accDoac210326 from "../../../../../admin-static-data/customer-table/productions/accDoac210326.json"
import accYnjac190715 from "../../../../../admin-static-data/customer-table/productions/accYnjac190715.json"
import accDonga181220 from "../../../../../admin-static-data/customer-table/productions/accDonga181220.json"
import accDssk200907 from "../../../../../admin-static-data/customer-table/productions/accDssk200907.json"
import accEchon200621 from "../../../../../admin-static-data/customer-table/productions/accEchon200621.json"
import accGyul181218 from "../../../../../admin-static-data/customer-table/productions/accGyul181218.json"
import accHaesolacc220919 from "../../../../../admin-static-data/customer-table/productions/accHaesolacc220919.json"
import accHangil200921 from "../../../../../admin-static-data/customer-table/productions/accHangil200921.json"
import accHanmi190607 from "../../../../../admin-static-data/customer-table/productions/accHanmi190607.json"
import accHanul181024 from "../../../../../admin-static-data/customer-table/productions/accHanul181024.json"
import accHdcpa191001 from "../../../../../admin-static-data/customer-table/productions/accHdcpa191001.json"
import accInduk220924 from "../../../../../admin-static-data/customer-table/productions/accInduk220924.json"
import accJinil201229 from "../../../../../admin-static-data/customer-table/productions/accJinil201229.json"
import accJungdong181218 from "../../../../../admin-static-data/customer-table/productions/accJungdong181218.json"
import accJungin210917 from "../../../../../admin-static-data/customer-table/productions/accJungin210917.json"
import accSerim181024 from "../../../../../admin-static-data/customer-table/productions/accSerim181024.json"
import accKr180724 from "../../../../../admin-static-data/customer-table/productions/accKr180724.json"
import accLian190709 from "../../../../../admin-static-data/customer-table/productions/accLian190709.json"
import accSamdo210518 from "../../../../../admin-static-data/customer-table/productions/accSamdo210518.json"
import accSamduk181218 from "../../../../../admin-static-data/customer-table/productions/accSamduk181218.json"
import accSamhwa210518 from "../../../../../admin-static-data/customer-table/productions/accSamhwa210518.json"
import accSedukacc230907 from "../../../../../admin-static-data/customer-table/productions/accSedukacc230907.json"
import accSeilone230523 from "../../../../../admin-static-data/customer-table/productions/accSeilone230523.json"
import accSeohyun200529 from "../../../../../admin-static-data/customer-table/productions/accSeohyun200529.json"
import accSeonjin201229 from "../../../../../admin-static-data/customer-table/productions/accSeonjin201229.json"
import accSeou190430 from "../../../../../admin-static-data/customer-table/productions/accSeou190430.json"
import accShinhan210414 from "../../../../../admin-static-data/customer-table/productions/accShinhan210414.json"
import accShinwoo181218 from "../../../../../admin-static-data/customer-table/productions/accShinwoo181218.json"
import accSunil210119 from "../../../../../admin-static-data/customer-table/productions/accSunil210119.json"
import accTsacc200914 from "../../../../../admin-static-data/customer-table/productions/accTsacc200914.json"
import accWoori181218 from "../../../../../admin-static-data/customer-table/productions/accWoori181218.json"
import accYeil190607 from "../../../../../admin-static-data/customer-table/productions/accYeil190607.json"
import accYejiacc220912 from "../../../../../admin-static-data/customer-table/productions/accYejiacc220912.json"
import accWooilacc231207 from "../../../../../admin-static-data/customer-table/productions/accWooilacc231207.json"
import accMelonacc231213 from "../../../../../admin-static-data/customer-table/developments/accMelonacc231213.json"
import accSejongacc240105 from "../../../../../admin-static-data/customer-table/productions/accSejongacc240105.json"
import accHyeanacc231214 from "../../../../../admin-static-data/customer-table/productions/accHyeanacc231214.json"
import accLemon231121 from "../../../../../admin-static-data/customer-table/developments/accLemon231121.json"
import accMango221013 from "../../../../../admin-static-data/customer-table/developments/accMango221013.json"
//--------
import { ConsoleLog } from "../../../../../components/basic-elements"

const GetCustomer = (self: any) => ({
	getCustomer() {
		const actionName = "getCustomer"

		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		let customerData: any = []
		const customerId = process.env.REACT_APP_CUSTOMER_ID

		switch (customerId) {
			case "accKiwi220429":
				customerData = accKiwi220429
				break

			// NOTE: Productions
			case "accDaejoo211016":
				customerData = accDaejoo211016
				break
			case "accDasan200914":
				customerData = accDasan200914
				break
			case "accDoac210326":
				customerData = accDoac210326
				break
			case "accYnjac190715": // DOHY
				customerData = accYnjac190715
				break
			case "accDonga181220": // DSCPA
				customerData = accDonga181220
				break
			case "accDssk200907":
				customerData = accDssk200907
				break
			case "accEchon200621":
				customerData = accEchon200621
				break
			case "accGyul181218": // EJGY
				customerData = accGyul181218
				break
			case "accHaesolacc220919":
				customerData = accHaesolacc220919
				break
			case "accHangil200921":
				customerData = accHangil200921
				break
			case "accHanmi190607":
				customerData = accHanmi190607
				break
			case "accHanul181024":
				customerData = accHanul181024
				break
			case "accHdcpa191001":
				customerData = accHdcpa191001
				break
			case "accInduk220924":
				customerData = accInduk220924
				break
			case "accJinil201229":
				customerData = accJinil201229
				break
			case "accJungdong181218":
				customerData = accJungdong181218
				break
			case "accJungin210917":
				customerData = accJungin210917
				break
			case "accSerim181024": // Jungjin Serim
				customerData = accSerim181024
				break
			case "accKr180724":
				customerData = accKr180724
				break
			case "accLian190709":
				customerData = accLian190709
				break
			case "accSamdo210518":
				customerData = accSamdo210518
				break
			case "accSamduk181218":
				customerData = accSamduk181218
				break
			case "accSamhwa210518":
				customerData = accSamhwa210518
				break
			case "accSedukacc230907":
				customerData = accSedukacc230907
				break
			case "accSeilone230523":
				customerData = accSeilone230523
				break
			case "accSeohyun200529":
				customerData = accSeohyun200529
				break
			case "accSeonjin201229":
				customerData = accSeonjin201229
				break
			case "accSeou190430":
				customerData = accSeou190430
				break
			case "accShinhan210414":
				customerData = accShinhan210414
				break
			case "accShinwoo181218":
				customerData = accShinwoo181218
				break
			case "accSunil210119":
				customerData = accSunil210119
				break
			case "accTsacc200914":
				customerData = accTsacc200914
				break
			case "accWoori181218":
				customerData = accWoori181218
				break
			case "accYeil190607":
				customerData = accYeil190607
				break
			case "accYejiacc220912":
				customerData = accYejiacc220912
				break
			case "accWooilacc231207":
				customerData = accWooilacc231207
				break
			case "accMelonacc231213":
				customerData = accMelonacc231213
				break
			case "accSejongacc240105":
				customerData = accSejongacc240105
				break
			case "accHyeanacc231214":
				customerData = accHyeanacc231214
				break
			case "accLemon231121":
				customerData = accLemon231121
				break
			case "accMango221013":
				customerData = accMango221013
				break
			default:
				console.log("No Matched Customer Data")
		}

		ConsoleLog("customerData:", customerData)
		const {
			accountId, // "526722991097"
			aliasId, // "kiwi"
			category,
			description,
			id, // "accKiwi220429"
			menus,
			name,
			status,
		} = customerData

		const customerInfo = {
			category,
			description,
			id,
		}
		self.setCustomerFromAdminApp(customerInfo)

		// NOTE: current admin app doesn't add index when it update the customer
		// --> add the index temporary in this step
		// --> this index has no impact in the app
		// --> it should replace all-app-menus' index in the future
		// to manage the menu orders by customer
		const tempMenus = menus.map((menu: any) => {
			return {
				...menu,
				index: 0,
			}
		})
		self.setAllowedMenus(tempMenus)
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.success,
		})
	},
})

export default GetCustomer
