import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	DLButton,
	DLRadio,
	DLRadioGroup,
	DLDatepickerWithoutInput,
} from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import styled from "styled-components"
import { DLCheckbox } from "@datalobby/components/lib"
import { useRootStore } from "../../../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"

export default observer(function RoleChangeDialog({
	i18n,
	partialStore,
}: {
	i18n: DLI18nProps
	partialStore: any
}) {
	const orgStore = useOrgStore()

	const [inputs, setInputs] = useState({
		accessRight: "read-only",
		accessExpiryDate: null,
		rentalAvailable: false,
		canAERHardCopyAdmin: false,
		canAERReadOnlyUser: false,
		canAERHardCopyData: false,
		canRentReturnReadOnlyUser: false,
	})

	const isArchiveAdmin = orgStore.checkin.orgInfo.isArchiveAdmin
	useEffect(() => {
		if (isArchiveAdmin) {
			setInputs((prev: any) => ({
				...prev,
				accessRight: "hard-copy-admin",
				canAERHardCopyAdmin: true,
				canAERReadOnlyUser: true,
				canAERHardCopyData: true,
				canRentReturnReadOnlyUser: true,
			}))
		}
	}, [])

	const handleInputs = (e: any) => {
		const { name, value } = e.target
		console.log(name, value)
		if (name === "accessRight") {
			setInputs((prev: any) => ({
				...prev,
				accessRight: value,
			}))
		} else {
			setInputs((prev: any) => ({
				...prev,
				[name]: e.target.checked,
			}))
		}
	}

	const handleDate = (date: any) => {
		setInputs((prev: any) => ({
			...prev,
			accessExpiryDate: date,
		}))
	}

	return (
		<DLDialog
			eleTestId="role-change-dialog"
			isOpen={partialStore.openRoleChangeDialog}
			setIsOpen={partialStore.setOpenRoleChangeDialog}
			showCloseBtn={true}
			dialogTitle="Edit Hard Copy User Access"
			dialogContents={
				<RoleChangeDialogContent
					inputs={inputs}
					handleInputs={handleInputs}
					handleDate={handleDate}
					isArchiveAdmin={isArchiveAdmin}
				/>
			}
			// actionReady={isReady || userEmail === inputs.email.value}
			maxWidth="sm"
			actionBtn="Proceed"
			cancelBtnText={i18n.twCancel || "Cancel"}
			// 			showSpinner={archiveMgmtStore.getActionStatus("getUsers") !== "SUCCESS"}
			// 			cannotUpdate={archiveMgmtStore.getActionStatus("getUsers") !== "SUCCESS"}
		/>
	)
})

const RoleChangeDialogContent = ({
	inputs,
	handleInputs,
	handleDate,
	isArchiveAdmin,
}: {
	inputs: any
	handleInputs: any
	handleDate: any
	isArchiveAdmin: boolean
}) => {
	const store = useRootStore()

	return (
		<StyledContent>
			<h3>User Information</h3>
			<hr />
			<div className="info-section">
				<RowItem label="Name" value="" />
				<RowItem label="Aliad Id" value="" />
				<RowItem label="Email" value="" />
			</div>
			<h3>Access Right</h3>
			<hr />
			<div className="access-right-section">
				<DLRadioGroup
					groupName="accessRight"
					itemDirection="row"
					items={[
						{
							value: "read-only",
							label: "Read Only",
						},
						{
							value: "hard-copy-admin",
							label: "Hard Copy Admin",
						},
					]}
					disabled={isArchiveAdmin}
					selectedValue={inputs.accessRight}
					eleOnChange={handleInputs}
				/>
				{inputs.accessRight === "read-only" ? (
					<div className="FC">
						<div className="FR rental-available">
							<DLCheckbox
								eleClassName="rental-available-checkbox"
								eleTestId="rental-available"
								eleName="rentalAvailable"
								clickHandler={handleInputs}
								isChecked={inputs.rentalAvailable}
							/>
							<div>Rental Is Available</div>
						</div>
						<div className="FR">
							<span>Access Expiry Date</span>
							<DLDatepickerWithoutInput
								eleTestId={`testid-access-expiry-date`}
								onChange={(date: any) => handleDate(date)}
								autoOk
								format={store.global.dateFormat.value}
							/>
							<div
								className="access-expiry FR AC"
								data-testid="access-expiry-display"
								style={{
									marginLeft: "1rem",
									opacity: 0.3,
								}}
							>
								{inputs.accessExpiryDate}
							</div>
						</div>
					</div>
				) : (
					<div className="FC">
						<div className="FR">
							<DLCheckbox
								eleClassName="aer-hard-copy-admin-checkbox"
								eleTestId="aer-hard-copy-admin"
								eleName="canAERHardCopyAdmin"
								clickHandler={handleInputs}
								isChecked={inputs.canAERHardCopyAdmin}
							/>
							<div>
								Able to add / edit / remove hard copy admin
							</div>
						</div>
						<div className="FR">
							<DLCheckbox
								eleClassName="aer-read-only-user-checkbox"
								eleTestId="aer-read-only-user"
								eleName="canAERReadOnlyUser"
								clickHandler={handleInputs}
								isChecked={inputs.canAERReadOnlyUser}
							/>
							<div>
								Able to add / edit / remove read only user
							</div>
						</div>
						<div className="FR">
							<DLCheckbox
								eleClassName="aer-hard-copy-data-checkbox"
								eleTestId="aer-hard-copy-data"
								eleName="canAERHardCopyData"
								clickHandler={handleInputs}
								isChecked={inputs.canAERHardCopyData}
							/>
							<div>
								Able to add / edit / remove hard copy data
							</div>
						</div>
						<div className="FR">
							<DLCheckbox
								eleClassName="rent-return-read-only-user-checkbox"
								eleTestId="rent-return-read-only-user"
								eleName="canRentReturnReadOnlyUser"
								clickHandler={handleInputs}
								isChecked={inputs.canRentReturnReadOnlyUser}
							/>
							<div>Able to rent / return read only user</div>
						</div>
					</div>
				)}
			</div>
		</StyledContent>
	)
}

const RowItem = ({ label, value }: { label: string; value: string }) => (
	<div className="FR row-item">
		<div className="label">{label}</div>
		{value}
	</div>
)

const StyledContent = styled.div`
	.info-section {
		margin-left: 1rem;
		margin-bottom: 2rem;
	}
	.access-right-section {
		margin-left: 1rem;
	}
`
