import React from "react"
import { Link } from "react-router-dom"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ConsoleLog } from "../../../../components/basic-elements"
import {
    NormalProjectIcon,
    NormalLockedProjectIcon,
    ReplicaProjectIcon,
    ArchivedProjectIcon,
    UnarchivedProjectIcon,
} from "../../../../components/basic-elements/icons/common-project-icons"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

const RecentProjects = observer(
    ({
        partialStore,
        actionStatus,
        dateFormat,
        contentsHeight,
        i18n,
    }: {
        partialStore: any
        actionStatus: ActionStatus
        dateFormat: string
        contentsHeight: number
        i18n: DLI18nProps
    }) => {
        ConsoleLog(" __________ RecentProjects __________ ")
        const store = useRootStore()

        const timeZone = store.global.timeZone.uiValue

        console.log(store.ui.contentsAreaHeight)

        return (
            <PageContainer
                pageTools={<div />}
                hasToolbar={false}
                fullWidth
                fixedHeight
                isLoading={actionStatus === ActionStatus.loading}
            >
                {actionStatus === ActionStatus.success && (
                    <StyledRecentProjectsTable>
                        <ReactTableV8
                            tableColumns={columns(partialStore, i18n)}
                            data={partialStore.formattedRecentProjList(
                                dateFormat,
                                timeZone
                            )}
                            hasPagination={false}
                            showPageSetting={false}
                            menuId={DLOrgSubMenus.recent}
                            height={store.ui.contentsAreaHeight + 75} // because this table doesn't have pagination
                        />
                    </StyledRecentProjectsTable>
                )}

                <CommonSnackbarComponent
                    i18n={i18n}
                    partialStore={partialStore}
                />
            </PageContainer>
        )
    }
)

export default RecentProjects

const StyledRecentProjectsTable = styled.div`
    padding: 0 1rem;
    .cannot-access {
        opacity: 0.3;
        /* color: ${(props) => props.theme.shade20}; */
        cursor: not-allowed;
    }
    table > thead > tr > th {
        padding: 0;
        .header {
            min-height: 2rem;
            padding-left: 8px;
            justify-content: center;
        }
    }
    table > tbody > tr > td {
        padding: 8px;
    }
`

const columns = (partialStore: any, i18n: DLI18nProps) => [
    {
        header: "Status",
        accessorKey: "statusName",
        size: 50,
        cell: (props: any) => {
            const projStatus = props.getValue()
            const cellId = props.cell.column.id + "-cell"

            return (
                <div data-testid={cellId}>
                    {projStatus === ProjectStatus.normal &&
                    !props.row.original.isLocked ? (
                        <NormalProjectIcon size={1} />
                    ) : projStatus === ProjectStatus.normal &&
                      props.row.original.isLocked ? (
                        <div className="FR AC regular-text">
                            <NormalLockedProjectIcon size={1} />
                        </div>
                    ) : projStatus === ProjectStatus.replica ? (
                        <div className="FR AC regular-text">
                            <ReplicaProjectIcon size={1} />
                        </div>
                    ) : projStatus === ProjectStatus.archived ? (
                        <div className="FR AC regular-text">
                            <ArchivedProjectIcon size={1} />
                        </div>
                    ) : projStatus === ProjectStatus.unarchived ? (
                        <div className="FR AC regular-text">
                            <UnarchivedProjectIcon size={1} />
                        </div>
                    ) : (
                        <div>UnKnown Status</div>
                    )}
                </div>
            )
        },
    },
    {
        header: "Period Type",
        accessorKey: "periodName",
        // filterFn: multiSelectFilter,
        size: 50,
    },
    {
        header: "Client",
        accessorKey: "clientName",
    },
    {
        header: "Project Name",
        accessorKey: "title",
        size: 200,
        cell: (props: any) => {
            const projId = props.row.original.id
            const cellId = props.cell.id + "-cell"
            const projNumId = idToNumber(projId, IdType.project)
            const title = props.getValue()
            const testId =
                "title-cell-" + title.toLowerCase().replace(/ /g, "-")
            return (
                <>
                    {props.row.original.accessible ? (
                        <Link
                            to={`/project/checkin/${projNumId}`}
                            data-testid={testId}
                        >
                            {title}
                        </Link>
                    ) : (
                        <div data-testid={testId}>{title}</div>
                    )}
                </>
            )
        },
    },
    {
        header: "Assigned Role",
        accessorKey: "roleName",
    },
    {
        header: "Project Version",
        accessorKey: "version",
        size: 50,
    },
    {
        header: "Created By",
        accessorFn: (pros: any) => pros.createdBy.name,
        accessorKey: "createdBy",
        size: 80,
    },
    {
        header: "Last Access Date",
        accessorKey: "lastAccessAt",
        // filterFn: dateRangeFilter,
    },
]
