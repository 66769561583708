import React from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import {
	NormalProjectIcon,
	NormalLockedProjectIcon,
	ReplicaProjectIcon,
	ArchivedProjectIcon,
	UnarchivedProjectIcon,
} from "../../../../components/basic-elements/icons/common-project-icons"
import styled from "styled-components"
import { DLTooltip } from "@datalobby/components"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"

export default observer(function ProjTitle() {
	const projStore = useProjStore()

	const project = projStore.projInfo.projInfo
	const {
		statusName,
		isLocked,
		engTypeName,
		clientName,
		financialYear,
		version,
	} = project

	return (
		<StyledProjectTitle className="FR AC">
			{statusName === ProjectStatus.normal && !isLocked ? (
				<NormalProjectIcon size={1.2} />
			) : statusName === ProjectStatus.normal && isLocked ? (
				<NormalLockedProjectIcon size={1.2} />
			) : statusName === ProjectStatus.replica ? (
				<ReplicaProjectIcon size={1.2} />
			) : statusName === ProjectStatus.archived ? (
				<ArchivedProjectIcon size={1.2} />
			) : statusName === ProjectStatus.unarchived ? (
				<UnarchivedProjectIcon size={1.2} />
			) : (
				statusName
			)}
			<DLTooltip title="Project Title">
				<h2>
					{project.projTitle
						? project.projTitle
						: "Project title be failed to fetch. Please reload"}
				</h2>
			</DLTooltip>
			<DLTooltip title="Client">
				<div className="client-name proj-info-item">
					{clientName ? clientName : "-"}
				</div>
			</DLTooltip>
			<DLTooltip title="Fiscal Year">
				<div className="fiscal-year proj-info-item">
					{financialYear ? financialYear : "-"}
				</div>
			</DLTooltip>
			<DLTooltip title="Project Status">
				<div className="project-status proj-info-item">
					{statusName ? statusName : "-"}
				</div>
			</DLTooltip>
			<DLTooltip title="Engagement Type">
				<div className="project-status proj-info-item">
					{engTypeName ? engTypeName : "-"}
				</div>
			</DLTooltip>
			<DLTooltip title="Version">
				<div className="project-status proj-info-item">
					{version ? "v." + version : "-"}
				</div>
			</DLTooltip>
		</StyledProjectTitle>
	)
})

const StyledProjectTitle = styled.div`
	h2 {
		margin-left: 0.8rem;
	}
	.proj-info-item {
		margin-left: 0.8rem;
	}
`
