import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { useOrgStore } from "../../../../../../../stores/org-store/org-store.provider"
import {
	InputWithLabel,
	DLInputField,
	DLTextArea,
	DLButton,
} from "../../../../../../../components/basic-elements"
import DefaultFields from "./DefaultFields"
import CustomFields from "./CustomFields"

export default observer(function AddHardCopyWithoutProject({
	customFields,
	hardCopyInput,
	setHardCopyInput,
	handleHardCopyInput,
	handleHardCopyInputs,
}: {
	customFields: any
	hardCopyInput: any
	setHardCopyInput: any
	handleHardCopyInput: any
	handleHardCopyInputs: any
}) {
	const handleDescription = (value: any) => {
		setHardCopyInput((prev: any) => ({
			...prev,
			description: value,
		}))
	}
	
	return (
		<StyledAddHardCopyWithoutProject>
			<div className="FR">
				<div className="default-fields">
					<h3>1. Default Input Fields</h3>
					<DefaultFields
						input={hardCopyInput}
						handleInput={handleHardCopyInput}
						handleDescription={handleDescription}
						handleHardCopyInputs={handleHardCopyInputs}
						// groupOptions={orgStore.groups.groupOptions}
					/>
				</div>

				<div className="custom-fields">
					<h3>2. Custom Fields</h3>
					{/* <p>* Custom fields reflect the custom columns.</p> */}
					<CustomFields
						fields={customFields}
						input={hardCopyInput}
						handleInput={handleHardCopyInput}
					/>
				</div>
			</div>
		</StyledAddHardCopyWithoutProject>
	)
})

const StyledAddHardCopyWithoutProject = styled.div`
	.default-fields {
		width: 50%;
	}
	.custom-fields {
		width: 50%;
	}
`
