import React from "react"
import { DLIconButton } from "../../../../basic-elements"
import { DLCheckbox } from "@datalobby/components"
import Icon from "@mdi/react"
import { mdiClose } from "@mdi/js"

const UploadFileFormTableColumns = ({
	checkedItemExist,
	checkSignOff,
	toggleAllPrepare,
	toggleAllReview,
	handleFiles,
	renderEditableRefNum,
	renderEditableFileName,
	handleRemoveFile,
	handleToggleCheckbox,
	handlePrepare,
	handleReview,
	handleToggleSelectAll,
	showPrepareCheckbox,
	showReviewCheckbox,
}: {
	checkedItemExist: boolean
	checkSignOff: (signOffType: "prepare" | "review") => boolean
	toggleAllPrepare: (on: boolean) => void
	toggleAllReview: (on: boolean) => void
	handleFiles: any
	renderEditableRefNum: any
	renderEditableFileName: any
	handleRemoveFile: any
	handleToggleCheckbox: any
	handlePrepare: any
	handleReview: any
	handleToggleSelectAll: () => void
	showPrepareCheckbox: boolean
	showReviewCheckbox: boolean
}) => {
	const columns: any = [
		{
			header: (
				<DLCheckbox
					eleTestId="add-file-checkbox-select-all"
					isChecked={checkedItemExist}
					color="primary"
					clickHandler={handleToggleSelectAll}
				/>
			),
			accessorKey: "xxxx",
			size: 15,
			enableSorting: false,
			cell: (props: any) => {
				return (
					<DLCheckbox
						eleTestId="add-file-checkbox"
						isChecked={props.row.original.selected}
						color="primary"
						clickHandler={() =>
							handleToggleCheckbox(props.row.original.tempId)
						}
					/>
				)
			},
		},
		{
			header: "no.",
			accessorKey: "xxxxx",
			size: 15,
			cell: (props: any) => {
				return <div>{props.row.index + 1}</div>
			},
		},
		{
			header: "Ref. num",
			accessorKey: "aliasId",
			cell: renderEditableRefNum,
		},
		{
			header: "File name",
			accessorKey: "name",
			cell: renderEditableFileName,
		},
		{
			header: "type",
			size: 30,
			accessorKey: "extension",
			cell: (props: any) => {
				const testId = props.cell.column.id + "-cell"
				return <div data-testid={testId}>{props.getValue()}</div>
			},
		},
		{
			header: "size",
			size: 30,
			accessorKey: "formattedSize",
			cell: (props: any) => {
				const testId = props.cell.column.id + "-cell"
				return <div data-testid={testId}>{props.getValue()}</div>
			},
		},
	]

	if (showPrepareCheckbox) {
		const prepare = checkSignOff("prepare")
		columns.push({
			header: (
				<div className="FR AC">
					<DLCheckbox
						eleTestId="add-file-checkbox-prepare-all"
						eleClassName="signoff-all-toggle-checkbox prepare"
						color="primary"
						isChecked={prepare}
						clickHandler={() => toggleAllPrepare(!prepare)}
					/>
					Prepare
				</div>
			),
			accessorKey: "prepared",
			size: 20,
			enableSorting: false,
			cell: (props: any) => {
				const testId = "upload-file-checkbox-prepare"
				return (
					<DLCheckbox
						// eleTestId={`checkbox-prepare-${row.original.aliasId}`}
						eleTestId={testId}
						color="primary"
						isChecked={props.getValue()}
						clickHandler={() =>
							handlePrepare(props.row.original.tempId)
						}
					/>
				)
			},
		})
	}

	if (showReviewCheckbox) {
		const review = checkSignOff("review")
		columns.push({
			header: (
				<div className="FR AC">
					<DLCheckbox
						eleTestId="add-file-checkbox-review-all"
						eleClassName="signoff-all-toggle-checkbox review"
						color="primary"
						isChecked={review}
						clickHandler={() => toggleAllReview(!review)}
					/>
					Review
				</div>
			),
			size: 20,
			enableSorting: false,
			accessorKey: "reviewed",
			cell: (props: any) => {
				const testId = "upload-file-checkbox-review"
				return (
					<DLCheckbox
						// eleTestId={`checkbox-review-${row.original.aliasId}`}
						eleTestId={testId}
						color="primary"
						isChecked={props.getValue()}
						clickHandler={() =>
							handleReview(props.row.original.tempId)
						}
					/>
				)
			},
		})
	}

	columns.push({
		header: "Action",
		accessorKey: "xxx",
		size: 15,
		cell: (props: any) => {
			const id = props.row.original.tempId
			const handleClickRemove = () => {
				handleRemoveFile(id)
			}
			const testId = "upload-file-cancel-select"
			return (
				<DLIconButton
					// eleTestId={`cancel-select-${row.original.aliasId}`}
					eleTestId={testId}
					clickHandler={handleClickRemove}
					tooltipText="Cancel select"
				>
					<Icon path={mdiClose} size={0.6} />
				</DLIconButton>
			)
		},
	})

	return columns
}

export default UploadFileFormTableColumns
