import React, { useState } from "react"
import { DLIconButton } from "../../../../../components/basic-elements"
//
import { mdiDotsHorizontal } from "@mdi/js"
import Icon from "@mdi/react"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"

// const OrgSetupGroupsTableColumns = (
// 	handleContextMenu: any,
// 	i18n: DLI18nProps,
// 	permission: PermissionAsObjectProps
// ) => [
// 	{
// 		Header: <SortingIconHeader title={i18n.twName} />,
// 		accessor: "groupName",
// 		Cell: (row: any) => {
// 			const cellId = row.column.id + "-cell"
// 			return <div data-testid={cellId}>{row.value}</div>
// 		},
// 	},
// 	{
// 		Header: <SortingIconHeader title={i18n.twTotalUsers} align="center" />,
// 		accessor: "userCount",
// 		style: { justifyContent: "center" },
// 		minWidth: 40,
// 		filterable: false,
// 		Cell: (row: any) => {
// 			const cellId = row.column.id + "-cell"
// 			return (
// 				<div
// 					className="FR AC JC"
// 					style={{ width: "100%" }}
// 					data-testid={cellId}
// 				>
// 					{row.value}
// 				</div>
// 			)
// 		},
// 	},
// 	{
// 		Header: <SortingIconHeader title={i18n.twAssignedUsers} />,
// 		accessor: "assignedUsers",
// 		Cell: (row: any) => {
// 			const assignedUsers = row.value ? row.value.replace(/,/g, ", ") : ""
// 			const cellId = row.column.id + "-cell"
// 			// const shrinkedValue = assignedUsers.slice(0, 20) + "..."

// 			return <div data-testid={cellId}>{assignedUsers}</div>
// 		},
// 	},
// 	{
// 		Header: (
// 			<SortingIconHeader title={i18n.twTotalClients} align="center" />
// 		),
// 		accessor: "clientsCount",
// 		style: { justifyContent: "center" },
// 		minWidth: 40,
// 		filterable: false,
// 		Cell: (row: any) => {
// 			const cellId = row.column.id + "-cell"

// 			return (
// 				<div
// 					className="FR AC JC"
// 					style={{ width: "100%" }}
// 					data-testid={cellId}
// 				>
// 					{row.value}
// 				</div>
// 			)
// 		},
// 	},
// 	{
// 		Header: <SortingIconHeader title={i18n.twAssignedClients} />,
// 		accessor: "assignedClients",
// 		Cell: (row: any) => {
// 			const assignedClients = row.value
// 				? row.value.replace(/,/g, ", ")
// 				: ""
// 			// const shrinkedValue = row.value.slice(0, 20) + "..."
// 			const cellId = row.column.id + "-cell"
// 			return <div data-testid={cellId}>{assignedClients}</div>
// 		},
// 	},
// 	{
// 		Header: <div className="FR JC">Action</div>,
// 		accessor: "xxx",
// 		minWidth: 30,
// 		filterable: false,
// 		sortable: false,
// 		show: permission.update || permission.delete,
// 		Cell: (row: any) => {
// 			const groupId = row.original.groupId

// 			return (
// 				<div className="FR AC JC" style={{ width: "100%" }}>
// 					<DLIconButton
// 						aria-label="more"
// 						clickHandler={(event: any) =>
// 							handleContextMenu(event, groupId)
// 						}
// 						eleTestId="group-ctx-btn-on-row"
// 					>
// 						<Icon path={mdiDotsHorizontal} size={0.8} />
// 					</DLIconButton>
// 				</div>
// 			)
// 		},
// 	},
// ]

const renderCustomCell = (props: any) => {
    const cellId = props.cell.column.id + "-cell"
    return <div data-testid={cellId}>{props.getValue()}</div>
}

const OrgSetupGroupsTableColumns = (
    handleContextMenu: any,
    i18n: DLI18nProps,
    handleClick: (groupId: string, type: string) => void
) => [
    {
        header: i18n.twName,
        accessorKey: "groupName",
        size: 120,
        cell: renderCustomCell,
    },
    {
        header: i18n.twAssignedUsers,
        accessorKey: "userCount",
        size: 32,
        enableColumnFilter: false,
        cell: (props: any) => {
            const cellId = props.cell.column.id + "-cell"
            const groupId = props.row.original.groupId
            return (
                <a
                    data-testid={cellId}
                    onClick={() => handleClick(groupId, "Users")}
                >
                    {props.getValue()}
                </a>
            )
        },
    },
    // {
    //     header: i18n.twAssignedUsers,
    //     accessorKey: "assignedUsers",
    //     size: 240,
    //     cell: (props: any) => {
    //         const cellId = props.cell.column.id + "-cell"
    //         const itemList = props.getValue()
    //             ? props
    //                   .getValue()
    //                   .split(",")
    //                   .map((item: string) => item.trim())
    //             : []

    //         return <ShowMoreColumn itemList={itemList} cellId={cellId} />
    //     },
    // },
    {
        header: i18n.twAssignedClients,
        accessorKey: "clientsCount",
        size: 32,
        enableColumnFilter: false,
        cell: (props: any) => {
            const cellId = props.cell.column.id + "-cell"
            const groupId = props.row.original.groupId
            return (
                <a
                    data-testid={cellId}
                    onClick={() => handleClick(groupId, "Clients")}
                >
                    {props.getValue()}
                </a>
            )
        },
    },
    // {
    //     header: i18n.twAssignedClients,
    //     accessorKey: "assignedClients",
    //     size: 240,
    //     cell: (props: any) => {
    //         const cellId = props.cell.column.id + "-cell"
    //         const itemList = props.getValue()
    //             ? props
    //                   .getValue()
    //                   .split(",")
    //                   .map((item: string) => item.trim())
    //             : []
    //         return <ShowMoreColumn itemList={itemList} cellId={cellId} />
    //     },
    // },
    {
        header: "Action",
        accessorKey: "xxx",
        enableColumnFilter: false,
        size: 32,
        cell: (props: any) => {
            const groupId = props.row.original.groupId

            return (
                <div className="FR AC JC" style={{ width: "100%" }}>
                    <DLIconButton
                        aria-label="more"
                        clickHandler={(event: any) =>
                            handleContextMenu(event, groupId)
                        }
                        eleTestId="group-ctx-btn-on-row"
                    >
                        <Icon path={mdiDotsHorizontal} size={0.8} />
                    </DLIconButton>
                </div>
            )
        },
    },
]

export default OrgSetupGroupsTableColumns
