import React from "react"
import styled from "styled-components"

const AddTemplateForm = ({ partialStore }: { partialStore: any }) => {
	return <StyledAddTemplateForm>AddTemplateForm</StyledAddTemplateForm>
}

const StyledAddTemplateForm = styled.div``

export default AddTemplateForm
