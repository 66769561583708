import { types } from "mobx-state-tree"
// model & view-model
import HcMgmtConfigModel from "./hc-mgmt-config.model"
import HcMgmtConfigViewModel from "./hc-mgmt-config.view-model"
import { initialClickPoint } from "../../../../../common-models/types/dialog.props"
import {
	HcMgmtConfigApiProps,
	readCustomColumns,
	removeCustomColumn,
	createCustomColumn,
	updateCustomColumn
} from "./hc-mgmt-config.apis"
import { HcMgmtUserDialogType as DialogType } from "./data-models/hard-copy-user-model"

export const HcMgmtConfigStore = ({
	apiReadCustomColumns,
	apiRemoveCustomColumn,
	apiAddCustomColumn,
	apiEditCustomColumn
}: HcMgmtConfigApiProps) =>
	types
		.compose(
			HcMgmtConfigModel({
				apiReadCustomColumns,
				apiRemoveCustomColumn,
				apiAddCustomColumn,
				apiEditCustomColumn
			}),
			HcMgmtConfigViewModel
		)
		.named("HcMgmtConfigStore")

export const initialStore = {
	clickPoint: initialClickPoint,
	dialogType: DialogType.suspend,
	// viewModel related parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const HcMgmtConfigStoreInstance = HcMgmtConfigStore({
	apiReadCustomColumns: readCustomColumns,
	apiRemoveCustomColumn: removeCustomColumn,
	apiAddCustomColumn: createCustomColumn,
	apiEditCustomColumn: updateCustomColumn
}).create(initialStore)

export default HcMgmtConfigStoreInstance
