import { flow } from "mobx-state-tree"
import { HardCopyMgmtApiProps } from "../hg-mgmt-hard-copy.apis"

const DeleteCustomColumn = (
	self: any,
	removeCustomColumn: HardCopyMgmtApiProps["apiRemoveCustomColumn"]
) => ({
	deleteCustomColumn: flow(function* (payload: any) {
		const actionName = "deleteCustomColumn"
		try {
			const response = yield removeCustomColumn(payload)
			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default DeleteCustomColumn
