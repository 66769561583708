import React, { useEffect } from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { DLDialog, InputWithLabel, DLInputField } from "../../../basic-elements"
import useForm from "../../../../library/use-form"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { FileScreenDialog } from "../../../../service-modules/file-module/data-models/dl-file-control-model"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import sharedRegEx from "../../../../library/sharedRegEx"
import { NoSpecialChar2Msg } from "../../../basic-elements/system-msg/DLSystemMsg"
import keyboardJS from "keyboardjs"

export default observer(function FileEditDialog({
	partialStore,
	projId,
	i18n,
}: {
	partialStore: any
	projId: string
	i18n: DLI18nProps
}) {
	const actionName = "editFile"
	const dialogName = FileScreenDialog.fileEdit

	const setOpen = (request: boolean) => {
		partialStore.setFileTreeDialogOpen(dialogName, request)
	}

	const selectedItems = partialStore.selectedItems

	let fileId = ""
	if (selectedItems.length > 1) {
		alert("(FileEditDialog) Cannot edit multiple files")
	} else if (selectedItems.length === 1) {
		fileId = selectedItems[0].id
	} else if (selectedItems.length <= 0) {
		alert("(FileEditDialog) No selected items")
	}

	const inputSchema = {
		aliasId: { value: "", error: "", requestInput: false },
		title: { value: "", error: "", requestInput: false },
	}
	const fileInfo = partialStore.fileShortInfo(fileId, "FileEditDialog")
	const prevAliasId = fileInfo?.aliasId
	const prevTitle = fileInfo?.title

	useEffect(() => {
		inputSchema.aliasId.value = prevAliasId
		inputSchema.title.value = prevTitle
	}, [fileInfo])

	const validationSchema = {
		aliasId: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialChar2,
				error: i18n.warningSpecialChar2 || <NoSpecialChar2Msg />,
			},
			maxLength: 50,
		},
		title: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialChar2,
				error: i18n.warningSpecialChar2 || <NoSpecialChar2Msg />,
			},
			maxLength: 50,
		},
	}

	const handleEditFile = () => {
		partialStore.editFile({
			projId,
			fileId,
			aliasId: inputs.aliasId.value,
			title: inputs.title.value,
		})
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputSchema,
		validationSchema,
		handleEditFile
	)

	const aliasId = inputs.aliasId.value
	const title = inputs.title.value

	let actionStatus = partialStore.getActionStatus(actionName)
	const open = partialStore.fileTreeDialogOpenStatus[dialogName]

	const isDuplicatedAliasId =
		aliasId !== prevAliasId && partialStore.isDuplicatedAliasId(aliasId)
	const isDuplicatedName =
		title !== prevTitle && partialStore.isDuplicatedName(title)

	return (
		<DLDialog
			eleTestId="file-edit-dialog"
			isOpen={open}
			setIsOpen={setOpen}
			handleAction={handleOnSubmit}
			showOpenBtn={false}
			showCloseBtn
			dialogTitle={i18n.twEditFileInfo || "Edit File Info"}
			dialogContents={
				<FileEditForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					isDuplicatedAliasId={isDuplicatedAliasId}
					isDuplicatedName={isDuplicatedName}
					i18n={i18n}
				/>
			}
			actionBtn={i18n.twEdit || "Edit"}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={
				isReady &&
				!isDuplicatedAliasId &&
				!isDuplicatedName &&
				(prevTitle !== title || prevAliasId !== aliasId)
			}
			showSpinner={actionStatus === ActionStatus.loading}
			draggable
		/>
	)
})

const FileEditForm = observer(
	({
		inputs,
		handleOnChange,
		isDuplicatedAliasId,
		isDuplicatedName,
		i18n,
	}: {
		inputs: any
		handleOnChange: (event: any) => void
		isDuplicatedAliasId: boolean
		isDuplicatedName: boolean
		i18n: DLI18nProps
	}) => {
		return (
			<StyledFileEditDialog>
				<div className="input-section FR">
					<InputWithLabel
						required
						label={i18n.twRefNum || "Ref. Num"}
					>
						<DLInputField
							// NOTE: MUST USE SAME TEST-ID with Add file dialog
							autoFocus
							eleTestId="aliasId-input"
							eleName="aliasId"
							eleValue={inputs.aliasId.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								isDuplicatedAliasId
									? i18n.tsSameNameExist ||
									  "The same name already exist"
									: inputs.aliasId.error
							}
							requestInput={inputs.aliasId.requestInput}
							eleFullWidth
						/>
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel
						required
						label={i18n.twFileName || "File Name"}
					>
						<DLInputField
							// NOTE: MUST USE SAME TEST-ID with Add file dialog
							eleTestId="fileName-input"
							eleName="title"
							eleValue={inputs.title.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								isDuplicatedName
									? i18n.tsSameNameExist ||
									  "The same name already exist"
									: inputs.title.error
							}
							requestInput={inputs.title.requestInput}
							eleFullWidth
						/>
					</InputWithLabel>
				</div>
			</StyledFileEditDialog>
		)
	}
)

const StyledFileEditDialog = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
