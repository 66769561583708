import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import Dashboard from "./Dashboard"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { useHcMgmtStore } from "../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { getDefaultYear } from "../../../../library/get-page-settings"

export default observer(function DashboardController() {
    const store = useRootStore()
	const orgStore = useOrgStore()
	const hcMgmtStore = useHcMgmtStore()
	//
	const menuId = DLOrgSubMenus.hard_copy_dashboard
	const actionName = "getMyRentalList"
	const i18nSheet = OrgI18n.hardCopyMgmt
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const needRefresh = hcMgmtStore.hardCopy.needRefresh
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	useEffect(() => {
		const orgId = orgStore.checkin.orgInfo.orgId.split("Id").join("")
		const year = getDefaultYear(menuId)
		hcMgmtStore.config.getCustomColumns(orgId)
		hcMgmtStore.hardCopy.getHardCopyList(orgId, year)
	}, [])

	const i18n = store.i18n.combineI18n(i18nSheet, "HardCopyMgmt")
    return (
		<>
			{menuAccess ? (
				<Dashboard
					partialStore={hcMgmtStore.hardCopy}
					fetchingStatus={hcMgmtStore.hardCopy.getActionStatus(
						actionName
					)}
					permission={permission}
					i18n={i18n}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})