// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { flow } from "mobx-state-tree"
import axios from "axios"
import { RentHardCopyProps } from "../hc-mgmt-rental.apis"

export type DirectS3UploadProps = {
	url: string
	file: any
	fields: any
}

const GetSignedUrl = (self: any) => ({
	// NOTE: update the file type
	getSignedUrl({
		params,
		postAction,
	}: {
		params: RentHardCopyProps
		postAction?: (data: any) => void
	}) {
		// Check the total files size before run this
		const actionName = "getSignedUrl"

		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		const { files } = params
		files.map((targetFile: any, i: number) => {
			// 1. get signed url for target file
			console.log("targetFile:", i, targetFile)
			const targetFileInfo = {
				allowedSeconds: 60,
				fileName: targetFile.fileName,
				extension: targetFile.extension,
				contentType: targetFile.file.type,
			}
			console.log("targetFileInfo---", targetFileInfo)
			self.readSignedUrl(targetFileInfo).then((response: any) => {
				if (response.status === 200) {
					console.log(actionName, "response:", response)

					/**
					 * ! old response example
					 * * response.data.data
					 * - data: {
					 * --- key: rental-attachments/01GBRW8NGTPHB75BP1P5Z33H8A_skull.png
					 * --- signedUrl: https://dl-hard-copy-poc-acck....
					 * --- expires: 2022-08-31T02:39:42.410Z
					 * }
					 * - functionName: _getSignedUrl
					 * - message: ""
					 * - messsageCode: "..."
					 */

					/**
					 * * New resonse example
					 data: {
						fields: {
							bucket: "dl-hard-copy-poc-acckiwi220429-rentals-bucket", X-Amz-Algorithm: "AWS4-HMAC-SHA256",…}
							Policy: "eyJleHBpcmF0aW9uIjoiMjAyMi0wOS0wMVQwODoyMTozNFoiLCJjb25kaXRpb25zIjpbeyJidWNrZXQiOiJkbC1oYXJkLWNvcHktcG9jLWFjY2tpd2kyMjA0MjktcmVudGFscy1idWNrZXQifSx7IlgtQW16LUFsZ29yaXRobSI6IkFXUzQtSE1BQy1TSEEyNTYifSx7IlgtQW16LUNyZWRlbnRpYWwiOiJBU0lBWFZJWkRBUDQ0UEFZVVJYQy8yMDIyMDkwMS9hcC1ub3J0aGVhc3QtMi9zMy9hd3M0X3JlcXVlc3QifSx7IlgtQW16LURhdGUiOiIyMDIyMDkwMVQwNzIxMzRaIn0seyJYLUFtei1TZWN1cml0eS1Ub2tlbiI6IklRb0piM0pwWjJsdVgyVmpFT2YvLy8vLy8vLy8vd0VhRG1Gd0xXNXZjblJvWldGemRDMHlJa2d3UmdJaEFNWElMY0hBcHlmUWJMYUVSU3pkSmtqMEduODhOL2p0clJQSWtBQllVRW5JQWlFQW82YjE2dTNzbGluNVd0UEJwZmZHcm5lYmpUc2laQnlWdDBROTUxR2ttWWNxcGdNSWNCQUFHZ3cxTWpZM01qSTVPVEV3T1RjaURQbkRsMTJ0WkdPNlRGaDZXQ3FEQTczVFBZclZmWlUyeHVBOEF6NTl5N0hiNllOM2V5aHVob1NTVzYwVkhhdWUvbUFlRW9WaDRaQ2s0dlVsZ1JDVksyQThEMG8rYXp3WFpYVlhabG83OENYR0N6QXIyd0loUnNWSkZ0TEZIY3dpK3RZTW9yRHJOK1hkV2g3c0s5RkVJYU5xM25zeWQraEYxbG9qU1l6ZFErQVE3TVBRblNta0k1VG44UENaSFc1czlLL2xNMjR0Ym81YnlUbmtGWS9iSGdHd3V4SWZvSzAzY2I3WlppdmVjUUJtaEQ1S3pQWk9kZXJEbjhMc2xDa0NWR0VvYlVETjBzMS9PdFUvUFh1Qjd1TzJ1OVZNbllJaE1rb2E1VFV1WXB4TGhGSzJkazNVTWhtUU1qWDU5VEt5bUwyUnFuKytnVmNNN0ljYmVrRENzTHZrYy9nR2c5RnBvZ1JlTkR3T1JXdWo1dXVtTVJTOVB0SklQTFRZZ0ZZWGQyVjBMU3FNbGRocHRIbUJMUHRuNzBwQW9PcDFjcDdMbS9VWktNd1psU3FrbzhsQ0YwU2tJQmVBZVpJRUNKYituc2h1NitVQmNLWXp2UEQ3UDlEMU94Z3g2dmwreElZSk5uT0tYMWt0MkNLMHpoVEg1cGFsR2Rrd0x5YW5DYXIxVk14NklJNmswZHlkajgrdnowWFZTbTZuYlREOXVzR1lCanFjQWZmenFRamFVSDJRMGFsaFVYSGJBWnBqVS9IS2Flc2R5MUNZSXZpb0lnZktOY0Rzd3pRZXh4OHVGem8xajZRYWZvOGR3WVlaeDY2QXZaSkxHcXp5N3NCaFJiRi9yV0JxVGFNZFdRRzgxZnVCcU5IdnBBV1lMaEZDK05JZVVlUE00ODh6STljeGptVDluRjRMWmo5aFpPL1huUkR4Rmp1TGFTNWhJZEVMQmE4Ty8zbjlQem1xN1ZrcGxIQU1wUnRPUVA5d1U4TnFVT3QwN0NJRU9nPT0ifSx7ImtleSI6InJlbnRhbC1hdHRhY2htZW50cy8wMUdCVllURDdIV1ZSODBENUFWR0FDOTkxVF9mb3JtYXQtcGRmLnBkZiJ9XX0="
							X-Amz-Algorithm: "AWS4-HMAC-SHA256"
							X-Amz-Credential: "ASIAXVIZDAP44PAYURXC/20220901/ap-northeast-2/s3/aws4_request"
							X-Amz-Date: "20220901T072134Z"
							X-Amz-Security-Token: "IQoJb3JpZ2luX2VjEOf//////////wEaDmFwLW5vcnRoZWFzdC0yIkgwRgIhAMXILcHApyfQbLaERSzdJkj0Gn88N/jtrRPIkABYUEnIAiEAo6b16u3slin5WtPBpffGrnebjTsiZByVt0Q951GkmYcqpgMIcBAAGgw1MjY3MjI5OTEwOTciDPnDl12tZGO6TFh6WCqDA73TPYrVfZU2xuA8Az59y7Hb6YN3eyhuhoSSW60VHaue/mAeEoVh4ZCk4vUlgRCVK2A8D0o+azwXZXVXZlo78CXGCzAr2wIhRsVJFtLFHcwi+tYMorDrN+XdWh7sK9FEIaNq3nsyd+hF1lojSYzdQ+AQ7MPQnSmkI5Tn8PCZHW5s9K/lM24tbo5byTnkFY/bHgGwuxIfoK03cb7ZZivecQBmhD5KzPZOderDn8LslCkCVGEobUDN0s1/OtU/PXuB7uO2u9VMnYIhMkoa5TUuYpxLhFK2dk3UMhmQMjX59TKymL2Rqn++gVcM7IcbekDCsLvkc/gGg9FpogReNDwORWuj5uumMRS9PtJIPLTYgFYXd2V0LSqMldhptHmBLPtn70pAoOp1cp7Lm/UZKMwZlSqko8lCF0SkIBeAeZIECJb+nshu6+UBcKYzvPD7P9D1Oxgx6vl+xIYJNnOKX1kt2CK0zhTH5palGdkwLyanCar1VMx6II6k0dydj8+vz0XVSm6nbTD9usGYBjqcAffzqQjaUH2Q0alhUXHbAZpjU/HKaesdy1CYIvioIgfKNcDswzQexx8uFzo1j6Qafo8dwYYZx66AvZJLGqzy7sBhRbF/rWBqTaMdWQG81fuBqNHvpAWYLhFC+NIeUePM488zI9cxjmT9nF4LZj9hZO/XnRDxFjuLaS5hIdELBa8O/3n9Pzmq7VkplHAMpRtOQP9wU8NqUOt07CIEOg=="
							X-Amz-Signature: "62225eef590950fc1cbdd6f8e2c5c63bf6a7f71af5b91662b26fcd27e48a1eaf"
							bucket: "dl-hard-copy-poc-acckiwi220429-rentals-bucket"
							key: "rental-attachments/01GBVYTD7HWVR80D5AVGAC991T_format-pdf.pdf"
						url: "https://s3.ap-northeast-2.amazonaws.com/dl-hard-copy-poc-acckiwi220429-rentals-bucket"
					 functionName: "_getSignedUrl"
					 message: ""
					 messageCode: "MsgCodeDoesNotExistYet"

					  */

					// 2. upload file to the signed url
					// const { signedUrl, key } = response.data.data
					// const payloadForS3Upload = {
					// 	...response.data.data,
					// 	file: targetFile.file,
					// 	contentType: targetFile.file.type,
					// }

					// console.log("payloadForS3Upload:", payloadForS3Upload)

					self.uploadFileToSignedUrl({
						presigned: response.data.data,
						file: targetFile.file,
					})
						.then((response: any) => {
							console.log(
								"uploadFileToSignedUrl response:",
								response
							)
							if (response === "SUCCEEDED") {
								if(files.length === i + 1){
									self.rentHardCopy({ params, postAction })
								}
							}
						})
						.catch((err: Error) => {
							console.log("err:", err)
						})
				}
			})
		})
	},
	uploadFileToSignedUrl: flow(function* ({
		presigned,
		file,
	}: {
		presigned: { fields: any; url: string }
		file: any
	}) {
		const actionName = "uploadFileToSignedUrl"

		console.log(actionName, "presigned:", presigned)

		//  s3Params: {
		// 		Bucket: 'dl-hard-copy-poc-acckiwi220429-rentals-bucket',
		// 		Key: 'rental-attachments/01GBVTTAHAGSHKF2SMV4KY27KM_skull.png',
		// 		Expires: 2022-09-01T06:12:37.130Z,
		// 		ContentType: 'image/png'
		//  }
		// const httpOptions = { headers: { "Content-Type": payload.contentType } }
		let formData = new FormData()
		for (const key in presigned.fields) {
			formData.append(key, presigned.fields[key])
		}
		// formData.append("Content-Type", file.type)
		formData.append("file", file)

		const instance = axios.create({
			headers: { authorization: "" },
		})

		return yield instance
			.post(presigned.url, formData)
			.then((response) => {
				console.log("response:", response)
				if (response.status === 204) {
					console.log("Succeeded to upload.")
					return "SUCCEEDED"
				}
			})
			.catch((error) => {
				console.log("error", error)
			})
		// yield axios
		// 	.put(payload.signedUrl, payload.file, httpOptions)
		// 	.then((response) => {
		// 		console.log("response:", response)
		// 	})
		// 	.catch((error) => {
		// 		console.log("error", error)
		// 	})

		// yield fetch(
		// 	new Request(payload.signedUrl, {
		// 		method: "PUT",
		// 		body: { ...payload.file, name: payload.key },
		// 		headers: new Headers({
		// 			"Content-Type": payload.contentType,
		// 		}),
		// 	})
		// )
		// 	.then((response) => {
		// 		if (response.ok) {
		// 			console.log("response:", response)
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		console.log("error")
		// 	})
	}),
})

export default GetSignedUrl
