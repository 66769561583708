import React from "react"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { SortingIconHeader } from "../../../../components/basic-elements"
import DLDateRangepicker from "../../../../components/basic-elements/date-picker/DLDateRangePicker"
import { InputFieldForTableV8 } from "../../../../components/basic-elements/tables/InputFieldForTable"
import { Column, Table } from "@tanstack/react-table"
import { dateRangeFilter } from "../../../../library/react-table-utils"

export const filterComponent = ({
	column,
	table,
	dateFormat,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
}) => {
	switch (column.id) {
		case "requestedDate":
		case "dueDate":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const PBCTableColumns = (partialStore: any, i18n: DLI18nProps) => [
	{
		header: i18n.twSelect || "Select",
		accessorKey: "select",
		size: 30,
		cell: (props: any) => {
			return <div data-testid="select-cell">{props.getValue()}</div>
		},
	},
	{
		header: i18n.twArea || "Area",
		accessorKey: "area",
		cell: (props: any) => {
			return <div data-testid="area-cell">{props.getValue()}</div>
		},
	},
	{
		header: i18n.twDescription || "Description",
		accessorKey: "description",
		cell: (props: any) => {
			return <div data-testid="description-cell">{props.getValue()}</div>
		},
	},
	{
		header: i18n.twRequestedDate || "Requested Date",
		accessorKey: "requestedDate",
		filterFn: dateRangeFilter,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return (
				<div data-testid={cellId}>
					{props.getValue() ? props.getValue() : "-"}
				</div>
			)
		},
	},
	{
		header: i18n.twDueDate || "Due Date",
		accessorKey: "dueDate",
		filterFn: dateRangeFilter,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return (
				<div data-testid={cellId}>
					{props.getValue() ? props.getValue() : "-"}
				</div>
			)
		},
	},
	{
		header: i18n.twReminderDays || "Reminder (days)",
		columns: [
			{
				header: "B",
				accessorKey: "before",
				cell: (props: any) => {
					const cellId = props.cell.column.id + "-cell"
					return <div data-testid={cellId}>{props.getValue()}</div>
				},
			},
			{
				header: "A",
				accessorKey: "after",
				cell: (props: any) => {
					const cellId = props.cell.column.id + "-cell"
					return <div data-testid={cellId}>{props.getValue()}</div>
				},
			},
			{
				header: "R",
				accessorKey: "recur",
				cell: (props: any) => {
					const cellId = props.cell.column.id + "-cell"
					return <div data-testid={cellId}>{props.getValue()}</div>
				},
			},
		],
	},
	{
		header: i18n.twRequestedBy || "Requested By",
		columns: [
			{
				header: "Name",
				accessorKey: "name",
				cell: (props: any) => {
					const cellId = props.cell.column.id + "-cell"
					return <div data-testid={cellId}>{props.getValue()}</div>
				},
			},
		],
	},
	{
		header: i18n.twAudUploadDoc || "Auditor Upload Document",
		accessorKey: "uploadDoc",
		cell: (props: any) => {
			return <div data-testid="uploadDoc-cell">{props.getValue()}</div>
		},
	},
	{
		header: i18n.twRequestedTo || "Requested To",
		columns: [
			{
				header: "Name",
				accessorKey: "name",
				cell: (props: any) => {
					const cellId = props.cell.column.id + "-cell"
					return <div data-testid={cellId}>{props.getValue()}</div>
				},
			},
		],
	},
	{
		header: i18n.twClientUploadDoc || "Client Upload Document",
		accessorKey: "clientDoc",
		cell: (props: any) => {
			return <div data-testid="clientDoc-cell">{props.getValue()}</div>
		},
	},
	{
		header: i18n.twNotes || "Notes",
		accessorKey: "notes",
		cell: (props: any) => {
			return <div data-testid="notes-cell">{props.getValue()}</div>
		},
	},
	{
		header: i18n.twStatus || "Status",
		accessorKey: "status",
		cell: (props: any) => {
			return <div data-testid="status-cell">{props.getValue()}</div>
		},
	},
	{
		header: i18n.twActions || "Actions",
		cell: (props: any) => {
			return <div data-testid="actions-cell"></div>
		},
	},
]

export default PBCTableColumns
