import {
    ActionStatus,
    MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const RemoveProjTrash = (self: any) => ({
    removeProjTrashItem(itemId: string) {
        // 0. set actionName
        const actionName = "removeProjTrashItem"
        // 1. set response cases
        // self.response.setCases(removeProjTrashResponseCases)
        // 2. set responses
        self.responses.setResponse(actionName, {
            actionName,
            status: ActionStatus.loading,
        })
        // 3. call API
        let params = {
            TrashID: itemId,
        }
        self.deleteProjTrashItem(params)
            .then((response: any) => {
                if (response.status === 200 && response.data.Status === 1) {
                    self.deleteItem(itemId)
                    self.pushOrSpliceSelectedItem(itemId)
                    self.setDeleteTrashDialogOpen()
                    // when success
                    self.handleResponse({
                        actionName,
                        status: ActionStatus.success,
                        code: 200,
                        color: MessageColorType.blue,
                        open: true,
                        autoHide: true,
                    })
                } else {
                    // when fail
                    self.handleResponse({
                        actionName,
                        status: ActionStatus.fail,
                        code: 999,
                        color: MessageColorType.orange,
                        open: true,
                    })
                }
            })
            .catch((error: Error) => {
                self.handleViewModelError({
                    error,
                    actionName,
                    open: true,
                })
            })
    },
    removeAllProjTrashItems() {
        // 0. set actionName
        const actionName = "removeAllProjTrashItems"
        // 1. set response cases
        // self.response.setCases(removeProjTrashResponseCases)
        // 2. set responses
        self.handleResponse({
            actionName,
            status: ActionStatus.loading,
            code: 111,
            color: MessageColorType.green,
            customMessage: "Deleting all trash items is in progress...",
            open: true,
        })
        // 3. call API
        self.deleteProjTrashItems()
            .then((response: any) => {
                if (response.status === 200 && response.data.Status === 1) {
                    self.resetList()
                    self.setDeleteAllTrashDialogOpen(false)
                    // when success
                    self.handleResponse({
                        actionName,
                        status: ActionStatus.success,
                        code: 200,
                        color: MessageColorType.blue,
                        customMessage: "Success to remove all trash items",
                        open: true,
                        autoHide: true,
                    })
                } else {
                    // when fail
                    self.handleResponse({
                        actionName,
                        status: ActionStatus.fail,
                        code: 999,
                        color: MessageColorType.orange,
                        // message: response.data.Message,
                        customMessage: "Fail to remove the trash items",
                        open: true,
                    })
                }
            })
            .catch((error: Error) => {
                self.handleViewModelError({
                    error,
                    actionName,
                    open: true,
                })
            })
    },
    removeMultipleItems(items: string[]) {
        // 0.
        const actionName = "removeMultipleItems"
        // 1.
        // 2.
        self.responses.setResponse(actionName, {
            actionName,
            status: ActionStatus.loading,
        })
        // 3.
        items.map((item) => {
            self.removeProjTrashItem(item)
        })

        self.setDeleteAllTrashDialogOpen(false)

        self.responses.setResponse(actionName, {
            actionName,
            status: ActionStatus.success,
        })
    },
})

export default RemoveProjTrash
