import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { idToString } from "../../../../../../library/converters/id-converter"

const AddHardCopyWithoutSoftCopy = (self: any) => ({
	addHardCopyWithoutSoftCopy(data: any) {
		// 0.
		const actionName = "addHardCopyWithoutSoftCopy"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.request API call
		self.createHardCopy(data)
			.then((response: any) => {
				console.log(response)

				if (response.status === 200) {
					const newHardCopy = response.data.data
					console.log("newHardCopy:", newHardCopy)

					self.setOpenAddHardCopyWithoutSoftCopyDialog(false)
					self.reflectHardCopyCreation(newHardCopy)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200, // temp
						color: MessageColorType.orange,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
	addHardCopiesWithoutSoftCopies({
		list,
		otherRequirements,
		index,
	}: {
		list: any[]
		otherRequirements: {
			customerId: string
			orgId: string
			createdBy: { id: string; alias: string; name: string }
		}
		index: number
	}) {
		const actionName = "addHardCopiesWithoutSoftCopies"

		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		const indexedItem = list[index]
		const target = {
			...indexedItem,
			...otherRequirements,
			hasSoftCopy: false,
			id: indexedItem.tempId,
			year: parseInt(indexedItem.year),
		}
		console.log(`@@@@@@@@@@ index: ${index} / target: ${target.id}`)

		console.log("updateBulkImportItemStatus 1 / for in progress")
		self.updateBulkImportItemStatus({
			statusMsg: "inprogress...",
			actionStatus: ActionStatus.loading,
			tempId: target.tempId,
		})
		self.createHardCopy(target)
			.then((response: any) => {
				if (response.status === 200) {
					const newHardCopy = response.data.data
					console.log(
						"updateBulkImportItemStatus 2 / for success case"
					)
					self.updateBulkImportItemStatus({
						statusMsg: response.data.message,
						actionStatus: ActionStatus.success,
						tempId: target.tempId,
						fetchedId: newHardCopy.id,
					})
					console.log("pushItemToSuccessList")
					self.pushItemToSuccessList(newHardCopy)
					if (index !== list.length - 1) {
						self.addHardCopiesWithoutSoftCopies({
							list,
							otherRequirements,
							index: index + 1,
						})
					}
				} else {
					console.log(
						"updateBulkImportItemStatus 3 / for failure case"
					)
					self.updateBulkImportItemStatus({
						statusMsg: response.data.message,
						actionStatus: ActionStatus.fail,
						tempId: target.tempId,
					})
					if (index !== list.length - 1) {
						self.addHardCopiesWithoutSoftCopies({
							list,
							otherRequirements,
							index: index + 1,
						})
					}
				}
				if (index === list.length - 1) {
					if (self.bulkImportHc_successList.length > 0) {
						console.log("mergeSuccessListToMainList")
						self.mergeSuccessListToMainList()
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							message: response.data.Message,
							customMessage:
								"Successfully created multiple hard copies",
							open: true,
						})
						self.setOpenBulkAddHardCopyWithoutSoftCopyDialog(false)
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 200,
							color: MessageColorType.coral,
							message: "Failed to import multiple hard copies",
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				console.log(
					"updateBulkImportItemStatus 4 / for error case",
					error
				)
				self.updateBulkImportItemStatus({
					statusMsg: "Error is occured.",
					actionStatus: ActionStatus.fail,
					tempId: target.tempId,
				})
				if (index !== list.length - 1) {
					self.addHardCopiesWithoutSoftCopies({
						list,
						otherRequirements,
						index: index + 1,
					})
				}
			})
		// WARNING: Do not place the next calling in the outside of the response status condition to take sequentiall process
	},
})

export default AddHardCopyWithoutSoftCopy
