import React from "react"
import { DLButton } from "../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
// import { DLI18nProps } from "../../../../common-models/types/common-props"

export default observer(function PBCActionBtns({
	partialStore,
// 	i18n,
}: {
	partialStore: any
// 	i18n: DLI18nProps
}) {
	return (
		<div className="pbc-action-area FR JSB">
			<DLButton
				eleTestId="start-pre-process-btn"
				// clickHandler={() =>}
			>
				Import Data
			</DLButton>
			<div className="FR AC">
        		<DLButton
        			eleTestId="clear-filters-btn"
        			color="primary"
        			// clickHandler={() =>}
        		>
        			Clear Filters
        		</DLButton>
        		<DLButton
        			eleTestId="template-btn"
        			color="primary"
        			// clickHandler={() =>}
        		>
        			Template
        		</DLButton>
        		<DLButton
        			eleTestId="expand-all-btn"
        			color="primary"
        			// clickHandler={() =>}
        		>
        			Expand All
        		</DLButton>
        		<DLButton
        			eleTestId="send-btn"
        			color="primary"
        			// clickHandler={() =>}
        		>
        			Send
        		</DLButton>
			</div>
			<div className="FR AC">
        		<DLButton
        			eleTestId="add-btn"
        			color="primary"
        			// clickHandler={() =>}
        		>
        			Add
        		</DLButton>
        		<DLButton
        			eleTestId="delete-all-btn"
        			color="primary"
        			// clickHandler={() =>}
        		>
        			Delete All
        		</DLButton>
			</div>
    		<DLButton
    			eleTestId="export-btn"
    			color="primary"
    			// clickHandler={() =>}
    		>
    			Export
    		</DLButton>
		</div>
	)
})
