import { flow } from "mobx-state-tree"

const CreateCustomColumn = (self: any, create: any) => ({
	createCustomColumn: flow(function* (payload: any) {
		const actionName = "createCustomColumn"
		try {
			const response = yield create(payload)

			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default CreateCustomColumn
