import { AxiosResponse } from "axios"
import {
	postRequest,
	deleteRequest,
	getRequest,
	putRequest,
} from "../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"

export async function readCustomColumns(orgId: string) {
	let url = `/custom-columns`

	const response = await getRequest({
		tokenType: TOKEN_TYPE.hardCopy,
		url,
		params: { orgId },
	})
	return response
}

export async function removeCustomColumn(payload: {
	pk: string
	id: string
	by: { id: string; alias: string; name: string }
}) {
	let url = `/custom-columns`

	const response = await deleteRequest({
		tokenType: TOKEN_TYPE.hardCopy,
		url,
		payload,
	})
	return response
}

export type CreateColumn = {
	customerId: string
	orgId: string
	createdBy: { id: string; alias: string; name: string }
	label: string
	description: string
	helpText: string
}

export async function createCustomColumn(payload: CreateColumn) {
	let url = `/custom-columns`

	const response = await postRequest({
		tokenType: TOKEN_TYPE.hardCopy,
		url,
		payload,
	})
	return response
}

export type UpdateColumn = {
	pk: string
	id: string
	by: { id: string; alias: string; name: string }
	label: string
	description: string
	helpText: string
}

export async function updateCustomColumn(payload: UpdateColumn) {
	let url = `/custom-columns`

	const response = await putRequest({
		tokenType: TOKEN_TYPE.hardCopy,
		url,
		payload,
	})
	return response
}

export type HcMgmtConfigApiProps = {
	apiReadCustomColumns: (orgId: string) => Promise<AxiosResponse<any>>
	apiAddCustomColumn: (payload: CreateColumn) => Promise<AxiosResponse<any>>
	apiEditCustomColumn: (payload: UpdateColumn) => Promise<AxiosResponse<any>>
	apiRemoveCustomColumn: (payload: {
		pk: string
		id: string
		by: { id: string; alias: string; name: string }
	}) => Promise<AxiosResponse<any>>
}
