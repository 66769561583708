import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import { initialStore } from "./hc-mgmt-rental.provider"
import {
	GetRentals,
	RentHardCopy,
	ReturnHardCopy,
	GetSignedUrl,
} from "./view-model-actions"

const HcMgmtRentalViewModel = types
	.model({
		// rentals
		//
		clickPoint: ClickPoint,
		selectedId: "",
		// dialogs
		openRentHardCopyDialog: false,
		openReturnHardCopyDialog: false,
		// common parts
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setOpenRentHardCopyDialog(request?: boolean) {
			self.openRentHardCopyDialog =
				request ?? !self.openRentHardCopyDialog
		},
		setOpenReturnHardCopyDialog(request?: boolean) {
			self.openReturnHardCopyDialog =
				request ?? !self.openReturnHardCopyDialog
		},
	}))
	.actions((self) => ({
		reflectRental() {},
		reflectReturn() {},
	}))
	// * API related parts
	.actions(GetRentals)
	.actions(RentHardCopy)
	.actions(ReturnHardCopy)
	.actions(GetSignedUrl)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default HcMgmtRentalViewModel
