import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import {
	DLButton,
	DLDialog,
	ConsoleLog,
	DLSpinnerCenterAligned,
} from "../../../../../components/basic-elements"
import styled from "styled-components"
import Icon from "@mdi/react"
import {
	mdiArrowUp,
	mdiArrowDown,
	mdiPlus,
	mdiPause,
	mdiInformationOutline,
} from "@mdi/js"
import { DLTooltip } from "@datalobby/components"
import * as XLSX from "xlsx"
import * as FileSaver from "file-saver"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import BulkAddClientsDialogTable from "./BulkAddClientsDialogTable"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import DuplicatedItemList from "./DuplicatedItemList"
import SubmitStatusList from "./SubmitStatusList"
import { handleExport } from "../../../../../components/basic-elements/export/ExportXLSX"
import { handleDownloadExcelTemplate } from "../../../../../library/bulk-import-utils/handle-download-template"

export default observer(function BulkAddClientsDialog({
	partialStore,
}: {
	partialStore: any
}) {
	const actionName = "createMultipleClients"
	useEffect(() => {
		partialStore.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.standby,
		})
		// partialStore.responses.removeResponse(actionName)
		partialStore.resetBulkClientList()
	}, [])

	const handleBulkAdd = () => {
		const clientList = partialStore.bulkClientList.filter(
			(client: any) => client.status === ActionStatus.standby
		)
		if (clientList.length > 0) {
			partialStore.setPauseMultipleClients(false)
			partialStore.responses.setResponse(actionName, {
				actionName,
				status: ActionStatus.loading,
			})
			partialStore.addMultipleClients(clientList, 0)
		}
	}

	const handleAddRow = () => {
		const clients = JSON.parse(JSON.stringify(partialStore.bulkClientList))
		const clientId = clients.reduce((acc: any, val: any) => {
			const id = idToNumber(val.clientId, IdType.client)
			acc = acc === undefined || id > acc ? id : acc
			return acc
		}, 0)
		const newRow = {
			clientId: `clientId${clientId + 1}`,
			name: "",
			aliasId: "",
			duplicatedAliasId: false,
			duplicatedName: false,
			status: ActionStatus.standby,
			statusMessage: "",
		}
		partialStore.pushItemToBulkList(newRow)
	}

	const handlePauseMultipleClients = () => {
		partialStore.setPauseMultipleClients(true)
		partialStore.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.standby,
		})
	}

	const viewDuplicatedInput = partialStore.viewDuplicatedInput
	const { duplicatedItems, duplicatedItemsInSheet } = viewDuplicatedInput

	const isReady = () => {
		const clients = JSON.parse(JSON.stringify(partialStore.bulkClientList))
		const emptyCheck =
			clients.filter((x: any) => x.aliasId === "" || x.name === "")
				.length > 0

		return (
			partialStore.bulkClientList.length !== 0 &&
			!emptyCheck &&
			duplicatedItems.length === 0 &&
			duplicatedItemsInSheet.length === 0
		)
	}

	const clients = JSON.parse(JSON.stringify(partialStore.bulkClientList))
	const multipleClientsStatus = partialStore.getActionStatus(actionName)
	const standByclients = clients.filter(
		(client: any) => client.status === ActionStatus.standby
	)
	// below view doesn't work properly...
	//  partialStore.viewSubmitStatusCount(
	// 	ActionStatus.standby
	// )

	const submitLoadingStatus = clients.filter(
		(client: any) => client.status === ActionStatus.loading
	)
	// below view doesn't work properly...
	// partialStore.viewSubmitStatusCount(
	// 	ActionStatus.loading
	// )

	return (
		<DLDialog
			eleTestId="bulk-add-clients-dialog"
			// isOpen
			isOpen={partialStore.bulkAddClientsDialogOpen}
			setIsOpen={partialStore.setBulkAddClientsDialogOpen}
			showOpenBtn={false}
			showCloseBtn
			dialogTitle="Add multiple clients"
			dialogContents={
				<BulkAddClientsDialogContents partialStore={partialStore} />
			}
			draggable
			actionBtn={
				<div className="FR JSB" style={{ width: "100%" }}>
					<DLButton
						eleTestId="add-new-row"
						startIcon={<Icon path={mdiPlus} size={0.8} />}
						clickHandler={() => handleAddRow()}
						disabled={
							multipleClientsStatus !== ActionStatus.standby
						}
					>
						Add Row
					</DLButton>
					{multipleClientsStatus === ActionStatus.loading ? (
						<DLButton
							eleTestId="pause-create-mul-users"
							startIcon={<Icon path={mdiPause} size={0.8} />}
							clickHandler={() => handlePauseMultipleClients()}
						>
							Pause
						</DLButton>
					) : (
						<div className="FR JSB">
							{partialStore.viewSubmitStatusCount(
								ActionStatus.success
							) > 0 && (
								<div
									className="FR AC"
									style={{ marginRight: 8 }}
								>
									Submitted:{" "}
									{partialStore.viewSubmitStatusCount(
										ActionStatus.success
									)}
									<span style={{ marginLeft: 8 }}>|</span>
								</div>
							)}
							{partialStore.viewSubmitStatusCount(
								ActionStatus.fail
							) > 0 && (
								<div
									className="FR AC"
									style={{ marginRight: 8 }}
								>
									Failed:{" "}
									{partialStore.viewSubmitStatusCount(
										ActionStatus.fail
									)}
									<span style={{ marginLeft: 8 }}>|</span>
								</div>
							)}
							{standByclients.length > 0 && (
								<span style={{ marginRight: 8 }}>
									{standByclients.length} Clients
								</span>
							)}
							<DLButton
								eleTestId="proceed-bulk-add"
								clickHandler={() => handleBulkAdd()}
								disabled={
									!isReady() ||
									submitLoadingStatus.length !== 0
								}
							>
								Submit
							</DLButton>
						</div>
					)}
				</div>
			}
			fullWidth
			maxWidth="md"
		/>
	)
})

type ClientItemProps = {
	clientId: string
	aliasId: string
	name: string
	duplicatedAliasId: boolean
	duplicatedName: boolean
	status: ActionStatus
	statusMessage: string
}

const BulkAddClientsDialogContents = observer(
	({ partialStore }: { partialStore: any }) => {
		const actionName = "createMultipleClients"

		function handleFile(e: any) {
			var file = e.target.files[0]
			var reader = new FileReader()

			reader.onload = function (e) {
				if (e.target) {
					if (
						e.target.result !== null &&
						typeof e.target.result !== "string"
					) {
						var data = new Uint8Array(e.target.result)
						var workbook = XLSX.read(data, { type: "array" })

						const jsonData = XLSX.utils.sheet_to_json(
							workbook.Sheets.Sheet1
						)
						let organizedList: ClientItemProps[] = []
						// let duplicatedList: DuplicatedItemProps[] = []
						const hasEmptyFields = jsonData.every(
							(item: any) =>
								Object.keys(item).includes("clientName") &&
								Object.keys(item).includes("clientAliasId")
						)

						if (!hasEmptyFields) {
							alert(
								"Excel file contains empty fields. Please input correct data."
							)
							partialStore.setBulkClientFileReadStatus(
								ActionStatus.fail
							)
							return false
						}
						jsonData.map((item: any, i: any) => {
							const aliasIdCheck =
								jsonData.filter(
									(x: any) =>
										x.clientAliasId === item.clientAliasId
								).length > 1
							const nameCheck =
								jsonData.filter(
									(x: any) => x.clientName === item.clientName
								).length > 1
							organizedList.push({
								clientId: `clientId${i + 1}`,
								aliasId: item.clientAliasId.toString(),
								name: item.clientName,
								duplicatedAliasId: aliasIdCheck,
								duplicatedName: nameCheck,
								status: ActionStatus.standby,
								statusMessage: "",
							})
						})

						partialStore.setBulkClientList(organizedList)
						partialStore.setBulkClientFileReadStatus(
							ActionStatus.success
						)
					}
				} else {
					ConsoleLog("event target is null")
				}
			}
			reader.readAsArrayBuffer(file)
		}

		const handleSelectFilesFromLocal = (e: any) => {
			handleFile(e)
			partialStore.setBulkClientFileReadStatus(ActionStatus.loading)
		}

		const getDescrition = (isDupAliasId: boolean, isDupName: boolean) => {
			if (isDupAliasId && isDupName) {
				return "Client name and alias ID are already exist"
			}
			if (isDupAliasId && !isDupName) {
				return "Client alias ID is already exist."
			}
			if (!isDupAliasId && isDupName) {
				return "Client name is already exist."
			}
		}

		const handleExportDuplicated = () => {
			let proceed = window.confirm(
				"Do you want to export duplicated clients list?"
			)
			if (!proceed) return
			const { duplicatedItems } = partialStore.viewDuplicatedInput
			const duplicatedList = duplicatedItems.map((item: any) => ({
				requestedClientName: item.reqName,
				requestedclientAliasId: item.reqAliasId,
				description: getDescrition(
					item.duplicatedAliasId,
					item.duplicatedName
				),
				duplicatedName: item.duplicatedName ? item.reqName : "N/A",
				duplicatedNamesAliasId: item.duplicatedName
					? item.aliasId
					: "N/A",
				duplicatedAliasId: item.duplicatedAliasId
					? item.reqAliasId
					: "N/A",
				duplicatedAliasIdsName: item.duplicatedAliasId
					? item.name
					: "N/A",
			}))

			handleExport(duplicatedList, "DuplicateClientImport")
		}

		const handleRemoveDuplicated = () => {
			let proceed = window.confirm(
				"Do you want to remove duplicated clients from the list?"
			)
			if (!proceed) return
			const { duplicatedItems } = partialStore.viewDuplicatedInput
			partialStore.removeDuplicatedBulkClients(duplicatedItems)
			// duplicatedItems.map((item: any) => {
			// 	partialStore.spliceBulkClient(item.reqId)
			// })
		}

		const clients = partialStore.viewBulkClientList
		const viewDuplicatedInput = partialStore.viewDuplicatedInput
		const { duplicatedItems, duplicatedItemsInSheet } = viewDuplicatedInput
		const fileReadStatus = partialStore.bulkClientFileReadStatus

		const multipleClientsStatus = partialStore.getActionStatus(actionName)
		return (
			<StyledDialogContents className="bulk-add-clients-dialog">
				{fileReadStatus === ActionStatus.loading && (
					<DLSpinnerCenterAligned absolute backgroundOpacity={0.6} />
				)}
				<div className="FR JSB">
					{multipleClientsStatus !== ActionStatus.success && (
						<div className="FR JSB">
							<DLButton
								eleTestId="download-template"
								startIcon={
									<Icon path={mdiArrowDown} size={0.8} />
								}
								clickHandler={() =>
									handleDownloadExcelTemplate(
										[
											{
												clientName: "",
												clientAliasId: "",
											},
										],
										"ClientsTemplate"
									)
								}
								// disabled={clients.length === 0}
							>
								Download Template
							</DLButton>
							<DLButton
								eleTestId="choose-files-btn"
								startIcon={
									<Icon path={mdiArrowUp} size={0.8} />
								}
								color="primary"
							>
								<label className="file-upload-btn-wrapper">
									Choose A file
									<input
										type="file"
										name="file"
										onChange={handleSelectFilesFromLocal}
										data-testid="file-input"
										accept=".xlsx,.xls"
									/>
								</label>
							</DLButton>
							<div style={{ marginTop: "11px" }}>
								<DLTooltip
									title={
										<div>
											<b>Note :</b>
											<br />
											<span>
												1.Please make sure the sheet
												name is "Sheet1"
											</span>
											<br />
											<span>
												2.Please make sure the column
												names are "clientName" and
												"clientAliasId"
											</span>
										</div>
									}
									elePlacement="right"
								>
									<Icon
										path={mdiInformationOutline}
										size={1}
									/>
								</DLTooltip>
							</div>
						</div>
					)}
					{multipleClientsStatus !== ActionStatus.success && (
						<div className="FR JSB">
							<DuplicatedItemList
								inputTotal={clients.length}
								dupItems={duplicatedItems}
								dupItemsInSheet={duplicatedItemsInSheet}
								handleRemoveDuplicated={handleRemoveDuplicated}
								handleExportDuplicated={handleExportDuplicated}
							/>
						</div>
					)}
				</div>
				<div>
					{multipleClientsStatus === ActionStatus.loading ||
					multipleClientsStatus === ActionStatus.success ? (
						<SubmitStatusList
							successCount={partialStore.viewSubmitStatusCount(
								ActionStatus.success
							)}
							failCount={partialStore.viewSubmitStatusCount(
								ActionStatus.fail
							)}
							list={partialStore.viewBulkClientList}
						/>
					) : (
						<BulkAddClientsDialogTable
							partialStore={partialStore}
							data={
								JSON.parse(
									JSON.stringify(
										partialStore.viewBulkClientList
									)
								) || []
							}
						/>
					)}
				</div>
			</StyledDialogContents>
		)
	}
)

const StyledDialogContents = styled.div`
	.duplicated-items-count {
		.warning {
			color: ${(props) => props.theme.themeRed};
			font-weight: 700;
		}
	}
	input[type="file"] {
		display: none;
	}
	.header {
		font-weight: bold;
	}
	.FAIL {
		color: ${(props) => props.theme.themeRed};
	}
	.SUCCESS {
		color: ${(props) => props.theme.emphasis};
	}
	.LOADING {
		color: ${(props) => props.theme.themeOrange};
	}
`
