import React, { useState, useEffect } from "react"
//
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
//
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"
import { getDefaultYear } from "../../../../library/get-page-settings"
import OtherProjects from "./OtherProjects"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import SharedProjTableInitialFilters from "../../../../components/combined-elements/projects-table/SharedProjectTableInitialFilters"

export default observer(function OtherProjectsController() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.other_projects
	const actionName = "getOtherProjects"
	const i18nSheet = OrgI18n.projects
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const needRefresh = orgStore.otherProjects.needRefresh
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	const [filters, setFilters] = useState(SharedProjTableInitialFilters)
	const filteredYear = filters.find(
		(filter: any) => filter.id === "financialYear"
	)?.value
	const [hasDefaultYear, setHasDefaultYear] = useState<"pending" | number>(
		"pending"
	)

	const handleContextMenu = (event: any, rowInfo: any) => {
		event.preventDefault()
		orgStore.reportsMgmt.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
		orgStore.reportsMgmt.setSelectedProjId(rowInfo.original.id)
	}

	const orgIdForCdk = "org" + orgStore.checkin.orgInfo.orgId.slice(5)

	useEffect(() => {
		if (menuAccess) {
			const defaultYear = getDefaultYear(menuId)
			orgStore.otherProjects.getOtherProjects({
				orgId: orgIdForCdk,
				year: defaultYear,
			})
		}
	}, [])

	const i18n = store.i18n.combineI18n(i18nSheet, "OtherProjectsController")

	return (
		<>
			{menuAccess ? (
				<OtherProjects
					partialStore={orgStore.otherProjects}
					fetchingStatus={orgStore.otherProjects.getActionStatus(
						actionName
					)}
					permission={permission}
					dateFormat={store.global.dateFormat.value}
					handleContextMenu={handleContextMenu}
					filters={filters}
					setFilters={setFilters}
					year={filteredYear}
					i18n={i18n}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
