import React from "react"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { PageContainer, PageHeader } from "../../../../components/app-frame-elements"
import styled from "styled-components"
import { DLSpinner } from "../../../../components/basic-elements"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import DefaultPageCtxMenus from "../../../../components/combined-elements/projects-table/DefaultPageCtxMenus"
import ChecklistsCtxMenus from "./sub-components/ChecklistsCtxMenus"
import ChecklistsTable from "./sub-components/ChecklistsTable"
import { DLI18nProps } from "../../../../common-models/types/common-props"

const Checklists = observer(({
	partialStore,
	fetchingStatus,
	permission,
	i18n,
}: {
	partialStore: any
	fetchingStatus: ActionStatus
	permission: PermissionAsObjectProps
	i18n: DLI18nProps 
}) => {
    const store = useRootStore()
// 	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.checklists

	const dntFormat = store.global.getDntFormat
	
    const handleContextMenu = (event: any, rowInfo: any) => {
		event.preventDefault()
		partialStore.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
		partialStore.setSelectedProjId(rowInfo.original.id)
	}
// 	const checklistsLength = partialStore.getProjLengthOfYear(year ? year : "")
	const clickPointExist = partialStore.clickPoint.mouseX !== null
	let contentHeight = store.ui.contentsAreaHeight
	console.log(JSON.parse(JSON.stringify(partialStore)))
    return (
		<PageContainer
			pageTools={<div />}
			hasToolbar={false}
			fullWidth
			fixedHeight
			isLoading={fetchingStatus === "LOADING"}
		>
			<StyledChecklists>
				{fetchingStatus === "SUCCESS" && (
					<>
						<PageHeader>
							<div className="FR AC counter-container">
								<div className="counter">
									Total Checklists:{" "}
									
								</div>
							</div>
							<div>
								<DefaultPageCtxMenus
									permission={permission}
									getTotalData={() => {}
								// 		partialStore.forExport(dntFormat)
									}
									getFilteredData={() => {}
								// 		partialStore.forExport(
								// 			dntFormat,
								// 			filteredData
								// 		)
									}
									openPageSettingDialog={() => {}
								// 		partialStore.setTablePageSettingDialog(
								// 			true
								// 		)
									}
									fileName="Checklists"
									i18n={i18n}
								/>
							</div>
						</PageHeader>
						<ChecklistsTable
							partialStore={partialStore}
							handleContextMenu={handleContextMenu}
							contentHeight={contentHeight}
						/>
					</>
				)}
				{/* ---------- context menus ---------- */}
				{clickPointExist && (
					<ChecklistsCtxMenus
						partialStore={partialStore}
						permission={permission}
						i18n={i18n}
					/>
				)}
				{/* response snackbar  */}
				<CommonSnackbarComponent
					i18n={i18n}
					partialStore={partialStore}
				/>
			</StyledChecklists>
		</PageContainer>
	)
})

export default Checklists

const StyledChecklists = styled.div`
	padding-right: 1rem;
	padding-left: 1rem;
	.counter-container {
		.counter {
			margin: 0 1rem;
			span {
				font-weight: 600;
				color: ${(props) => props.theme.emphasis};
			}
		}
	}
	.partition {
		margin: 1rem 1rem;
	}
`