import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

const EditHardCopyDetail = (self: any) => ({
	// NOTE: this 'hard copy without soft copy detail update' means title, year, description, and custom columns
	editHcWithoutScDetail(data: any) {
		// 0.
		const actionName = "editHcWithoutScDetail"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		// 3.request API call
		self.updateHardCopyDetail(data)
			.then((response: any) => {
				console.log(actionName, "response:", response)

				const { id, title, description, year } = data

				let customColumns: { id: string; value: string }[] = []

				const keys = Object.keys(data)
				const customColumnKeys = keys.filter((key: any) =>
					key.includes("CC_")
				)

				customColumnKeys.map((ccKey: string) => {
					// add the item to array
					customColumns.push({
						id: ccKey,
						value: data[ccKey],
					})
					// remove the property from the hard copy item
					delete data[ccKey]
				})

				if (response.status === 200) {
					self.setOpenEditHardCopyDetailDialog(false)
					self.reflectHcWithoutScDetailUpdate({
						id,
						title,
						description,
						year,
						customColumns,
					})
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
					// TODO: edit the hard copy item in the stored list
					// TODO: Close the dialog
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200, // temp
						color: MessageColorType.orange,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default EditHardCopyDetail
