import React, { useState } from "react"
// components
import {
	DLIconButton,
	DLButton,
	DLMenus,
	DLBadge,
	DLContextMenuWithButton,
} from "../../../../../components/basic-elements"

// icons
import Icon from "@mdi/react"
import {
	mdiCheck,
	mdiCheckAll,
	mdiMinusCircleOutline,
	mdiMinusCircle,
	mdiPlaylistCheck,
	mdiCommentMultipleOutline,
	mdiImport,
	mdiFolderRemoveOutline,
	mdiPlaylistEdit,
	mdiMagnify,
	mdiPlusCircle,
	mdiCommentMultiple,
	mdiFormatListChecks,
} from "@mdi/js"
import styled from "styled-components"
import { OldSignOffType } from "../../../../../service-modules/file-module/data-models/sign-off.data-model"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import {
	DLIcon,
	DLIconName,
} from "../../../../../components/basic-elements/icons/common-icons"
import { Link } from "react-router-dom"
import {
	DragOnOffIconButton,
	CheckboxOnOffIconButton,
	ExpandAllIconButton,
	CollapseAllIconButton,
	SortingIconButton,
} from "../../../../../components/basic-elements/buttons/toggle-icon-buttons"
import { observer } from "mobx-react-lite"
import FilePageSettingDialog from "../dialogs/FilesPageSettingDialog"
import { FileMenuToolbarProps } from "./file-menu-toolbar-props"
import {
	DLProjSubMenus,
	DLProjMenuGroups,
} from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import {
	DefaultSignOffPolicy,
	PfSignOffPolicy,
} from "../../../../../temporary-data/project-side/signoff-policy"
import { FileMenuViewType } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"

export default observer(function FileMenuToolbar({
	permission,
	ctxCreateMenus,
	//
	structureLock,
	showCheckbox,
	viewType,
	//
	commentDrawerStatus,
	commentBadgeContent,
	diagnosisDrawerStatus,
	libDrawerStatus,
	//
	countSelectedFiles,
	//
	handleAllowDrag,
	handleShowCheckbox,
	//
	handleExpandAll,
	handleCollapseAll,
	needExpandAll,
	needCollapseAll,
	handleSignOffFocusedView,
	//
	handleViewType,
	//
	handleCommentDrawer,
	handleDiagnosisDrawer,
	handleOpenedFilesDialog,
	handleLibraryDrawer,
	handleSearchView,
	//
	exportMenuOptions,
	allMenuOptions,
	//
	availableSignOffs,
	handleSignOffByToolbar,
	handleUnSignOffByToolbar,
	//
	partialStore,
	i18n,
	//
	isAsc,
	handleSortRootFolders,
	projMenuTabStore,
}: FileMenuToolbarProps) {
	// ConsoleLog("availableSignOffs in comp", availableSignOffs)
	const projStore = useProjStore()
	const defaultUrl = projStore.checkin.defaultUrl
	const handleRedirectTab = () => {
		const item = {
			id: DLProjSubMenus.signoff_history,
			menuGroup: DLProjMenuGroups.archive,
			subMenus: [],
			title: "Sign Off History",
			url: `${defaultUrl}/archive/sign-off-history`,
		}
		projMenuTabStore.setCurrentMainMenu(item.id)
		projMenuTabStore.addOpenedMenu(item)
	}

	const menuId = partialStore.storeName
	const signOffPolicy =
		menuId === DLProjSubMenus.wp
			? DefaultSignOffPolicy
			: menuId === DLProjSubMenus.pf
			? PfSignOffPolicy
			: []
	const userRole = projStore.checkin.user.roleId

	const ableToPrepare = signOffPolicy[0].availableRoles.includes(userRole)
	const ableToReview = signOffPolicy[1].availableRoles.includes(userRole)

	return (
		<StyledFileMenuToolbar className="toolbar-container FR AC JSB">
			{/* ========== Main Sign Off buttons ========== */}

			<div
				className={`sign-off-main-btns-container FR AC ${
					countSelectedFiles > 0 &&
					permission.signOff &&
					"sign-off-mode"
				}`}
			>
				<DLButton
					variant="outlined"
					disabled={!availableSignOffs.prepare || !ableToPrepare}
					eleTestId="prepare-btn-in-toolbar"
					startIcon={<Icon path={mdiCheck} size={0.8} />}
					clickHandler={() =>
						handleSignOffByToolbar(OldSignOffType.prepare)
					}
				>
					{i18n.twPrepare || "Prepare"}
				</DLButton>
				<DLButton
					variant="outlined"
					disabled={!availableSignOffs.unPrepare || !ableToPrepare}
					eleTestId="unprepare-btn-in-toolbar"
					startIcon={<Icon path={mdiMinusCircleOutline} size={0.8} />}
					clickHandler={() =>
						handleUnSignOffByToolbar(OldSignOffType.prepare)
					}
				>
					{i18n.twUnPrepare || "Un Prepare"}
				</DLButton>
				<div
					className="prepare-review-buttons-partition"
					style={{ width: 16 }}
				></div>

				<DLButton
					variant="outlined"
					disabled={!availableSignOffs.review || !ableToReview}
					eleTestId="review-btn-in-toolbar"
					startIcon={<Icon path={mdiCheckAll} size={0.8} />}
					clickHandler={() =>
						handleSignOffByToolbar(OldSignOffType.review)
					}
				>
					{i18n.twReview || "Review"}
				</DLButton>
				<DLButton
					variant="outlined"
					disabled={!availableSignOffs.unReview || !ableToReview}
					eleTestId="unreview-btn-in-toolbar"
					startIcon={<Icon path={mdiMinusCircle} size={0.8} />}
					clickHandler={() =>
						handleUnSignOffByToolbar(OldSignOffType.review)
					}
				>
					{i18n.twUnReview || "Un Review"}
				</DLButton>
			</div>

			{/* The First individual button to create  */}
			<div className={`FR AC JSB file-tree-basic-btns-container`}>
				{/* <div
				className={`FR AC JSB file-tree-basic-btns-container ${countSelectedFiles >
					0 &&
					permission.create &&
					"sign-off-mode"}`}
			> */}
				{/* TODO: update with another context menu comp */}
				{permission.create && (
					<DLContextMenuWithButton
						eleTestId="file-toolbar-create-menu"
						menuOptions={ctxCreateMenus}
						useScroll={false}
						showButton={false}
						showIcon={true}
						eleIcon={
							<Icon
								path={mdiPlusCircle}
								size={1}
								className="create-menu-btn"
							/>
						}
					/>
				)}

				<div className="first-section-of-buttons FR AC">
					{permission.structureLock && (
						<DragOnOffIconButton
							handleOnOff={handleAllowDrag}
							structureLock={structureLock}
						/>
					)}
					{/* {permission.update && ( */}
					<CheckboxOnOffIconButton
						handleOnOff={handleShowCheckbox}
						showCheckbox={showCheckbox}
					/>
					{/* )} */}
					{permission.update && !structureLock && (
						<SortingIconButton
							isAsc={isAsc}
							handleSort={handleSortRootFolders}
							by="- Root Folders"
						/>
					)}
				</div>

				{/* ========== Other buttons ========== */}
				<div className="button-group FR AC">
					{/* ------ Toolbar Section for View Types ------ */}
					<ExpandAllIconButton
						handleExpandAll={handleExpandAll}
						disabled={
							!needExpandAll ||
							partialStore.viewType !== FileMenuViewType.tree
						}
					/>
					<CollapseAllIconButton
						handleCollapseAll={handleCollapseAll}
						disabled={
							!needCollapseAll ||
							partialStore.viewType !== FileMenuViewType.tree
						}
					/>

					<DLIconButton
						variant={
							partialStore.viewType === FileMenuViewType.fileTable
								? "contained"
								: "iconOnly"
						}
						eleTestId="table-view-onoff"
						clickHandler={() =>
							handleViewType(
								partialStore.viewType ===
									FileMenuViewType.fileTable
									? FileMenuViewType.tree
									: FileMenuViewType.fileTable
							)
						}
						color={
							partialStore.viewType === FileMenuViewType.fileTable
								? "primary"
								: "default"
						}
						// tooltipText={i18n.twFlatView}
						tooltipText={
							partialStore.viewType === FileMenuViewType.fileTable
								? "Table View Off"
								: "Table View On"
						}
					>
						<Icon path={mdiFolderRemoveOutline} size={0.8} />
					</DLIconButton>
					<DLIconButton
						variant={
							partialStore.viewType === FileMenuViewType.search
								? "contained"
								: "iconOnly"
						}
						eleTestId="search-view-onoff"
						clickHandler={handleSearchView}
						tooltipText={i18n.twSearch || "Search"}
						color={
							partialStore.viewType === FileMenuViewType.search
								? "primary"
								: "default"
						}
					>
						<Icon path={mdiMagnify} size={0.8} />
					</DLIconButton>
				</div>
				{/* ------ Toolbar Section for Drawers ------ */}
				<div className="divider" />
				<div className="button-group FR AC">
					<DLIconButton
						variant="iconOnly"
						eleTestId="open-signoff-history-screen"
						eleClassName="move-to-signoff-screen-btn"
						clickHandler={() => handleRedirectTab()}
						tooltipText={"Sign Off History"}
					>
						<Link to={`${defaultUrl}/archive/sign-off-history`}>
							<Icon path={mdiFormatListChecks} size={0.8} />
						</Link>
					</DLIconButton>
					<DLIconButton
						variant="iconOnly"
						eleTestId="comment-list-onoff"
						eleClassName="comment-drawer-btn"
						clickHandler={handleCommentDrawer}
						color={commentDrawerStatus ? "orange" : "default"}
						tooltipText={
							commentDrawerStatus
								? i18n.twHideComments || "Hide Comments Drawer"
								: i18n.twShowComments || "Show Comments Drawer"
						}
					>
						{commentDrawerStatus ? (
							<DLBadge
								eleTestId="comment-count-on-toolbar"
								content={commentBadgeContent}
							>
								<Icon path={mdiCommentMultiple} size={0.8} />
							</DLBadge>
						) : (
							<DLBadge
								eleTestId="comment-count-on-toolbar"
								content={commentBadgeContent}
							>
								<Icon
									path={mdiCommentMultipleOutline}
									size={0.8}
								/>
							</DLBadge>
						)}
					</DLIconButton>
					{/* <DLIconButton
						variant="iconOnly"
						eleTestId="diagnosis-onoff"
						clickHandler={handleDiagnosisDrawer}
						tooltipText={
							diagnosisDrawerStatus
								? i18n.twHideDiagnosis
								: i18n.twShowDiagnosis
						}
					>
						{diagnosisDrawerStatus ? (
							<Icon
								path={mdiPlaylistCheck}
								size={0.8}
								color="orange"
							/>
						) : (
							<Icon path={mdiPlaylistCheck} size={0.8} />
						)}
					</DLIconButton> */}
					{permission.read && (
						<DLIconButton
							variant="iconOnly"
							eleTestId="opened-files-dialog-btn"
							clickHandler={handleOpenedFilesDialog}
							tooltipText={i18n.twOpenedFiles || "Opened Files"}
						>
							<Icon path={mdiPlaylistEdit} size={0.8} />
						</DLIconButton>
					)}

					{partialStore.storeName === DLProjSubMenus.wp && (
						<DLIconButton
							variant="iconOnly"
							clickHandler={handleLibraryDrawer}
							eleTestId="library-onoff"
							color={libDrawerStatus ? "orange" : "default"}
							tooltipText={
								libDrawerStatus
									? i18n.twHideFileLib || "Hide File Library"
									: i18n.twShowFileLib || "Show File Library"
							}
						>
							{libDrawerStatus ? (
								<Icon path={mdiImport} size={0.8} />
							) : (
								<Icon
									path={mdiImport}
									size={0.8}
									rotate={180}
								/>
							)}
						</DLIconButton>
					)}
				</div>
				{/* ------ Last Toolbar Section - Additional Actions------ */}
				<div className="divider" />
				<div className="button-group FR AC">
					{permission.export && (
						<DLMenus
							eleTestId="export-btn"
							options={exportMenuOptions}
							// showIcon={true}
							btn={
								<DLIcon
									name={DLIconName.download}
									noMargin
									tooltipText={i18n.twExport || "Export All"}
								/>
							}
						/>
					)}
					<FilePageSettingDialog partialStore={partialStore} />
				</div>
			</div>
		</StyledFileMenuToolbar>
	)
})

const StyledFileMenuToolbar = styled.div`
	&.toolbar-container {
		transition: 0.4s;
		.sign-off-main-btns-container {
			opacity: 0;
			width: 0;
			overflow: hidden;
			transition: 0.4s;
			&.sign-off-mode {
				opacity: 1;
				width: 100%;
			}
		}
		.file-tree-basic-btns-container {
			width: 100%;
			&.sign-off-mode {
				opacity: 0;
				width: 0;
			}
			.create-menu-btn {
				.button-group {
					.comment-drawer-btn {
						span.comment-counting-badge {
							span {
								border: 1px solid black;
							}
						}
					}
				}
			}
			.divider {
				width: 1px;
				height: 1.8rem;
				background-color: gray;
				/* margin: 0 0.8rem; */
			}
		}
	}
`
