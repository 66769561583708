import { types } from "mobx-state-tree"
// model common action
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
// separated actions

import {
	ReadAmArchivedProjectList,
	ReadReadOnlyUsers,
	ReadFilteredUsers,
	DeleteReadOnlyUser,
	AddReadOnlyUser,
	UpdateReadOnlyUser,
	ReadProjUsersAndFileCount,
	DeleteAmArchivedProject,
	UpdateArchivedProjectUnarchive,
	ReadExportArchivedZipFile,
	ReadVerifyDigitalSignatureHashValue,
} from "./model-actions"
import { AmArchivedApiProps } from "./am-archived-projects.apis"

import ReadProjectDetails from "../../../../../service-modules/project-module/model-actions/read-project-details"
import RequestRegenPreProcessPdfs from "./model-actions/create-pre-process-pdfs"
import RequestRepairReplica from "./model-actions/request-repair-replica"
import ReadPreConditionStatus from "../../../../project-side/archive/archive-pre-process/store/model-actions/read-pre-condition-status"
import CreatePreProcessing from "../../../../project-side/archive/archive-pre-process/store/model-actions/create-pre-processing"
import ReadFilters from "../../am-archived-projects-2/store/model-actions/read-filters"
import ReadAmArchivedProjectList2 from "../../am-archived-projects-2/store/model-actions/read-am-archived-projects2"
import { ReadArchiveDiagnosis } from "../../../../project-side/archive/archive-diagnosis/store/model-actions"
import { read as readArchiveDiagnosisApi } from "../../../../project-side/archive/archive-diagnosis/store/archive-diagnosis.apis"

const AmArchivedProjectsModel = ({
	apiRead,
	apiUnarchiveProject,
	apiDelete,
	// readonly assign related
	apiAddAccessMgmtUser,
	apiReadAccessMgmtUser,
	apiReadFilteredUsers,
	apiDeleteReadOnlyUser,
	apiUpdateReadOnlyUser,
	apiRepairReplica,
	apiCheckPreConditionStatus,
	apiReadFilters,
}: // apiStartPreProcess,
AmArchivedApiProps) =>
	types
		.model({})
		// CRUD actions
		.actions((self) => ReadAmArchivedProjectList(self, apiRead))
		.actions((self) => ReadAmArchivedProjectList2(self, apiRead))
		.actions((self) => ReadReadOnlyUsers(self, apiReadAccessMgmtUser))
		.actions((self) => ReadFilteredUsers(self, apiReadFilteredUsers))
		.actions((self) => AddReadOnlyUser(self, apiAddAccessMgmtUser))
		.actions((self) => UpdateReadOnlyUser(self, apiUpdateReadOnlyUser))
		.actions((self) => DeleteReadOnlyUser(self, apiDeleteReadOnlyUser))
		.actions((self) => DeleteAmArchivedProject(self, apiDelete))
		.actions((self) =>
			UpdateArchivedProjectUnarchive(self, apiUnarchiveProject)
		)
		.actions(ReadExportArchivedZipFile)
		.actions(ReadVerifyDigitalSignatureHashValue)

		.actions((self) => RequestRepairReplica(self, apiRepairReplica))
		//
		.actions(RequestRegenPreProcessPdfs)
		.actions((self) => ReadArchiveDiagnosis(self, readArchiveDiagnosisApi))
		//
		.actions((self, preConditionStatus = apiCheckPreConditionStatus) =>
			ReadPreConditionStatus(self, preConditionStatus)
		)
		//
		.actions((self, readFilterData = apiReadFilters) =>
			ReadFilters(self, readFilterData)
		)
		// .actions((self, startPreProcess = apiStartPreProcess) =>
		// 	CreatePreProcessing(self, startPreProcess)
		// )
		// common model actions
		.actions(CommonModelActions)

export default AmArchivedProjectsModel
