import React from "react"
import { observer } from "mobx-react-lite"
import { Doughnut } from "react-chartjs-2"
import styled from "styled-components"


const PieChartCard = () => {
    return(
        <StyledCardComponent cardWidth={1000}>
            <div>
                <Doughnut 
                    data={{
                        datasets: [{
                            data: [12, 19],
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                            ],
                        }],
                        labels: ["Without Soft Copy", "With Soft Copy"]
                    }}
                    options={{
        				cutoutPercentage: 0,
        				title: {
        					display: true,
        					text: "Total Hard Copies",
        				},
        				legend: {
        					display: false,
        				},
        			}}
                />
            </div>
            <div>
            </div>
        </StyledCardComponent>
    )
}

export default PieChartCard

type StyledCardProps = {
	cardWidth: number
}

const StyledCardComponent = styled.div<StyledCardProps>`
	width: ${(props) => props.cardWidth}px;
	&.simple-card {
		padding: 1rem;
		height: 22rem;
		box-shadow: 0px 2px 6px 1px rgba(192, 203, 220, 0.3);
		border: 1px solid ${(props) => props.theme.shade10};
		border-radius: 4px;
		margin-bottom: 1rem;
		overflow: hidden;
		transition: 0.4s;
		.click-area {
			cursor: pointer;
			// border: 1px solid orange;
			z-index: 1;
		}
		.overall-total {
			margin-top: 1rem;
			opacity: 0.7;
		}
		.summary-table {
			width: 100%;
		}
		:hover {
			/* box-shadow: 0px 2px 8px 1px rgba(192, 203, 220, 0.6); */
			box-shadow: 0px 2px 8px 1px ${(props) => props.theme.shade40};
			border: 1px solid ${(props) => props.theme.primaryLightDeep};
		}
		.doughnut-filling-container {
			width: ${(props) => props.cardWidth}px;
			// border: 1px solid pink;
			position: relative;
			.doughnut-filling-contents {
				position: absolute;
				width: 100%;
				/* left: ${(props) => props.cardWidth / 2}px;*/
				top: 78px;
				text-align: center;
				.filling-title {
				}
				.filling-ratio {
				}
			}
		}
		.button-container {
			width: ${(props) => props.cardWidth}px;
			border: 1px solid green;
			position: absolute;
			.view-detail-btn {
				transform: rotate(90deg);
				position: absolute;
				right: 2rem;
				top: 78px;
				:hover {
					color: ${(props) => props.theme.emphasis};
				}
			}
		}
	}
`