// NOTE: Project Type = audit, sox, ... etc
// NOTE: Project status = todo, work in progress, done(archived),  replica, unarchived... etc
// WARNING: This part will be updated again at next Monday.. maybe as .. Phase?
export enum ProjectStatus {
	normal = "NORMAL",
	// TODO: Normal Locked should be removed
	normalLocked = "NORMAL_LOCKED", // this part should be removed
	replica = "REPLICA",
	archived = "ARCHIVED",
	unarchived = "UNARCHIVED",
	roamArchived = "ROAM_ARCHIVED",
	unknown = "UNKNOWN",
}

export enum ProjectStatusColor {
	unarchived = "#B20000",
	late = "#ff5815",
	urgent = "#ffa500",
	timely = "#6592f0", // timely or archived
	replica = "#32475d",
	normal = "#e3e3e3",
	undefined = "gray",
}
