import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog, DLButton } from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import styled from "styled-components"
import { Icon } from "@mdi/react"
import { mdiArrowLeft } from "@mdi/js"
import AddHardCopyWithoutProject from "./add-hard-copy-dialog/InputTempHardCopyContents"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { useHcMgmtStore } from "../../../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"

export default observer(function AddHardCopyWithoutSoftCopyDialog({
    i18n,
    partialStore,
}: {
    i18n: DLI18nProps
    partialStore: any
}) {
    const orgStore = useOrgStore()
    const hcMgmtStore = useHcMgmtStore()
    const hcStore = hcMgmtStore.hardCopy
    const configStore = hcMgmtStore.config
    //
    const customFields = configStore.viewCustomColumns

    const [input, setInput] = useState<any>({
        status: "AVAILABLE",
        year: new Date().getFullYear(),
        title: "",
        description: "",
    })

    const handleInput = (e: any) => {
        const { name, value } = e.target
        setInput((inputs: any) => ({
            ...inputs,
            [name]: value,
        }))
    }

    const handleHardCopyInputs = (name: string, value: any) => {
        setInput((inputs: any) => ({
            ...inputs,
            [name]: value,
        }))
    }

    const customerId = process.env.REACT_APP_CUSTOMER_ID
    const orgId = orgStore.checkin.orgInfo.orgId.split("Id").join("") // localStorage.getItem("orgId")?.split("Id").join("")
    const userInfo = {
        id: orgStore.checkin.orgInfo.userId.split("Id").join(""),
        alias: orgStore.checkin.orgInfo.userAliasId,
        name: orgStore.checkin.orgInfo.userName,
    }

    const handleSubmit = () => {
        console.log("handleSubmit input:", input)

        hcStore.addHardCopyWithoutSoftCopy({
            ...input,
            customerId,
            orgId,
            hasSoftCopy: false,
            createdBy: userInfo,
            year:
                typeof input.year === "number"
                    ? input.year
                    : parseInt(input.year),
        })
    }

    return (
        <DLDialog
            eleTestId="add-hard-copy-without-soft-copy-dialog"
            isOpen={partialStore.openAddHardCopyWithoutSoftCopyDialog}
            setIsOpen={partialStore.setOpenAddHardCopyWithoutSoftCopyDialog}
            showCloseBtn={true}
            dialogTitle="Add Hard Copy Without Soft Copy"
            dialogContents={
                <AddHardCopyWithoutProject
                    customFields={customFields}
                    hardCopyInput={input}
                    setHardCopyInput={setInput}
                    handleHardCopyInput={handleInput}
                    handleHardCopyInputs={handleHardCopyInputs}
                />
            }
            actionReady={
                input.title !== "" &&
                input.title !== undefined &&
                input.status &&
                input.status !== undefined &&
                input.year &&
                input.year !== undefined
            }
            maxWidth="md"
            actionBtn="Submit"
            handleAction={handleSubmit}
            cancelBtnText={i18n.twCancel || "Cancel"}
            // showSpinner={hcStore.getActionStatus("addHardCopy") !== "SUCCESS"}
            // cannotUpdate={hcStore.getActionStatus("addHardCopy") !== "SUCCESS"}
        />
    )
})
