import React from "react"
import { DLIconButton } from "../../../../../../../components/basic-elements"
import { Icon } from "@mdi/react"
import { mdiClose } from "@mdi/js"
import { renderEditableCell } from "./render-editable-cell"

export const getTableColumns = ({
	handleRemoveRow,
	customColumnsData,
	bulkHardCopyList,
	updateSingleItemInput,
}: {
	handleRemoveRow: any
	customColumnsData: any
	bulkHardCopyList: any
	updateSingleItemInput: (updatedItem: any) => void
}) => {
	const preDefinedColumns = [
		{
			header: "Title",
			accessorKey: "title",
			cell: (cellInfo: any) =>
				renderEditableCell({
					cellInfo,
					data: bulkHardCopyList,
					updateSingleItemInput,
				}),
		},
		{
			header: "Status",
			accessorKey: "status",
			cell: (cellInfo: any) =>
				renderEditableCell({
					cellInfo,
					data: bulkHardCopyList,
					updateSingleItemInput,
				}),
		},
		{
			header: "Year",
			accessorKey: "year",
			cell: (cellInfo: any) =>
				renderEditableCell({
					cellInfo,
					data: bulkHardCopyList,
					updateSingleItemInput,
				}),
		},
		{
			header: "Description",
			accessorKey: "description",
			cell: (cellInfo: any) =>
				renderEditableCell({
					cellInfo,
					data: bulkHardCopyList,
					updateSingleItemInput,
				}),
		},
	]

	const actionColumn = {
		header: "Remove",
		accessorKey: "xxx",
		cell: (props: any) => {
			return (
				<DLIconButton
					eleTestId="remove-row"
					clickHandler={() => handleRemoveRow(props.row.original.id)}
					tooltipText="Cancel select"
				>
					<Icon path={mdiClose} size={0.6} />
				</DLIconButton>
			)
		},
	}

	const customColumns = customColumnsData.map((item: any) => ({
		header: item.label,
		accessorKey: item.id,
		cell: (cellInfo: any) =>
			renderEditableCell({
				cellInfo,
				data: bulkHardCopyList,
				updateSingleItemInput,
			}),
	}))

	const columns = [...preDefinedColumns, ...customColumns, actionColumn]
	// console.log("get-table-columns > columns:", columns)
	return columns
}
