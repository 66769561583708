import React from "react"
import styled from "styled-components"

const StyledDLTab = styled.div`
	/* width: 100%; */
	min-height: var(--standard-unit);
	display: flex;
	flex-direction: row;
	align-items: center;
	transition: 0.4s;
	svg {
		width: 0px;
		overflow: hidden;
		transition: 0.4s;
		fill: ${(props) => props.theme.textHighEmphasis};
	}
	.tab-label {
		width: 100%;
		.text-label {
			padding-left: 6px;
		}

		.MuiBadge-dot {
			background-color: ${(props) => props.theme.warning};
		}
		svg.update-icon {
			margin-left: var(--spacing-rg);
			/* for center align by human view (not calculated value) */
			margin-right: -0.5rem;
			width: 0px;
			overflow: hidden;
		}
	}
	.tab-control {
		svg {
			margin-right: 0;
		}
	}
	&:hover {
		color: ${(props) => props.theme.emphasis};
		font-weight: var(--font-medium);
		svg.update-icon {
		}
		svg.close-icon {
			width: 1.2rem;
			margin-left: 1rem;
			z-index: 999;
		}
		.refresh-button {
			width: 1rem;
			svg.update-icon {
				opacity: 1;
			}
			:hover {
				svg.update-icon {
					fill: ${(props) => props.theme.emphasis};
				}
			}
		}
	}
	.refresh-button {
		width: 0px;
		// margin-right: 1rem;
		overflow: hidden;
		transition: 0.4s;
		margin-left: var(--spacing-rg);
		svg.update-icon {
			transition: 0.4s;
			opacity: 0;
			fill: ${(props) => props.theme.textHighEmphasis};
		}
	}
`

export default StyledDLTab
