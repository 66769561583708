import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import {
    DLButton,
    DLRadioGroup,
} from "../../../../../components/basic-elements"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

export default observer(function ArchiveChecklist({
    projStore,
    partialStore,
}: {
    projStore: any
    partialStore: any
}) {
    const store = useRootStore()

    const { timeZone, dateFormat, timeFormat } = store.global.getDntFormat
    const {
        checklistItem1,
        checklistItem2,
        updateByAliasName,
        updateByUserName,
        lastUpdatedDate,
        finalReportDate,
    } = projStore.getChecklistInfo(timeZone, dateFormat, timeFormat)

    const { isLocked } = projStore.projInfo

    const [checklist, setChecklist] = useState({
        checklistItem1,
        checklistItem2,
    })

    const handleChecklist = (e: any) => {
        const { name, value } = e.target
        console.log(name, value)
        setChecklist((prev: any) => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleSaveChecklist = () => {
        let proceed = window.confirm(
            `The following results related to the archive checklist are saved
            Archive Checklist 1: ${checklist.checklistItem1}
            Archive Checklist 2: ${checklist.checklistItem2}`
        )
        if (!proceed) return
        projStore.editArchiveChecklist(
            checklist.checklistItem1,
            checklist.checklistItem2,
            partialStore.handleResponse
        )
    }

    return (
        <StyledArchiveChecklist>
            <h3>Archive Checklist</h3>
            {isLocked && (
                <h4 style={{ color: "red" }}>
                    To update the archive checklist, please unlock the project
                    first.
                </h4>
            )}
            <div className="checklist">
                <h4>
                    1. The project's final report date is entered correctly.
                    <span className="final-report-date">
                        ({finalReportDate})
                    </span>
                </h4>
                <span className="info">
                    Please update the date in the project information screen if
                    it is incorrect or empty.
                </span>
                <DLRadioGroup
                    items={[
                        { value: "Confirmed", label: "Confirmed" },
                        { value: "N/A", label: "Not Applicable" },
                    ]}
                    selectedValue={checklist.checklistItem1}
                    eleOnChange={handleChecklist}
                    itemDirection="column"
                    eleClassName="checklist-radio"
                    eleTestId="checklist-1"
                    groupName="checklistItem1"
                    disabled={isLocked}
                />
            </div>
            <div className="checklist">
                <h4>
                    2. All project participants' independence signatures are
                    properly documented by the completion of the project.
                </h4>
                <DLRadioGroup
                    items={[
                        { value: "Confirmed", label: "Confirmed" },
                        { value: "N/A", label: "Not Applicable" },
                    ]}
                    selectedValue={checklist.checklistItem2}
                    eleOnChange={handleChecklist}
                    itemDirection="column"
                    eleClassName="checklist-radio"
                    eleTestId="checklist-2"
                    groupName="checklistItem2"
                    disabled={isLocked}
                />
            </div>
            <div className="last-update-info">
                <div>
                    Last Updated By: {updateByUserName} ({updateByAliasName})
                </div>
                <div>Last Updated Date: {lastUpdatedDate}</div>
            </div>
            <div className="FR AC save-btn">
                <DLButton
                    eleTestId="save-arch-checklist"
                    clickHandler={handleSaveChecklist}
                    color="primary"
                    disabled={
                        isLocked ||
                        !checklist.checklistItem1 ||
                        !checklist.checklistItem2
                    }
                >
                    Save Archive Checklist
                </DLButton>
            </div>
        </StyledArchiveChecklist>
    )
})

const StyledArchiveChecklist = styled.div`
    .checklist,
    .save-btn {
        margin-top: 1rem;
    }
    .checklist-radio {
        margin-top: 0.6rem;
        margin-left: 2rem;
    }
    .info,
    .final-report-date,
    .last-update-info {
        opacity: 0.5;
    }
`
