import { types } from "mobx-state-tree"
// ViewModel, Model, UiHelper
import OrgTemplatesModel from "./org-proj-templates.model"
import OrgTemplatesViewModel from "./org-proj-templates.view-model"
import OrgTemplateUiHelper from "./org-proj-templates.ui-helper"
// Common
import { OrgProjTemplateItemType } from "./data-models/org-proj-templates.data-model"
import { initialClickPoint } from "../../../../../common-models/types/dialog.props"
// apis
import * as api from "./org-proj-templates.apis"
import * as fileApi from "../../../../../service-modules/file-module/apis/file-system-file-apis"
import * as folderApi from "../../../../../service-modules/file-module/apis/file-system-folder-apis"
import * as downloadApi from "../../../../../service-modules/file-module/apis/file-system-download-apis"
import { assignRole } from "../../../../../service-modules/file-module/apis/file-system-assign-apis"

//
import { OrgProjTemplatesApiProps } from "./org-proj-templates.api-props"
import {
	FileMenuViewType,
	SignOffDisplayType,
} from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import ProjTemplateFileControl from "./org-proj-templates.file-control"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DialogActionType } from "../../../../../common-models/enumerations/common-enums"
import { OldSignOffType } from "../../../../../service-modules/file-module/data-models/sign-off.data-model"
import { initialFileInfo } from "../../../../../service-modules/file-module/data-models/initial-file"

export const OrgTemplatesStore = (props: OrgProjTemplatesApiProps) =>
	types
		.compose(
			OrgTemplatesModel(props),
			OrgTemplatesViewModel,
			OrgTemplateUiHelper,
			// NOTE:
			ProjTemplateFileControl
		)
		.named("OrgTemplatesStore")

export const initialStore = {
	storeName: DLOrgSubMenus.proj_templates,
	selectedTemplate: {
		id: "",
		parentId: "",
		title: "",
		roleSetId: "default_proj_role_set_id",
		type: OrgProjTemplateItemType.template,
		expanded: undefined,
	},
	viewType: FileMenuViewType.tree,
	fileInfo: initialFileInfo,
	//

	dialogOpenStatus: {},
	multipleSignOffType: { type: OldSignOffType.prepare },
	commentDetailDialogMode: DialogActionType.read,
	//
	// file tree related part
	fileTreeDialogOpenStatus: {},
	selectedFile: "",
	clickPoint: initialClickPoint,
	folderClickPoint: initialClickPoint,
	fileClickPoint: initialClickPoint,
	//
	// placeholder (not be used in the project template)
	showColumns: {},
	showSignOffColumns: {},
	columnWidth: {},
	signOffCellType: SignOffDisplayType.nameAndDate,
	signOffDisplayRange: "LATEST",
	signOffSortType: "ASCENDING",
	//
	searchType: "file",
	// for template group
	templateGroupClickPoint: initialClickPoint,
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const OrgProjTemplatesStoreInstance = OrgTemplatesStore({
	apiReadProjTemplateGroups: api.readProjTemplateGroups,
	apiReadProjTemplateDetails: api.readProjTemplateDetails,
	/**
	 *  * separated actions hierarchy
	 * - Template Folder > Template > File Folder > File
	 * - Template Folder
	 * - Template
	 * - File Folder (in Template)
	 * - File (in Template)
	 */

	// 1.Template Group
	apiCreateProjTemplateGroup: api.createProjTemplateGroup,
	apiDeleteTemplateGroup: api.deleteTemplateGroup,
	apiUpdateTemplateGroup: api.updateTemplateGroup,
	apiRequestDuplicateProjTemplateGroup: api.requestDuplicateProjTemplateGroup,
	//
	// 2. Template
	apiCreateTemplate: api.createTemplate,
	apiUpdateTemplate: api.updateTemplate,
	apiDeleteTemplate: api.deleteTemplate,
	apiDuplicateTemplate: api.duplicateTemplate,

	// 3. File Folder (in Template)
	apiCreateFileFolder: folderApi.createFolder,
	apiReadFileFolderDetalis: api.readFileFolderDetails,
	apiUpdateFileFolder: folderApi.updateFolder,
	apiDeleteFileFolder: folderApi.deleteFolder,
	apiReorderChildren: folderApi.reorderChildren,

	// 4. file
	apiCreateFile: fileApi.createFile,
	apiUpdateFile: fileApi.updateFile,
	apiDeleteFile: fileApi.deleteFile,
	apiReplaceFile: fileApi.replaceFile,
	apiDuplicateFile: api.duplicateFile,
	//
	apiAssignRole: assignRole,

	// download
	apiDownloadFile: downloadApi.downloadFile,
	apiDownloadFolder: downloadApi.downloadFolder,
	apiDownloadAll: downloadApi.downloadAll,
	//
}).create(initialStore)

export default OrgProjTemplatesStoreInstance
