import React from "react"
import { DLButton } from "../../../../components/basic-elements"
import { observer } from "mobx-react-lite"

export default observer(function AuditProgramActionBtns({
	partialStore,
}: {
	partialStore: any
}) {
	return (
		<div className="pbc-action-area FR JSB">
			<DLButton
				eleTestId="start-pre-process-btn"
				// clickHandler={() =>}
			>
				Import Data
			</DLButton>
			<div className="FR AC">
        		<DLButton
        			eleTestId="clear-filters-btn"
        			color="primary"
        			// clickHandler={() =>}
        		>
        			Clear Filters
        		</DLButton>
        		<DLButton
        			eleTestId="template-btn"
        			color="primary"
        			// clickHandler={() =>}
        		>
        			Template
        		</DLButton>
        		<DLButton
        			eleTestId="add-btn"
        			color="primary"
        			// clickHandler={() =>}
        		>
        			Add New Step
        		</DLButton>
			</div>
			<div className="FR AC">
			    <DLButton
        			eleTestId="prepare-btn"
        			color="primary"
        			// clickHandler={() =>}
        		>
        			Prepare
        		</DLButton>
        		<DLButton
        			eleTestId="review-btn"
        			color="primary"
        			// clickHandler={() =>}
        		>
        			Review
        		</DLButton>
        		<DLButton
        			eleTestId="assign-users-btn"
        			color="primary"
        			// clickHandler={() =>}
        		>
        			Assign Users
        		</DLButton>
			</div>
    		<DLButton
    			eleTestId="export-btn"
    			color="primary"
    			// clickHandler={() =>}
    		>
    			Export
    		</DLButton>
		</div>
	)
})
