import React from "react"
import { observer } from "mobx-react-lite"
import ReportsManagementTableColumns, {
	filterComponent,
} from "./ReportsManagementTableColumns"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { Table } from "@tanstack/react-table"
import { PageHeader } from "../../../../../components/app-frame-elements"
import BasicCounter from "../../../../../components/app-frame-elements/sub-components/BasicCounter"
import { DLButton } from "../../../../../components/basic-elements"
import { ProjScreenType } from "../../../../../components/combined-elements/projects-table/projects-table-props"

export default observer(function ReportsManagementTable({
	partialStore,
	data, // previous: partialStore.formattedProjList(dntFormat)
	dateFormat,
	contentHeight,
	handleContextMenu,
	menuId,
	i18n,
}: {
	partialStore: any
	data: any
	dateFormat: string
	contentHeight: number
	handleContextMenu: (event: any, targetInfo: any) => void
	menuId: DLOrgSubMenus
	i18n?: DLI18nProps
}) {
	const handleYearChange = (year: string) => {
		partialStore.getOnlineProjects(year)
	}
	const pageHeader = (table: Table<any>) => {
		const totalProjects = table.getPreFilteredRowModel().flatRows.length
		const filteredProjects = table.getFilteredRowModel().flatRows.length

		return (
			<PageHeader>
				<div className="FR AC counter-container">
					<BasicCounter
						label={i18n?.twTotal || "Total :"}
						value={totalProjects}
					/>

					{totalProjects > filteredProjects && (
						<div className="FR AC">
							<span className="partition">|</span>
							<div className="filtered-count">
								Filtered Projects: {filteredProjects}
							</div>
							<span className="partition">|</span>
							<DLButton
								eleTestId="clear-filters"
								clickHandler={table.resetColumnFilters}
							>
								Clear Filters
							</DLButton>
						</div>
					)}
				</div>
			</PageHeader>
		)
	}

	const epList = partialStore.viewEPUsers(ProjScreenType.reportAndChecklist)
	const qcList = partialStore.viewQCList(ProjScreenType.reportAndChecklist)

	return (
		<ReactTableV8
			tableColumns={ReportsManagementTableColumns({
				partialStore,
				handleContextMenu,
				i18n,
			})}
			data={data}
			menuId={menuId}
			customFilters={(props: any) =>
				filterComponent({
					...props,
					dateFormat,
					epList,
					qcList,
					handleYearChange,
				})
			}
			pageHeader={pageHeader}
			handleContextMenu={handleContextMenu}
			hasPagination
			height={contentHeight - 48}
		/>
	)
})
