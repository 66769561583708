import React from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import ChecklistDialogContent from "../../../organization-side/reports-management/online-projects/sub-components/checklist-dialog/ChecklistDialogContent"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import AddChecklist from "./sub-components/AddChecklist"

export default observer(function Checklist({ 
	partialStore,
	actionStatus
}: { 
	partialStore: any
	actionStatus: ActionStatus	
}) {
	const projStore = useProjStore()
	return (
		<PageContainer hasToolbar={false} isLoading={actionStatus === "LOADING"}>
			{partialStore.checklistDetails ? (
				<ChecklistDialogContent partialStore={partialStore} selectedProjId={projStore.checkin.projId}/>
			):(
				<AddChecklist />
			)}
		</PageContainer>
	)
})
