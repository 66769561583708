import React, { useCallback } from "react"
import styled from "styled-components"
import RentHardCopyDialogContentLeftSide from "./LeftSide"
import RentHardCopyDialogContentRightSide from "./RightSide"
import { formatFileSize } from "@datalobby/common/lib/format-file-size"
import { v4 as uuidv4 } from "uuid"

const RentHardCopyDialogContent = ({
	inputs,
	setInputs,
	userOptions,
	dateFormat,
	hardCopyDetails,
	customColumns,
}: {
	inputs: any
	setInputs: any
	userOptions: any[]
	dateFormat: string
	hardCopyDetails: any
	customColumns: any
}) => {
	const {
		hasSoftCopy,
		title,
		projectAlias,
		projectName,
		qcUsers,
		epUsers,
		groupId,
		clientAlias,
		clientName,
		year,
	} = hardCopyDetails

	const customColumnsInfo = customColumns.reduce(function (r: any, e: any) {
		r[e.label] = hardCopyDetails[e.id]
		return r
	}, {})

	const projInfo = {
		hardCopyName: title,
		hasSoftCopy,
		...(hasSoftCopy === "Yes"
			? {
					projectAlias,
					projectName,
					qcUsers,
					epUsers,
					groupId,
					clientAlias,
					clientName,
					year,
			  }
			: customColumnsInfo),
	}

	const handleInputs = (e: any) => {
		const { name, value } = e.target
		setInputs((inputs: any) => ({
			...inputs,
			[name]: value,
		}))
	}

	const handleDueDate = (date: string) =>
		setInputs((inputs: any) => ({
			...inputs,
			dueDate: date,
		}))

	const handleComboBox = (userInfo: any) =>
		setInputs((inputs: any) => ({
			...inputs,
			rentedBy: {
				id: userInfo.userId,
				name: userInfo.name,
				alias: userInfo.userAliasId,
			},
		}))

	const handleFiles = (event: any) => {
		const choosedFiles = Array.from(event.target.files)
		const files: any[] = []
		choosedFiles.map((item: any) => {
			const formattedSize = formatFileSize(item.size, 2)
			const extension = item.name.slice(item.name.lastIndexOf("."))
			const fileName = item.name.slice(0, item.name.lastIndexOf("."))
			files.push({
				tempId: uuidv4(),
				extension,
				fileName,
				size: formattedSize,
				file: item,
			})
		})
		setInputs((inputs: any) => ({
			...inputs,
			files,
		}))
	}

	const removeFilefromList = useCallback((id: any) => {
		setInputs((inputs: any) => ({
			...inputs,
			files: inputs.files.filter((file: any) => file.tempId !== id),
		}))
	}, [])

	return (
		<StyledRentHardCopyDialog className="FR">
			<RentHardCopyDialogContentLeftSide projInfo={projInfo} />
			<RentHardCopyDialogContentRightSide
				inputs={inputs}
				handleInputs={handleInputs}
				handleFiles={handleFiles}
				handleComboBox={handleComboBox}
				handleDueDate={handleDueDate}
				dateFormat={dateFormat}
				userOptions={userOptions}
				removeFilefromList={removeFilefromList}
			/>
		</StyledRentHardCopyDialog>
	)
}

export default RentHardCopyDialogContent

const StyledRentHardCopyDialog = styled.div`
	.left-side,
	.right-side {
		width: 50%;
	}
	.section {
		padding: 10px 2px 15px 20px;
	}
	.file-upload {
		padding: 15px;
		border: 1px solid rgba(204, 204, 204, 0.5);
		border-radius: 5px;
	}
`
