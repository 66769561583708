import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"

export const RefreshForProjects = (self: any) => ({
	customRefresh(projStatus: ProjectStatus, isAssignedOnly?: boolean) {
		const actionName = "getOrgProjectList_" + projStatus
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.standby,
		})
		
		if (projStatus === ProjectStatus.normal) {
			self.normalProjList.length = 0
			self.setNeedRefresh(projStatus, true, isAssignedOnly)
		} else if (ProjectStatus.replica) {
			self.replicaProjList.length = 0
			self.setNeedRefresh(projStatus, true, isAssignedOnly)
		} else if (ProjectStatus.archived) {
			self.archivedProjList.length = 0
			self.setNeedRefresh(projStatus, true, isAssignedOnly)
		} else if (ProjectStatus.unarchived) {
			self.unarchivedProjList.length = 0
			self.setNeedRefresh(projStatus, true, isAssignedOnly)
		} else if (ProjectStatus.roamArchived) {
			self.roamArchivedProjList.length = 0
			self.setNeedRefresh(projStatus, true, isAssignedOnly)
		} else {
			self.initializeStore()
		}
	},
})
