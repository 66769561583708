import React from "react"
import { observer } from "mobx-react-lite"
import {
	InputWithLabel,
	DLSingleSelect,
	DLInputField,
	DLComboBox,
	DLDatepickerWithoutInput,
	DLIconButton,
	DLSystemMsg,
	ConsoleLog,
} from "../../basic-elements"

import {
	formattedDateWithTz,
	getFormattedEOD,
} from "../../../library/converters/date-utc-converter"

import BreadCrumbsAndOptions from "../postpone-to-classify/BreadCrumbsAndOptions"
import { CreateProjectSectionLeftProps } from "./create-project.comp-props"
import Icon from "@mdi/react"
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js"
import { createFilterOptions } from "@material-ui/lab/Autocomplete"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"
import { MessageColorType } from "../../../common-models/enumerations/common-enums"
import DLYearpicker from "../../basic-elements/date-picker/DLYearpicker"
import { getYear } from "date-fns"

export default observer(function CreateProjectSectionLeft({
	path,
	handleSelectPath,
	getLocationDetail,
	//
	inputs,
	handleInputs,
	handleComboBoxInput,
	handleDateInput,
	handleYearInput,
	// options
	breadCrumbOptions,
	clientList,
	engTypeList,
	periodTypeList,
	archPolicyPeriodList,
	raList,
	//
	dateFormat,
	timeFormat,
	timeZone,
	highlight,
	//
	i18n,
	//
	sectionOn,
	handleSectionOn,
}: CreateProjectSectionLeftProps) {
	const pathLength = path.length
	ConsoleLog("handle section of off 1 " + sectionOn)
	ConsoleLog("inputs on section1 " + inputs)

	return (
		<div className={`left-area ${sectionOn ? "section-on" : ""}`}>
			{/* 
				<div className="dialog-section-header heading3 FR AC JSB">
					{sectionOn ? "1. Basic Information" : "1"}

					<DLIconButton
						eleTestId="section1-on-off"
						clickHandler={() => handleSectionOn("section1")}
						size="small"
					>
						{sectionOn ? (
							<Icon path={mdiChevronLeft} size={0.8} />
						) : (
							<Icon path={mdiChevronRight} size={0.8} />
						)}
					</DLIconButton>
				</div> 
				*/}
			<BreadCrumbsAndOptions
				path={path}
				setPath={handleSelectPath}
				options={breadCrumbOptions}
				getLocationDetail={getLocationDetail}
			/>
			{/* TODO: Hide breacrumbs option when the user click the other field automatically */}
			{path.length === 0 && (
				<div className="location-warning">! Please select a group</div>
			)}
			{path.length === 1 && (
				<div className="location-warning">
					! Please select a cabinet
				</div>
			)}

			<div className="input-section FR">
				<InputWithLabel
					label={i18n.twClient}
					required
					eleClassName="with-label"
					highlight={highlight && inputs.clientId === ""}
				>
					{pathLength <= 1 ? (
						<div className="request-required-field">
							Please set location first
						</div>
					) : (
						<div>
							<DLComboBox
								eleTestId="client-input-field"
								withoutLabel
								placeholder={i18n.tsSelectClient}
								options={clientList}
								filterOptions={createFilterOptions({
									// matchFrom: 'start',
									stringify: (option: any) =>
										option.name + option.aliasId,
								})}
								// getOptionLabel={(option: any) =>
								// 	"(" +
								// 	option.aliasId +
								// 	") " +
								// 	option.name
								// }
								renderOption={(
									option: any,
									{ inputValue }: { inputValue: any }
								) => {
									const matches_name = match(
										option.name,
										inputValue
									)
									const parts_name = parse(
										option.name,
										matches_name
									)
									const matches_aliasId = match(
										option.aliasId,
										inputValue
									)
									const parts_aliasId = parse(
										option.aliasId,
										matches_aliasId
									)
									return (
										<div className="FR AC">
											<div
												style={{
													marginRight: "0.4rem",
												}}
											>
												{parts_name.map((part, i) => (
													<span
														key={i}
														style={{
															fontWeight:
																part.highlight
																	? 700
																	: 400,
														}}
													>
														{part.text}
													</span>
												))}
											</div>

											<div
												style={{
													marginRight: "1rem",
													opacity: 0.8,
												}}
											>
												(
												{parts_aliasId.map(
													(part, i) => (
														<span
															key={i}
															style={{
																fontWeight:
																	part.highlight
																		? 700
																		: 400,
															}}
														>
															{part.text}
														</span>
													)
												)}
												)
											</div>
										</div>
									)
								}}
								onChange={(value: any) =>
									handleComboBoxInput("clientId", value)
								}
								eleValue={
									inputs.clientAliasId && inputs.clientName
										? {
												aliasId: inputs.clientAliasId,
												name: inputs.clientName,
										  }
										: undefined
								}
							/>
						</div>
					)}
				</InputWithLabel>
			</div>

			<div className="input-section FR">
				<InputWithLabel
					label={i18n.twEngType}
					required
					eleClassName="with-label"
					highlight={highlight && inputs.engTypeId === ""}
				>
					{pathLength <= 1 ? (
						<div className="request-required-field">
							Please set location first
						</div>
					) : (
						<DLComboBox
							eleTestId="engType-input-field"
							withoutLabel
							placeholder={i18n.tsSelectEngType}
							onChange={(value: any) =>
								handleComboBoxInput("engTypeId", value)
							}
							getOptionLabel={(option: any) => option.title}
							options={engTypeList}
							defaultValue={{
								id: inputs.engTypeId,
								title: inputs.engTypeName,
							}}
						/>
					)}
				</InputWithLabel>
			</div>

			<div className="input-section FR">
				<InputWithLabel
					label={i18n.twPeriodType}
					required
					eleClassName="with-label"
					highlight={highlight && inputs.periodId === ""}
				>
					<DLComboBox
						eleTestId="period-input-field"
						withoutLabel
						placeholder={i18n.tsSelectPeriodType}
						onChange={(value: any) =>
							handleComboBoxInput("periodId", value)
						}
						getOptionLabel={(option: any) => option.title}
						options={periodTypeList}
						defaultValue={{
							id: inputs.periodId,
							title: inputs.periodName,
						}}
					/>
				</InputWithLabel>
			</div>

			<div className="input-section FR">
				<InputWithLabel
					label={i18n.twPeriodEndDate}
					required
					eleClassName="with-label"
					highlight={highlight && inputs.periodEndDate === ""}
				>
					<div className="FR AC" style={{ paddingTop: 6 }}>
						<DLDatepickerWithoutInput
							eleTestId="period-end-input"
							onChange={(date: any) =>
								handleDateInput("periodEndDate", date)
							}
							autoOk
							format={dateFormat}
						/>
						<div
							className="date-info FR AC"
							data-testid="period-end-date-display"
							style={{ marginLeft: "1rem", opacity: 0.3 }}
						>
							{inputs.periodEndDate !== ""
								? formattedDateWithTz({
										date: inputs.periodEndDate,
										timeZone: timeZone.uiValue,
										timeFormat,
										dateFormat,
										tzLabel: timeZone.value,
								  })
								: "Please select a date"}
						</div>
					</div>
				</InputWithLabel>
			</div>

			<div className="input-section FR">
				<InputWithLabel
					label={i18n.twExpectedReportDate}
					required
					eleClassName="with-label"
					highlight={highlight && inputs.expectedReportDate === ""}
				>
					<div className="FR AC" style={{ paddingTop: 6 }}>
						<DLDatepickerWithoutInput
							eleTestId="expected-report-date-input"
							onChange={(date: any) =>
								handleDateInput("expectedReportDate", date)
							}
							autoOk
							format={dateFormat}
						/>
						<div
							className="date-info FR AC"
							data-testid="expected-report-date-display"
							style={{ marginLeft: "1rem", opacity: 0.3 }}
						>
							{inputs.expectedReportDate !== ""
								? getFormattedEOD({
										date: inputs.expectedReportDate,
										timeZone: timeZone.uiValue,
										timeFormat,
										dateFormat,
										tzLabel: timeZone.value,
								  })
								: "Please select a date"}
						</div>
					</div>
					{inputs.periodEndDate !== "" &&
						inputs.expectedReportDate !== "" && (
							<>
								{new Date(inputs.periodEndDate).getTime() >
									new Date(
										inputs.expectedReportDate
									).getTime() && (
									<DLSystemMsg
										type={MessageColorType.orange}
										msg="Period End Date is late than Expected Report Date"
									/>
								)}
							</>
						)}
				</InputWithLabel>
			</div>

			<div className="input-section FR">
				<InputWithLabel
					label={i18n.twFinalReportDate}
					required
					eleClassName="with-label"
					highlight={highlight && inputs.finalReportDate === ""}
				>
					<div className="FR AC" style={{ paddingTop: 6 }}>
						<DLDatepickerWithoutInput
							eleTestId="final-report-date-input"
							onChange={(date: any) =>
								handleDateInput("finalReportDate", date)
							}
							autoOk
							format={dateFormat}
						/>
						<div
							className="date-info FR AC"
							data-testid="final-report-date-display"
							style={{ marginLeft: "1rem", opacity: 0.3 }}
						>
							{inputs.finalReportDate !== ""
								? getFormattedEOD({
										date: inputs.finalReportDate,
										timeZone: timeZone.uiValue,
										timeFormat,
										dateFormat,
										tzLabel: timeZone.value,
								  })
								: "Please select a date"}
						</div>
					</div>
				</InputWithLabel>
			</div>

			<div className="input-section FR">
				<InputWithLabel
					label={i18n.twFinancialYear}
					required
					eleClassName="with-label"
					// highlight={highlight && inputs.expectedReportDate === ""}
				>
					<div className="FR AC" style={{ paddingTop: 6 }}>
						<DLYearpicker
							eleTestId="financial-year-select"
							eleName="year"
							selected={inputs.year}
							onChange={(date: any) => handleYearInput(date)}
						/>
					</div>
					{inputs.periodEndDate !== "" &&
						getYear(new Date(inputs.periodEndDate)) !==
							inputs.year && (
							<DLSystemMsg
								type={MessageColorType.orange}
								msg="Selected financial year is different from period end date"
							/>
						)}
				</InputWithLabel>
			</div>

			<div className="input-section FR">
				<InputWithLabel
					label={
						i18n.twArchivePolicyPeriod || "Archive Policy - Period"
					}
					required
					eleClassName="with-label"
					highlight={highlight && inputs.archPolicyPeriod === ""}
				>
					<DLComboBox
						eleTestId="archive-policy-period-select"
						withoutLabel
						placeholder={i18n.tsSelectArchivePolicyPeriod}
						onChange={(value: any) =>
							handleComboBoxInput("archPolicyPeriod", value)
						}
						getOptionLabel={(option: any) => option.title}
						options={archPolicyPeriodList}
						// defaultValue={{
						// 	id: inputs.archPolicyPeriod,
						// 	// title: archPolicyPeriodList.find(
						// 	// 	(ele: any) =>
						// 	// 		ele.id === inputs.archPolicyPeriod
						// 	// ).title,
						// }}
					/>

					{inputs.expectedArchiveDate !== "" && (
						<div>
							Expected Archive Date:{" "}
							{getFormattedEOD({
								date: inputs.expectedArchiveDate,
								timeZone: timeZone.uiValue,
								timeFormat,
								dateFormat,
								tzLabel: timeZone.value,
							})}
						</div>
					)}
				</InputWithLabel>
			</div>

			<div className="input-section FR">
				<InputWithLabel
					label={i18n.twProjectTitle}
					required
					eleClassName="with-label"
					highlight={highlight && inputs.title === ""}
				>
					<DLInputField
						eleTestId="project-title"
						eleName="title"
						eleValue={inputs.title}
						eleHandleChange={handleInputs}
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel
					label={i18n.twProjectId}
					required
					eleClassName="with-label"
					highlight={highlight && inputs.aliasId === ""}
				>
					<DLInputField
						eleTestId="project-alias-id"
						eleName="aliasId"
						eleValue={inputs.aliasId}
						eleHandleChange={handleInputs}
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel
					label={i18n.twRiskAssessment}
					required
					eleClassName="with-label"
					highlight={highlight && inputs.riskAssessment === ""}
				>
					<DLComboBox
						eleTestId="risk-assessment-select"
						withoutLabel
						placeholder={i18n.tsSelectRiskAssessment}
						onChange={(value: any) =>
							handleComboBoxInput("riskAssessment", value)
						}
						getOptionLabel={(option: any) => option.title}
						options={raList}
						// defaultValue={{
						// 	id: inputs.archivePolicyPeriod,
						// 	// title: archPolicyPeriodList.find(
						// 	// 	(ele: any) =>
						// 	// 		ele.id === inputs.archivePolicyPeriod
						// 	// ).title,
						// }}
					/>
					{/* <DLSingleSelect
							//// placeholder={i18n.tsSelectRiskAssessment}
							eleName="riskAssessment"
							eleValue={inputs.riskAssessment}
							////eleTestId="risk-assessment-select"
							//// optionList={raList}
							eleOnChange={handleInputs}
							//// withLabel={false}
						/> */}
				</InputWithLabel>
			</div>
		</div>
	)
})
