import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
import { ReadHcMgmtHistories } from "./model-actions"
import { HcMgmtHistoryApiProps } from "./hc-mgmt-history.apis"

const HcMgmtHistoryModel = ({ apiReadHistories }: HcMgmtHistoryApiProps) =>
	types
		.model({})
		.actions((self) => ReadHcMgmtHistories(self, apiReadHistories))
		// model common actions
		.actions(CommonModelActions)

export default HcMgmtHistoryModel
