import React from "react"

export const renderEditableCell = ({
	cellInfo,
	data,
	updateSingleItemInput,
}: {
	cellInfo: any
	data: any
	updateSingleItemInput: (updatedItem: any) => void
}) => {
	const copiedData = JSON.parse(JSON.stringify(data))
	const target = copiedData.find(
		(item: any) => item.tempId === cellInfo.row.original.tempId
	)
	if (target) {
		const columnId = cellInfo.column.id

		let error = []
		let className = ""

		if (columnId === "title") {
			let duplicateName =
				copiedData.filter((x: any) => x.title === target[columnId])
					.length > 1
			// if (partialStore.isDuplicatedAliasId(target[columnId])) {
			// 	className = "error"
			// 	error.push("Alias id already exist")
			// }
			if (duplicateName) {
				className = "error"
				error.push("Duplicate name")
			}
		}

		let testId = columnId + "-cell"
		return (
			<div className="FC" style={{ width: "100%" }}>
				<div
					data-testid={testId}
					className={className}
					style={{
						backgroundColor: "#fafafa",
						width: "100%",
					}}
					contentEditable
					suppressContentEditableWarning
					onBlur={(e: any) => {
						const tempId = cellInfo.row.original.tempId
						const prevItem = copiedData.find(
							(item: any) => item.tempId === tempId
						)
						console.log("prevItem:", prevItem)

						if (prevItem) {
							console.log("columnId", columnId)
							console.log(
								"e.target.innerHTML",
								e.target.innerHTML
							)
							const updatedItem = {
								...prevItem,
								[columnId]: e.target.innerHTML
									.replace(/&nbsp;/g, " ")
									.trim(),
							}
							const objKeys = Object.keys(updatedItem)
							let customColumns: { id: string; value: any }[] = []

							objKeys.map((key) => {
								if (key.includes("CC_")) {
									customColumns.push({
										id: key,
										value: updatedItem[key],
									})
								}
							})

							updateSingleItemInput({
								...updatedItem,
								customColumns,
							})
						} else {
							console.log(
								"renderEditableCell > cannot find the matched item. tempId:",
								tempId
							)
						}
					}}
					dangerouslySetInnerHTML={{
						__html:
							target && target[columnId] ? target[columnId] : "",
					}}
				/>
				{error.length > 0 && (
					<div
						style={{ color: "red" }}
						data-testid={`${columnId}-error`}
					>
						{error.map((item: any) => {
							return (
								<>
									<span>{item}</span>
									<br />
								</>
							)
						})}
					</div>
				)}
			</div>
		)
	}
	return null
}
