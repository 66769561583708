import React from "react"
import Trash from "./Trash"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { useHcMgmtStore } from "../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"

const TrashController = () => {
	const orgStore = useOrgStore()
	const hcMgmtStore = useHcMgmtStore()
	const menuId = DLOrgSubMenus.hard_copy_trash

	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	// 	const needRefresh = hcMgmtStore.hardCopy.needRefresh
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	return (
		<>
			{menuAccess ? (
				<Trash
					partialStore={hcMgmtStore.hardCopy}
					permission={permission}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
}

export default TrashController
