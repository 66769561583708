import React, { useEffect } from "react"
import { useProjStore } from "../../../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import RationaleFilesColumns, { filterComponent } from "../RationaleFileColumns"
import { DLDialog } from "../../../../../../components/basic-elements"
import ReactTableV8 from "../../../../../../components/basic-elements/tables/DLReactTable"
import styled from "styled-components"

export default observer(function RationaleDialog() {
	const projStore = useProjStore()
	const actionName = "getRationaleFiles"
	useEffect(() => {
		projStore.archive.getRationaleFiles()
	}, [])

	return (
		<DLDialog
			isOpen={projStore.archive.rationaleDialogOpen}
			setIsOpen={projStore.archive.setRationaleDialogOpen}
			showCloseBtn
			eleTestId="archive-dialog"
			dialogTitle={<div className="FR AC">Rationale</div>}
			dialogContents={
				projStore.archive.getActionStatus(actionName) === "SUCCESS" && (
					<RationaleDialogContents />
				)
			}
			// handleAction={handleOnSubmit}
			maxWidth="lg"
			actionBtn={false}
			openBtn={false}
			showSpinner={
				projStore.archive.getActionStatus(actionName) !== "SUCCESS"
			}
		/>
	)
})

const RationaleDialogContents = observer(() => {
	const projStore = useProjStore()
	const handleEdit = () => {}
	const handleDelete = () => {}

	return (
		<StyledRationaleDialog>
			<div className="summary-area FC">
				<div className="FR AC">
					Total Files :{" "}
					{projStore.archive.viewRationaleFiles().length}
				</div>
				<div className="FR AC">
					(Workpapers: {projStore.archive.getRationaleWpLength} /
					Permanent Files: {projStore.archive.getRationalePfLength})
				</div>
			</div>
			<hr />
			<ReactTableV8
				tableColumns={RationaleFilesColumns(handleEdit, handleDelete)}
				customFilters={filterComponent}
				data={projStore.archive.viewRationaleFiles()}
				menuId="RationaleDialog"
				showPageSetting={false}
				hasPagination={false}
			/>
		</StyledRationaleDialog>
	)
})

const StyledRationaleDialog = styled.div`
	.rt-table {
		padding-left: 0rem !important;
	}
`
