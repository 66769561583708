import { flow } from "mobx-state-tree"

const ReadTrashItems = (self: any, readTrashItems: any) => ({
	readTrashItems: flow(function* (params: any) {
		const actionName = "readTrashItems"
		try {
			const response = yield readTrashItems(params)

			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default ReadTrashItems
