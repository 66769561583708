import React from "react"
import styled from "styled-components"

interface StyledSidebarProps {
	drawerWidth: number
	collapsedDrawerWidth: number
}

const StyledSidebar = styled.div<StyledSidebarProps>`
	.MuiDrawer-paper {
		z-index: 998;
		/* for authentication progress bar screen */
		/* if the progress bar be removed by some reason, this z-index style is not required */
	}
	.drawer {
		width: ${(props) => props.drawerWidth}px;
		flex-shrink: 0;
		white-space: nowrap;
		color: ${(props) => props.theme.shade60};
		::-webkit-scrollbar {
			width: 0px; /* Remove scrollbar space */
			background: transparent; /* Optional: just make scrollbar invisible */
		}
		.sidebar-on-off-button {
		}
	}
	.drawer-open {
		width: ${(props) => props.drawerWidth}px;
		transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
	}
	.drawer-close {
		width: ${(props) => props.collapsedDrawerWidth}px;
		transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
		overflow-x: hidden;
	}
	.MuiDrawer-paperAnchorDockedLeft {
		border-right: none;
	}
	.org-drawer {
		background-color: ${(props) => props.theme.orgSidebar};
		color: ${(props) => props.theme.shade60};
		box-shadow: ${(props) => props.theme.shadowRegularRightDeep};
		.view-location {
			background-color: ${(props) => props.theme.orgSidebar};
			border-bottom: 1px solid ${(props) => props.theme.shade10};
			color: ${(props) => props.theme.orgSidebarEmphasisTxt};
		}
		.nested-menu {
			background-color: ${(props) => props.theme.primaryLightDeep};
		}
		.view-location,
		.company-title,
		.active-menu {
			color: ${(props) => props.theme.orgSidebarEmphasisTxt};
		}
	}
	.proj-drawer {
		background-color: ${(props) => props.theme.projSidebar};
		color: ${(props) => props.theme.shade60};
		box-shadow: ${(props) => props.theme.shadowRegularRightLight};
		.view-location {
			background-color: ${(props) => props.theme.projSidebar};
			border-bottom: 1px solid ${(props) => props.theme.shade10};
			color: ${(props) => props.theme.projSidebarEmphasisTxt};
		}
		.view-location,
		.company-title,
		.active-menu {
			color: ${(props) => props.theme.projSidebarEmphasisTxt};
		}
	}
	.company-title {
		width: 100%;
		padding-left: 1rem;
		transition: 0.4s;
		overflow: hidden;
		&.off {
			width: 0;
			opacity: 0;
			padding: 0;
			overflow: hidden;
		}
	}
	.view-location {
		padding: 0 8px 0 16px;
		height: var(--standard-unit);
		font-weight: var(--font-regular);
		& svg {
			fill: ${(props) => props.theme.shade20};
			transition: 0.4s;
			&:hover {
				fill: ${(props) => props.theme.emphasis};
			}
		}
	}
	/* below are shared in sub components */
	.link {
		text-decoration: none;
		padding: nont;
	}
	.menu-icon {
		min-width: 1.5rem;
		width: 1.5rem;
		margin-right: 1rem;
		transition: 0.4s;
		& svg {
			fill: ${(props) => props.theme.shade60};
			color: ${(props) => props.theme.shade60};
		}
	}
	.menu-text span {
		font-size: var(--text-xl);
		font-weight: var(--font-light);
	}
	.nested-menu {
		background-color: ${(props) => props.theme.shade05};
		height: calc(var(--standard-unit) * 0.85);
		transition: 0.3s;

		.menu-text span {
			font-size: var(--text-lg);
		}
		.menu-icon {
			& svg {
				fill: ${(props) => props.theme.shade40};
				color: ${(props) => props.theme.shade40};
			}
		}
	}
	.sidebar-menu-item {
		padding-top: 0;
		padding-bottom: 0;
	}
	.indent {
		& .nested-menu {
			padding-left: 2rem;
		}
	}
	.active-menu {
		background-color: ${(props) => props.theme.shade10};
		.menu-icon svg {
		}
		.menu-text span {
			font-weight: var(--font-bold);
		}
		.nested-menu {
			background-color: ${(props) => props.theme.shade05};
		}
	}
`

export default StyledSidebar
