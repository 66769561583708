import { types } from "mobx-state-tree"
// model & view-model
import HcMgmtHardCopyModel from "./hg-mgmt-hard-copy.model"
import HcMgmgtHardCopyViewModel from "./hg-mgmt-hard-copy.view-model"
import { initialClickPoint } from "../../../../../common-models/types/dialog.props"
import {
	HardCopyMgmtApiProps,
	moveToTrashHardCopy,
	addHardCopy,
	readHardCopies,
	updateHardCopyDetail,
	updateHardCopyStatus,
	deleteCustomColumn,
} from "./hg-mgmt-hard-copy.apis"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"

export const HcMgmtHardCopyStore = ({
	apiMoveToTrashHardCopy,
	apiCreateHardCopy,
	apiReadHardCopies,
	apiUpdateHardCopyDetail,
	apiUpdateHardCopyStatus,
	apiRemoveCustomColumn,
}: HardCopyMgmtApiProps) =>
	types
		.compose(
			HcMgmtHardCopyModel({
				apiMoveToTrashHardCopy,
				apiCreateHardCopy,
				apiReadHardCopies,
				apiUpdateHardCopyDetail,
				apiUpdateHardCopyStatus,
				apiRemoveCustomColumn,
			}),
			HcMgmgtHardCopyViewModel
		)
		.named("HcMgmtHardCopyStore")

export const initialStore = {
	clickPoint: initialClickPoint,
	selectedItems: [],
	bulkImportHc_readLocalFileStatus: ActionStatus.standby,
	// viewModel related parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const HcMgmtHardCopyStoreInstance = HcMgmtHardCopyStore({
	apiMoveToTrashHardCopy: moveToTrashHardCopy,
	apiCreateHardCopy: addHardCopy,
	apiReadHardCopies: readHardCopies,
	apiRemoveCustomColumn: deleteCustomColumn,
	apiUpdateHardCopyDetail: updateHardCopyDetail,
	apiUpdateHardCopyStatus: updateHardCopyStatus,
}).create(initialStore)

export default HcMgmtHardCopyStoreInstance
