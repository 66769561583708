import React, { ReactNode, useEffect } from "react"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { DLButton, DLIconButton } from "../buttons"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiMoveResize } from "@mdi/js"
import { MenuItemLabel } from "./DLContextMenu"
import { ConsoleLog } from ".."
import keyboardJS from "keyboardjs"
import { useRootStore } from "../../../stores/root-store/root-store.provider"

const ITEM_HEIGHT = 48
interface DLContextMenuWithButtonProps {
	menuOptions: {
		label: string
		value: string
		clickEvent?: () => void
		icon?: React.ReactNode
		testId?: string
	}[]
	showButton?: boolean
	showIcon?: boolean
	eleIcon?: ReactNode
	btnText?: string
	btnColor?: "default" | "primary" | "secondary" | "warning"
	eleTestId: string
	useScroll?: boolean
	hasDivider?: Array<number>
}

export default function DLContextMenuWithButton(
	props: DLContextMenuWithButtonProps
) {
	const store = useRootStore()
	const bgColor = store.ui.getThemeValue("background")
	const txtColor = store.ui.getThemeValue("textMediumEmphasis")

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)

	useEffect(() => {
		keyboardJS.pause()
	}, [])

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault()
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const {
		menuOptions,
		showButton,
		showIcon,
		eleIcon,
		btnText,
		btnColor,
		eleTestId,
		useScroll,
		hasDivider,
	} = props
	return (
		<StyledDLMenus>
			{showButton && (
				<DLButton
					variant="contained"
					eleTestId={`${eleTestId}-btn`}
					color={btnColor}
					clickHandler={handleClick}
				>
					{" "}
					{btnText}{" "}
				</DLButton>
			)}

			{showIcon && (
				<DLIconButton
					aria-label="more"
					aria-controls="long-menu"
					aria-haspopup="true"
					clickHandler={handleClick}
					eleTestId={`${eleTestId}-icon-btn`}
					variant="iconOnly"
				>
					{eleIcon ? eleIcon : <Icon path={mdiMoveResize} size={1} />}
				</DLIconButton>
			)}
			<Menu
				anchorEl={anchorEl}
				MenuListProps={{
					id: eleTestId,
					className: `dl-ctx-menu-testid ${
						open ? "opened" : "closed"
					}`,
					style: { backgroundColor: bgColor, color: txtColor },
				}}
				// WARNING: DO NOTE REMOVE OR UPDATE this className 'dl-ctx-menu-testid' and 'opened'
				// It is used on test instead of the testid because this component does not support that property
				// and 'opened' is also required to distinguish the opened ctx menu

				keepMounted
				open={open}
				onClose={() => {
					keyboardJS.resume()
					handleClose()
				}}
				data-testid={`${eleTestId}-menu`}
				PaperProps={{
					style: {
						maxHeight: useScroll ? ITEM_HEIGHT * 4.5 : "none",
						width: 200,
					},
				}}
			>
				{menuOptions.map((option, index) => {
					const { label, value, icon, clickEvent, testId } = option
					return (
						<MenuItem
							id={value}
							data-testid={testId ? testId : value}
							key={index}
							onClick={
								clickEvent
									? () => {
											clickEvent && clickEvent()
											handleClose()
									  }
									: handleClick
							}
							className="menu-item FR AC"
						>
							<MenuItemLabel
								hasDivider={hasDivider}
								index={index}
								icon={icon}
								label={label}
							/>
						</MenuItem>
					)
				})}
			</Menu>
		</StyledDLMenus>
	)
}

const StyledDLMenus = styled.div`
	.menu-item {
		font-size: ${(props) => props.theme.shared.textMd};
	}
`
