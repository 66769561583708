import React, { useState } from "react"
import {
	DLButton,
	InputWithLabel,
	DLComboBox,
} from "../../../../../../../components/basic-elements"
import styled from "styled-components"

export const SelectProjectContent = ({
	partialStore,
	setSelectedProj,
}: {
	partialStore: any
	setSelectedProj: any
}) => {
	const handleComboBoxInput = (name: string, value: string) => {}

	const [searchResult, setSearchResult] = useState<any>([])

	const handleSearch = () => {
		setSearchResult([
			{
				projId: "projId1",
				periodType: "FY",
				projectTitle: "Final External Audit",
				projectAlias: "FE",
				epUsers: "John",
			},
			{
				projId: "projId2",
				periodType: "Q1",
				projectTitle: "Q1 Interim",
				projectAlias: "Int",
				epUsers: "Doe",
			},
		])
	}

	return (
		<StyledSelectProject>
			Please select the project
			<div className="FC">
				<div className="input-section FR">
					<InputWithLabel label="Year" required>
						<DLComboBox
							eleTestId="yaer-input-field"
							withoutLabel
							placeholder="Select Year"
							onChange={(value: any) =>
								handleComboBoxInput("year", value)
							}
							getOptionLabel={(option: any) => option.title}
							options={[]}
						/>
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel label="Client">
						<DLComboBox
							eleTestId="client-input-field"
							withoutLabel
							placeholder="Select Client"
							onChange={(value: any) =>
								handleComboBoxInput("periodId", value)
							}
							getOptionLabel={(option: any) => option.title}
							options={[]}
						/>
					</InputWithLabel>
				</div>
				<div className="FR JC">
					<DLButton
						eleTestId="search-btn"
						clickHandler={() => handleSearch()}
						color="primary"
					>
						Search
					</DLButton>
				</div>
			</div>
			{!!searchResult.length && (
				<div className="search-result">
					<div className="FR header">
						<div className="period">Period</div>
						<div className="title">Project Title</div>
						<div className="alias">Project Alias</div>
						<div className="ep">E.P.</div>
					</div>
					<hr />
					{searchResult.map((item: any) => (
						<div
							className="FR row-item"
							key={item.projId}
							onClick={() => setSelectedProj(item.projId)}
						>
							<div className="period">{item.periodType}</div>
							<div className="title">{item.projectTitle}</div>
							<div className="alias">{item.projectAlias}</div>
							<div className="ep">{item.epUsers}</div>
						</div>
					))}
				</div>
			)}
		</StyledSelectProject>
	)
}

const StyledSelectProject = styled.div`
	.input-section {
		.label {
			min-width: 5rem;
		}
		.input-area {
			min-width: 20rem;
		}
	}
	.search-result {
		margin-top: 25px;
		.header {
			padding: 4px;
			font-weight: bold;
		}
		.period {
			flex-basis: 5rem;
		}
		.title {
			flex-basis: 11rem;
		}
		.alias {
			flex-basis: 9rem;
		}
		.ep {
			flex-basis: 8rem;
		}
		.row-item {
			padding: 4px;
			:hover {
				cursor: pointer;
				background-color: ${(props) => props.theme.primaryLightDeep};
			}
		}
	}
`
