import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { AssignProjUserInputProps } from "../data-models/proj-setup-users.data-models"
import { findProjRoleId } from "../../../../../temporary-data/project-side/find-proj-role"
import {
	findProjAccessType,
	getProjAccessLabel,
} from "../../../../../temporary-data/project-side/find-proj-accessType"
import {
	idToString,
	IdType,
	idToNumber,
} from "../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

function handleAssignedUser(
	self: any,
	response: any,
	userInfo: AssignProjUserInputProps,
	actionName: string
) {
	const fetchedData = response.data
	const assignedUser = {
		// below are same as getProjUsers
		// This response use different key name with getUsers response... : (
		// because some part does not return proper value, use frontend side input data temporarily
		// organization sied info
		id: idToString(fetchedData.UserId, IdType.user), // in getUsers, UserID, and here, UserId
		name: fetchedData.Name,
		aliasId: fetchedData.UserName,
		email: fetchedData.Email,
		orgTitle: fetchedData.Title, // --> There is no proper value... : (
		type: userInfo.type,
		// project side info
		status: fetchedData.isGrayed ? "inactive" : "active",
		// ----- permission info
		roleId:
			fetchedData.RoleId === 0
				? "id_external"
				: findProjRoleId(fetchedData.RoleId),
		roleNumId: fetchedData.RoleId,
		roleName: fetchedData.RoleName === null ? "-" : fetchedData.RoleName,
		accessTypeId:
			userInfo.type === "internal"
				? userInfo.accessTypeId
				: userInfo.extUserRight, // @Noah: it seens incorrect
		accessTypeName: getProjAccessLabel(userInfo.accessTypeId),
		canRollForward: userInfo.canRollForward,
		// ----- date related info
		// modifiedById: "orgUser" + fetchedData.ModifiedBy.toString(), --> This part return null
		// modifiedByName: fetchedData.ModifiedByName,
		modifiedAt: fetchedData.ModifiedDate,
		lastActivity: fetchedData.LastActivity,
		expDate: fetchedData.ExpDate,
		inactiveDueTo: fetchedData.InActiveDueTo,
		strikedOut: fetchedData.IsStrikeOut,
	}

	self.pushItemToList(assignedUser)
	// close the dialog
	self.setOpenAdd(false)
	// If user self assign we need new projToken
	const orgUserId = localStorage.getItem("orgUserId")
	console.log(orgUserId === userInfo.id, orgUserId)
	if (orgUserId === userInfo.id) {
		localStorage.removeItem("projTokens")

		// Do not set return here
		// Do not replace the window.location to project/checkout
		// It will checkout users to the organization and take long time
	}
	// set success case response
	self.handleResponse({
		actionName,
		status: ActionStatus.success,
		code: 200,
		color: MessageColorType.blue,
		open: true,
	})
}

const AssignProjUser = (self: any) => ({
	assignProjUser(
		userInfo: AssignProjUserInputProps,
		projId: string,
		actBy: string
	) {
		// 0.
		const actionName = "assignProjUser"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		const projNumberId = idToNumber(projId, IdType.project)
		const actByNumberId = idToNumber(actBy, IdType.user)

		const payload = {
			AccessRightsID:
				userInfo.type === "internal"
					? findProjAccessType(userInfo.accessTypeId)
					: findProjAccessType(userInfo.extUserRight),
			ClientId: projNumberId,
			CreatedBy: actByNumberId,
			ExpirationDate:
				userInfo.type === "internal" ? null : userInfo.expDate, // TODO: set expiration date for external user
			RoleId:
				userInfo.type === "internal"
					? findProjRoleId(userInfo.roleId)
					: 0,
			UserId: idToNumber(userInfo.id, IdType.user),
			isRollForward: userInfo.canRollForward ? 1 : 0,
			isVerified: false, // this part is required for checking previous assigned log
		}

		self.requestAssignProjUser(payload)
			.then((response: any) => {
				// console.log(actionName, "__response: ", response)
				if (response.status === 200 && response.data.Status === 1) {
					handleAssignedUser(self, response, userInfo, actionName)
				} else if (
					response.status === 200 &&
					response.data.Status === 2
				) {
					let proceed = window.confirm(response.data.Message)
					proceed
						? self
								.requestAssignProjUser({
									...payload,
									isVerified: true,
								})
								.then((response: any) => {
									if (
										response.status === 200 &&
										response.data.Status === 1
									) {
										handleAssignedUser(
											self,
											response,
											userInfo,
											actionName
										)
									} else {
										// set fail case response(2) // TODO: Does this case exist? (Get confirm from Razia)
										self.handleResponse({
											actionName,
											status: ActionStatus.fail,
											code: 999,
											color: MessageColorType.orange,
											open: true,
											autoHide: true,
											autoHideDuration: 2000,
										})
									}
								})
						: // if user select cancel button on the alert
						  self.responses.setResponse(actionName, {
								actionName,
								status: ActionStatus.standby,
						  })
				} else {
					// set fail case response (1)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default AssignProjUser
