import { types } from "mobx-state-tree"

export const HardCopyUserModel = types.model({
	userId: types.string,
	role: types.string,
	userName: types.string,
	alias: types.string,
	status: types.string,
	isRentalAvailable: types.boolean,
	addedDate: types.string,
	modifiedDate: types.string,
	modifiedBy: types.string,
})

export enum HcMgmtUserDialogType {
	suspend = "SUSPEND",
	remove = "REMOVE",
	restore = "RESTORE",
}
