import React from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import styled from "styled-components"
import { DLButton } from "../../../../components/basic-elements"
import AuditProgramTableColumns from "./AuditProgramTableColumns"
import AuditProgramActionBtns from "./AuditProgramActionBtns"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"
import DLDateRangepicker from "../../../../components/basic-elements/date-picker/DLDateRangePicker"
import { Table, Column } from "@tanstack/react-table"
import { InputFieldForTableV8 } from "../../../../components/basic-elements/tables/InputFieldForTable"

export default observer(function AuditProgramEditor({
	partialStore,
}: {
	partialStore: any
}) {
	const store = useRootStore()

	const dateFormat = store.global.dateFormat.value
	const contentsHeight = store.ui.contentsAreaHeight

	return (
		<StyledAuditProgramEditor>
			<AuditProgramActionBtns partialStore={partialStore} />
			<div className="table-container">
				<ReactTableV8
					tableColumns={AuditProgramTableColumns()}
					customFilters={(props: any) =>
						filterComponent({ ...props, dateFormat })
					}
					data={[]}
					menuId="AuditProgramEditor"
					showPageSetting={false}
					hasPagination={false}
					height={contentsHeight}
				/>
			</div>
			<div className="FR JR">
				<DLButton
					eleTestId="preview-btn"
					// clickHandler={() =>}
				>
					Preview Changes
				</DLButton>
				<DLButton
					eleTestId="save-btn"
					// clickHandler={() =>}
				>
					Save Changes
				</DLButton>
				<DLButton
					eleTestId="cancel-btn"
					// clickHandler={() =>}
				>
					Cancel Changes
				</DLButton>
			</div>
		</StyledAuditProgramEditor>
	)
})

const filterComponent = ({
	column,
	table,
	dateFormat,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
}) => {
	switch (column.id) {
		case "dueDate":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const StyledAuditProgramEditor = styled.div`
	.table-container {
		margin-top: 1rem;
	}
`
