import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
//
import { LibFile, LibFileProps } from "./lib-in-proj.data-model"
import { initialStore } from "./lib-in-proj.provider"
// separated actions
import GetLibrariesInProj from "./view-model-actions/get-libraries-in-proj"
// ---------- common actions
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
import GetLibFilesInProj from "./view-model-actions/get-lib-files-in-proj"
import ViewsForTreeNav from "./sub-views/views-for-tree-nav"
import { ViewsForCreateFileDialog } from "./sub-views/views-for-create-file-dialog"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

const LibInProjViewModel = types
	.model({
		flatList: types.array(LibFile),
		drawerOpen: false, // TODO: if the actions are expanded, move to ui-helper
		openedOn: types.union(
			types.enumeration<DLProjSubMenus>(Object.values(DLProjSubMenus)),
			types.undefined
		),
		selectedItemId: "",
		// common parts
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	// flat list actions
	.actions((self) => ({
		setSelectedItemId(id: string) {
			self.selectedItemId = id
		},
		setFlatList(list: any) {
			self.flatList = list
		},
		pushChildItem(item: LibFileProps) {
			const targetIndex = self.flatList.findIndex(
				(prev) => prev.id === item.id
			)
			if (targetIndex === -1) {
				self.flatList.push(item)
			} else {
				self.flatList.splice(targetIndex, 1)
				self.flatList.push(item)
			}
		},
		setOpenedOn(menu: DLProjSubMenus) {
			self.openedOn = menu
		},
	}))
	// AGER
	.actions(GetLibrariesInProj)
	.actions(GetLibFilesInProj)
	// sub actions
	.actions((self) => ({
		setDrawerOpen(request?: boolean) {
			if (request !== undefined) {
				self.drawerOpen = request
			} else {
				self.drawerOpen = !self.drawerOpen
			}
		},
		toggleExpand(id: string, expanded?: boolean) {
			const target = self.flatList.find((item) => item.id === id)
			if (target) {
				if (expanded !== undefined) {
					target.expanded = expanded
				} else {
					target.expanded = !target.expanded
				}
			}
		},
		refreshInDrawer(orgId: string) {
			self.flatList.length = 0
			self.getLibrariesInProj(orgId)
		},
	}))
	.views((self) => ({
		get drawerOpenStatus() {
			return self.drawerOpen
		},
		viewFlatList() {
			return self.flatList
		},
	}))
	.views(ViewsForTreeNav)
	.views(ViewsForCreateFileDialog)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions((self) => ({
		refresh(orgId: string) {
			self.initializeStore()
			self.getLibrariesInProj(orgId)
		},
	}))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default LibInProjViewModel
