import React from "react"
import { DLContextMenu } from "../../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { PermissionAsObjectProps } from "../../../../../../common-models/permission"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
	RestoreIcon,
	IconPlaceholder,
} from "../../../../../../components/basic-elements/icons/common-icons"
import { useHcMgmtStore } from "../../../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"
import { HcMgmtUserDialogType as DialogType } from "../../store/data-models/hard-copy-user-model"

export default observer(function AccessCtrlCtxMenus({
	partialStore,
	permission,
	i18n,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) {
	const hcMgmtStore = useHcMgmtStore()
	const historyStore = hcMgmtStore.history
	const configStore = hcMgmtStore.config

	const handleClose = () =>
		partialStore.setClickPoint({
			mouseX: null,
			mouseY: null,
		})

	const menuOptions = [
		{
			label: "Suspend",
			value: "suspend",
			available: permission.update,
			clickEvent: () => {
				configStore.setDialogType(DialogType.suspend)
				configStore.setOpenSuspendOrRemoveUserDialog(true)
			},
			icon: <IconPlaceholder />,
		},
		{
			label: "Remove",
			value: "remove",
			available: permission.remove,
			clickEvent: () => {
				configStore.setDialogType(DialogType.remove)
				configStore.setOpenSuspendOrRemoveUserDialog(true)
			},
			icon: <IconPlaceholder />,
		},
		{
			label: "Rental History",
			value: "rental-history",
			available: permission.read,
			clickEvent: () => historyStore.setOpenRentalHistoryDialog(true),
			icon: <IconPlaceholder />,
		},
		{
			label: "Role Change",
			value: "role-change",
			available: permission.read,
			clickEvent: () => configStore.setOpenRoleChangeDialog(true),
			icon: <IconPlaceholder />,
		},
	]

	return (
		<DLContextMenu
			eleId="configuration-ctx-menus"
			clickPoint={{
				mouseX: partialStore.clickPoint.mouseX,
				mouseY: partialStore.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions}
			hasDivider={[0]}
		/>
	)
})
