import React, { useState, useEffect } from "react"
//
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
//
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"
import { getDefaultYear } from "../../../../library/get-page-settings"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import SharedProjTableInitialFilters from "../../../../components/combined-elements/projects-table/SharedProjectTableInitialFilters"
import Checklists from "./Checklists"

export default observer(function ChecklistsController() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.checklists
	const actionName = "getChecklists"
	const i18nSheet = OrgI18n.projects
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const needRefresh = orgStore.checklists.needRefresh
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	// const [filters, setFilters] = useState(SharedProjTableInitialFilters)
	// const filteredYear = filters.find(
	// 	(filter: any) => filter.id === "financialYear"
	// )?.value
	// const [hasDefaultYear, setHasDefaultYear] = useState<"pending" | number>(
	// 	"pending"
	// )

	const orgIdForCdk = "org" + orgStore.checkin.orgInfo.orgId.slice(5)
	useEffect(() => {
		if (menuAccess) {
			// const defaultYear = getDefaultYear(menuId)
			orgStore.checklists.getChecklists({ orgId: orgIdForCdk })
		}
	}, [])


	const i18n = store.i18n.combineI18n(i18nSheet, "ChecklistsController")
	return (
		<>
			{menuAccess ? (
				<Checklists
					partialStore={orgStore.checklists}
					fetchingStatus={orgStore.checklists.getActionStatus(
						actionName
					)}
					permission={permission}
					i18n={i18n}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
