import { flow } from "mobx-state-tree"

const ReadRentals = (self: any, readRentals: any) => ({
	readRentals: flow(function* (params: any) {
		const actionName = "readRentals"
		try {
			const response = yield readRentals(params)

			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default ReadRentals
