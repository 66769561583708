import React from "react"
import {
	DLTextArea,
	DLComboBox,
	DLDatepickerWithoutInput,
    DLIconButton,
} from "../../../../../../../components/basic-elements"
import { DLCheckbox } from "@datalobby/components/lib"
import { mdiPublish, mdiClose } from "@mdi/js"
import { Icon } from "@mdi/react"
import { formatFileSize } from "@datalobby/common/lib"

const RentHardCopyDialogContentRightSide = ({
	inputs,
	handleInputs,
	handleFiles,
	handleComboBox,
	handleDueDate,
	dateFormat,
	userOptions,
	removeFilefromList
}: {
	inputs: any
	handleInputs: any
	handleFiles: any
	handleComboBox: any
	handleDueDate: any
	dateFormat: any
	userOptions: any[]
	removeFilefromList: any
}) => {
	return (
		<div className="right-side">
			<div className="select-user-section">
				<h4>Select User</h4>
				<div className="section">
					<DLComboBox
						eleTestId="select-user"
						eleClassName="select-user"
						withoutLabel
						placeholder={"Please select a user"}
						options={userOptions}
						getOptionLabel={(option: any) => option.name}
						onChange={(item: any) => handleComboBox(item)}
						variant="outlined"
					/>
				</div>
			</div>
			<div className="due-date-section">
				<h4>Due Date</h4>
				<div className="section FR">
					<DLDatepickerWithoutInput
						eleTestId="testid-due-date"
						onChange={(date: any) => handleDueDate(date)}
						autoOk
						format={dateFormat}
					/>
					<div
						className="date-info FR AC"
						data-testid="due-date-display"
						style={{
							marginLeft: "1rem",
							opacity: 0.3,
						}}
					>
						{inputs.dueDate || "Please select a date"}
					</div>
				</div>
			</div>
			<div className="due-date-section">
				<h4>Description</h4>
				<div className="section">
					<DLTextArea
						eleTestId="description-input"
						eleFullWidth
						eleName="description"
						eleValue={inputs.description}
						eleHandleChange={handleInputs}
						eleFieldHeight="5"
						variant="outlined"
					/>
				</div>
			</div>
			<div className="attachment-section">
				<h4>Attachment</h4>
				<div className="section">
					<div className="FR file-upload">
						<Icon
							path={mdiPublish}
							size={1}
							style={{ marginRight: 8 }}
						/>
						<span>
							Select File
							<input
								type="file"
								name="file"
								multiple
								onChange={handleFiles}
								data-testid="file-input"
							/>
						</span>
					</div>
					{!!inputs.files.length && (
						<div>
							<div className="FR JSB">
								<div className="fileType">Type</div>
								<div className="fileSize">Size</div>
								<div className="fileName">File Name</div>
								<div className="fileActions">Actions</div>
							</div>
							<hr />
							{inputs.files.map((item: any) => {
								return (
									<div
										key={item.tempId}
									>
										<div className="FR JSB">
											<div className="fileType">
												{item.extension}
											</div>
											<div className="fileSize">
												{item.size}
											</div>
											<div className="fileName">
												{item.fileName}
											</div>
											<div className="fileActions">
												<DLIconButton
													clickHandler={() =>
														removeFilefromList(
															item.tempId
														)
													}
													color="warning"
													align="center"
													eleTestId="close-btn"
													tooltipText="Remove file"
													size="small"
												>
													<Icon
														path={mdiClose}
														size={1}
													/>
												</DLIconButton>
											</div>
										</div>
									</div>
								)
							})}
						</div>
					)}
				</div>
			</div>
			{/* <div>
				<DLCheckbox
					eleTestId="confirm-checkbox"
					labelText="This rent is processed by"
				/>
			</div> */}
		</div>
	)
}

export default RentHardCopyDialogContentRightSide
