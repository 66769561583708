import React from "react"
import List from "@material-ui/core/List"
import ListSubheader from "@material-ui/core/ListSubheader"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import SideBarMenuForOrg2 from "./SidebarMenuForOrg2"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { OrgI18n } from "../../../common-models/enumerations/translation-sheets"

export default observer(function OrgSidebarMenuList() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const i18nSheet = OrgI18n.menus

	const i18n = store.i18n.combineI18n(i18nSheet, "OrgSidebarMenuList")

	return (
		<List
			subheader={
				<ListSubheader
					component="div"
					id="sidebar-location"
					style={{ padding: 0, height: `var(--standard-unit)` }}
				>
					<div className="view-location FR JSB AC">
						{store.ui.isSidebarOpen
							? i18n.twOrgMenu || "Organization Menu"
							: "Org"}
					</div>
				</ListSubheader>
			}
		>
			{orgStore.checkin.accessibleTreeMenusForNormal.map((item: any) => {
				return (
					<div key={item.id}>
						<SideBarMenuForOrg2 menuInfo={item} i18n={i18n} />
					</div>
				)
			})}

			{orgStore.checkin.orgInfo.isArchiveAdmin && (
				<div
					className="view-location FR JSB AC"
					style={{ marginTop: 48 }}
				>
					{store.ui.isSidebarOpen ? "Admin Menus" : "Admin"}
				</div>
			)}

			{orgStore.checkin.accessibleTreeMenusForAdmin.map((item: any) => {
				return (
					<>
						<div key={item.id}>
							<SideBarMenuForOrg2 menuInfo={item} i18n={i18n} />
						</div>
					</>
				)
			})}
		</List>
	)
})
