import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { RentHardCopyProps } from "../hc-mgmt-rental.apis"

const RentHardCopy = (self: any) => ({
	rentHardCopy({
		params,
		postAction,
	}: {
		params: RentHardCopyProps
		postAction?: (data: any) => void
	}) {
		// 0.
		const actionName = "rentHardCopy"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		self.mRentHardCopy(params)
			.then((response: any) => {
				console.log(response)
				if (response.status === 200) {
					const newRental = response.data.data.newRental
					/**
					 * 1. update the rental list
					 * 2. update the hard copy
					 * 3. close the dialog
					 */

					self.reflectRental(newRental)
					postAction && postAction(newRental)
					self.setOpenRentHardCopyDialog(false)

					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200, // temp
						color: MessageColorType.orange,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default RentHardCopy
