import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import {
	PageContainer,
	PageHeader,
} from "../../../../components/app-frame-elements"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import {
	DLOrgSubMenus,
	DLOrgMenuGroups,
} from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { InitialDateFilters } from "../../../../components/combined-elements/projects-table/proj-page-setting.data-model"
import styled from "styled-components"
import { DLSpinner, DLButton } from "../../../../components/basic-elements"
import ReportsManagementTable from "../online-projects/sub-components/ReportsManagementTable"
import CreateProjectDialog from "../../../../components/combined-elements/create-project/CreateProjectDialog"
import { CreateProjectMethod } from "../../../../components/combined-elements/create-project/create-project.data-props"
import ReportsManagementCtxMenus from "../online-projects/sub-components/ReportsManagementCtxMenus"
import ProjInfoUpdateDialog from "../../../project-side/project-information/sub-components/ProjInfoUpdateDialog"
import { ProjScreenType } from "../../../../components/combined-elements/projects-table/projects-table-props"

export default observer(function OtherProjects({
	partialStore,
	fetchingStatus = ActionStatus.success,
	permission,
	dateFormat,
	handleContextMenu,
	filters,
	setFilters,
	year,
	i18n,
}: {
	partialStore: any
	fetchingStatus: ActionStatus
	permission: PermissionAsObjectProps
	dateFormat: string
	handleContextMenu: any
	filters: any
	setFilters: any
	year: any
	i18n: DLI18nProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.other_projects
	const [filteredData, setFilteredData] = useState<any>([])

	const dntFormat = store.global.getDntFormat

	const [dateFilters, setDateFilters] = useState(InitialDateFilters)

	let contentHeight = store.ui.contentsAreaHeight
	const projLength = partialStore.otherProjects.length
	const clickPointExist = partialStore.clickPoint.mouseX !== null
	const clearFilters = () => {}

	const path = orgStore.groups.selectionPathWithNameAndFormat
	const setIdPath = orgStore.groups.setPathForSelection
	const presetInputs = orgStore.projects.createProjectPresetInputs

	return (
		<PageContainer
			pageTools={<div />}
			hasToolbar={false}
			fullWidth
			fixedHeight
			isLoading={fetchingStatus === "LOADING"}
		>
			<StyledOtherProjects>
				{fetchingStatus === "SUCCESS" && (
					<>
						<PageHeader>
							<div className="FR AC counter-container">
								<div className="counter">
									<span>{year}</span> Total projects:{" "}
									{fetchingStatus === "SUCCESS" && projLength}
								</div>
								{projLength > filteredData.length && (
									<div className="FR AC">
										<span className="partition">|</span>
										<div className="filtered-count">
											Filtered Projects :{" "}
											{filteredData.length}
										</div>
										<span className="partition">|</span>
										<DLButton
											eleTestId="clear-filters"
											clickHandler={clearFilters}
										>
											Clear Filters
										</DLButton>
									</div>
								)}
							</div>
							<DLButton
								eleTestId="create-project"
								clickHandler={() =>
									orgStore.projects.setOpenCreateProjDialog(
										true
									)
								}
							>
								Create Project
							</DLButton>
						</PageHeader>
						{/* we are not using this menu */}
						{/* <ReportsManagementTable
							partialStore={partialStore}
							data={partialStore.formattedOtherProjects(
								dntFormat
							)}
							handleContextMenu={handleContextMenu}
							dateFormat={dateFormat}
							contentHeight={contentHeight}
							setFilteredData={setFilteredData}
							filters={filters}
							setFilters={setFilters}
							dateFilters={dateFilters}
							setDateFilters={setDateFilters}
							menuId={menuId}
							i18n={i18n}
							projScreenType={ProjScreenType.otherProjects}
						/> */}
					</>
				)}
				{/* ---------- context menus ---------- */}
				{clickPointExist && (
					<ReportsManagementCtxMenus
						permission={permission}
						i18n={i18n}
					/>
				)}
				{/* response snackbar  */}
				<CommonSnackbarComponent
					i18n={i18n}
					partialStore={partialStore}
				/>
				{orgStore.projects.openCreateProjDialog && (
					<CreateProjectDialog
						open={orgStore.projects.openCreateProjDialog}
						setOpen={orgStore.projects.setOpenCreateProjDialog}
						//
						currentMenu={DLOrgMenuGroups.reports_mgmt}
						createMethod={CreateProjectMethod.byHardCopyOnly}
						//
						path={path}
						setIdPath={setIdPath}
						presetInputs={presetInputs}
					/>
				)}
				{orgStore.projects.updateDialogOpenStatus && (
					<ProjInfoUpdateDialog
						i18n={i18n}
						partialStore={orgStore.projects}
					/>
				)}
			</StyledOtherProjects>
		</PageContainer>
	)
})

const StyledOtherProjects = styled.div`
	padding-right: 1rem;
	padding-left: 1rem;
	.counter-container {
		.counter {
			margin: 0 1rem;
			span {
				font-weight: 600;
				color: ${(props) => props.theme.emphasis};
			}
		}
	}
	.partition {
		margin-right: 1rem;
	}
`
