import React, { HTMLProps } from "react"
import {
	singleSelectFilter,
	yearFilter,
} from "../../../../../library/react-table-utils"
import { Link } from "react-router-dom"
import ReactMarkdown from "react-markdown"

export const columns = (
	partialStore: any,
	handleContextMenu: (event: any, targetInfo: any) => void
) => [
	{
		header: "",
		accessorKey: "id",
		enableColumnFilter: false,
		size: 50,
		cell: (props: any) => {
			return (
				<IndeterminateCheckbox
					{...{
						checked: props.row.getIsSelected(),
						indeterminate: props.row.getIsSomeSelected(),
						onChange: props.row.getToggleSelectedHandler(),
					}}
				/>
			)
		},
	},
	{
		header: "Soft Copy",
		accessorKey: "hasSoftCopy",
		filterFn: singleSelectFilter,
		size: 50,
		cell: (props: any) => {
			// const id = props.row.original.id
			const cellId = props.cell.id + "-cell"

			return (
				<span style={{ marginRight: 4 }} data-testid={cellId}>
					{props.getValue()}
				</span>
			)
		},
	},
	{
		header: "Year",
		accessorKey: "year",
		filterFn: yearFilter,
		size: 100,
	},
	{
		header: "Project Name",
		accessorKey: "projectName",
	},
	{
		header: "Project Alias",
		accessorKey: "projectAlias",
	},
	{
		header: "Client",
		accessorKey: "clientName",
	},
	{
		header: "Client Alias",
		accessorKey: "clientAlias",
	},
	{
		header: "Hard Copy Title",
		accessorKey: "title",
		size: 240,
		cell: (props: any) => {
			const id = props.row.original.id
			const cellId = props.cell.id + "-cell"

			return (
				<Link
					to={`/organization/hard-copy-management/hard-copy-list/${id}`}
					onClick={() =>
						partialStore.setSelectedId(props.row.original.id)
					}
				>
					<span style={{ marginRight: 4 }} data-testid={cellId}>
						{props.getValue()}
					</span>
				</Link>
			)
		},
	},
	{
		header: "Hard Copy Description",
		accessorKey: "description",
		size: 80,
		cell: (props: any) => (
			<div style={{ height: "50px", overflow: "hidden" }}>
				<ReactMarkdown
					children={props.getValue()}
					className="md-area"
				/>
			</div>
		),
	},
	{
		header: "Status",
		accessorKey: "status",
		filterFn: singleSelectFilter,
		size: 80,
		cell: (props: any) => {
			// const id = props.row.original.id
			const cellId = props.cell.id + "-cell"
			const status = props.getValue()

			// TODO: set colors by status
			return (
				<span style={{ marginRight: 4 }} data-testid={cellId}>
					{props.getValue()}
				</span>
			)
		},
	},
	{
		header: "Rental Status",
		accessorKey: "rentalStatus",
		filterFn: singleSelectFilter,
		cell: (props: any) => {
			// const id = props.row.original.id
			const cellId = props.cell.id + "-cell"
			const status = props.getValue()
			// console.log("status:", status)

			// TODO: set colors by status
			return (
				<span style={{ marginRight: 4 }} data-testid={cellId}>
					{status ? status : "-"}
				</span>
			)
		},
	},
	{
		header: "Rented At",
		accessorKey: "rentalAt",
	},
	{
		header: "Due Date",
		accessorKey: "dueDate",
	},
	{
		header: "E.P.",
		accessorKey: "epUsers",
	},
	{
		header: "Q.C.",
		accessorKey: "qcUsers",
	},
	{
		header: "Created By",
		accessorFn: (props: any) => props.createdBy.name,
		accessorKey: "createdBy",
	},
	{
		header: "Created Date",
		accessorKey: "createdDate",
	},
	// {
	// 	header: "Action",
	// 	accessorKey: "pending",
	// 	enableColumnFilter: false,
	// 	cell: (props: any) => {
	// 		const userId = props.row.original.id
	// 		const cellId = props.cell.id + "-cell"

	// 		return (
	// 			<div data-testid={cellId}>
	// 				<DLIconButton
	// 					variant="iconOnly"
	// 					aria-label="more"
	// 					clickHandler={(event: any) =>
	// 						handleContextMenu(event, props.row)
	// 					}
	// 					eleTestId="hard-copy-ctx-btn-on-row"
	// 				>
	// 					<Icon path={mdiDotsVertical} size={0.8} />
	// 				</DLIconButton>
	// 			</div>
	// 		)
	// 	},
	// },
]

function IndeterminateCheckbox({
	indeterminate,
	...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
	const ref = React.useRef<HTMLInputElement>(null!)

	React.useEffect(() => {
		if (typeof indeterminate === "boolean") {
			ref.current.indeterminate = !rest.checked && indeterminate
		}
	}, [ref, indeterminate])

	return (
		<input
			type="checkbox"
			ref={ref}
			className={"cursor-pointer"}
			{...rest}
		/>
	)
}
