import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import {
	PageContainer,
	PageHeader,
} from "../../../../components/app-frame-elements"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { InitialDateFilters } from "../../../../components/combined-elements/projects-table/proj-page-setting.data-model"
import styled from "styled-components"
import { DLSpinner, DLButton } from "../../../../components/basic-elements"
import ChecklistDialog from "./sub-components/checklist-dialog/ChecklistDialog"
import ReportsManagementTable from "./sub-components/ReportsManagementTable"
import ReportsManagementCtxMenus from "./sub-components/ReportsManagementCtxMenus"
import ProjInfoUpdateDialog from "../../../project-side/project-information/sub-components/ProjInfoUpdateDialog"
import AddChecklistDialog from "./sub-components/add-checklist-dialog/AddChecklistDialog"
import DefaultPageCtxMenus from "../../../../components/combined-elements/projects-table/DefaultPageCtxMenus"
import TablePageSettingDialog from "../../../../components/combined-elements/dialogs/TablePageSettingDialog"
import { ReportManagementInitialPageSetting } from "./store/data-models/initial-page-settings"
import { savePageSetting } from "../../../../library/get-page-settings"
import ReportsManagementTableInitialFilters from "./sub-components/ReportsManagementTableInitialFilters"
import { ProjScreenType } from "../../../../components/combined-elements/projects-table/projects-table-props"
import ProjectDetailsDialog from "../../../../components/combined-elements/project-dialogs/ProjectDetailsDialog"

export default observer(function OnlineProjects({
	partialStore,
	fetchingStatus = ActionStatus.success,
	permission,
	dateFormat,
	handleContextMenu,
	i18n,
}: {
	partialStore: any
	fetchingStatus: ActionStatus
	permission: PermissionAsObjectProps
	dateFormat: string
	handleContextMenu: any
	i18n: DLI18nProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.online_projects

	const dntFormat = store.global.getDntFormat
	let contentHeight = store.ui.contentsAreaHeight
	const clickPointExist = partialStore.clickPoint.mouseX !== null

	return (
		<PageContainer
			pageTools={<div />}
			hasToolbar={false}
			fullWidth
			fixedHeight
			isLoading={fetchingStatus === "LOADING"}
		>
			<StyledOnlineProjects>
				{fetchingStatus === "SUCCESS" && (
					<ReportsManagementTable
						partialStore={partialStore}
						data={partialStore.formattedProjList(dntFormat)}
						handleContextMenu={handleContextMenu}
						dateFormat={dateFormat}
						contentHeight={contentHeight}
						menuId={menuId}
						i18n={i18n}
					/>
				)}
				{/* ---------- context menus ---------- */}
				{clickPointExist && (
					<ReportsManagementCtxMenus
						permission={permission}
						i18n={i18n}
					/>
				)}
				{/* response snackbar  */}
				<CommonSnackbarComponent
					i18n={i18n}
					partialStore={partialStore}
				/>
				{/* Dialogs */}
				{partialStore.openChecklistDialog && (
					<ChecklistDialog i18n={i18n} partialStore={partialStore} />
				)}
				{orgStore.projects.updateDialogOpenStatus && (
					<ProjInfoUpdateDialog
						i18n={i18n}
						partialStore={orgStore.projects}
					/>
				)}
				{partialStore.openAddChecklistDialog && (
					<AddChecklistDialog
						i18n={i18n}
						partialStore={partialStore}
					/>
				)}
				{partialStore.tablePageSettingDialog && (
					<TablePageSettingDialog
						partialStore={partialStore}
						menuId={menuId}
						initialPageSetting={ReportManagementInitialPageSetting}
					/>
				)}
				{orgStore.projects.projectDetailsDialogOpen && (
					<ProjectDetailsDialog partialStore={orgStore.projects} />
				)}
			</StyledOnlineProjects>
		</PageContainer>
	)
})

const StyledOnlineProjects = styled.div`
	padding-right: 1rem;
	padding-left: 1rem;
	.counter-container {
		.counter {
			margin: 0 1rem;
			span {
				font-weight: 600;
				color: ${(props) => props.theme.emphasis};
			}
		}
	}
	.partition {
		margin: 1rem 1rem;
	}
`
