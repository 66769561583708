import { ActionStatus } from "@datalobby/types/lib"
import { monotonicFactory } from "ulid"
import {
	HardCopyCustomColumnType,
	HardCopyStatusEnum,
} from "../data-models/hard-copy-list.data-models"

const BulkImportActions = (self: any) => ({
	resetBulkHardCopyList() {
		self.bulkImportHc_hcWithoutScList.length = 0
	},
	pushItemToBulkList(item: any) {
		self.bulkImportHc_hcWithoutScList.push(item)
	},
	// WARNING: DO NOT USE This action below to update the list by direct input.
	// This will set total new temporary Id for all.
	setBulkHardCopyList({
		list,
		ccHeadersObjForBulkImport,
	}: {
		list: any
		ccHeadersObjForBulkImport: { [x: string]: string } // 'label': 'id array of custom columns
	}) {
		console.log("setBulkHardCopyList - list:", list)
		console.log(
			"setBulkHardCopyList - ccHeadersObjForBulkImport:",
			ccHeadersObjForBulkImport
		)
		const customColumnLabels = Object.keys(ccHeadersObjForBulkImport) // returns { label: id } array of custom column

		let convertedList: any = []
		const ulid = monotonicFactory()

		list.map((item: any) => {
			const tempId = ulid()
			const objKeys = Object.keys(item)

			let customColumns: { id: string; value: any }[] = []

			objKeys.map((key) => {
				if (customColumnLabels.includes(key)) {
					customColumns.push({
						id: ccHeadersObjForBulkImport[key],
						value: item[key],
					})
				}
			})

			convertedList.push({
				...item,
				tempId,
				isDuplicated: undefined,
				actionStatus: ActionStatus.standby,
				statusMessage: "",
				customColumns,
			})
		})

		console.log("setBulkHardCopyList > convertedList:", convertedList)

		self.bulkImportHc_hcWithoutScList.length = 0
		self.bulkImportHc_hcWithoutScList = convertedList
	},
	updateBulkImportItemStatus({
		statusMsg,
		actionStatus,
		tempId,
		fetchedId,
	}: {
		statusMsg: string
		actionStatus: ActionStatus
		tempId: string
		fetchedId?: string
	}) {
		console.log("-----------updateBulkImportItemStatus")
		console.log("statusMsg", statusMsg)
		console.log("actionStatus", actionStatus)
		console.log("tempId", tempId)
		console.log("fetchedId", fetchedId)

		const index = self.bulkImportHc_hcWithoutScList.findIndex(
			(item: any) => item.tempId === tempId
		)
		console.log(">>>>>> updateBulkImportItemStatus > index", index)
		self.bulkImportHc_hcWithoutScList[index].actionStatus = actionStatus
		self.bulkImportHc_hcWithoutScList[index].statusMessage = statusMsg
		if (fetchedId) {
			self.bulkImportHc_hcWithoutScList[index].tempId = fetchedId
		}
	},
	pushItemToSuccessList(item: any) {
		const correctStatus = Object.values(HardCopyStatusEnum)
		self.bulkImportHc_successList.push({
			...item,
			year:
				typeof item.year === "number" ? item.year : parseInt(item.year),
			status: correctStatus.includes(item.status)
				? item.status
				: HardCopyStatusEnum.incorrect,
		})
	},
	mergeSuccessListToMainList() {
		console.log("mergeSuccessListToMainList")
		const defaultList = JSON.parse(JSON.stringify(self.hardCopyList))
		const newList = JSON.parse(
			JSON.stringify(self.bulkImportHc_successList)
		)
		self.hardCopyList.length = 0
		self.hardCopyList = defaultList.concat(newList)
		self.bulkImportHc_successList.length = 0
		self.bulkImportHc_hcWithoutScList.length = 0
	},
	spliceBulkHardCopy(tempId: string) {
		const targetIndex = self.bulkImportHc_hcWithoutScList.findIndex(
			(item: any) => item.tempId === tempId
		)
		self.bulkImportHc_hcWithoutScList.splice(targetIndex, 1)
	},
	updateBulkAddHardCopyItem(updatedItem: {
		tempId: string
		title: string
		status: HardCopyStatusEnum
		year: number
		description?: string
		customColumns: HardCopyCustomColumnType[]
	}) {
		console.log("updateBulkAddHardCopyItem > updatedItem", updatedItem)
		const target = self.bulkImportHc_hcWithoutScList.find(
			(item: any) => item.tempId === updatedItem.tempId
		)
		if (target) {
			// default colummns
			target.title = updatedItem.title
			target.status = updatedItem.status
			target.year = updatedItem.year
			target.description = updatedItem.description
			// custom columns
			target.customColumns = updatedItem.customColumns
		} else {
			console.log(
				"updateBulkAddHardCopyItem > cannot find the target",
				updatedItem.tempId
			)
		}
	},
	setBulkHardCopyFileReadStatus(status: ActionStatus) {
		self.bulkImportHc_readLocalFileStatus = status
	},
})

export default BulkImportActions
