import { flow } from "mobx-state-tree"

const ReadMyRentalList = (self: any, read: any) => ({
	readMyRentalList: flow(function*() {
		const actionName = "readMyRentalList"
		try {
// 			const response = yield read()
            const response = [{
                id: "hc1",
                projectName:"projectName",
    		    projectAlias:"projectAlias",
    		    clientName:"clientName",
    		    clientAlias:"clientAlias",
    		    hardCopyName:"Hard Copy Name",
    		    description:"description",
    		    rentalAt: "2022-06-23",
    		    dueDate: "2022-07-23"
            },
            {
                id: "hc2",
                projectName:"project Name",
    		    projectAlias:"project Alias",
    		    clientName:"client Name",
    		    clientAlias:"client Alias",
    		    hardCopyName:"Hard Copy Name",
    		    description:"description",
    		    rentalAt: "2022-06-23",
    		    dueDate: "2022-07-23"
            }]
			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default ReadMyRentalList
