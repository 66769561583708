import { LibFileProps, LibHierarchy } from "../lib-in-proj.data-model"

export const ViewsForCreateFileDialog = (self: any) => ({
	viewLibGroups({ simpleMode }: { simpleMode?: boolean }) {
		const libGroups = self.flatList.filter(
			(item: LibFileProps) => item.hierarchy === LibHierarchy.group
		)

		if (simpleMode) {
			return libGroups.map((group: LibFileProps) => {
				return {
					value: group.id,
					label: group.title,
				}
			})
		}
		return libGroups
	},
	viewLibListByLibGroup({
		libGroupId,
		simpleMode,
	}: {
		libGroupId: string
		simpleMode?: boolean
	}) {
		const libList = self.flatList.filter(
			(item: LibFileProps) => item.parentId === libGroupId
			// NOTE: Is this correct and best condition?
			// NOTE: APG lib has file type hierarchy now in the store. is this fine..?
			// NOTE: FYI, hierarchy maybe be used on drawer to render the row..? not sure...
		)

		if (simpleMode) {
			return libList.map((lib: LibFileProps) => {
				return {
					value: lib.id,
					label: lib.title,
				}
			})
		}
		return libList
	},
	viewLibFiles({
		libId,
		simpleMode,
	}: {
		libId: string
		simpleMode?: boolean
	}) {
		const files = self.flatList.filter(
			(item: LibFileProps) => item.parentId === libId
		)
		if (simpleMode) {
			return files.map((file: LibFileProps) => {
				return {
					value: file.id,
					label: file.title, // API response has no aliasId and extension yet
				}
			})
		}
		return files
	},
	viewLibFileInfo(fileId: string) {
		return self.flatList.find((item: LibFileProps) => item.id === fileId)
	},
})
