import { flow } from "mobx-state-tree"

const DeleteCustomColumn = (self: any, remove: any) => ({
	deleteCustomColumn: flow(function* (payload: any) {
		const actionName = "deleteCustomColumns"
		try {
			const response = yield remove(payload)

			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default DeleteCustomColumn
