import { ProjectStatus } from "../common-models/enumerations/project-related-enums"
import { ConsoleLog } from "../components/basic-elements"
import { DLProjectStatusList } from "../service-modules/project-module/data-model/project-module-data-models"

export function getProjectStatus(typeId: number, isLocked: boolean) {
	let status: ProjectStatus = ProjectStatus.normal
	if (typeId === 1 && !isLocked) {
		status = ProjectStatus.normal
	} else if (typeId === 1 && isLocked) {
		status = ProjectStatus.normalLocked
	} else if (typeId === 2) {
		status = ProjectStatus.replica
	} else if (typeId === 3) {
		status = ProjectStatus.archived
	} else if (typeId === 4) {
		status = ProjectStatus.unarchived
	} else {
		status = ProjectStatus.unknown
	}
	ConsoleLog(`getProjectStatus - ${typeId}, ${isLocked} __ ${status}`)
	return status
}

export function convertProjectStatus(statusId: number) {
	return DLProjectStatusList[statusId - 1]
}

export function revertProjectStatus(statusId: string) {
	const target = DLProjectStatusList.find((item) => item.id === statusId)
	if (target) {
		return target.oldId
	} else {
		ConsoleLog(
			"cannot find matched id in the project status list -" + statusId
		)
	}
}

export const convertProjStatusAsEnum = (status: string) => {
	const fetchedName = status.toLowerCase()
	if (fetchedName === "normal") {
		return ProjectStatus.normal
	} else if (fetchedName === "replica") {
		return ProjectStatus.replica
	} else if (fetchedName === "archived") {
		return ProjectStatus.archived
	} else if (fetchedName === "unarchived") {
		return ProjectStatus.unarchived
	} else {
		return ProjectStatus.unknown
	}
}
