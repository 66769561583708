import React, { useState } from "react"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { DLButton, ConsoleLog } from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import Icon from "@mdi/react"
import {
    mdiMessageTextOutline,
    mdiDownload,
    mdiShieldLockOutline,
} from "@mdi/js"
import { ProjectLockDialog, ProjectUnLockDialog } from "./dialogs"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import {
    DLProjSubMenus,
    DLProjMenuGroups,
} from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { Link } from "react-router-dom"
import { ProjectStatus } from "../../../../../common-models/enumerations/project-related-enums"

export default observer(function ArchMainActionBtns({
    permission,
    partialStore,
    projectStatus,
    projectId,
}: {
    permission: PermissionAsObjectProps
    partialStore: any
    projectStatus: any
    projectId: string
}) {
    const projStore = useProjStore()
    const defaultUrl = projStore.checkin.defaultUrl

    const [projLockDialogOpen, setProjLockDialogOpen] = useState(false)
    const [projUnLockDialogOpen, setProjUnLockDialogOpen] = useState(false)
    //
    /**
     * < permission >
     * - archive
     * - lock
     * - export
     * - viewRationales
     */
    const redirectTab = () => {
        const item = {
            id: DLProjSubMenus.arch_pre_process,
            menuGroup: DLProjMenuGroups.archive,
            subMenus: [],
            title: "Archive Pre-Process",
            url: "archive-pre-process",
        }
        projStore.menuTabs.setCurrentMainMenu(item.id)
        projStore.menuTabs.addOpenedMenu(item)
    }

    const renderRationaleButton = () => {
        const menuId = DLProjSubMenus.rationales
        const menuInfo = projStore.checkin.getMenuInfoById(menuId)
        const parentMenu =
            menuInfo?.menuGroup &&
            projStore.checkin.getMenuInfoById(menuInfo.menuGroup)

        const parentUrl = parentMenu && parentMenu.url

        if (menuInfo && parentUrl) {
            return (
                <Link
                    to={defaultUrl + "/" + parentUrl + "/" + menuInfo.url}
                    onClick={() => {
                        menuInfo && projStore.menuTabs.addOpenedMenu(menuInfo)
                    }}
                >
                    <DLButton
                        eleTestId="rationale-btn"
                        startIcon={
                            <Icon path={mdiMessageTextOutline} size={0.8} />
                        }
                    >
                        Rationales
                    </DLButton>
                </Link>
            )
        } else {
            return <div />
        }
    }

    const adminMode = localStorage.getItem("isAdminAppUser") === "true"
    const projSize = projStore.archive.projectSize
    const numSize = parseInt(projSize.slice(0, -2))
    let oversized: boolean = false
    if (projSize.includes("MB")) {
        if (numSize >= 3072) {
            oversized = true
        }
    } else if (projSize.includes("KB")) {
        //
    } else {
        console.log("? project size has weird format")
    }

    // ConsoleLog([partialStore.getConditionInfo("archiveConditionId16"), "lock"])
    return (
        <div className="archive-action-area FR AC JSB">
            <div className="FR AC">
                {permission.update &&
                    (partialStore.getConditionInfo("archiveConditionId16") &&
                    partialStore.getConditionInfo("archiveConditionId16")
                        ?.status === "DONE" ? (
                        <ProjectUnLockDialog
                            open={projUnLockDialogOpen}
                            setOpen={setProjUnLockDialogOpen}
                        />
                    ) : (
                        <div className="FR AC">
                            {adminMode ? (
                                <ProjectLockDialog
                                    open={projLockDialogOpen}
                                    setOpen={setProjLockDialogOpen}
                                    isAdminMode={true}
                                />
                            ) : !oversized ? (
                                <ProjectLockDialog
                                    open={projLockDialogOpen}
                                    setOpen={setProjLockDialogOpen}
                                />
                            ) : (
                                <div>
                                    Sorry, Project size is over 3GB. Please
                                    contact to admin.
                                </div>
                            )}
                        </div>
                    ))}
                {partialStore.getConditionInfo("archiveConditionId16") &&
                    partialStore.getConditionInfo("archiveConditionId16")
                        ?.status === "DONE" &&
                    partialStore.getCompletedCount("mandatory") ===
                        partialStore.getMandatoryList.length &&
                    projectStatus !== ProjectStatus.archived && (
                        <Link to={`${defaultUrl}/archive/archive-pre-process`}>
                            <DLButton
                                eleTestId="archive-pre-process-btn"
                                startIcon={
                                    <Icon
                                        path={mdiShieldLockOutline}
                                        size={0.8}
                                    />
                                }
                                clickHandler={() => redirectTab()}
                                color="primary"
                            >
                                Archive Pre-Process
                            </DLButton>
                        </Link>
                    )}
            </div>
            <div className="FR AC">
                {permission.export &&
                    projectStatus !== ProjectStatus.archived && (
                        <DLButton
                            eleTestId="export-diagnosis-btn"
                            startIcon={<Icon path={mdiDownload} size={0.8} />}
                            clickHandler={() =>
                                partialStore.exportArchiveDiagnosisPdf()
                            }
                        >
                            Export Diagnosis
                        </DLButton>
                    )}
                {/* WARNING: for Archive management screen's use  */}
                {permission.export &&
                    projectStatus === ProjectStatus.archived && (
                        // TODO:  Have to check hasExportAccess
                        // and required API is GetArchivedZipUrl
                        <DLButton
                            eleTestId="export-archived-zip-btn"
                            startIcon={<Icon path={mdiDownload} size={0.8} />}
                            clickHandler={() =>
                                partialStore.exportArchivedZipFile(projectId)
                            }
                        >
                            Export Archive Zip
                        </DLButton>
                    )}
                {/* TODO: how about to use rationale permission directly? */}
                {permission.viewRationales && renderRationaleButton()}
            </div>
        </div>
    )
})
