import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
import ReadChecklists from "../../../../organization-side/reports-management/online-projects/store/model-actions/read-checklists"
import { ReportsMgmtApiProps } from "../../../../organization-side/reports-management/online-projects/store/reports-management.apis"
import { OrgEngTypeApiProps } from "../../../../organization-side/org-setup/org-setup-eng-types/store/org-eng-types.apis"
import ReadChecklistTemplates from "../../../../organization-side/org-setup/org-setup-eng-types/store/model-actions/read-checklist-templates"
import CreateChecklist from "../../../../organization-side/reports-management/online-projects/store/model-actions/create-checklist"

const ChecklistModel = ({
	apiReadChecklists,
	apiAddChecklist,
	apiReadChecklistTemplates
}: {
	apiReadChecklists: ReportsMgmtApiProps['apiReadChecklists'],
	apiAddChecklist: ReportsMgmtApiProps['apiAddChecklist'],
	apiReadChecklistTemplates: OrgEngTypeApiProps["apiReadChecklistTemplates"]
}) =>
	types
		.model({})
		.actions((self) => ReadChecklists(self, apiReadChecklists))
		.actions((self) =>
			ReadChecklistTemplates(self, apiReadChecklistTemplates)
		)
		.actions((self) => CreateChecklist(self, apiAddChecklist))
		// common parts
		.actions(CommonModelActions)

export default ChecklistModel
