import { types, applySnapshot, IModelType } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import { initialStore } from "./hc-mgmt-history.provider"
import { GetHcMgmtHistories } from "./view-model-actions"
import { HistoryItem } from "./data-models/history-model"
import { DnTFormatProps } from "../../../../../common-models/types/common-props"
import { getTzDate, getTzDateAndTime } from "../../../../../library/converters/date-utc-converter"

const HcMgmtHistoryViewModel = types
	.model({
		histories: types.array(HistoryItem),
		//
		clickPoint: ClickPoint,
		selectedId: "",
		// dialogs
		openHardCopyHistoryDialog: false,
		openRentalHistoryDialog: false,
		// common parts
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setHistories(histories: any){
			self.histories = histories
		},
		setOpenHardCopyHistoryDialog(request?: boolean) {
			self.openHardCopyHistoryDialog =
				request ?? !self.openHardCopyHistoryDialog
		},
		setOpenRentalHistoryDialog(request?: boolean) {
			self.openRentalHistoryDialog =
				request ?? !self.openRentalHistoryDialog
		},
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setSelectedId(id: string) {
			self.selectedId = id
		},
	}))
	.views((self) => ({
		viewHistories(dntFormat: DnTFormatProps) {
			const { dateFormat, timeFormat, timeZone } = dntFormat
			function formatDate(date: string | null) {
				if (!date) {
					return ""
				}
				//  getTzDate({
				// 	date,
				// 	timeZone,
				// 	dateFormat,
				// })
				return getTzDateAndTime({
					date,
					timeZone,
					dateFormat,
					timeFormat,
				})
			}
			let formattedList: any[] = []
			self.histories.map((history) => {
				const formattedProject = {
					...history,
					createdDate: formatDate(history.createdDate),
				}
				formattedList.push(formattedProject)
			})
			return formattedList.sort(
			  (objA: any, objB: any) => Number(new Date(objA.createdDate)) - Number(new Date(objB.createdDate)),
			);
		},
	}))
	// * API related parts
	.actions(GetHcMgmtHistories)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default HcMgmtHistoryViewModel
