import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import PageContainer from "../../../../components/app-frame-elements/sub-components/PageContainer"
import styled from "styled-components"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import { ProjScreenType } from "../../../../components/combined-elements/projects-table/projects-table-props"
import SharedProjectTableV8 from "../../../../components/combined-elements/projects-table/SharedProjectTableV8"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"
import SharedCtxMenuForAssignedProjects from "../shared-components/SharedCtxMenuForAssignedProjects"
import { getDefaultFinancialYear } from "../../../../library/get-page-settings"
import ProjectDetailsDialog from "../../../../components/combined-elements/project-dialogs/ProjectDetailsDialog"

export default observer(function ROAMArchivedProjectsController() {
    const store = useRootStore()
    const orgStore = useOrgStore()
    const menuId = DLOrgSubMenus.assignedArchivedReadonlyProjects // Need to update to assigned normal
    const projStatus = ProjectStatus.roamArchived
    const actionName = "getOrgProjectList_" + projStatus
    //
    const menuAccess = orgStore.checkin.checkAccess(menuId)
    const permission = orgStore.checkin.getActionsAsObject(menuId)

    const needRefresh = orgStore.projects.needRefreshForROAM
    const i18nSheet = OrgI18n.projects

    useEffect(() => {
        if (menuAccess && needRefresh) {
            const defaultYear = getDefaultFinancialYear(menuId)
            orgStore.projects.getOrgProjectList({
                projStatus,
                year: defaultYear,
                PTMAssignedOnly: false,
                ForROAM: true,
            })
        }
    }, [menuAccess, needRefresh])

    const loadingStatus = orgStore.projects.getActionStatus(actionName)
    const i18n = store.i18n.combineI18n(i18nSheet)
    const clickPointExist = orgStore.projects.clickPoint.mouseX !== null

    return (
        <>
            {menuAccess ? (
                <PageContainer
                    pageTools={<div />}
                    hasToolbar={false}
                    fullWidth
                    fixedHeight
                    isLoading={loadingStatus === "LOADING"}
                >
                    <StyledRoamArchivedProjects>
                        {loadingStatus === "SUCCESS" && (
                            <SharedProjectTableV8
                                partialStore={orgStore.projects}
                                i18n={i18n}
                                // common part 2
                                projScreenType={ProjScreenType.roamArchived}
                                menuId={
                                    DLOrgSubMenus.assignedArchivedReadonlyProjects
                                }
                                projectStatus={ProjectStatus.roamArchived}
                                permission={permission}
                            />
                        )}
                    </StyledRoamArchivedProjects>
                    {clickPointExist && (
                        <SharedCtxMenuForAssignedProjects
                            partialStore={orgStore.projects}
                            permission={permission}
                            projStatus={ProjectStatus.roamArchived}
                        />
                    )}
                    {orgStore.projects.projectDetailsDialogOpen && (
                        <ProjectDetailsDialog
                            partialStore={orgStore.projects}
                        />
                    )}
                </PageContainer>
            ) : (
                <OrgNoPermissionOnMenu menuId={menuId} />
            )}
        </>
    )
})

const StyledRoamArchivedProjects = styled.div`
    padding: 0 1rem;
`
