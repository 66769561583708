import React, { useState, useEffect } from "react"
import {
	DLDialog,
	DLInputField,
	InputWithLabel,
} from "../../../../../../components/basic-elements"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { DLCheckbox } from "@datalobby/components/lib"

const initialSettings = {
	rentalItemCount: {
		mustUseDefaultRentalCount: false,
		defaultRentalCount: 0,
	},
	rentalPeriod: {
		mustUseDefaultRentalPeriod: false,
		defaultRentalPeriod: 0,
	},
	notificationSetting: {
		// time
		// notificationTime: 9,
		// reminder
		remindDueDate: true,
		remindDueDateBefore: 3,
		// overdue warning is basic (when the rental is overdue, an email will be sented anyways)
		repeatOverdueWarning: true,
		repeatOverdueWarningInterval: 1, // days
	},
	overdueDisadvantage: {
		restrictMoreRental: true,
		restrictNextRental: true,
		disadvantagePeriod: 7,
	},
}
const dummy = {
	rentalItemCount: {
		mustUseDefaultRentalCount: true,
		defaultRentalCount: 5,
	},
	rentalPeriod: {
		mustUseDefaultRentalPeriod: true,
		defaultRentalPeriod: 14,
	},
	notificationSetting: {
		// time
		notificationTime: 9,
		// reminder
		remindDueDate: true,
		remindDueDateBefore: 3,
		// overdue warning is basic (when the rental is overdue, an email will be sented anyways)
		repeatOverdueWarning: true,
		repeatOverdueWarningInterval: 1, // days
	},
	overdueDisadvantage: {
		restrictMoreRental: true,
		restrictNextRental: true,
		disadvantagePeriod: 7,
	},
}

const HardCopyConfigs = observer(() => {
	const [settings, setSettings] = useState(initialSettings)
	const [editMode, setEditMode] = useState(false)

	useEffect(() => {
		setSettings(dummy)
	}, [])

	const handleInputs = (parentKey: any, childKey: any, value?: any) => {
		setSettings((prev: any) => ({
			...prev,
			[parentKey]: {
				...prev[parentKey],
				[childKey]: value || !prev[parentKey][childKey],
			},
		}))
	}

	return (
		<StyledHardCopyConfigs>
			<div className="FR AC edit-mode">
				<DLCheckbox
					eleTestId="edit-mode"
					color="primary"
					isChecked={editMode}
					clickHandler={() => setEditMode((prev) => !prev)}
				/>
				Edit Mode
			</div>
			{/* ---------- SECTION 1: AVAILABLE RENTAL ITEM COUNt ---------- */}
			<div className="section">
				<div className="setting-label">
					1. Default available rental item count
				</div>
				<div className="sub-section">
					<div className="FR JL AC main-item">
						<DLCheckbox
							eleTestId="use-default-rental-count"
							color="primary"
							isChecked={
								settings.rentalItemCount
									.mustUseDefaultRentalCount
							}
							clickHandler={() =>
								handleInputs(
									"rentalItemCount",
									"mustUseDefaultRentalCount"
								)
							}
						/>
						Only allow the default rental count.
					</div>
					{settings.rentalItemCount.mustUseDefaultRentalCount && (
						<div className="FR AC sub-item">
							Default available item count:{" "}
							{editMode ? (
								<DLInputField
									eleType="number"
									eleTestId="rental-count"
									eleClassName="rental-count input-field"
									eleValue={settings.rentalItemCount.defaultRentalCount.toString()}
									eleFullWidth={false}
									eleHandleChange={(e: any) =>
										handleInputs(
											"rentalItemCount",
											"defaultRentalCount",
											e.target.value
										)
									}
								/>
							) : (
								<span className="highlight">
									{
										settings.rentalItemCount
											.defaultRentalCount
									}
								</span>
							)}
						</div>
					)}
				</div>
			</div>
			{/* ---------- SECTION 2: DEFAULT RENTAL PERIOD---------- */}
			<div className="section">
				<div className="setting-label">2. Default Rental Period</div>
				<div className="sub-section">
					<div className="FR JL AC main-item">
						<DLCheckbox
							eleTestId="use-default-rental-period"
							color="primary"
							isChecked={
								settings.rentalPeriod.mustUseDefaultRentalPeriod
							}
							clickHandler={() =>
								handleInputs(
									"rentalPeriod",
									"mustUseDefaultRentalPeriod"
								)
							}
						/>
						Only allow the default rental period.
					</div>
					{settings.rentalPeriod.mustUseDefaultRentalPeriod && (
						<div className="FR AC sub-item">
							Default rental period:{" "}
							{editMode ? (
								<DLInputField
									eleType="number"
									eleTestId="rental-period"
									eleClassName="rental-period input-field"
									eleValue={settings.rentalPeriod.defaultRentalPeriod.toString()}
									eleFullWidth={false}
									eleHandleChange={(e: any) =>
										handleInputs(
											"rentalPeriod",
											"defaultRentalPeriod",
											e.target.value
										)
									}
								/>
							) : (
								<span className="highlight">
									{settings.rentalPeriod.defaultRentalPeriod}
								</span>
							)}
							days
						</div>
					)}
				</div>
			</div>
			{/* ---------- SECTION 3: NOTIFICATION SETTING ---------- */}
			<div className="section">
				<div className="setting-label">3. Notification Setting</div>
				{/* <div className="main-item">
					Send notifications on{" "}
					{settings.notificationSetting.notificationTime} everyday.
				</div> */}
				<div className="sub-section">
					<div className="FR JL AC main-item">
						<DLCheckbox
							eleTestId="checkbox-noti-1"
							isChecked={
								settings.notificationSetting.remindDueDate
							}
							color="primary"
							clickHandler={() =>
								handleInputs(
									"notificationSetting",
									"remindDueDate"
								)
							}
						/>
						Send email to remind the due date for who rented.
					</div>
					{settings.notificationSetting.remindDueDate && (
						<div className="FR AC sub-item">
							Send remind email before{" "}
							{editMode ? (
								<DLInputField
									eleType="number"
									eleTestId="due-date-before"
									eleClassName="due-date-before input-field"
									eleValue={settings.notificationSetting.remindDueDateBefore.toString()}
									eleFullWidth={false}
									eleHandleChange={(e: any) =>
										handleInputs(
											"notificationSetting",
											"remindDueDateBefore",
											e.target.value
										)
									}
								/>
							) : (
								<span className="highlight">
									{
										settings.notificationSetting
											.remindDueDateBefore
									}
								</span>
							)}
							days of due date.
						</div>
					)}
				</div>
				<div className="sub-section">
					<div className="FR JL AC main-item">
						<DLCheckbox
							eleTestId="checkbox-noti-2"
							isChecked={
								settings.notificationSetting
									.repeatOverdueWarning
							}
							color="primary"
							clickHandler={() =>
								handleInputs(
									"notificationSetting",
									"repeatOverdueWarning"
								)
							}
						/>
						Repeat the overdue notification.
					</div>
					{settings.notificationSetting.repeatOverdueWarning && (
						<div className="FR AC sub-item">
							Repeat the overdue notificaiton every{" "}
							{editMode ? (
								<DLInputField
									eleType="number"
									eleTestId="due-date-warning"
									eleClassName="due-date-warning input-field"
									eleValue={settings.notificationSetting.repeatOverdueWarningInterval.toString()}
									eleFullWidth={false}
									eleHandleChange={(e: any) =>
										handleInputs(
											"notificationSetting",
											"repeatOverdueWarningInterval",
											e.target.value
										)
									}
								/>
							) : (
								<span className="highlight">
									{
										settings.notificationSetting
											.repeatOverdueWarningInterval
									}
								</span>
							)}
							days.
						</div>
					)}
				</div>
			</div>
			{/* ---------- SECTION 4: OVERDUE DISADVANTAGE ---------- */}
			<div className="section">
				<div className="setting-label">4. Overdue Disadvantage</div>
				<div className="sub-section">
					<div className="FR JL AC main-item">
						<DLCheckbox
							eleTestId="overdue-disadventage-1"
							isChecked={
								settings.overdueDisadvantage.restrictMoreRental
							}
							color="primary"
							clickHandler={() =>
								handleInputs(
									"overdueDisadvantage",
									"restrictMoreRental"
								)
							}
						/>
						Any users who has overdue rental cannot rent additional
						resources.
					</div>
				</div>
				<div className="sub-section">
					<div className="FR JL AC main-item">
						<DLCheckbox
							eleTestId="overdue-disadventage-2"
							isChecked={
								settings.overdueDisadvantage.restrictNextRental
							}
							color="primary"
							clickHandler={() =>
								handleInputs(
									"overdueDisadvantage",
									"restrictNextRental"
								)
							}
						/>
						User who overed the due date cannot use rental even
						return the item.
					</div>
					{settings.overdueDisadvantage.restrictNextRental && (
						<div className="FR JL AC sub-item">
							User who overed the due date be restricted{" "}
							{editMode ? (
								<DLInputField
									eleType="number"
									eleTestId="disadvantage-period"
									eleClassName="disadvantage-period input-field"
									eleValue={settings.overdueDisadvantage.disadvantagePeriod.toString()}
									eleFullWidth={false}
									eleHandleChange={(e: any) =>
										handleInputs(
											"overdueDisadvantage",
											"disadvantagePeriod",
											e.target.value
										)
									}
								/>
							) : (
								<span className="highlight">
									{
										dummy.overdueDisadvantage
											.disadvantagePeriod
									}
								</span>
							)}
							days to rent by 1 item.
						</div>
					)}
				</div>
			</div>
		</StyledHardCopyConfigs>
	)
})

export default HardCopyConfigs

const StyledHardCopyConfigs = styled.div`
	.edit-mode {
		margin-bottom: 1rem;
	}
	.section {
		.setting-label {
			font-weight: 600;
			margin-bottom: 0.6rem;
		}
		margin-bottom: 1rem;
		padding: 1rem;
		border-radius: 4px;
		border: 1px solid #ccc;
		.sub-item {
			padding-left: 2rem;
			.input-field {
				margin-left: 5px;
				width: 3rem;
			}
		}
		.sub-section {
			margin-bottom: 8px;
		}
		span.highlight {
			font-weight: 700;
			color: ${(props) => props.theme.emphasis};
		}
	}
`
