import {
	ActionStatus,
	MessageColorType,
	DLObjectFormat,
} from "../../../../../../common-models/enumerations/common-enums"
import { ProjectStatus } from "../../../../../../common-models/enumerations/project-related-enums"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { getProjectStatus } from "../../../../../../library/get-project-status"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const GetOrgCabinetDetail = (self: any) => ({
	getOrgCabinetDetail(cabinetId: string) {
		// 0.
		const actionName = "getOrgCabinetDetail"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		self.readOrgCabinetDetail(cabinetId)
			.then((response: any) => {
				// NOTE: this API returns [{CabinetsList: [], ClientsList: [] ... }] (this client means project)
				// NOTE: This API does not use response.data.Status
				if (response.status === 200) {
					const fetchedGroupCabinets = response.data[0].CabinetsList
					const existingGroupCabinetItems =
						response.data[0].ClientsList

					let cabinetAndFiles: any = []
					// 2-3-A) Flatten Cabinets
					fetchedGroupCabinets.map((cabinet: any) => {
						// 1) save cabinet? (TBD)
						//
						// 2) Flatten cabinets and push to flat list
						const reOrganizedGroupCabinet = {
							id: idToString(cabinet.CabinetID, IdType.cabinet),
							title: cabinet.CabinetName,
							parentId: cabinetId,
							createdBy: idToString(
								cabinet.CreatedBy,
								IdType.user
							),
							createdAt: cabinet.CreatedDate,
							expanded: false,
							type: DLObjectFormat.cabinet,
							detail: null,
						}
						// duplication check
						if (
							self.orgGroupFlatList.findIndex(
								(prevItem: any) =>
									prevItem.id === reOrganizedGroupCabinet.id
							) === -1
						) {
							// if there are no duplicated items
							cabinetAndFiles.push(reOrganizedGroupCabinet)
						} else {
							// if there is a duplicated item
							self.orgGroupFlatList.map((prevItem: any) =>
								prevItem.id === reOrganizedGroupCabinet.id
									? {
											...reOrganizedGroupCabinet,
											expanded: prevItem.expanded,
									  }
									: prevItem
							)
						}
					})
					// 2-3-B) Flatten Projects
					existingGroupCabinetItems.map((item: any) => {
						// 1) save Item?
						//
						// 2) Flatten Items and push to flat list
						// ConsoleLog("item in viewmodel", item)
						const reOrganizedGroupCabinetItem = {
							id: idToString(item.ClientID, IdType.project),
							title: item.LinkName,
							parentId: cabinetId,
							createdBy: idToString(
								item.ClientCreatedBy,
								IdType.user
							),
							createdAt: item.CreatedDate,
							expanded: false,
							type: DLObjectFormat.project,
							detail: {
								projectStatus: getProjectStatus(
									item.ProjectType,
									item.IsLocked
								), // ex) "Normal" (projectType: 1)
								isLocked: item.IsLocked,
								aliasId: item.ProjectBusinessId,
								accessRight: item.AccessRights,
								archived: item.Archive,
								archivedBy: {
									id: idToString(
										item.ArchivedBy,
										IdType.user
									),
									name: item.ArchivedByUser // Getting null sometimes
										? item.ArchivedByUser
										: "",
								},
								archivedAt: item.ProjectArchivedDate,
								createdBy: {
									id: idToString(
										item.ClientCreatedBy,
										IdType.user
									),
									name: item.CreatedUser,
								},
								clientId: idToString(
									item.ClientMasterID,
									IdType.client
								),
								clientName: item.ClientMasterName,
								clientAliasId: "", // this API doesn't return the client alias ID (old key: client short name)
								expirationDate: item.ExpirationDate,
								financialYear: item.Year,
								periodEndDate: item.FinancialYear,
								periodName: item.PeriodName,
								assignedRole: item.RoleName,
								version: item.Version,
								// accessible: item.canVisit, // NOTE: canVisit seems like.. isAssigned. Let's use isGrayout
								accessible: !item.isGrayout,
							},
						}
						// duplication check
						if (
							self.orgGroupFlatList.findIndex(
								(prevItem: any) =>
									prevItem.id ===
									reOrganizedGroupCabinetItem.id
							) === -1
						) {
							// if there are no duplicated items
							cabinetAndFiles.push(reOrganizedGroupCabinetItem)
						} else {
							self.removeFlatItem(reOrganizedGroupCabinetItem.id)
							cabinetAndFiles.push(reOrganizedGroupCabinetItem)
							// if there is a duplicated item
							// self.orgGroupFlatList.map((prevItem: any) => {
							// 	if(prevItem.id === reOrganizedGroupCabinetItem.id){
							// 		console.log("if")
							// 		return {
							// 			...reOrganizedGroupCabinetItem,
							// 			expanded: prevItem.expanded,
							// 		}
							// 	} else {
							// 		console.log("else")
							// 		return prevItem
							// 	}
							// })
						}
					})
					
					console.log(cabinetAndFiles, "cabinetAndFiles")
					if (cabinetAndFiles.length > 0) {
						const concatList =
							self.orgGroupFlatList.concat(cabinetAndFiles)
						self.concatNewList(concatList)
					}
					// when success
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: false,
						autoHide: true,
					})
				} else {
					// when fail
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetOrgCabinetDetail
