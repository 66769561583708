import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { CreateColumn } from "../hc-mgmt-config.apis"

const AddCustomColumn = (self: any) => ({
	addCustomColumn(payload: CreateColumn, postAction: () => void) {
		// 0.
		const actionName = "addCustomColumn"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.request API call
		self.createCustomColumn(payload)
			.then((response: any) => {
				if (response.status === 200) {
					console.log(actionName, "response:", response)
					const { newCustomColumn } = response.data.data
					console.log("items:", newCustomColumn)

					let customColumns = {
						pk: newCustomColumn.pk,
						id: newCustomColumn.id,
						orgId: newCustomColumn.orgId,
						//
						label: newCustomColumn.label,
						createdBy: newCustomColumn.createdBy.name,
						createdDate: newCustomColumn.createdDate,
						//
						description: newCustomColumn.description,
						helpText: newCustomColumn.helpText,
					}

					console.log(customColumns)
					self.pushItemToCustomColumns(customColumns)
					postAction()
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200, // temp
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default AddCustomColumn
