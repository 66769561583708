import React from "react"
import { Route } from "react-router-dom"
import OnlineProjectsController from "./online-projects/OnlineProjectsController"
import OtherProjectsController from "./other-projects/OtherProjectsController"
import ChecklistsController from "./checklists/ChecklistsController"

export default function ReportsManagement() {
	return (
		<>
			<Route
				exact
				path="/organization/reports-management"
				component={OnlineProjectsController}
			/>
			<Route
				exact
				path="/organization/reports-management/online-projects"
				component={OnlineProjectsController}
			/>
			<Route
				exact
				path="/organization/reports-management/other-projects"
				component={OtherProjectsController}
			/>
			<Route
				exact
				path="/organization/reports-management/checklists"
				component={ChecklistsController}
			/>
		</>
	)
}
