import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

const GetCustomColumns = (self: any) => ({
	getCustomColumns(orgId: string) {
		// 0.
		const actionName = "getCustomColumns"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.request API call
		self.readCustomColumns(orgId)
			.then((response: any) => {
				console.log(actionName, "response:", response)
				const items = response.data.data.Items
				console.log("items:", items)

				let customColumns: any[] = []
				items.map((item: any) => {
					customColumns.push({
						...item,
						createdBy: item.createdBy.name,
					})
				})
				self.setCustomColumns(customColumns)
				self.handleResponse({
					actionName,
					status: ActionStatus.success,
					code: 200, // temp
					color: MessageColorType.blue,
					open: true,
					autoHide: true,
				})
				// } else {
				// 	self.handleResponse({
				// 		actionName,
				// 		status: ActionStatus.fail,
				// 		code: 200, // temp
				// 		color: MessageColorType.orange,
				// 		open: true,
				// 		autoHide: false,
				// 	})
				// }
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetCustomColumns
