import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog, DLButton, ConsoleLog } from "../../basic-elements"
import { DLCheckbox } from "@datalobby/components"
import styled from "styled-components"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"

const TablePageSettingDialog = observer(
	({
		partialStore,
		menuId,
		initialPageSetting,
	}: {
		partialStore: any
		menuId: any
		initialPageSetting: any
	}) => {
		const [columns, setColumns] = useState<any>([])
		const actionName = "tablePageSetting"

		const handlePageSettingColumns = (id: string, value: boolean) => {
			let data = [...columns]
			const index = data.findIndex((i: any) => i.aliasName === id)
			data[index].show = value
			setColumns(data)
		}

		const handlePageSetting = (columns: any) => {
			const prevPageSetting = localStorage.getItem("pageSetting")
			const userId = localStorage.getItem("orgUserId")
			const pageSettingName = `pageSetting_${menuId}`

			const props = prevPageSetting ? JSON.parse(prevPageSetting) : []

			if (props.some((item: any) => item.userId === userId)) {
				let prevData = props.find((item: any) => item.userId === userId)
				ConsoleLog(prevData)
				const index = props.findIndex(
					(item: any) => item.userId === userId
				)
				prevData = {
					...prevData,
					[pageSettingName]: {
						...prevData[pageSettingName],
						tableSetting: columns,
					},
				}
				props[index] = prevData
			} else {
				props.push({
					userId,
					[pageSettingName]: {
						tableSetting: columns,
					},
				})
			}
			localStorage.setItem("pageSetting", JSON.stringify(props))
			partialStore.responses.setResponse(actionName, {
				actionName,
				status: ActionStatus.success,
			})
			partialStore.setTablePageSettingDialog(false)
		}

		useEffect(() => {
			partialStore.responses.setResponse(actionName, {
				actionName,
				status: ActionStatus.standby,
			})
			const pageSettingData = JSON.parse(
				JSON.stringify(partialStore.tableColumnsPageSetting)
			)

			setColumns(pageSettingData)
		}, [])

		const handleMakeDafault = () => {
			// const initialSettingData = InitialPageSetting(menuId)
			setColumns(initialPageSetting)
			handlePageSetting(initialPageSetting)
		}

		const actionStatus = partialStore.getActionStatus("pageSetting")
		return (
			<DLDialog
				eleTestId="page-setting-dialog"
				isOpen={partialStore.tablePageSettingDialog}
				setIsOpen={partialStore.setTablePageSettingDialog}
				showCloseBtn={true}
				showOpenBtn={false}
				dialogTitle="Page Setting"
				dialogContents={
					<TablePageSettingDialogContainer
						columns={columns}
						handlePageSetting={handlePageSettingColumns}
					/>
				}
				actionBtn={
					<div className="FR JSB">
						<DLButton
							eleTestId="default-page-setting-btn"
							size="thin"
							color="primary"
							clickHandler={handleMakeDafault}
							disabled={
								JSON.stringify(initialPageSetting) ===
								JSON.stringify(columns)
							}
						>
							Set Default
						</DLButton>
						<DLButton
							size="thin"
							eleTestId="save-page-setting"
							clickHandler={() => handlePageSetting(columns)}
							color="primary"
							disabled={
								JSON.stringify(
									partialStore.tableColumnsPageSetting
								) === JSON.stringify(columns)
							}
						>
							Save
						</DLButton>
					</div>
				}
				actionReady={true}
				// handleAction={handlePageSetting}
				cancelBtnText="Cancel"
				showSpinner={actionStatus === "LOADING"}
				cannotUpdate={actionStatus === "LOADING"}
				draggable
			/>
		)
	}
)

const TablePageSettingDialogContainer = ({
	columns,
	handlePageSetting,
}: {
	columns: any
	handlePageSetting: any
}) => {
	return (
		<StyledPageSettingContainer className="page-setting-container">
			{columns.map((item: any) => {
				return (
					<div className="FR column-div" key={item.aliasName}>
						<DLCheckbox
							eleTestId={`checkbox-${item.aliasName}`}
							color="primary"
							isChecked={item.show}
							clickHandler={() =>
								handlePageSetting(item.aliasName, !item.show)
							}
							labelText={item.displayName}
						/>
					</div>
				)
			})}
		</StyledPageSettingContainer>
	)
}

const StyledPageSettingContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	.column-div {
		padding: 0.4rem;
		transition: backgrond-color 0.4s;
		:hover {
			background-color: ${(props) => props.theme.secondary};
		}
		margin: 0.2rem 0 0.2rem 0;
		cursor: pointer;
		width: 250px;
	}
`

export default TablePageSettingDialog
