import { types, applySnapshot } from "mobx-state-tree"
import { ActionStatus } from "../../common-models/enumerations/common-enums"
import Responses from "../../common-models/responses"
import ResponseSnackbar from "../../common-models/response-snackbar"
import { CommonViewModelActions } from "../../common-models/common-view-model-actions"
import { initialStore } from "./hc-mgmt-store.provider"

const HcMgmtStoreViewModel = types
	.model({
		// ---------- common models
		actionStatus: types.enumeration<ActionStatus>(
			Object.values(ActionStatus)
		),
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(CommonViewModelActions)

export { HcMgmtStoreViewModel }
