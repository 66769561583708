import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog } from "../../../../../components/basic-elements"
import { FileScreenDialog } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { convertTestId } from "../../../../../library/converters/id-converter"
import SignOffDetailDailogContents from "./SignOffDetailDailogContents"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import styled from "styled-components"

const FileInfoDialog = observer(
	({ partialStore, i18n }: { partialStore: any; i18n: DLI18nProps }) => {
		const store = useRootStore()
		const dntFormat = store.global.getDntFormat
		const contentHeight = store.ui.contentsAreaHeight
		const fileId = partialStore.selectedItems[0].id

		const [fileInfo, setFileInfo] = useState<any>({})

		useEffect(() => {
			if (partialStore.storeName === DLProjSubMenus.wp) {
				// Workpaper Files
				partialStore.getFileInfo(fileId, "wp")
			} else if (partialStore.storeName === DLProjSubMenus.pf) {
				// Permanent Files
				partialStore.getFileInfo(fileId, "pf")
			} else {
				// Project Template
				const file = partialStore.formattedFileInfoById(
					fileId,
					dntFormat
				)

				setFileInfo(file)
			}
		}, [fileId, partialStore.storeName])

		const dialogName = FileScreenDialog.fileInfo
		const setOpen = () => {
			partialStore.setFileTreeDialogOpen(dialogName)
		}

		const open = partialStore.fileTreeDialogOpenStatus[dialogName]

		const downloadPDF = () => {
			const input = document.getElementById("file-information-content")!

			html2canvas(input).then((canvas) => {
				input.style.height = "auto"
				const imageData = canvas.toDataURL("image/png")
				const pdf = new jsPDF("p", "mm", "a4")

				// const imgProps = pdf.getImageProperties(imageData)
				// const pdfWidth = pdf.internal.pageSize.getWidth()
				// const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width

				const pdfWidth = 200
				const pdfHeight = (canvas.height * pdfWidth) / canvas.width

				pdf.addImage(imageData, "PNG", 5, 5, pdfWidth, pdfHeight)
				pdf.save("file-info.pdf")
			})

			// table[0].style.height = "auto"
			// const doc = new jsPDF("p", "pt", "letter")
			// if (input) {
			//     doc.html(input, {
			//         html2canvas: {
			//             scale: 595.26 / input.scrollWidth, //595.26 is the width of A4 page
			//             scrollY: 0,
			//         },
			//         x: 0,
			//         y: 0,
			//         callback: function (doc: any) {
			//             console.log("in callback")
			//             doc.save("file-info")
			//         },
			//         margin: 10,
			//     })
			// }
		}

		return (
			<DLDialog
				eleTestId="file-info-dialog"
				isOpen={open}
				setIsOpen={setOpen}
				showOpenBtn={false}
				showCloseBtn={true}
				dialogTitle={i18n.twFileInfo || "File Information"}
				dialogContents={
					<div id="file-information-content">
						<FileInformation
							fileInfo={
								Object.keys(fileInfo).length > 1
									? fileInfo
									: partialStore.viewFileInfo(dntFormat)
							}
							contentHeight={contentHeight}
						/>
						<hr />
						<SignOffDetailDailogContents
							partialStore={partialStore}
						/>
					</div>
				}
				openBtn={<div />}
				cancelBtnText={i18n.twCancel || "Cancel"}
				actionReady={true}
				actionBtn="Export"
				handleAction={downloadPDF}
				maxWidth="md"
				showSpinner={
					partialStore.getActionStatus("getFileInfo") === "LOADING"
				}
			/>
		)
	}
)

const FileInformation = React.memo(
	({ fileInfo, contentHeight }: { fileInfo: any; contentHeight: any }) => {
		let fileInfoArray: { label: string; value: any }[] = []

		for (const prop in fileInfo) {
			fileInfoArray.push({
				label: prop,
				value: fileInfo[prop],
			})
		}

		return (
			<StyledFileInfo>
				<ReactTableV8
					tableColumns={columns}
					data={fileInfoArray}
					hasPagination={false}
					showPageSetting={false}
					height={contentHeight + 20}
					menuId="FileInformation"
				/>
			</StyledFileInfo>
		)
	}
)

const columns = [
	{
		header: "Label",
		accessorKey: "label",
	},
	{
		header: "Value",
		accessorKey: "value",
		cell: (props: any) => {
			const testId = convertTestId(props.row.original.label) + "-cell"
			return <div data-testid={testId}>{props.getValue()}</div>
		},
	},
]

const StyledFileInfo = styled.div`
	table {
		thead > tr > th {
			padding: 0;
			.header {
				min-height: 2rem;
				padding-left: 8px;
				justify-content: center;
			}
		}
		tbody {
			.MuiTableCell-root {
				padding: 8px;
			}
		}
	}
`

export default FileInfoDialog
