import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

/**
*
* @param self
* @returns
*
* * i18n records
* - success, fail
*
*/

const GetNotes = (self: any) => ({
	getNotes() {
		// 0.
		const actionName = "getProjNotes"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.readNotes()
			.then((response: any) => {
				// if success
				self.setNoteId(0)

				if (response.status === 200 && response.data.Status === 1) {
					self.setNotes(response.data.notes)
					self.setNoteId(response.data.id)
					self.setNeedRefresh(false)
					// if the note is empty, it will be refreshed again
					// if you want to restrict the refresh for empty note also, pelase add setNeedRefresh(false) to the second condition, or move the above one to one more upper side
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						// code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else if (
					response.status === 200 &&
					response.data.Status === 0
				) {
					// TODO: Need to confirm (empty note === status code 0 ?)
					// NOTE: Are there any other error cases..?
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetNotes
