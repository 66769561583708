import { types } from "mobx-state-tree"
// model & view-model
import HcMgmtRentalModel from "./hc-mgmt-rental.model"
import HcMgmtRentalViewModel from "./hc-mgmt-rental.view-model"
import { initialClickPoint } from "../../../../../common-models/types/dialog.props"
import {
	HcMgmtRentalApiProps,
	readRentals,
	rentHardCopy,
	returnHardCopy,
	signedUrl
} from "./hc-mgmt-rental.apis"

export const HcMgmtRentalStore = ({
	apiReadRentals,
	apiRentHardCopy,
	apiReturnHardCopy,
	apiReadSignedUrl
}: HcMgmtRentalApiProps) =>
	types
		.compose(
			HcMgmtRentalModel({
				apiReadRentals,
				apiRentHardCopy,
				apiReturnHardCopy,
				apiReadSignedUrl
			}),
			HcMgmtRentalViewModel
		)
		.named("HcMgmtRentalStore")

export const initialStore = {
	clickPoint: initialClickPoint,
	// viewModel related parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const HcMgmtRentalStoreInstance = HcMgmtRentalStore({
	apiReadRentals: readRentals,
	apiRentHardCopy: rentHardCopy,
	apiReturnHardCopy: returnHardCopy,
	apiReadSignedUrl: signedUrl,
}).create(initialStore)

export default HcMgmtRentalStoreInstance
