import React, { useCallback, useState } from "react"
import {
	PageContainer,
	PageHeader,
} from "../../../components/app-frame-elements"
import { observer } from "mobx-react-lite"
import { DLIconButton, DLButton } from "../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiDotsHorizontal } from "@mdi/js"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import CommonSnackbarComponent from "../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { PermissionAsObjectProps } from "../../../common-models/permission"
import BasicCounter from "../../../components/app-frame-elements/sub-components/BasicCounter"
import { DLI18nProps } from "../../../common-models/types/common-props"
import DLDateRangepicker from "../../../components/basic-elements/date-picker/DLDateRangePicker"
import ApprovalDialog from "../../../components/combined-elements/dialogs/ApprovalDialog"
import ProjectTrashCtxMenus from "./sub-components/ProjectTrashCtxMenus"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { DLCheckbox } from "@datalobby/components"
import { InputFieldForTableV8 } from "../../../components/basic-elements/tables/InputFieldForTable"
import {
	dateRangeFilter,
	multiSelectFilter,
} from "../../../library/react-table-utils"
import DLComboBoxWithCheckbox from "../../../components/basic-elements/autocomplete-fields/DLComboBoxWithCheckbox"
import { Column, Table } from "@tanstack/react-table"
import ReactTableV8 from "../../../components/basic-elements/tables/DLReactTable"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

export default observer(function ProjectTrash({
	partialStore,
	permission,
	contentsHeight,
	actionStatus,
	dateFormat,
	i18n,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
	contentsHeight: any
	actionStatus: ActionStatus
	dateFormat: string
	i18n: DLI18nProps
}) {
	const store = useRootStore()

	// select related
	const setCheckbox = partialStore.pushOrSpliceSelectedItem
	const isSelected = (id: string) => partialStore.selectedItems.includes(id)
	const handleCheckbox = useCallback((id: string) => {
		setCheckbox(id)
	}, [])

	const handleContextMenu = (event: any, itemId: string) => {
		event.preventDefault()
		partialStore.setSelectedItem(itemId)
		partialStore.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const dnt = store.global.getDntFormat
	const { timeZone } = dnt
	return (
		<PageContainer
			fixedHeight
			hasToolbar={false}
			isLoading={actionStatus === "LOADING"}
		>
			<PageHeader noPadding>
				<BasicCounter
					label="Project Trashed Item Count"
					value={partialStore.projTrashList.length}
				/>
				<div className="FR JSA">
					<DLButton
						eleTestId="select-10-files-btn"
						size="thin"
						color="primary"
						clickHandler={() => partialStore.selectFirst10Files()}
						disabled={partialStore.projTrashList.length < 1}
					>
						Select 10 Files
					</DLButton>
					<DLButton
						eleTestId="select-2-folders-btn"
						size="thin"
						color="primary"
						clickHandler={() => partialStore.selectFirst2Folders()}
						disabled={partialStore.projTrashList.length < 1}
					>
						Select 2 Folders
					</DLButton>
					<DLButton
						eleTestId="unselect-all-btn"
						size="thin"
						color="primary"
						clickHandler={() => partialStore.resetSelectedItems()}
						disabled={partialStore.selectedItems.length < 1}
					>
						UnSelect All
					</DLButton>
				</div>
				<div>
					{permission.delete && (
						<DLButton
							eleTestId="delete-all-btn"
							size="thin"
							color="primary"
							clickHandler={() =>
								partialStore.setDeleteAllTrashDialogOpen()
							}
							disabled={partialStore.selectedItems.length < 1}
						>
							Permanently Delete Selected Items
						</DLButton>
					)}
				</div>
			</PageHeader>
			{actionStatus === "SUCCESS" && (
				<ReactTableV8
					tableColumns={Columns(
						handleContextMenu,
						permission,
						i18n,
						handleCheckbox,
						isSelected
					)}
					data={partialStore.viewProjTrashList(dateFormat, timeZone)}
					hasPagination={true}
					showPageSetting={false}
					customFilters={(props: any) =>
						filterComponent({
							...props,
							dateFormat,
							trashTypes: partialStore.viewTrashItemType(),
						})
					}
					handleContextMenu={(e: any, rowInfo: any) =>
						handleContextMenu(e, rowInfo.id)
					}
					height={contentsHeight}
					i18n={i18n}
					menuId={DLProjSubMenus.trash}
				/>
			)}
			{/* ----- snackbar ----- */}
			<CommonSnackbarComponent i18n={i18n} partialStore={partialStore} />

			{/*---------Context Menu---------*/}
			<ProjectTrashCtxMenus permission={permission} i18n={i18n} />

			{partialStore.restoreTrashDialogOpen && (
				<ApprovalDialog
					title="Restore Item"
					msg="Do you really want to restore this file?"
					approvalType="restore"
					dialogAction={() =>
						partialStore.restoreProjTrashItem(
							partialStore.selectedItems[0]
						)
					}
					dialogBtnText={i18n.twRestore || "Restore"}
					open={partialStore.restoreTrashDialogOpen}
					setOpen={partialStore.setRestoreTrashDialogOpen}
					actionStatus={partialStore.getActionStatus(
						"restoreProjTrashItem"
					)}
				/>
			)}
			{partialStore.deleteTrashDialogOpen && (
				<ApprovalDialog
					title="Delete Item"
					msg="Do you really want to delete the file permanently? This file cannot be restored"
					approvalType="delete"
					dialogAction={() =>
						partialStore.removeProjTrashItem(
							partialStore.selectedItems[0]
						)
					}
					dialogBtnText={i18n.twDelete || "Delete"}
					open={partialStore.deleteTrashDialogOpen}
					setOpen={partialStore.setDeleteTrashDialogOpen}
					actionStatus={partialStore.getActionStatus(
						"removeProjTrashItem"
					)}
				/>
			)}
			{partialStore.deleteAllTrashDialogOpen && (
				<ApprovalDialog
					title="Delete Selected Items"
					msg="Do you really want to delete the selected files permanently? These files cannot be restored"
					approvalType="delete"
					dialogAction={() =>
						partialStore.removeMultipleItems(
							partialStore.selectedItems
						)
					}
					dialogBtnText={i18n.twDelete}
					open={partialStore.deleteAllTrashDialogOpen}
					setOpen={partialStore.setDeleteAllTrashDialogOpen}
					actionStatus={partialStore.getActionStatus(
						"removeMultipleItems"
					)}
				/>
			)}
		</PageContainer>
	)
})

const filterComponent = ({
	column,
	table,
	dateFormat,
	trashTypes,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
	trashTypes: string[]
}) => {
	switch (column.id) {
		case "type":
			return (
				<DLComboBoxWithCheckbox
					options={trashTypes}
					getOptionLabel={(option: any) => option}
					onChange={(value: any) => {
						column.setFilterValue(value)
					}}
					eleValue={(column.getFilterValue() ?? []) as string[]}
					eleTestId={`${column.id}-select`}
				/>
			)

		case "deletedAt":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	return <div data-testid={cellId}>{props.getValue()}</div>
}

const Columns = (
	handleContextMenu: (event: any, itemId: string) => void,
	permission: PermissionAsObjectProps,
	i18n: DLI18nProps,
	handleCheckbox: (id: string) => void,
	isSelected: (id: string) => boolean
) => [
	{
		header: "Select",
		accessorKey: "id",
		size: 10,
		enableColumnFilter: false,
		cell: (props: any) => {
			const id = props.row.original.id
			const handleClickCheckbox = () => {
				handleCheckbox(id)
			}
			return (
				<div className="FR AC JC" style={{ width: "100%" }}>
					<DLCheckbox
						eleTestId={`checkbox-${id}`}
						clickHandler={handleClickCheckbox}
						color="primary"
						isChecked={isSelected(id)}
					/>
				</div>
			)
		},
	},
	{
		header: i18n.twType || "Type",
		accessorKey: "type",
		cell: renderCustomCell,
		filterFn: multiSelectFilter,
	},
	{
		header: i18n.twName || "Name",
		accessorKey: "name",
		cell: renderCustomCell,
	},
	{
		header: i18n.deletedBy || "Deleted by",
		accessorKey: "deletedBy",
		cell: renderCustomCell,
	},
	{
		header: i18n.twDeletedAt || "Deleted At",
		accessorKey: "deletedAt",
		filterFn: dateRangeFilter,
		cell: renderCustomCell,
	},
	{
		header: "Action",
		size: 10,
		enableColumnFilter: false,
		cell: (props: any) => {
			const itemId = props.row.original.id
			return (
				<div className="FR AC JC" style={{ width: "100%" }}>
					<DLIconButton
						aria-label="more"
						clickHandler={(event: any) =>
							handleContextMenu(event, itemId)
						}
						eleTestId="trash-list-context-btn"
					>
						<Icon path={mdiDotsHorizontal} size={0.8} />
					</DLIconButton>
				</div>
			)
		},
	},
]
