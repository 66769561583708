import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../common-models/common-model-actions"
import ReadLibrariesInProj from "./model-actions/read-libraries-in-proj"
import ReadLibFilesInProj from "./model-actions/read-lib-files-in-proj"

// separated model actions

const LibInProjModel = () =>
	types
		.model({})
		// CRUD
		.actions(ReadLibrariesInProj)
		.actions(ReadLibFilesInProj)
		// model common actions
		.actions(CommonModelActions)

export default LibInProjModel
