import React from "react"
import { dateRangeFilter } from "../../../../library/react-table-utils"

const AuditProgramTableColumns = () => [
	{
		header: "Legend",
		accessorKey: "legend",
		size: 60,
		cell: (props: any) => {
			return <div data-testid="legend-cell">{props.getValue()}</div>
		},
	},
	{
		header: "Audit Steps",
		accessorKey: "auditSteps",
		size: 60,
		cell: (props: any) => {
			return <div data-testid="auditSteps-cell">{props.getValue()}</div>
		},
	},
	{
		header: "Assertions",
		accessorKey: "assertions",
		size: 60,
		cell: (props: any) => {
			return <div data-testid="assertions-cell">{props.getValue()}</div>
		},
	},
	{
		header: "Notes",
		accessorKey: "notes",
		size: 60,
		cell: (props: any) => {
			return <div data-testid="notes-cell">{props.getValue()}</div>
		},
	},
	{
		header: "Ref",
		accessorKey: "ref",
		size: 60,
		cell: (props: any) => {
			return <div data-testid="ref-cell">{props.getValue()}</div>
		},
	},
	{
		header: "Preparer",
		accessorKey: "preparer",
		size: 60,
		cell: (props: any) => {
			return <div data-testid="preparer-cell">{props.getValue()}</div>
		},
	},
	{
		header: "Due Date",
		accessorKey: "dueDate",
		filterFn: dateRangeFilter,
		cell: (props: any) => {
			const cellId = props.row.cell.id + "-cell"
			return (
				<div data-testid={cellId}>
					{props.getValue() ? props.getValue() : "-"}
				</div>
			)
		},
	},
	{
		header: "Reviewer",
		accessorKey: "reviewer",
		size: 60,
		cell: (props: any) => {
			return <div data-testid="reviewer-cell">{props.getValue()}</div>
		},
	},
	{
		header: "Due Date",
		accessorKey: "dueDate",
		filterFn: dateRangeFilter,
		cell: (props: any) => {
			const cellId = props.row.cell.id + "-cell"
			return (
				<div data-testid={cellId}>
					{props.getValue() ? props.getValue() : "-"}
				</div>
			)
		},
	},
	{
		header: "Prepared",
		accessorKey: "prepared",
		size: 60,
		cell: (props: any) => {
			return <div data-testid="prepared-cell">{props.getValue()}</div>
		},
	},
	{
		header: "Reviewed",
		accessorKey: "reviewed",
		size: 60,
		cell: (props: any) => {
			return <div data-testid="reviewed-cell">{props.getValue()}</div>
		},
	},
	{
		header: "Actions",
		size: 60,
		cell: (props: any) => {
			return <div data-testid="actions-cell"></div>
		},
	},
]

export default AuditProgramTableColumns
