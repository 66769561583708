import { ProjScreenType } from "../../../components/combined-elements/projects-table/projects-table-props"

const ProjectTableFilterViews = (self: any) => ({
	getProjectList(projType: ProjScreenType){
		let project
		if (projType === ProjScreenType.normal) {
			project = self.normalProjList
		}
		if (projType === ProjScreenType.replica) {
			project = self.replicaProjList
		}
		if (projType === ProjScreenType.archived) {
			project = self.archivedProjList
		}
		if (projType === ProjScreenType.unarchived) {
			project = self.unarchivedProjList
		}
		if (projType === ProjScreenType.roamArchived) {
			project = self.roamArchivedProjList
		}
		if (projType === ProjScreenType.amArchived) {
			project = self.amArchivedProjectList
		}
		if (projType === ProjScreenType.amUnarchived) {
			project = self.amUnarchivedProjectList
		}
		if (projType === ProjScreenType.assigned) {
			project = self.assignedProjList
		}
		if (projType === ProjScreenType.reportAndChecklist) {
			project = self.onlineProjects
		}
		if (projType === ProjScreenType.otherProjects) {
			project = self.otherProjects
		}
		return project
	},
	viewEPUsers(projType: ProjScreenType) {
		let epList: any[] = []
		const project = self.getProjectList(projType)
		project.map((project: any) => {
			const fetchedEps = project.epUsers
			project.epUsers &&
				fetchedEps.map((ep: any) => {
					if (
						epList.findIndex((item: any) => item === ep.name) === -1
					) {
						epList.push(ep.name)
					}
				})
		})

		return epList.sort()
	},
	viewQCList(projType: ProjScreenType) {
		let qcList: any[] = []
		const project = self.getProjectList(projType)
		project.map((project: any) => {
			const fetchedQcs = project.qcUsers
			project.qcUsers &&
				fetchedQcs.map((qc: any) => {
					if (
						qcList.findIndex((item: any) => item === qc.name) === -1
					) {
						qcList.push(qc.name)
					}
				})
		})
		return qcList.sort()
	},
	viewClients(projType: ProjScreenType) {
		let clients: any[] = []
		const project = self.getProjectList(projType)
		project.map((project: any) => {
			if (
				clients.findIndex(
					(item: any) => item === project.clientName
				) === -1
			) {
				clients.push(project.clientName)
			}
		})
		return clients.sort()
	},
	viewEngTypes(projType: ProjScreenType) {
		let engTypes: any[] = []
		const project = self.getProjectList(projType)
		project.map((project: any) => {
			if (
				engTypes.findIndex(
					(item: any) => item === project.engTypeName
				) === -1
			) {
				engTypes.push(project.engTypeName)
			}
		})
		return engTypes.sort()
	},
	viewYearList(projType: ProjScreenType) {
		let years: any[] = []
		const project = self.getProjectList(projType)
		project.map((project: any) => {
			if (
				years.findIndex(
					(item: any) => item === project.financialYear
				) === -1
			) {
				years.push(project.financialYear)
			}
		})
		return years.sort()
	},
	viewPeriodTypes(projType: ProjScreenType) {
		let periodTypes: any[] = []
		const project = self.getProjectList(projType)
		project.map((project: any) => {
			if (
				periodTypes.findIndex(
					(item: any) => item === project.periodName
				) === -1
			) {
				periodTypes.push(project.periodName)
			}
		})
		const months = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		]
		return periodTypes.sort((a: any, b: any) => {
			// if(months.includes(a) || months.includes(b)){
			return months.indexOf(a) - months.indexOf(b)
			// }
			// return a - b
		})
	},
	viewGroupNames(projType: ProjScreenType) {
		let groupNames: any[] = []
		const project = self.getProjectList(projType)
		project.map((project: any) => {
			if (
				groupNames.findIndex(
					(item: any) => item === project.groupName
				) === -1
			) {
				groupNames.push(project.groupName)
			}
		})
		return groupNames.sort()
	},
})

export default ProjectTableFilterViews
