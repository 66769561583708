import React, { useState } from "react"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { PageContainer } from "../../../../components/app-frame-elements"
import styled from "styled-components"
import HistoryTable from "./sub-components/HistoryTable"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { observer } from "mobx-react-lite"


const History = observer(({
	partialStore,
	permission,
	fetchingStatus
}: {
	partialStore: any
	permission: PermissionAsObjectProps
	fetchingStatus: ActionStatus
}) => {
	const handleContextMenu = (event: any, rowInfo: any) => {
		event.preventDefault()
		partialStore.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
		partialStore.setSelectedId(rowInfo.id)
	}
	const clickPointExist = partialStore.clickPoint.mouseX !== null
	console.log(fetchingStatus, "fetchingStatus")
    return (
    	<PageContainer
			pageTools={<div />}
			hasToolbar={false}
			fullWidth
			fixedHeight
			isLoading={
				fetchingStatus === "LOADING"
			}
		>
	    	<StyledHistory>
				<HistoryTable 
					partialStore={partialStore}
					handleContextMenu={handleContextMenu}
					menuId={DLOrgSubMenus.hard_copy_history}
					rederingFrom="Page"
					permission={permission}
				/>
				
				{/* ---------- context menus ---------- */}
				{/*{clickPointExist && (
					<TrashCtxMenus
						partialStore={partialStore}
						permission={permission}
					/>
				)}*/}
			</StyledHistory>
		</PageContainer>
    )
})

export default History

const StyledHistory = styled.div`
	padding: 0 1rem;
`