import React from "react"
import { Route } from "react-router-dom"
import AmArchivedProjectsController from "./am-archived-projects/AmArchivedProjectsController"
import AmUnarchivedProjectsController from "./am-unarchived-projects/AmUnarchivedProjectsController"

export default function ArchiveManagement() {
	return (
		<>
			<Route
				exact
				path="/organization/archive-management"
				component={AmArchivedProjectsController}
			/>
			<Route
				exact
				path="/organization/archive-management/archived"
				component={AmArchivedProjectsController}
			/>
			<Route
				exact
				path="/organization/archive-management/unarchived"
				component={AmUnarchivedProjectsController}
			/>
		</>
	)
}
