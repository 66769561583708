import { AxiosResponse } from "axios"
import {
	postRequest,
	deleteRequest,
	getRequest,
	putRequest,
} from "../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { HardCopyStatusEnum } from "./data-models/hard-copy-list.data-models"

export async function moveToTrashHardCopy(payload: {
	pk: string
	id: string
	by: { id: string; alias: string; name: string }
}) {
	let url = `/hard-copies`

	const response = await deleteRequest({
		tokenType: TOKEN_TYPE.hardCopy,
		url,
		payload,
	})

	return response
}

type AddHardCopy = {
	status: string
	year: number
	title: string
	description: string
	customerId: string
	orgId: string
	hasSoftCopy: false
	createdBy: { id: string; alias: string; name: string }
}

export async function addHardCopy(payload: AddHardCopy) {
	let url = "/hard-copies"

	const response = await postRequest({
		tokenType: TOKEN_TYPE.hardCopy,
		url,
		payload,
	})
	return response
}

export async function readHardCopies(orgId: string, year: string | number) {
	console.log("year in readHardCopies: ", year)
	let url = "/hard-copies"

	const response = await getRequest({
		tokenType: TOKEN_TYPE.hardCopy,
		url,
		params: {
			year,
			orgId,
		},
	})
	return response
}

type UpdateHardCopy = {
	id: string
	pk: string
	by: { id: string; alias: string; name: string }
	[x: string]: any
}

export async function updateHardCopyDetail(payload: UpdateHardCopy) {
	let url = `/hard-copies/update-detail`

	const response = await putRequest({
		tokenType: TOKEN_TYPE.hardCopy,
		payload,
		url,
	})
	return response
}

export async function updateHardCopyStatus(payload: {
	id: string
	pk: string
	by: { id: string; alias: string; name: string }
	status: HardCopyStatusEnum
}) {
	let url = `/hard-copies/update-status`

	const response = await putRequest({
		tokenType: TOKEN_TYPE.hardCopy,
		payload,
		url,
	})
	return response
}

type DeleteCustomColumnParams = {
	pk: string
	id: string
	by: { id: string; alias: string; name: string }
}

export async function deleteCustomColumn(payload: DeleteCustomColumnParams) {
	let url = `/custom-columns`
	const response = await deleteRequest({
		tokenType: TOKEN_TYPE.hardCopy,
		payload,
		url,
	})
	return response
}

export type HardCopyMgmtApiProps = {
	apiCreateHardCopy: (params: AddHardCopy) => Promise<AxiosResponse<any>>
	apiUpdateHardCopyDetail: (
		params: UpdateHardCopy
	) => Promise<AxiosResponse<any>>
	apiUpdateHardCopyStatus: (params: {
		id: string
		pk: string
		by: { id: string; alias: string; name: string }
		status: HardCopyStatusEnum
	}) => Promise<AxiosResponse<any>>
	apiRemoveCustomColumn: (
		params: DeleteCustomColumnParams
	) => Promise<AxiosResponse<any>>
	apiMoveToTrashHardCopy: (params: {
		pk: string
		id: string
		by: { id: string; alias: string; name: string }
	}) => Promise<AxiosResponse<any>>
	apiReadHardCopies: (
		orgId: string,
		year: string | number
	) => Promise<AxiosResponse<any>>
}
