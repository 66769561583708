import React from "react"
import {
	InputWithLabel,
	DLInputField,
	DLTextArea,
} from "../../../../../../../components/basic-elements"
import styled from "styled-components"
import ProjectInfoDetailsContent from "../../ProjectInfoDetailsContent"

export const AddHardCopyContent = ({
	inputs,
	handleInputs,
}: {
	inputs: any
	handleInputs: any
}) => {
	const projInfo = {
		title: "projectName",
		projectAlias: "projectAlias",
		clientName: "clientName",
		// clientAlias:"clientAlias",
		// hardCopyName:"Hard Copy Name",
		// description:"description",
		// rentalStatus: "done",
		statusName: "NORMAL",
		version: "-",
		periodName: "FULL YEAR",
		financialYear: "2020",
		finalReportDate: "2022-08-15",
	}
	return (
		<StyledHardCopyContent>
			<div className="proj-info-section">
				<h3>Project Information</h3>
				<ProjectInfoDetailsContent projInfo={projInfo} />
			</div>
			<div className="hard-copy-form">
				<div className="input-section FR">
					<InputWithLabel
						direction="vertical"
						required
						label="Hard Copy Title"
					>
						<DLInputField
							autoFocus
							eleTestId="hard-copy-title-input"
							eleFullWidth
							eleName="title"
							eleValue={inputs.title}
							eleHandleChange={handleInputs}
							eleRequired
							variant="outlined"
						/>
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel direction="vertical" label="Description">
						<DLTextArea
							eleTestId="description-input"
							eleFullWidth
							eleName="description"
							eleValue={inputs.description}
							eleHandleChange={handleInputs}
							eleFieldHeight="5"
							variant="outlined"
						/>
					</InputWithLabel>
				</div>
			</div>
		</StyledHardCopyContent>
	)
}

const StyledHardCopyContent = styled.div`
	.proj-info-details {
		margin-top: 1rem;
		margin-left: 1rem;
	}
`
