import React from "react"
import { observer } from "mobx-react-lite"
import { PageContainer } from "../../../../components/app-frame-elements"
import styled from "styled-components"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"

import { DLI18nProps } from "../../../../common-models/types/common-props"
import { ProjScreenType } from "../../../../components/combined-elements/projects-table/projects-table-props"

import ProjBasicCtxMenus from "../../../../components/combined-elements/projects-table/ProjBasicCtxMenus"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import ArchivedProjectDeleteDialog from "../common-dialogs/AmProjectDeleteDialog"
import ProjectDetailsDialog from "../../../../components/combined-elements/project-dialogs/ProjectDetailsDialog"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import SharedProjectTableV8 from "../../../../components/combined-elements/projects-table/SharedProjectTableV8"

export default observer(function ArchiveManagementUnarchived({
    partialStore,
    fetchingStatus = ActionStatus.success,
    permission,
    i18n,
}: {
    partialStore: any
    fetchingStatus: ActionStatus
    permission: PermissionAsObjectProps
    i18n: DLI18nProps
}) {
    const orgStore = useOrgStore()

    const handleCtxMenuClose = () => {
        orgStore.unarchiveMgmt.setClickPoint({
            mouseX: null,
            mouseY: null,
        })
    }

    const clickPoint = {
        mouseX: orgStore.unarchiveMgmt.clickPoint.mouseX,
        mouseY: orgStore.unarchiveMgmt.clickPoint.mouseY,
    }
    let clientId = orgStore.unarchiveMgmt.selectedProj
    let cabinetId = orgStore.unarchiveMgmt.selectedCabinetId

    const userRole = orgStore.checkin.assignedRole.shortName
    return (
        <PageContainer
            pageTools={<div />}
            hasToolbar={false}
            fullWidth
            fixedHeight
            isLoading={fetchingStatus === "LOADING"}
        >
            <StyledAmUnArchivedProjects>
                <SharedProjectTableV8
                    partialStore={partialStore}
                    i18n={i18n}
                    // common part 2
                    projScreenType={ProjScreenType.amUnarchived}
                    menuId={DLOrgSubMenus.unarchived_mgmt}
                    projectStatus={ProjectStatus.unarchived}
                    permission={permission}
                />
                <ProjBasicCtxMenus
                    projStatus={ProjectStatus.unarchived}
                    partialStore={partialStore}
                    permission={permission}
                    clickPoint={clickPoint}
                    handleClose={handleCtxMenuClose}
                    userRole={userRole}
                />
                {/* response snackbar  */}
                <CommonSnackbarComponent
                    i18n={i18n}
                    partialStore={partialStore}
                />
                {/* Dialogs */}
                {partialStore.projectDetailsDialogOpen && (
                    <ProjectDetailsDialog partialStore={partialStore} />
                )}
                {partialStore.openDeleteProjDialog && (
                    <ArchivedProjectDeleteDialog
                        i18n={i18n}
                        isOpen={partialStore.openDeleteProjDialog}
                        setIsOpen={partialStore.setOpenDeleteProjDialog}
                        clientId={partialStore.selectedProj}
                        cabinetId={partialStore.selectedCabinetId}
                        deleteAction={() =>
                            partialStore.removeAMUnarchivedProject(
                                clientId,
                                cabinetId
                            )
                        }
                    />
                )}
            </StyledAmUnArchivedProjects>
        </PageContainer>
    )
})

const StyledAmUnArchivedProjects = styled.div`
    padding-right: 1rem;
    padding-left: 1rem;
    .counter-container {
        .counter {
            margin: 0 1rem;
            span {
                font-weight: 600;
                color: ${(props) => props.theme.emphasis};
            }
        }
    }
    .partition {
        margin-right: 1rem;
    }
`
