import { flow } from "mobx-state-tree"
import {
	HcMgmtRentalApiProps,
	ReturnHardCopyProps,
} from "../hc-mgmt-rental.apis"

const MReturnHardCopy = (
	self: any,
	returnHardCopy: HcMgmtRentalApiProps["apiReturnHardCopy"]
) => ({
	mReturnHardCopy: flow(function* (params: ReturnHardCopyProps) {
		const actionName = "mReturnHardCopy"
		try {
			const response = yield returnHardCopy(params)
			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default MReturnHardCopy
