import React, { useEffect } from "react"
import styled from "styled-components"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import { DLComboBox } from "../../../basic-elements"
import { DLCheckbox } from "@datalobby/components"
import { CreateProjectMethod } from "../create-project.data-props"
import { WithRollForwardPartProps } from "../create-project.comp-props"
import { createFilterOptions } from "@material-ui/lab/Autocomplete"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"

export default observer(function WithRollForward({
	createMethod,
	//
	groupId,
	handleSelectRfSource,
	handleCheckbox,
	highlight,
	inputs,
	i18n,
}: WithRollForwardPartProps) {
	const orgStore = useOrgStore()
	useEffect(() => {
		orgStore.projects.getRfProjectsByGroup(groupId)
	}, [])

	const handleSelect = (value: any) => {
		// this value.value return project ID
		if (value !== null) {
			handleSelectRfSource(value.value)
		} else {
			handleSelectRfSource(null)
		}
	}

	const handleOption = (event: any) => {
		handleCheckbox(event.target.name)
	}

	const { copyFrom, copyContents, copyUsers, moveFsDataAsPy } = inputs

	const rfSource = orgStore.projects.createProjectPresetInputs
	return (
		<StyledWithRollForward
			className={`${highlight && inputs.copyFrom === null && "request"}`}
		>
			{createMethod === CreateProjectMethod.byRollForward ? (
				<div className="rf-src-info" data-testid="rf-src-info">
					<div className="section-title">
						Roll Forward Source Information
					</div>
					{rfSource && (
						<div>
							<div data-testid="rf-src-title">
								Title: {rfSource.title}
							</div>
							<div data-testid="rf-src-client">
								Client: {rfSource.clientName}
							</div>
							<div data-testid="rf-src-period">
								Period Type: {rfSource.periodName}
							</div>
							{/* <div>Eng Type: {rfSource.engTypeName}</div> */}{" "}
							{/* // NOTE: group item has no engTypeName */}
						</div>
					)}
				</div>
			) : (
				<DLComboBox
					eleClassName="rf-source-select"
					eleName="copyFrom"
					onChange={handleSelect}
					options={orgStore.projects.rfOptions}
					placeholder={i18n.tsSelectProjectToCopy}
					// eleValue={copyFrom}
					filterOptions={createFilterOptions({
						// matchFrom: 'start',
						stringify: (option: any) => option.name + option.year,
					})}
					renderOption={(
						option: any,
						{ inputValue }: { inputValue: any }
					) => {
						const matches_name = match(option.name, inputValue)
						const parts_name = parse(option.name, matches_name)
						const matches_year = match(
							option.year.toString(),
							inputValue
						)
						const parts_year = parse(
							option.year.toString(),
							matches_year
						)
						return (
							<div className="FR AC">
								<div
									style={{
										marginRight: "1rem",
										opacity: 0.8,
									}}
								>
									(
									{parts_year.map((part, i) => (
										<span
											key={i}
											style={{
												fontWeight: part.highlight
													? 700
													: 400,
											}}
										>
											{part.text}
										</span>
									))}
									)
								</div>
								<div
									style={{
										marginRight: "0.4rem",
										whiteSpace: "nowrap",
									}}
								>
									{parts_name.map((part, i) => (
										<span
											key={i}
											style={{
												fontWeight: part.highlight
													? 700
													: 400,
											}}
										>
											{part.text}
										</span>
									))}
								</div>
							</div>
						)
					}}
				/>
			)}

			{copyFrom !== null && (
				<div className="FR">
					<div>RF Target information (pending)</div>
					<div>
						<div className="FR rf-option">
							<DLCheckbox
								eleClassName="rf-option-checkbox"
								eleTestId="copy-content"
								eleName="copyContents"
								clickHandler={handleOption}
								isChecked={copyContents}
							/>
							<div>{i18n.tpRfOption1}</div>
						</div>
						<div className="help-text">
							<div style={{ fontWeight: 700 }}>Note</div>
							<ol>
								<li>{i18n.tpRfNote1}</li>
								<li>{i18n.tpRfNote2}</li>
								<li>{i18n.tpRfNote3}</li>
							</ol>
						</div>
						<div className="FR rf-option">
							<DLCheckbox
								eleName="copyUsers"
								eleClassName="rf-option-checkbox"
								eleTestId="copy-users"
								clickHandler={handleOption}
								isChecked={copyUsers}
							/>
							<div>{i18n.tpRfOption2}</div>
						</div>
						<div className="FR rf-option">
							<DLCheckbox
								eleClassName="rf-option-checkbox"
								eleTestId="copy-fs"
								eleName="moveFsDataAsPy"
								clickHandler={handleOption}
								isChecked={moveFsDataAsPy}
							/>
							<div>{i18n.tpRfOption3}</div>
						</div>
					</div>
				</div>
			)}
		</StyledWithRollForward>
	)
})

const StyledWithRollForward = styled.div`
	width: 100%;
	height: 100%;
	padding: 0 2rem;
	border: 1px solid transparent;
	border-radius: 4px;
	transition: 0.3s;
	&.request {
		color: red;
		border: 1px solid red;
		background-color: rgba(255, 0, 0, 0.3);
	}
	.rf-src-info {
		margin-bottom: 1rem;
		.section-title {
			font-weight: 700;
			margin-bottom: 0.6rem;
		}
		background-color: ${(props) => props.theme.shade05};
		border-radius: 3px;
		padding: 1rem;
	}
	.rf-source-select {
		margin-bottom: 2.4rem;
	}
	.rf-option {
		margin-bottom: 1.6rem;
		.rf-option-checkbox {
			margin-right: 0.4rem;
		}
	}
	.help-text {
		margin-bottom: 1rem;
		li {
			margin-bottom: 0.6rem;
		}
	}
`
