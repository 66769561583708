import React, { useCallback } from "react"
import { Link } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import {
	DLIconButton,
	DLChip,
	ConsoleLog,
	DLButton,
} from "../../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiDotsVertical } from "@mdi/js"
import { ReactTableFilterProps } from "../../../../../common-models/types/react-table.props"
import { differenceInDays } from "date-fns/esm"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import DLDateRangepicker from "../../../../../components/basic-elements/date-picker/DLDateRangePicker"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { Column, Table, FilterFn } from "@tanstack/react-table"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import BasicCounter from "../../../../../components/app-frame-elements/sub-components/BasicCounter"
import { AddUserButton } from "../dialogs/AddUserDialog"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import {
	dateRangeFilter,
	singleSelectFilter,
} from "../../../../../library/react-table-utils"

export default observer(function OrgSetupUserListTable({
	i18n,
	permission,
}: {
	i18n: DLI18nProps
	permission: PermissionAsObjectProps
}) {
	ConsoleLog(" -----* OrgSetupUserListTable *----- ")

	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.setup_users

	const handleContextMenu = (event: any, row: any) => {
		event.preventDefault()
		orgStore.setupUsers.setSelectedUserId(row.id)
		orgStore.setupUsers.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const dateFormat = store.global.dateFormat.value
	const timeZone = store.global.timeZone.uiValue

	const pageHeader = (instance: Table<any>) => {
		const totalUsers = instance.getPreFilteredRowModel().flatRows.length
		const filteredUsers = instance.getFilteredRowModel().flatRows.length
		const totalInternalUsers = instance
			.getPreFilteredRowModel()
			.flatRows.filter(
				(item: any) => item.original.type === "internal"
			).length
		const totalExternalUsers = instance
			.getPreFilteredRowModel()
			.flatRows.filter(
				(item: any) => item.original.type === "external"
			).length

		return (
			<div className="FR JSB" style={{ width: "100%" }}>
				<div className="FR AC count-container">
					<BasicCounter
						label={i18n.twTotalUsers || "Total Users"}
						value={totalUsers}
					/>
					<span className="partition">|</span>
					<div className="internal-count FR AC">
						Internal Users :{" "}
						<span className="internal-count-value">
							{totalInternalUsers}
						</span>
					</div>
					<span className="partition">|</span>
					<div className="external-count FR AC">
						External Users :{" "}
						<span className="external-count-value">
							{totalExternalUsers}
						</span>
					</div>
					{totalUsers > filteredUsers && (
						<div className="FR AC">
							<span className="partition">|</span>
							<div className="filtered-count FR AC">
								Filtered Users :{" "}
								<span className="filtered-count-value">
									{filteredUsers}
								</span>
							</div>
							<span className="partition">|</span>
							<DLButton
								eleTestId="clear-filters"
								clickHandler={instance.resetColumnFilters}
							>
								Clear Filters
							</DLButton>
						</div>
					)}
				</div>
				<div className="FR">
					{permission.create && (
						<>
							<AddUserButton i18n={i18n} />
							<DLButton
								eleTestId="bulk-import-user-btn"
								clickHandler={() =>
									orgStore.setupUsers.setBulkAddUsersDialogOpen(
										true
									)
								}
							>
								{/* TODO: This button will be moved to the add users dialog  */}
								Import Users
							</DLButton>
						</>
					)}
				</div>
			</div>
		)
	}

	return (
		<ReactTableV8
			tableColumns={columns1({
				handleContextMenu,
				setSelectedUserId: orgStore.setupUsers.setSelectedUserId,
				i18n,
				dateFormat,
				permission,
			})}
			data={orgStore.setupUsers.formattedOrgUserList(
				dateFormat,
				timeZone
			)}
			// defaultFilters={[{ id: "lastAccessAt", value: { lastAccessStartDate: null, lastAccessEndDate: null }}]}
			hasPagination={true}
			customFilters={({ column, table }: { column: any; table: any }) =>
				filterComponent({ column, table, dateFormat })
			}
			handleContextMenu={handleContextMenu}
			height={store.ui.contentsAreaHeight}
			permission={permission}
			i18n={i18n}
			pageHeader={pageHeader}
			menuId={menuId}
		/>
	)
})

type ColumnProps = {
	handleContextMenu: any
	setSelectedUserId: any
	i18n: DLI18nProps
	dateFormat: string
	permission: PermissionAsObjectProps
}

const filterComponent = ({
	column,
	table,
	dateFormat,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
}) => {
	// console.log(column.id,column.getFilterValue(), "filters")
	switch (column.id) {
		case "type":
		case "status":
		case "accessRight":
			return (
				<select
					onChange={(e: any) => {
						// savePageSetting("periodName", value, pageSettingName)
						column.setFilterValue(e.target.value)
					}}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "all") as string[]}
					data-testid="user-type-select"
				>
					<option value="all">All</option>
					{[...column.getFacetedUniqueValues().keys()].map(
						(item: any) => (
							<option key={item} value={item}>
								{item}
							</option>
						)
					)}
				</select>
			)

		case "createdAt":
		case "lastAccess":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		case "isArchiveAdmin":
			return (
				<select
					onChange={(event: any) =>
						column.setFilterValue(event.target.value)
					}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "all") as string}
					data-testid="isArchiveAdmin-select"
				>
					<option value="all">All</option>
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select>
			)
		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	return <div data-testid={cellId}>{props.getValue()}</div>
}

const columns1 = ({
	handleContextMenu,
	setSelectedUserId,
	i18n,
	dateFormat,
	permission,
}: ColumnProps) => [
	{
		header: "User Type",
		accessorKey: "type",
		width: 80,
		filterFn: singleSelectFilter,
		cell: (props: any) => {
			const type = props.getValue()
			const cellId = props.cell.column.id + "-cell"

			return (
				<div
					style={{ color: type === "internal" ? "" : "orange" }}
					data-testid={cellId}
				>
					{type}
				</div>
			)
		},
	},
	{
		header: "Name",
		accessorKey: "name",
		width: 150,
		cell: (props: any) => {
			const userId = props.row.original.id
			const cellId = props.cell.column.id + "-cell"

			const distance = differenceInDays(
				new Date(),
				new Date(props.row.original.createdAt)
			)
			return (
				<Link
					to={`/organization/organization-setup/organization-user-list/detail/${userId}`}
					onClick={() => setSelectedUserId(props.row.original.id)}
					className="FR"
				>
					<span style={{ marginRight: 4 }} data-testid={cellId}>
						{props.getValue()}
					</span>
					{distance <= 1 && (
						<DLChip
							label="new"
							color="primary"
							size="small"
							eleTestId={props.getValue() + "_is_new"}
						/>
					)}
				</Link>
			)
		},
	},
	{
		header: "Alias ID",
		accessorKey: "aliasId",
		cell: renderCustomCell,
	},
	{
		header: "Email",
		accessorKey: "email",
		cell: renderCustomCell,
	},
	{
		header: "Title",
		accessorKey: "title",
		cell: renderCustomCell,
	},
	{
		header: "Status",
		accessorKey: "status",
		filterFn: singleSelectFilter,
		cell: renderCustomCell,
	},
	{
		header: "Org. Role",
		accessorKey: "accessRight",
		filterFn: singleSelectFilter,
		cell: renderCustomCell,
	},
	{
		header: "Archive Admin",
		accessorKey: "isArchiveAdmin",
		filterFn: singleSelectFilter,
		cell: (props: any) => {
			const isArchManager = props.getValue()
			const cellId = props.cell.column.id + "-cell"
			return (
				<div
					className="FR AC JC"
					style={{ width: "100%" }}
					data-testid={cellId}
				>
					{isArchManager === "Yes" ? "Yes" : "-"}
				</div>
			)
		},
	},
	{
		header: "Hard Copy Admin",
		accessorKey: "xxx", // Need To Update
		filterFn: singleSelectFilter,
		cell: (props: any) => {
			const isHCManager = props.row.original.isArchiveAdmin
			const cellId = "isHardCopyAdmin-cell"
			return (
				<div
					className="FR AC JC"
					style={{ width: "100%" }}
					data-testid={cellId}
				>
					{isHCManager === "Yes" ? "Yes" : "-"}
				</div>
			)
		},
	},
	// {
	// 	header: <SortingIconHeader title={i18n.twAssignedGroups || "Assigned Groups"} />,
	// 	accessor: "assignedGroups",
	// 	cell: (props: any) => {
	// 		const groupList = props.getValue()
	// 		const cellId = props.cell.id + "-cell"

	// 		return (
	// 			<>
	// 				{groupList.length !== 0 && groupList[0] !== "" ? (
	// 					groupList.map((group: string, index: number) => {
	// 						return (
	// 							<div
	// 								key={group}
	// 								style={{ marginRight: 8 }}
	// 								data-testid={cellId}
	// 							>
	// 								{group}
	// 								{index < groupList.length - 1 && ","}
	// 							</div>
	// 						)
	// 					})
	// 				) : (
	// 					<div data-testid={cellId}>-</div>
	// 				)}
	// 			</>
	// 		)
	// 	},
	// },
	{
		header: "Created By",
		accessorKey: "createdBy",
		cell: renderCustomCell,
	},
	{
		header: "Created At",
		accessorKey: "createdAt",
		filterFn: dateRangeFilter,
		cell: renderCustomCell,
	},
	{
		header: "Last Access At",
		accessorKey: "lastAccess",
		filterFn: dateRangeFilter,
		cell: renderCustomCell,
	},
	{
		header: "Action",
		accessorKey: "pending",
		enableColumnFilter: false,
		// width: 50,
		// filterable: false,
		// sortable: false,
		// show: permission.update || permission.delete,
		cell: (props: any) => {
			const cellId = "actions-cell"

			return (
				<div data-testid={cellId}>
					<DLIconButton
						variant="iconOnly"
						aria-label="more"
						clickHandler={(event: any) =>
							handleContextMenu(event, props.row.original)
						}
						eleTestId="user-ctx-btn-on-row"
					>
						<Icon path={mdiDotsVertical} size={0.8} />
					</DLIconButton>
				</div>
			)
		},
	},
]
