import React from "react"
import { Route } from "react-router-dom"
import HardCopyController from "./hcmgmt-hard-copy/HardCopyController"
import DashboardController from "./hcmgmt-dashboard/DashboardController"
import MyRentalListController from "./my-rental-list/MyRentalListController"
import ConfigurationController from "./hcmgmt-configuration/ConfigurationController"
import HardCopyDetailsPage from "./hcmgmt-hard-copy/sub-components/HardCopyDetailsPage"
import TrashController from "./hcmgmt-trash/TrashController"
import HistoryController from "./hcmgmt-history/HistoryController"
import { HcMgmtStoreProvider } from "../../../stores/hc-mgmt-store/hc-mgmt-store.provider"

export default function HardCopyManagement() {
	return (
		<HcMgmtStoreProvider>
			<Route
				exact
				path="/organization/hard-copy-management"
				component={HardCopyController}
			/>
			<Route
				exact
				path="/organization/hard-copy-management/hard-copy-list/:id"
				component={HardCopyDetailsPage}
			/>
			<Route
				exact
				path="/organization/hard-copy-management/hard-copy-list"
				component={HardCopyController}
			/>
			<Route
				exact
				path="/organization/hard-copy-management/my-rental-list"
				component={MyRentalListController}
			/>
			<Route
				exact
				path="/organization/hard-copy-management/dashboard"
				component={DashboardController}
			/>
			<Route
				exact
				path="/organization/hard-copy-management/configuration"
				component={ConfigurationController}
			/>
			<Route
				exact
				path="/organization/hard-copy-management/history"
				component={HistoryController}
			/>
			<Route
				exact
				path="/organization/hard-copy-management/hard-copy-trash"
				component={TrashController}
			/>
		</HcMgmtStoreProvider>
	)
}
