import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import {
	PageContainer,
	PageHeader,
} from "../../../../components/app-frame-elements"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import HardCopyAccessCtrlTable from "./sub-components/access-control/HardCopyAccessCtrlTable"
import AccessCtrlCtxMenus from "./sub-components/access-control/AccessCtrlCtxMenus"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import styled from "styled-components"
import SuspendOrRemoveUserDialog from "./sub-components/access-control/SuspendOrRemoveUserDialog"
import RentalHistoryDialog from "./sub-components/access-control/RentalHistoryDialog"
import RoleChangeDialog from "./sub-components/access-control/RoleChangeDialog"
import CustomColumnSettingTable from "./sub-components/custom-columns/CustomColumnSettingTable"
import { useHcMgmtStore } from "../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"
import LeftSideHardCopyConfigNav from "./sub-components/LeftSideHardCopyConfigNav"
import HardCopyConfigs from "./sub-components/settings/HardCopySettings"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import AddUserDialog from "./sub-components/access-control/AddUserDialog"

const Configuration = observer(
	({
		partialStore,
		fetchingStatus,
		permission,
		i18n,
	}: {
		partialStore: any
		fetchingStatus: ActionStatus
		permission: PermissionAsObjectProps
		i18n: DLI18nProps
	}) => {
		const store = useRootStore()
		const hcMgmtStore = useHcMgmtStore()
		const configStore = hcMgmtStore.config
		const historyStore = hcMgmtStore.history

		const subMenus = [
			{
				id: "config",
				label: "Configuration",
			},
			{
				id: "customColumn",
				label: "Custom Columns",
			},
			{
				id: "accessControl",
				label: "Access Control",
			},
		]
		const [tab, setTab] = useState<
			"config" | "customColumn" | "accessControl"
		>("config")

		const handleContextMenu = (event: any, rowInfo: any) => {
			event.preventDefault()
			partialStore.setClickPoint({
				mouseX: event.clientX - 2,
				mouseY: event.clientY - 4,
			})
			partialStore.setSelectedId(rowInfo.id)
		}
		const clickPointExist = partialStore.clickPoint.mouseX !== null
		
		let contentsAreaWidth = store.ui.contentsAreaWidth
		const rightSideWidth = contentsAreaWidth - (contentsAreaWidth / 8)

		return (
			<PageContainer
				pageTools={<div />}
				hasToolbar={false}
				fullWidth
				fixedHeight
				isLoading={fetchingStatus === "LOADING"}
			>
				<StyledHardCopyConfig className="FR JL" rightSideWidth={rightSideWidth}>
					<LeftSideHardCopyConfigNav
						tab={tab}
						setTab={setTab}
						tabs={subMenus}
					/>
					<div className="right-side-contents">
						{tab === "config" && (
							<div className="tab-contents-container">
								<HardCopyConfigs />
							</div>
						)}
						{tab === "customColumn" && (
							<div className="tab-contents-container">
								<CustomColumnSettingTable />
							</div>
						)}

						{tab === "accessControl" && (
							<div className="tab-contents-container">
								<HardCopyAccessCtrlTable
									handleContextMenu={handleContextMenu}
								/>
							</div>
						)}
					</div>
				</StyledHardCopyConfig>
				{/* ---------- context menus ---------- */}
				{clickPointExist && (
					<AccessCtrlCtxMenus
						partialStore={partialStore}
						permission={permission}
						i18n={i18n}
					/>
				)}
				{/* response snackbar  */}
				<CommonSnackbarComponent
					i18n={i18n}
					partialStore={partialStore}
				/>

				{historyStore.openRentalHistoryDialog && (
					<RentalHistoryDialog i18n={i18n} />
				)}

				{configStore.openRoleChangeDialog && (
					<RoleChangeDialog i18n={i18n} partialStore={configStore} />
				)}
				
				{configStore.openAddUserDialog && (
					<AddUserDialog i18n={i18n} partialStore={configStore} />
				)}

				{configStore.openSuspendOrRemoveUserDialog && (
					<SuspendOrRemoveUserDialog
						i18n={i18n}
						partialStore={configStore}
					/>
				)}
			</PageContainer>
		)
	}
)

export default Configuration

interface StyledHardCopyConfigProps {
	rightSideWidth: number
}

const StyledHardCopyConfig = styled.div<StyledHardCopyConfigProps>`
	padding-right: 1rem;
	padding-left: 1rem;
	.partition {
		margin: 1rem 1rem;
	}
	.tab-contents-container {
		padding: 1rem;
	}
	.right-side-contents{
		width:  ${(props) => props.rightSideWidth}px;
	}
`
