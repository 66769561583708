import { AxiosResponse } from "axios"
import {
	postRequest,
	deleteRequest,
	getRequest,
	putRequest,
} from "../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"

export async function readTrashItems(params: any) {
	let url = `/trashes`

	const response = await getRequest({
		tokenType: TOKEN_TYPE.hardCopy,
		url,
		params,
	})
	return response
}

export type HcMgmtTrashApiProps = {
	apiReadTrashItems: (params: any) => Promise<AxiosResponse<any>>
}
