import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import OnlineProjects from "./OnlineProjects"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { getDefaultFinancialYear } from "../../../../library/get-page-settings"

export default observer(function OnlineProjectsController() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.online_projects
	const actionName = "getOnlineProjects"
	const i18nSheet = OrgI18n.projects
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const needRefresh = orgStore.reportsMgmt.needRefresh
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	const orgIdForCdk = "org" + orgStore.checkin.orgInfo.orgId.slice(5)
	useEffect(() => {
		const defaultYear = getDefaultFinancialYear(menuId)
		if (menuAccess && needRefresh) {
			orgStore.reportsMgmt.getOnlineProjects(defaultYear)
			// NOTE: Get the checklist from cdk backend
			orgStore.reportsMgmt.getChecklists({ orgId: orgIdForCdk })
			orgStore.reportsMgmt.getChecklistCollections(orgIdForCdk)
			orgStore.setupEngTypes.getChecklistTemplates(orgIdForCdk)
		}
	}, [menuAccess, needRefresh])

	const handleContextMenu = (event: any, rowInfo: any) => {
		event.preventDefault()
		orgStore.reportsMgmt.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
		orgStore.reportsMgmt.setSelectedProjId(rowInfo.id)
	}

	const i18n = store.i18n.combineI18n(
		i18nSheet,
		"OnlineProjectsController" // previous name: ReportsManagementController
	)

	return (
		<>
			{menuAccess ? (
				<OnlineProjects
					partialStore={orgStore.reportsMgmt}
					fetchingStatus={orgStore.reportsMgmt.getActionStatus(
						actionName
					)}
					permission={permission}
					dateFormat={store.global.dateFormat.value}
					handleContextMenu={handleContextMenu}
					i18n={i18n}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
