import React from "react"
import { useLocalObservable } from "mobx-react-lite"
import { types } from "mobx-state-tree"
import { ActionStatus } from "../../common-models/enumerations/common-enums"
// default placeholders
import { HcMgmtDefaultModel } from "./hc-mgmt-store.model"
import { HcMgmtStoreViewModel } from "./hc-mgmt-store.view-model"
// sub stores
import HcMgmtHardCopyStoreInstance from "../../screens/organization-side/hard-copy-management/hcmgmt-hard-copy/store/hg-mgmt-hard-copy.provider"
import HcMgmtConfigStoreInstance from "../../screens/organization-side/hard-copy-management/hcmgmt-configuration/store/hc-mgmt-config.provider"
import HcMgmtHistoryStoreInstance from "../../screens/organization-side/hard-copy-management/hcmgmt-history/store/hc-mgmt-history.provider"
import HcMgmtRentalStoreInstance from "../../screens/organization-side/hard-copy-management/hcmgmt-rental/store/hc-mgmt-rental.provider"
import HcMgmtTrashStoreInstance from "../../screens/organization-side/hard-copy-management/hcmgmt-trash/store/hc-mgmt-trash.provider"

const HcMgmtDefaultStore = types
	.compose(HcMgmtDefaultModel, HcMgmtStoreViewModel)
	.named("HcMgmtDefaultStore")

export const initialStore = {
	// common parts
	actionStatus: ActionStatus.standby,
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const HcMgmtDefaultStoreInstance = HcMgmtDefaultStore.create(initialStore)

const createHcMgmtStore = () => {
	const store = {
		default: HcMgmtDefaultStoreInstance,
		//
		hardCopy: HcMgmtHardCopyStoreInstance,
		config: HcMgmtConfigStoreInstance,
		rental: HcMgmtRentalStoreInstance,
		history: HcMgmtHistoryStoreInstance,
		trash: HcMgmtTrashStoreInstance,
	}
	return store
}

type HcMgmtStoreType = ReturnType<typeof createHcMgmtStore>

const HcMgmtStoreContext = React.createContext<HcMgmtStoreType | null>(null)

const HcMgmtStoreProvider = ({ children }: { children: React.ReactNode }) => {
	const hcMgmtStore = useLocalObservable(createHcMgmtStore)

	return (
		<HcMgmtStoreContext.Provider value={hcMgmtStore}>
			{children}
		</HcMgmtStoreContext.Provider>
	)
}

const useHcMgmtStore = () => {
	const hcMgmtStore = React.useContext(HcMgmtStoreContext)
	if (!hcMgmtStore) {
		throw Error("Hard Copy Management Store is null")
	}
	return hcMgmtStore
}

export { HcMgmtStoreContext, HcMgmtStoreProvider, useHcMgmtStore }
