import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog, DLButton } from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import styled from "styled-components"
import { DLCheckbox } from "@datalobby/components/lib"
import { mdiLogin } from "@mdi/js"
import { Icon } from "@mdi/react"
import { useHcMgmtStore } from "../../../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"
import ProjectInfoDetailsContent from "../ProjectInfoDetailsContent"
import { useRootStore } from "../../../../../../stores/root-store/root-store.provider"

export default observer(function ReturnHardCopyDialog({
	i18n,
	partialStore,
	rentalStore,
}: {
	i18n: DLI18nProps
	partialStore: any
	rentalStore: any
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const hcMgmtStore = useHcMgmtStore()
	const configStore = hcMgmtStore.config

	const handleReturn = () =>
		rentalStore.returnHardCopy({
			by: {
				id: orgStore.checkin.orgInfo.userId.split("Id").join(""),
				alias: orgStore.checkin.orgInfo.userAliasId,
				name: orgStore.checkin.orgInfo.userName,
			},
			id: partialStore.selectedId,
			pk: partialStore.getHardCopyInfo.pk,
		})

	const dntFormat = store.global.getDntFormat
	return (
		<>
			<DLDialog
				eleTestId="return-hard-copy-dialog"
				isOpen={rentalStore.openReturnHardCopyDialog}
				setIsOpen={rentalStore.setOpenReturnHardCopyDialog}
				showCloseBtn={true}
				dialogTitle={
					<div className="FR AC">
						<Icon
							path={mdiLogin}
							size={1}
							style={{ marginRight: 8 }}
						/>
						Return Hard Copy
					</div>
				}
				dialogContents={
					<ReturnHardCopyDialogContent
						hardCopyDetails={partialStore
							.viewHardCopyList(dntFormat)
							.find(
								(item: any) =>
									item.id === partialStore.selectedId
							)}
						customColumns={configStore.viewCustomColumns}
					/>
				}
				actionReady={true}
				handleAction={handleReturn}
				maxWidth="sm"
				actionBtn="Return"
				cancelBtnText={i18n.twCancel || "Cancel"}
				// showSpinner={}
			/>
		</>
	)
})

const ReturnHardCopyDialogContent = ({
	hardCopyDetails,
	customColumns,
}: {
	hardCopyDetails: any
	customColumns: any
}) => {
	const {
		hasSoftCopy,
		title,
		projectAlias,
		projectName,
		qcUsers,
		epUsers,
		groupId,
		clientAlias,
		clientName,
		year,
	} = hardCopyDetails

	const customColumnsInfo = customColumns.reduce(function (r: any, e: any) {
		r[e.label] = hardCopyDetails[e.id]
		return r
	}, {})

	const projInfo = {
		hardCopyName: title,
		...(hasSoftCopy === "Yes"
			? {
					projectAlias,
					projectName,
					qcUsers,
					epUsers,
					groupId,
					clientAlias,
					clientName,
					year,
			  }
			: customColumnsInfo),
	}

	return (
		<StyledRentHardCopyDialog>
			<div className="title">{projInfo.hardCopyName}</div>
			<br />
			<div className="proj-info-section">
				<div className="label">Project Information</div>
				<ProjectInfoDetailsContent projInfo={projInfo} />
			</div>
			<div className="other-details">
				<RowItem label="Rented By" value="App Mango" />
				<RowItem label="Rental Processed By" value="Chicken Mango" />
				<RowItem label="Attachment" value="Rental request form" />
				<RowItem label="Rented At" value="2022-05-10" />
				<RowItem label="Due Date" value="2022-06-10" />
			</div>
			<div>
				<DLCheckbox
					eleTestId="confirm-checkbox"
					labelText="This return is processed by"
				/>
			</div>
		</StyledRentHardCopyDialog>
	)
}

const RowItem = ({ label, value }: { label: string; value: string }) => (
	<div className="FR row-item">
		<div className="label">{label}</div>
		{value}
	</div>
)

const StyledRentHardCopyDialog = styled.div`
	.title {
		font-size: 1rem;
		font-weight: bold;
		color: ${(props) => props.theme.emphasis};
	}
	.proj-info-section {
		.label {
			font-size: 0.8rem;
			font-weight: bold;
		}
	}
	.proj-info-details {
		padding: 10px 2px 15px 20px;
	}
	.other-details {
		.row-item {
			margin-bottom: 20px;
		}
		.label {
			font-size: 0.8rem;
			font-weight: bold;
			min-width: 15rem;
		}
	}
`
