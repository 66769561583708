import { types } from "mobx-state-tree"
import LibInProjModel from "./lib-in-proj.model"
import LibInProjViewModel from "./lib-in-proj.view-model"
import LibInFolderUiHelper from "./lib-in-proj.ui-helper"
// Model, ViewModel, UiHelper
// common
// apis

export const LibInProjStore = () =>
	types
		.compose(LibInProjModel(), LibInProjViewModel, LibInFolderUiHelper)
		.named("LibInProjStore")

export const initialStore = {
	//
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const LibInProjStoreInstance = LibInProjStore().create(initialStore)

export default LibInProjStoreInstance
