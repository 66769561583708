import React from "react"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DLIconButton } from "../../../../../components/basic-elements"
import { mdiDotsVertical } from "@mdi/js"
import { Icon } from "@mdi/react"

const HardCopyTrash = ({ handleContextMenu }: { handleContextMenu: any }) => {
	return (
		<ReactTableV8
			tableColumns={columns(handleContextMenu)}
			data={[]}
			handleContextMenu={handleContextMenu}
			menuId={DLOrgSubMenus.hard_copy}
			showPageSetting={false}
			// 			customFilters={(props: any) => filterComponent({...props, handleYearChange})}
		/>
	)
}

const columns = (
	// 	partialStore: any,
	handleContextMenu: (event: any, targetInfo: any) => void
) => [
	{
		header: "Year",
		accessorKey: "year",
		// 		filterFn: yearFilter,
	},
	{
		header: "Project Name",
		accessorKey: "projectName",
	},
	{
		header: "Project Alias",
		accessorKey: "projectAlias",
	},
	{
		header: "Client",
		accessorKey: "clientName",
	},
	{
		header: "Client Alias",
		accessorKey: "clientAlias",
	},
	{
		header: "Hard Copy Name",
		accessorKey: "name",
		// 		cell: (props: any) => {
		// 			const id = props.row.original.id
		// 			const cellId = props.cell.id + "-cell"

		// 			return (
		// 				<Link
		// 					to={`/organization/hard-copy-management/hard-copy-list/${id}`}
		// 					onClick={() =>
		// 						partialStore.setSelectedId(props.row.original.id)
		// 					}
		// 				>
		// 					<span style={{ marginRight: 4 }} data-testid={cellId}>
		// 						{props.getValue()}
		// 					</span>
		// 				</Link>
		// 			)
		// 		},
	},
	{
		header: "Hard Copy Description",
		accessorKey: "description",
	},
	{
		header: "Rental Status",
		accessorKey: "rentalStatus",
		// 		filterFn: singleSelectFilter,
	},
	{
		header: "Rented At",
		accessorKey: "rentalAt",
	},
	{
		header: "Due Date",
		accessorKey: "dueDate",
	},
	{
		header: "E.P.",
		accessorKey: "epUsers",
	},
	{
		header: "Q.C.",
		accessorKey: "qcUsers",
	},
	{
		header: "Created By",
		accessorFn: (props: any) => props.createdBy.name,
		accessorKey: "createdBy",
	},
	{
		header: "Created Date",
		accessorKey: "createdDate",
	},
	{
		header: "Action",
		accessorKey: "pending",
		enableColumnFilter: false,
		cell: (props: any) => {
			const userId = props.row.original.id
			const cellId = props.cell.id + "-cell"

			return (
				<div data-testid={cellId}>
					<DLIconButton
						variant="iconOnly"
						aria-label="more"
						clickHandler={(event: any) =>
							handleContextMenu(event, props.row.original)
						}
						eleTestId="hard-copy-ctx-btn-on-row"
					>
						<Icon path={mdiDotsVertical} size={0.8} />
					</DLIconButton>
				</div>
			)
		},
	},
]

export default HardCopyTrash
