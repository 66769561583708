import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { HardCopyMgmtHistorySourceEnum } from "../data-models/history-model"

// NOTE: This 'getHistories' means hard copy mgmt service history
// Not only the 'hard copy' in the hard copy mgmt service, but for all the others (rental, custom column...)
const GetHcMgmtHistories = (self: any) => ({
	getHcMgmtHistories(params: {
		orgId: string
		source: HardCopyMgmtHistorySourceEnum
		targetId?: string
	}) {
		console.log(params)
		// 0.
		const actionName = "getHcMgmtHistories"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.request API call
		self.readHcMgmtHistories(params)
			.then((response: any) => {
				console.log(actionName, "response:", response)
				const items = response.data.data
				
				let histories: any[] = []
				items.map((item: any) => {
					histories.push({
						actionName: item.actionName,
						createdDate: item.createdDate,
						// groupId: item.groupId,
						source: item.source,
						actionSummary: item.actionSummary,
						createdBy: item.createdBy.name,
						service: item.service,
						orgId: item.orgId,
						eventType: item.eventType,
						pk: item.pk,
						id: item.id
					})
				})
				console.log(histories)
				self.setHistories(histories)
				self.handleResponse({
					actionName,
					status: ActionStatus.success,
					code: 200, // temp
					color: MessageColorType.blue,
					open: true,
					autoHide: true,
				})
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetHcMgmtHistories
