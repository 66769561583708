export enum ActionStatus {
	standby = "STANDBY",
	loading = "LOADING",
	fail = "FAIL",
	success = "SUCCESS",
	checkout = "CHECKOUT",
}

export enum MessageColorType {
	default = "DEFAULT",
	green = "GREEN",
	blue = "BLUE",
	orange = "ORANGE",
	coral = "CORAL",
	red = "RED",
}

export const tempErrorMsg = (serverResponse: any) => {
	return (
		serverResponse.data.Message ||
		serverResponse.data.message ||
		serverResponse.Data.Message ||
		serverResponse.Data.message ||
		"Something went wrong..."
	)
}

export enum SnackbarLocation {
	rightTop = "RIGHT_TOP",
	rightBottom = "RIGHT_BOTTOM",
	leftBottom = "LEFT_BOTTOM",
}

export enum FlatItemType {
	folder = "FOLDER",
	file = "FILE",
}
export enum MenuType {
	group = "MENU_GROUP",
	sub = "SUB_MENU",
}

export enum PriorityType {
	high = "High",
	medium = "Medium",
	low = "Low",
}

export enum ProgressStatus {
	todo = "TODO",
	inProgress = "IN_PROGRESS",
	done = "DONE",
	unknown = "UNKNOWN",
}

export enum SupportedLanguage {
	us = "en-US",
	kr = "ko-KR",
}

export enum DLSector {
	// NOTE: Do not use capital here. the values are matched with i18n store's model name
	// @Noah: but.. the packages use Capital....
	global = "global",
	lobby = "lobby",
	org = "org",
	proj = "proj",
}

/**
 * Need to integrate whole file type with below
 * "DLItemFormat" seems better...?
 */
export enum DLObjectFormat {
	group = "GROUP", // NOTE: TBD
	project = "PROJECT",
	folder = "FOLDER",
	cabinet = "CABINET",
	// NOTE: TBD... for some folders which have mixed type files
	// NOTE: for --> lib-in-poj.view-model.ts > listByFileType
	//
	normalFile = "NORMAL_FILE",
	customFile = "CUSTOM_FILE", // custom format files
	// --> isn't it better to say just 'file'?
	//
	// TBD
	// apg = "AUDIT_PROGRAM",
	// aje = "ADJUSTMENT",
	// paje = "PASSED_ADJUSTMENT",
}

// NOTE: WARNING: It is hard to match with backend side enum directly because there are some difference and required updates
// Let's take individual enum here...
export enum DLSrcFormat {
	excel = "EXCEL",
	word = "WORD",
	pdf = "PDF",
	zip = "ZIP",
	// custom formats
	leadSheet = "LEAD_SHEET",
	fileGroup = "FILE_GRUOP", // UploadFile, uploaded files (bunch of uploaded files in one dl-file)
	auditProgram = "AUDIT_PROGRAM",
	fluxAnalysis = "FLUX_ANALYSIS",
	finalAnalysis = "FINAL_ANALYSIS",
	aje = "ADJUSTMENT",
	paje = "PASSED_ADJUSTMENT",
	//
	others = "OTHERS",
	//
	unknown = "UNKNOWN",
	undefined = "UNDEFINED",
}

export enum MediaTypeForApi {
	excel = "Excel",
	word = "Word",
	pdf = "Pdf",
	xbrl = "XBRL",
	others = "Others",
	hwp = "HWP",
	dart = "DART",
	ls = "Leadsheet",
	fileGroup = "UploadFile",
	apg = "Auditprogram",
	fluxAnalysis = "FluxAnalytics",
	finalAnalysis = "FinalAnalytics",
}

/**
 * - xbrl: XBRL
 * - xml: XBRL
 * - xsd: XBRL
 * - dsd: DART
 * - docx: Word
 * - xlsx: Excel
 * - odt: Others
 * - doc: Word
 * - zip: Others
 * - xls: Excel
 * - pdf: Pdf
 * - png: Others
 * - jpg: Others
 * - hwp: HWP
 */
export const fileExtensionAndMediaTypes = [
	{ extension: ".xsd", mediaType: "XBRL" },
	{ extension: ".xbrl", mediaType: "XBRL" },
	{ extension: ".xml", mediaType: "XBRL" },
	//
	{ extension: ".xls", mediaType: "Excel" },
	{ extension: ".xlsx", mediaType: "Excel" },
	//
	{ extension: ".doc", mediaType: "Word" },
	{ extension: ".docx", mediaType: "Word" },
	//
	{ extension: ".pdf", mediaType: "Pdf" },
	//
	{ extension: ".png", mediaType: "Others" },
	{ extension: ".zip", mediaType: "Others" },
	{ extension: ".csv", mediaType: "Others" }, // NOTE: Need to check is this csv format is banned or not... (angular app doesn't allow to upload csv )
	{ extension: ".jpg", mediaType: "Others" },
	{ extension: ".odt", mediaType: "Others" },
	//
	{ extension: ".dsd", mediaType: "DART" },
	{ extension: ".hwp", mediaType: "HWP" },
	//
	{ extension: "", mediaType: "Leadsheet" },
	{ extension: "", mediaType: "UploadFile" },
	{ extension: "", mediaType: "Auditprogram" },
	{ extension: "", mediaType: "FluxAnalytics" },
	{ extension: "", mediaType: "FinalAnalytics" },
]

/** from Jahnavi (backend side mediatype enum setting)
 * public enum MediaType
        {
            [Description("Auditprogram")]
            Auditprogram = 1,
            [Description("Excel")]
            Excel,
            [Description("FluxAnalysis")]
            FluxAnalysis,
            [Description("FluxAnalytics")]
            FluxAnalytics,
            [Description("Folder")]
            Folder,
            [Description("Leadsheet")]
            Leadsheet,
            [Description("Pdf")]
            Pdf,
            [Description("UploadFile")]
            UploadFile,
            [Description("Word")]
            Word,
            [Description("File")]
            File,
            [Description("Qcv")]
            Qcv,
            [Description("AuditProgramTemplate")]
            AuditProgramTemplate,
            [Description("WorkpaperTemplate")]
            WorkpaperTemplate,
            [Description("Template")]
            Template,
            [Description("TemplateFolder")]
            TemplateFolder,
            [Description("TemplateFile")]
            TemplateFile,
            [Description("TemplateParentFolder")]
            TemplateParentFolder,
            [Description("QcManual")]
            QcManual,
            [Description("WorkpaperTemplateParentFolder")]
            WorkpaperTemplateParentFolder,
            [Description("WorkPaperTempFiles")]
            WorkPaperTempFiles,
            [Description("ChecklistTemplate")]
            ChecklistTemplate,
            [Description("Checklist")]
            Checklist,
        }
 */

// NOTE: This enumeration sholud be fetched from backend
// WARNING: ...COMMENT Type seems not required.
// WARNING:--> Comment type should not exist (TBD)
// WARNING: THIS PART SHOULD BE DEPRECATED !!!!!
// WARNING: TemporaryCommentType으로 바꾸려다가 너무 일이 많아서 8ㅁ8 그냥 두는데... 바꾸는게 나은가?
export enum DLCommentType {
	// all = "ALL", // NOTE: Do not add "all" on the enum...
	normal = "NORMAL_COMMENT", // review comment
	qc = "QC_COMMENT",
}

// NOTE: 'read' is for the readonly mode dialog
// If you want to open the dialog which is easy to edit, use 'edit' than 'read'
// edit mode dialog will catch the input change and activate the edit button only when there are changes
export enum DialogActionType {
	read = "READ",
	edit = "EDIT",
	add = "ADD",
}


export enum ChecklistStatus {
	todo = "TODO",
	inProgress = "IN_PROGRESS",
	done = "DONE",
	exist = "EXIST",
	notExist = "NOT_EXIST",
}