import { flow } from "mobx-state-tree"

const ReadCustomColumns = (self: any, read: any) => ({
	readCustomColumns: flow(function* (orgId: string) {
		const actionName = "readCustomColumns"
		try {
			const response = yield read(orgId)

			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default ReadCustomColumns
