import { types } from "mobx-state-tree"
// model & view-model
import HcMgmtHistoryModel from "./hc-mgmt-history.model"
import HcMgmtHistoryViewModel from "./hc-mgmt-history.view-model"
import { initialClickPoint } from "../../../../../common-models/types/dialog.props"
import { HcMgmtHistoryApiProps, readHistories } from "./hc-mgmt-history.apis"

export const HcMgmtHistoryStore = ({
	apiReadHistories,
}: HcMgmtHistoryApiProps) =>
	types
		.compose(
			HcMgmtHistoryModel({
				apiReadHistories,
			}),
			HcMgmtHistoryViewModel
		)
		.named("HcMgmtHistoryStore")

export const initialStore = {
	clickPoint: initialClickPoint,
	// viewModel related parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const HcMgmtHistoryStoreInstance = HcMgmtHistoryStore({
	apiReadHistories: readHistories,
}).create(initialStore)

export default HcMgmtHistoryStoreInstance
