import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { ReturnHardCopyProps } from "../hc-mgmt-rental.apis"

const ReturnHardCopy = (self: any) => ({
	returnHardCopy(params: ReturnHardCopyProps) {
		// 0.
		const actionName = "returnHardCopy"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		self.mReturnHardCopy(params)
			.then((response: any) => {
				console.log(response)
				if (response.status === 200) {
					// self.setMyRentalList(response)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200, // temp
						color: MessageColorType.orange,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default ReturnHardCopy
