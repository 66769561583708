import React from "react"
import styled from "styled-components"
import { Icon } from "@mdi/react"
import { mdiArrowLeftRight } from "@mdi/js"

export default function SignOffPartWidthControl({
	handleMouseDown,
}: {
	handleMouseDown: any
}) {
	return (
		<StyledContainer onMouseDown={() => handleMouseDown("signOffArea")}>
			<Icon path={mdiArrowLeftRight} size={0.8} />
		</StyledContainer>
	)
}

const StyledContainer = styled.div`
	left: 16px;
	transition: 0.3s;
	opacity: 0.3;
	cursor: col-resize;
	:hover {
		opacity: 1;
	}
`
