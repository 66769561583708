import React from "react"
import {
	InputWithLabel,
	DLInputField,
} from "../../../../../../../components/basic-elements"

const CustomFields = ({
	fields,
	input,
	handleInput,
}: {
	fields: any[]
	input: any
	handleInput: (e: any) => void
}) => {
	return (
		<div>
			{fields.map((field) => {
				return (
					<div className="input-section FR">
						<InputWithLabel
							direction="vertical"
							label={field.label}
						>
							<DLInputField
								eleTestId={field.label}
								eleFullWidth
								eleName={field.id}
								eleValue={input[field.id]}
								eleHandleChange={handleInput}
								variant="box"
							/>
						</InputWithLabel>
					</div>
				)
			})}
		</div>
	)
}

export default CustomFields
