import { types } from "mobx-state-tree"
import { ActionStatus } from "../../../../../../common-models/enumerations/common-enums"

export enum HardCopyStatusEnum {
	stocked = "STOCKED",
	available = "AVAILABLE",
	unavailable = "UNAVAILABLE",
	readyToDelete = "READY_TO_DELETE",
	incorrect = "INCORRECT",
}

export enum HardCopyRentalStatusEnum {
	rented = "RENTED",
	overdue = "OVERDUE",
	extended = "EXTENDED",
}

const SimpleUserModel = types.model({
	id: types.string,
	alias: types.string,
	name: types.string,
})

export const HardCopyItem = types.model({
	pk: types.string,
	id: types.string,

	//
	hasSoftCopy: types.boolean,
	//
	title: "",
	description: types.union(types.null, types.string, types.undefined),
	//
	status: types.enumeration(Object.values(HardCopyStatusEnum)),
	rentalAvailable: types.boolean,
	//
	rentalStatus: types.union(
		types.null,
		types.enumeration(Object.values(HardCopyRentalStatusEnum))
	),
	rentedBy: types.union(SimpleUserModel, types.null, types.undefined),
	rentedDate: "",
	dueDate: "",
	//
	groupId: "",
	projectName: "",
	projectAlias: "",
	year: types.number,
	clientName: "",
	clientAlias: "",
	epUsers: "",
	qcUsers: "",
	//
	createdBy: SimpleUserModel,
	createdDate: types.string,
	lastModifiedBy: types.union(SimpleUserModel, types.null, types.undefined),
	lastModifiedDate: types.union(types.string, types.null, types.undefined),
	//
	// files:
	//
	lastActionName: "",
	lastActionSummary: "",
	//
	customColumns: types.array(
		types.model({
			id: types.string,
			// columnLabel: types.string,
			value: types.union(
				types.number,
				types.string,
				types.undefined,
				types.null
			),
		})
	),
})

export const MyRentalItem = types.model({
	id: types.string,
	hardCopyName: types.string,
	description: types.string,
	rentalAt: types.string,
	dueDate: types.string,
	projectName: types.string,
	projectAlias: types.string,
	clientName: types.string,
	clientAlias: types.string,
})

export type HardCopyCustomColumnType = {
	id: string
	value: string | number | undefined | null
}

export const bulkImportHc_hcWithoutScModel = types.model({
	tempId: types.string, // This is not a actual id. It is temporary ID
	// mandatory parts: title, year, status (description is optional field)
	title: types.string,
	year: types.union(types.number, types.string),
	status: types.union(
		types.enumeration<HardCopyStatusEnum>(
			Object.values(HardCopyStatusEnum)
		),
		types.string
	),
	description: types.union(types.undefined, types.null, types.string),
	//
	customColumns: types.array(
		types.model({
			id: types.string,
			// columnLabel: types.string,
			value: types.union(
				types.number,
				types.string,
				types.undefined,
				types.null
			),
		})
	),
	//
	isDuplicated: types.union(types.boolean, types.undefined),
	actionStatus: types.enumeration<ActionStatus>(Object.values(ActionStatus)),
	statusMessage: types.union(types.string, types.undefined, types.null),
})
