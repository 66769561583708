import { types, applySnapshot, IModelType } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import { initialStore } from "./hc-mgmt-config.provider"
import { CustomColumnItem } from "./data-models/custom-column-model"
import {
	HcMgmtUserDialogType as DialogType,
	HardCopyUserModel,
} from "./data-models/hard-copy-user-model"
import {
	GetCustomColumns,
	GetHardCopyUsers,
	RemoveCustomColumn,
	AddCustomColumn,
	EditCustomColumn
} from "./view-model-actions"

const HcMgmtConfigViewModel = types
	.model({
		customColumns: types.array(CustomColumnItem),
		hardCopyUsers: types.array(HardCopyUserModel),
		//
		dialogType: types.enumeration<DialogType>(Object.values(DialogType)),
		//
		clickPoint: ClickPoint,
		selectedId: "",
		// dialogs
		openCustomColumnSettingDialog: false,
		openSuspendOrRemoveUserDialog: false,
		openAddUserDialog: false,
		openRoleChangeDialog: false,
		// common parts
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setOpenCustomColumnSettingDialog(request?: boolean) {
			self.openCustomColumnSettingDialog =
				request ?? !self.openCustomColumnSettingDialog
		},
		setOpenSuspendOrRemoveUserDialog(request?: boolean) {
			self.openSuspendOrRemoveUserDialog =
				request ?? !self.openSuspendOrRemoveUserDialog
		},
		setOpenRoleChangeDialog(request?: boolean) {
			self.openRoleChangeDialog = request ?? !self.openRoleChangeDialog
		},
		setOpenAddUserDialog(request?: boolean) {
			self.openAddUserDialog = request ?? !self.openAddUserDialog
		},
	}))

	.actions((self) => ({
		setCustomColumns(customColumns: any) {
			self.customColumns = customColumns
		},
		pushItemToCustomColumns(customColumn: any) {
			self.customColumns.push(customColumn)
		},
		setHardCopyUsers(hardCopyUsers: any) {
			self.hardCopyUsers = hardCopyUsers
		},
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setDialogType(type: DialogType) {
			self.dialogType = type
		},
		updateCustomColumns(colId: string, colData: any){
			const targetIndex = self.customColumns.findIndex(
				(col) => col.id === colId
			)
			self.customColumns[targetIndex] = {
				...self.customColumns[targetIndex],
				...colData
			}
		}
	}))
	.actions((self) => ({
		getCustomColumnsForBulkImport() {
			const columns = self.customColumns
			let columnsEmptyObject: any = {}
			let columnsEmptyArray: { id: string; value: string }[] = []
			let columnIdsAndLabels: { label: string; id: string }[] = []

			columns.map((column: any) => {
				columnsEmptyObject[column.label] = ""
				//
				columnsEmptyArray.push({
					id: column.id,
					value: "",
				})
				//
				columnIdsAndLabels.push({
					label: column.label,
					id: column.id,
				})
			})

			return { columnsEmptyObject, columnsEmptyArray, columnIdsAndLabels }
		},
	}))
	.views((self) => ({
		get viewCustomColumns() {
			return self.customColumns.map((item: any) => ({
				id: item.id,
				label: item.label,
			}))
		},
		viewCustomColumnsList() {
			return self.customColumns
		},
		viewCustomColumnById(id: string) {
			return self.customColumns.find((item: any) => item.id === id)
		},
		get ccHeadersObj() {
			const headersObj: { [x: string]: string } = {}
			self.customColumns.map(
				(item: any) => (headersObj[item.id] = item.label)
			)

			console.log("ccHeadersObj", headersObj)
			return headersObj
		},
		get revertedCcHeadersObj() {
			const headersObj: { [x: string]: string } = {}
			self.customColumns.map(
				(item: any) => (headersObj[item.label] = item.id)
			)

			console.log("revertedCcHeadersObj", headersObj)
			return headersObj
		},
	}))
	// * API related parts
	// --- Configs > Custom Columns
	.actions(GetCustomColumns)
	.actions(AddCustomColumn)
	.actions(EditCustomColumn)
	.actions(RemoveCustomColumn)
	// --- Configs > Access
	.actions(GetHardCopyUsers)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default HcMgmtConfigViewModel
