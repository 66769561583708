import React from "react"
import {
	DLIconButton,
	DLButton,
	ConsoleLog,
} from "../../../../components/basic-elements"
import { mdiDownload } from "@mdi/js"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiPencil, mdiTrashCanOutline } from "@mdi/js"
import { Link } from "react-router-dom"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { InputFieldForTableV8 } from "../../../../components/basic-elements/tables/InputFieldForTable"
import { singleSelectFilter } from "../../../../library/react-table-utils"
import { Column } from "@tanstack/react-table"

export default function Rationales({
	partialStore,
	permission,
	formattedList,
	userList,
	isLocked,
	finalReportDate,
	i18n,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
	formattedList: any
	userList: any
	isLocked: boolean
	finalReportDate: any
	i18n: DLI18nProps
}) {
	const projStore = useProjStore()
	const defaultUrl = projStore.checkin.defaultUrl
	ConsoleLog(" ______ Rationales Rendering _____ ")

	const handleReset = (itemId: string) => {
		let proceed = window.confirm(
			"Do you really want to reset this rationale?"
		)
		if (proceed === false) {
			return
		} else {
			partialStore.removeRationale(itemId)
		}
	}
	const diff = partialStore.diff

	return (
		<StyledRationales>
			<div className="FR AC JSB">
				<div className="info-area">
					<p>
						<span style={{ fontWeight: "bold" }}>
							{i18n.twFinalReportDate}
						</span>
						:{" "}
						<span data-testid="final-report-date">
							{finalReportDate}
						</span>
						<span
							className={
								diff > 0 ? "overdue diff-days" : "diff-days"
							}
						>
							(
							<span data-testid="diff-days">
								{diff > 0 ? `+${diff}` : diff}
							</span>{" "}
							{diff === 0 || Math.abs(diff) === 1
								? "day"
								: "days"}
							)
						</span>
					</p>

					<p>
						{i18n.tsHeader1}:{" "}
						<span data-testid="modified-files-count">
							{partialStore.modifiedFilesCount}
						</span>
					</p>

					<p>
						{i18n.tsHeader2}:{" "}
						<span data-testid="completed-rationales-count">
							{partialStore.completedRationaleCount}
						</span>{" "}
						/{" "}
						<span data-testid="required-rationales-count">
							{partialStore.rationaleList.length}
						</span>
					</p>
				</div>
				<div>
					{permission.export && (
						<DLButton
							eleTestId="export-rationale-btn"
							startIcon={<Icon path={mdiDownload} size={0.8} />}
							clickHandler={() =>
								partialStore.downloadRationales()
							}
						>
							Download
						</DLButton>
					)}
				</div>
			</div>
			{defaultUrl ? (
				<ReactTableV8
					tableColumns={Columns(
						handleReset,
						permission,
						isLocked,
						i18n,
						defaultUrl
					)}
					data={formattedList}
					menuId={DLProjSubMenus.rationales}
					showPageSetting={false}
					customFilters={(props: any) =>
						filterComponent({ ...props, userList })
					}
					hasPagination={false}
					height={750}
				/>
			) : (
				<div>
					Project ID is incorrect. Please refresh or reopen this
					project.
				</div>
			)}

			{/* ----- response snackbar ----- */}
			<CommonSnackbarComponent i18n={i18n} partialStore={partialStore} />
		</StyledRationales>
	)
}
const filterComponent = ({
	column,
	userList,
}: {
	column: Column<any>
	userList: any
}) => {
	switch (column.id) {
		case "type":
			return (
				<select
					onChange={(event: any) => {
						column.setFilterValue(event.target.value)
					}}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "all") as string}
					data-testid="type-select"
				>
					<option value="all">All</option>
					<option value="WP">Workpaper</option>
					<option value="PF">Permanent Files</option>
				</select>
			)

		case "modifiedBy":
		case "rationaleBy":
			return (
				<select
					onChange={(event: any) => {
						column.setFilterValue(event.target.value)
					}}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "all") as string}
					data-testid={`${column.id}-select`}
				>
					<option value="all">All</option>
					{userList.map((user: any) => {
						return <option value={user}>{user}</option>
					})}
				</select>
			)

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const Columns = (
	handleReset: (itemId: string) => void,
	permission: PermissionAsObjectProps,
	isLocked: boolean,
	i18n: DLI18nProps,
	defaultUrl: string
) => {
	const columns = [
		{
			header: "no.",
			accessorKey: "x",
			size: 20,
			enableColumnFilter: false,
			cell: (props: any) => {
				return props.row.index + 1
			},
		},
		{
			header: i18n.twType,
			accessorKey: "type",
			cell: (props: any) => {
				const aliasId = props.row.original.aliasId
				return (
					<div data-testid={"fileMenu-cell-" + aliasId}>
						{props.getValue()}
					</div>
				)
			},
			filterFn: singleSelectFilter,
		},
		{
			header: i18n.twRefNum,
			accessorKey: "aliasId",
			cell: (props: any) => {
				return (
					<div data-testid={"aliasId-cell-" + props.getValue()}>
						{props.getValue()}
					</div>
				)
			},
		},
		{
			header: i18n.twFileDesc,
			accessorKey: "description", // NOTE: Update as title
			cell: (props: any) => {
				const aliasId = props.row.original.aliasId
				return (
					<div data-testid={"fileTitle-cell-" + aliasId}>
						{props.getValue()}
					</div>
				)
			},
		},

		{
			header: i18n.twModifiedBy,
			accessorKey: "modifiedBy",
			cell: (props: any) => {
				const aliasId = props.row.original.aliasId
				return (
					<div data-testid={"modifiedBy-cell-" + aliasId}>
						{props.getValue()}
					</div>
				)
			},
			filterFn: singleSelectFilter,
		},
		{
			header: i18n.twAt,
			accessorKey: "modifiedAt",
			cell: (props: any) => {
				const aliasId = props.row.original.aliasId
				return (
					<div data-testid={"modifiedAt-cell-" + aliasId}>
						{props.getValue()}
					</div>
				)
			},
		},
		{
			header: i18n.twRationale,
			accessorKey: "rationaleDescription",
			cell: (props: any) => {
				const { podId: parentId, aliasId } = props.row.original.podId
				return (
					<Link
						to={`${defaultUrl}/archive/rationales/${parentId}`}
						data-testid={"rationale-contetns-cell-" + aliasId}
					>
						{props.getValue()}
					</Link>
				)
			},
		},
		{
			header: i18n.twBy,
			accessorKey: "rationaleBy",
			cell: (props: any) => {
				const aliasId = props.row.original.aliasId
				return (
					<div data-testid={"createdBy-cell-" + aliasId}>
						{props.getValue()}
					</div>
				)
			},
			filterFn: singleSelectFilter,
		},
		{
			header: i18n.twCreatedAt,
			accessorKey: "rationaleAt",
			cell: (props: any) => {
				const aliasId = props.row.original.aliasId
				return (
					<div data-testid={"createdAt-cell-" + aliasId}>
						{props.getValue()}
					</div>
				)
			},
		},
	]

	if (!isLocked) {
		columns.push({
			header: "Action",
			accessorKey: "xxx",
			size: 50,
			enableColumnFilter: false,
			cell: (props: any) => {
				console.log(props.row.original)
				const {
					podId: parentId,
					rationaleId,
					aliasId,
				} = props.row.original
				return (
					<>
						{permission.update && (
							<DLIconButton
								eleClassName={`restore-wp-${rationaleId}`}
								tooltipText="Update rationale"
								eleTestId={"rationale-edit-btn-" + aliasId}
							>
								<Link
									to={`${defaultUrl}/archive/rationales/edit/${parentId}`}
								>
									<Icon path={mdiPencil} size={0.8} />
								</Link>
							</DLIconButton>
						)}

						{permission.delete && (
							<DLIconButton
								eleTestId={"rationale-delete-btn-" + aliasId}
								eleClassName={`remove-wp-${rationaleId}`}
								tooltipText="Reset the rationale contents"
								disabled={
									props.row.original.rationaleDescription ===
									""
								}
								clickHandler={() => handleReset(rationaleId)}
							>
								<Icon path={mdiTrashCanOutline} size={0.8} />
							</DLIconButton>
						)}
					</>
				)
			},
		})
	}
	return columns
}

const StyledRationales = styled.div`
	.info-area {
		padding-left: 2rem;
		.diff-days {
			margin-left: 0.6rem;
		}
		.overdue {
			color: ${(props) => props.theme.warning};
		}
	}
`
