import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog, DLButton } from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import ChecklistDialogContent from "./ChecklistDialogContent"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"

export default observer(function ChecklistDialog({
	i18n,
	partialStore,
}: {
	i18n: DLI18nProps
	partialStore: any
}) {
	const orgStore = useOrgStore()

	useEffect(() => {
		const orgIdForCdk = "org" + orgStore.checkin.orgInfo.orgId.slice(5)
		const checklistId = orgStore.reportsMgmt.getSelectedChecklistId()
		if (checklistId) {
			partialStore.getChecklists({ orgId: orgIdForCdk, id: checklistId })
		} else {
			// TODO:
		}
	}, [])
	
	const projInfo = partialStore.viewSelectedProj()

	return (
		<>
			<DLDialog
				eleTestId="checklist-detail-dialog"
				isOpen={partialStore.openChecklistDialog}
				setIsOpen={partialStore.setOpenChecklistDialog}
				showCloseBtn={true}
				dialogTitle={`Checklist - ${projInfo.title}`}
				dialogContents={
					<>
						{partialStore.getActionStatus("getChecklists") ===
							"SUCCESS" && (
							<ChecklistDialogContent
								partialStore={partialStore}
								selectedProjId={partialStore.selectedProjId}
							/>
						)}
					</>
				}
				// actionReady={isReady || userEmail === inputs.email.value}
				maxWidth="md"
				cancelBtnText={i18n.twCancel || "Cancel"}
				showSpinner={partialStore.getActionStatus("getChecklists") === "LOADING"}
			/>
		</>
	)
})
