import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import { initialStore } from "./hg-mgmt-hard-copy.provider"
import {
	HardCopyItem,
	MyRentalItem,
	bulkImportHc_hcWithoutScModel,
    HardCopyRentalStatusEnum,
} from "./data-models/hard-copy-list.data-models"
import {
	GetHardCopyList,
	GetMyRentalList,
	//
	AddHardCopy,
	AddHardCopyWithoutSoftCopy,
	//
	EditHardCopyDetail,
	EditHardCopyStatus,
	//
	MoveToTrashHardCopy,
	//
	ReflectHardCopyUpdateActions,
	BulkImportActions,
	//
} from "./view-model-actions"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { sortBy } from "@datalobby/common/lib"
import { getTzDate, getTzDateAndTime } from "../../../../../library/converters/date-utc-converter"
import { DnTFormatProps } from "../../../../../common-models/types/common-props"

const HcMgmgtHardCopyViewModel = types
	.model({
		hardCopyList: types.array(HardCopyItem),
		myRentalList: types.array(MyRentalItem),
		// shared parts
		clickPoint: ClickPoint,
		selectedId: "",
		selectedItems: types.array(types.string),
		// bulk import
		bulkImportHc_hcWithoutScList: types.array(
			bulkImportHc_hcWithoutScModel
		),
		bulkImportHc_readLocalFileStatus: types.enumeration<ActionStatus>(
			Object.values(ActionStatus)
		),
		bulkImportHc_successList: types.array(HardCopyItem), // for temporary saving to update the main hard copy list
		// dialogs
		openEditHardCopyStatusDialog: false,
		openEditHardCopyDetailDialog: false,
		openAddHardCopyDialog: false,
		openAddHardCopyWithoutSoftCopyDialog: false,
		openBulkAddHardCopyWithoutSoftCopyDialog: false,
		// common parts
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setOpenEditHardCopyStatusDialog(request?: boolean) {
			self.openEditHardCopyStatusDialog =
				request ?? !self.openEditHardCopyStatusDialog
		},
		setOpenEditHardCopyDetailDialog(request?: boolean) {
			self.openEditHardCopyDetailDialog =
				request ?? !self.openEditHardCopyDetailDialog
		},
		setOpenAddHardCopyDialog(request?: boolean) {
			self.openAddHardCopyDialog = request ?? !self.openAddHardCopyDialog
		},
		setOpenAddHardCopyWithoutSoftCopyDialog(request?: boolean) {
			self.openAddHardCopyWithoutSoftCopyDialog =
				request ?? !self.openAddHardCopyWithoutSoftCopyDialog
		},
		setOpenBulkAddHardCopyWithoutSoftCopyDialog(request?: boolean) {
			self.openBulkAddHardCopyWithoutSoftCopyDialog =
				request ?? !self.openBulkAddHardCopyWithoutSoftCopyDialog
		},
	}))
	.actions(ReflectHardCopyUpdateActions)
	.actions(BulkImportActions)
	.actions((self) => ({
		pushItemToHardCopyList(newHardCopy: any) {
			self.hardCopyList.push(newHardCopy)
		},
		reflectItemToHardCopyList(updatedHardCopy: any) {
			const index = self.hardCopyList.findIndex(
				(item: any) => item.id === updatedHardCopy.id
			)
			self.hardCopyList[index] = updatedHardCopy
		},
		reflectRentalToHardCopy(newRental: any) {
			const index = self.hardCopyList.findIndex(
				(item: any) => item.id === newRental.hardCopyId
			)
			console.log(newRental.rentedBy)
			self.hardCopyList[index].rentalStatus = HardCopyRentalStatusEnum.rented
			self.hardCopyList[index].rentalAvailable = false
			self.hardCopyList[index].rentedBy = { 
				id: newRental.rentedBy.id,
				alias: newRental.rentedBy.aliasId || newRental.rentedBy.alias,
				name: newRental.rentedBy.name
			}
			self.hardCopyList[index].dueDate = newRental.dueDate
			self.hardCopyList[index].rentedDate = newRental.createdDate
		},
	}))
	.actions((self) => ({
		setHardCopyList(hardCopyList: any) {
			console.log("setHardCopyList:", hardCopyList)

			self.hardCopyList = hardCopyList
		},
		setMyRentalList(myRentalList: any) {
			self.myRentalList = myRentalList
		},
		setSelectedId(id: string) {
			self.selectedId = id
		},
		setSelectedItems(items: any) {
			console.log(items)
			self.selectedItems = items
		},
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
	}))
	.views((self) => ({
		get viewBulkHardCopyList() {
			const importedData = self.bulkImportHc_hcWithoutScList.map(
				({ customColumns, ...items }) => {
					const cols = Object.assign(
						{},
						...customColumns.map((col: any) => ({
							[col.id]: col.value,
						}))
					)
					return {
						...items,
						...cols,
					}
				}
			)
			// console.log("viewBulkHardCopyList > importedData", importedData)
			return importedData
		},
		getIsRented() {
			return !self.hardCopyList.find(
				(item: any) => item.id === self.selectedId
			)?.rentalAvailable
		},
		getHardCopyStatus() {
			return self.hardCopyList.find(
				(item: any) => item.id === self.selectedId
			)?.status
		},
		getHardCopyInfo() {
			return self.hardCopyList.find(
				(item: any) => item.id === self.selectedId
			)
		},
		getHardCopyCustomFields() {
			// below array is by {id, value} object
			const customColumns = self.hardCopyList.find(
				(item: any) => item.id === self.selectedId
			)?.customColumns

			let newArray: any = []
			if (customColumns) {
				customColumns.map((column: { id: string; value: any }) => {
					newArray[column.id] = column.value
				})
			} else {
				newArray = []
			}

			return newArray
		},
		viewSelectedItem() {
			return self.hardCopyList.find(
				(item: any) => item.id === self.selectedId
			)
		},
		getSelectedItems(){
			return self.selectedItems
		},

		get totalHardCopiesCount() {
			return self.hardCopyList.length
		},
		viewHardCopyList(dntFormat: DnTFormatProps) {
			const { dateFormat, timeFormat, timeZone } = dntFormat
			function formatDate(date: string | null) {
				if (!date) {
					return ""
				}
				// return getTzDate({
				// 	date,
				// 	timeZone,
				// 	dateFormat,
				// })
				return getTzDateAndTime({
					date,
					timeZone,
					dateFormat,
					timeFormat,
				})
			}
			const hardCopies = self.hardCopyList.map(
				({ customColumns, ...items }) => {
					const cols = Object.assign(
						{},
						...customColumns.map((col: any) => ({
							[col.id]: col.value,
						}))
					)
					return {
						...items,
						createdDate: formatDate(items.createdDate),
						hasSoftCopy: items.hasSoftCopy ? "Yes" : "No",
						...cols,
					}
				}
			)
			const sortedHardCopies = sortBy(hardCopies, "createdDate", false)

			return sortedHardCopies
		},
	}))
	// * API related parts
	// --- Hard Copy
	.actions(AddHardCopy)
	.actions(AddHardCopyWithoutSoftCopy)
	.actions(GetHardCopyList)
	.actions(EditHardCopyDetail)
	.actions(EditHardCopyStatus)
	.actions(MoveToTrashHardCopy)
	// --- Rental
	.actions(GetMyRentalList)

	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default HcMgmgtHardCopyViewModel
