import React, { useState } from "react"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { Tabs, Tab } from "@material-ui/core"
import { PageContainer } from "../../../../components/app-frame-elements"
import HardCopyTrash from "./sub-components/HardCopyTrash"
import CustomColumnTrash from "./sub-components/CustomColumnTrash"
import styled from "styled-components"
import TrashCtxMenus from "./sub-components/TrashCtxMenus"

enum TABS {
	HARD_COPY = 0,
	CUSTOM_COLUMN = 1
}

const Trash = ({
	partialStore,
	permission,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
}) => {
	const [selectedTab, setSelectedTab] = useState<TABS>(TABS.HARD_COPY)
	
	const handleContextMenu = (event: any, rowInfo: any) => {
		event.preventDefault()
		partialStore.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
		partialStore.setSelectedId(rowInfo.id)
	}
	const clickPointExist = partialStore.clickPoint.mouseX !== null
	
    return (
    	<PageContainer
			pageTools={<div />}
			hasToolbar={false}
			fullWidth
			fixedHeight
			// isLoading={
			// 	fetchingStatus === "LOADING"
			// }
		>
	    	<StyledTrash>
				<Tabs
					value={selectedTab}
					onChange={(e: any, value: any) =>
						setSelectedTab(selectedTab === TABS.HARD_COPY ? TABS.CUSTOM_COLUMN : TABS.HARD_COPY)
					}
					className="hard-copy-trash-tabs"
					indicatorColor="primary"
					textColor="primary"
				>
					<Tab
						label="Hard Copy"
						data-testid="hard-copy-tab"
						className={
							"trash-tab " +
							(selectedTab === TABS.HARD_COPY ? "selected" : "")
						}
					/>
					<Tab
						label="Custom Columns"
						data-testid="custom-column-tab"
						className={
							"trash-tab " +
							(selectedTab === TABS.CUSTOM_COLUMN ? "selected" : "")
						}
					/>
				</Tabs>	
				{selectedTab === TABS.HARD_COPY && (
					<HardCopyTrash handleContextMenu={handleContextMenu} />
				)}
				{selectedTab === TABS.CUSTOM_COLUMN && (
					<CustomColumnTrash handleContextMenu={handleContextMenu} />
				)}
				
				{/* ---------- context menus ---------- */}
				{clickPointExist && (
					<TrashCtxMenus
						partialStore={partialStore}
						permission={permission}
					/>
				)}
			</StyledTrash>
		</PageContainer>
    )
}

export default Trash

export const StyledTrash = styled.div`
	padding: 0 1rem;
	.hard-copy-trash-tabs {
		min-height: 2rem;
		.trash-tab {
			min-width: 50%;
			border-top: 1px solid transparent;
			border-right: 1px solid transparent;
			border-left: 1px solid transparent;
			border-bottom: 1px solid ${(props) => props.theme.emphasis};
			min-height: 2rem;
			border-top-right-radius: 3px;
			border-top-left-radius: 3px;
			background-color: ${(props) => props.theme.shade05};
			font-size: 0.75rem;
			overflow: hidden;
			color: ${(props) => props.theme.textMediumEmphasis};
			&.selected {
				color: ${(props) => props.theme.emphasis};
				font-weight: ${(props) => props.theme.shared.fontBold};
				border-top: 1px solid ${(props) => props.theme.emphasis};
				border-right: 1px solid ${(props) => props.theme.emphasis};
				border-left: 1px solid ${(props) => props.theme.emphasis};
				border-bottom: 1px solid transparent;
				background-color: ${(props) => props.theme.background};
			}
			span {
				/* for the border bottom highlighter */
				background-color: transparent;
			}
		}
	}
`