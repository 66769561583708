import React from "react"
import { observer } from "mobx-react-lite"
import { DLButton } from "../../../../../components/basic-elements"
import { mdiDotsHorizontal } from "@mdi/js"
import { Icon } from "@mdi/react"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"

export default observer(function ChecklistsTable({
	partialStore,
	contentHeight,
	handleContextMenu,
}: {
	partialStore: any
	contentHeight: number
	handleContextMenu: (event: any, targetInfo: any) => void
}) {
	return (
		<ReactTableV8
			tableColumns={ChecklistsTableColumns({
				partialStore,
				handleContextMenu,
			})}
			data={partialStore.checklists}
			menuId="ChecklistsTable"
			showPageSetting={false}
			height={contentHeight}
			handleContextMenu={handleContextMenu}
			getRowClass={(row: any) => {
				if (row.original.fixToTop) {
					return "fixToTop"
				}
				return ""
			}}
		/>
	)
})

const ChecklistsTableColumns = ({
	partialStore,
	handleContextMenu,
}: {
	partialStore: any
	handleContextMenu: any
}) => [
	{
		header: "Checklist Name",
		accessorKey: "name",
		Cell: (row: any) => {
			return <div data-testid="checklist-name-cell">{row.value}</div>
		},
	},
	{
		header: "Action",
		accessorKey: "xxx",
		enableColumnFilter: false,
		enableSorting: false,
		cell: (props: any) => {
			const handleClick = (event: any) => {
				// partialStore.setSelectedItem({
				// 	id,
				// })
				handleContextMenu(event, props.row)
			}
			return (
				<div style={{ width: "100%", textAlign: "center" }}>
					<DLButton
						variant="text"
						eleTestId="project-list-context-btn"
						clickHandler={(event: any) => handleClick(event)}
					>
						<Icon path={mdiDotsHorizontal} size={0.8} />
					</DLButton>
				</div>
			)
		},
	},
]
