import React, { useEffect } from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { DLSpinner } from "../../../../../components/basic-elements"
import { SignoffModel2Props } from "../store/data-models/signoff-history.data-model"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"

function formatSignoff(info: SignoffModel2Props | null, column: string) {
	let signoff = ""

	if (info !== null && info !== undefined) {
		if (info.isNegative) {
			signoff = "-- (" + info.role + ") " + info.by
			return (
				<div
					data-testid={`signoff-${column}`}
					className="FR AC"
					style={{ fontStyle: "italic", opacity: 0.5 }}
				>
					{signoff}
				</div>
			)
		} else {
			signoff = "(" + info.role + ") " + info.by
			return (
				<div data-testid={`signoff-${column}`} className="FR AC">
					{signoff}
				</div>
			)
		}
	}
}

export default observer(function SignOffHistoryDetailMore({
	fileId,
	fromTable,
}: {
	fileId: string
	fromTable: boolean
}) {
	const store = useRootStore()
	const projStore = useProjStore()
	const currentMenu = projStore.signOff.selectedTab
	const actionName = "GetSignoffHistoryDetail" + currentMenu + fileId
	//
	useEffect(() => {
		projStore.signOff.getSignoffHistoryDetail(fileId, currentMenu)
	}, [fileId])

	const dntFormat = store.global.getDntFormat
	// const dnt = store.global.getDntFormat
	const history = projStore.signOff.viewDetailHistory(
		fileId,
		projStore.signOff.selectedTab,
		dntFormat
		// dnt.dateFormat,
		// dnt.timeFormat
	)
	const status = projStore.signOff.getActionStatus(actionName)
	// ConsoleLog(["history on the comp", history])
	return (
		<StyledDetailHistory
			className="detail-history-table"
			fromTable={fromTable}
		>
			{!fromTable && status === "LOADING" && (
				<div
					style={{ width: "100%", marginTop: "3rem" }}
					className="FR AC JC"
				>
					{" "}
					<DLSpinner />
				</div>
			)}
			{status === "SUCCESS" && <SignoffDetailTable data={history} />}
		</StyledDetailHistory>
	)
})

export const SignoffDetailTable = ({ data }: { data: any }) => {
	return (
		<StyledSignoffDetailTable>
			<ReactTableV8
				tableColumns={columns}
				data={data}
				hasPagination={false}
				showPageSetting={false}
				height={data.length * 90}
				menuId="FileInformation"
			/>
		</StyledSignoffDetailTable>
	)
}

const columns = [
	{
		header: "Date",
		accessorKey: "at",
		cell: (props: any) => {
			return <span data-testid="signoff-date">{props.getValue()}</span>
		},
	},
	{
		header: "Prepare",
		accessorKey: "prepare",
		cell: (props: any) => {
			return <>{formatSignoff(props.getValue(), "prepare")}</>
		},
	},
	{
		header: "Review",
		accessorKey: "review",
		cell: (props: any) => {
			return <>{formatSignoff(props.getValue(), "review")}</>
		},
	},
	{
		header: "EP Review",
		accessorKey: "epReview",
		cell: (props: any) => {
			return <>{formatSignoff(props.getValue(), "epReivew")}</>
		},
	},
	{
		header: "CP Review",
		accessorKey: "cpReview",
		cell: (props: any) => {
			return <>{formatSignoff(props.getValue(), "cpReview")}</>
		},
	},
	{
		header: "QC Review",
		accessorKey: "qcReview",
		cell: (props: any) => {
			return <>{formatSignoff(props.getValue(), "qcReview")}</>
		},
	},
]

interface StyledProps {
	fromTable: boolean
}

const StyledDetailHistory = styled.div<StyledProps>`
	background-color: ${(props) => props.fromTable && props.theme.secondary};
	${({ fromTable }) =>
		fromTable
			? `
    padding-left: 6rem;
  `
			: `padding:1rem;
			margin-top: 2rem;
			width: 100%;`}
`

const StyledSignoffDetailTable = styled.div`
	table {
		thead > tr > th {
			// background-color: ${(props) => props.theme.secondary};
			padding: 0;
			.header {
				min-height: 2rem;
				padding-left: 8px;
				justify-content: center;
			}
		}
		tbody {
			.MuiTableCell-root {
				padding: 8px;
			}
		}
	}
`
