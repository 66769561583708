import React from "react"
import { DLContextMenu } from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
	RestoreIcon,
	IconPlaceholder,
} from "../../../../../components/basic-elements/icons/common-icons"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { useHcMgmtStore } from "../../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"
import {
	HardCopyStatusEnum,
	HardCopyRentalStatusEnum,
} from "../store/data-models/hard-copy-list.data-models"

export default observer(function HardCopyListCtxMenus({
	partialStore,
	permission,
	i18n,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) {
	const orgStore = useOrgStore()
	const hcMgmtStore = useHcMgmtStore()
	const hcStore = hcMgmtStore.hardCopy
	const historyStore = hcMgmtStore.history
	const rentalStore = hcMgmtStore.rental
	const hardCopy = hcStore.getHardCopyInfo()

	const handleClose = () =>
		partialStore.setClickPoint({
			mouseX: null,
			mouseY: null,
		})

	const handleMoveToTrash = () => {
		let proceed = window.confirm(
			"Do you want to move this hard copy to trash?"
		)

		const userInfo = {
			id: orgStore.checkin.orgInfo.userId.split("Id").join(""),
			alias: orgStore.checkin.orgInfo.userAliasId,
			name: orgStore.checkin.orgInfo.userName,
		}
		if (proceed && hardCopy) {
			// partialStore.moveToTrashHardCopy(partialStore.selectedItem)
			partialStore.moveToTrashHardCopy({
				id: hardCopy.id,
				pk: hardCopy.pk,
				by: userInfo,
			})
		}
	}

	/**
	 * - rentalAvailable: boolean
	 * - rentalStatus: null | RENTED | OVERDUE | EXTENDED
	 * - status: STOCKED | AVAILABLE | UNAVAILABLE | READY_TO_DELETE | INCORRECT
	 */
	const isRented = hardCopy?.rentalAvailable
	// const rentalAvailable =
	
	const itemCount = hcStore.selectedItems.length
	console.log(itemCount, hcStore.getSelectedItems(), "rentalAvailable")

	const menuOptions = [
		{
			label: "Edit Status" + (itemCount > 1 ? " (" + itemCount + ")" : ""),
			value: "edit-hard-copy-status",
			available: permission.update,
			disabled: hardCopy?.rentalStatus !== null, // unable to update the status when the hard copy is rented
			clickEvent: () =>
				partialStore.setOpenEditHardCopyStatusDialog(true),
			icon: <IconPlaceholder />,
		},
		{
			label: "Edit Detail",
			value: "edit-hard-copy-detail",
			available: permission.update && itemCount < 1,
			clickEvent: () =>
				partialStore.setOpenEditHardCopyDetailDialog(true),
			icon: <IconPlaceholder />,
		},
		{
			label: "Rent Hard Copy",
			value: "rent-hard-copy",
			available: permission.update,
			disabled:
				!hardCopy?.rentalAvailable ||
				hardCopy?.rentalStatus !== null ||
				hardCopy?.status !== HardCopyStatusEnum.available,
			clickEvent: () => rentalStore.setOpenRentHardCopyDialog(true),
			icon: <IconPlaceholder />,
		},
		{
			label: "Return Hard Copy",
			value: "return-hard-copy",
			available: permission.update,
			disabled:
				hardCopy?.rentalStatus === null ||
				hardCopy?.status !== HardCopyStatusEnum.available,
			clickEvent: () => rentalStore.setOpenReturnHardCopyDialog(true),
			icon: <IconPlaceholder />,
		},
		{
			label: "View History" + (itemCount > 1 ? " (" + itemCount + ")" : ""),
			value: "hard-copy-history",
			available: permission.read,
			clickEvent: () => historyStore.setOpenHardCopyHistoryDialog(true),
			icon: <IconPlaceholder />,
		},
		{
			label: "Rental History" + (itemCount > 1 ? " (" + itemCount + ")" : ""),
			value: "rental-history",
			available: permission.read,
			clickEvent: () => historyStore.setOpenRentalHistoryDialog(true),
			icon: <IconPlaceholder />,
		},
		{
			label: "Connect Soft Copy" + (itemCount > 1 ? " (" + itemCount + ")" : ""),
			value: "connect-soft-copy",
			available: permission.update,
			disabled: true,
			clickEvent: () => {},
			icon: <IconPlaceholder />,
		},
		{
			label: "Remove" + (itemCount > 1 ? " (" + itemCount + ")" : ""),
			value: "remove-hard-copy",
			available: permission.delete,
			disabled: hardCopy?.rentalStatus !== null,
			clickEvent: handleMoveToTrash,
			icon: <IconPlaceholder />,
		},
	]

	return (
		<DLContextMenu
			eleId="hard-copy-list-ctx-menus"
			clickPoint={{
				mouseX: partialStore.clickPoint.mouseX,
				mouseY: partialStore.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions}
			hasDivider={[1, 3, 5]}
		/>
	)
})
