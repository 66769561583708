import React from "react"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import {
	PageContainer,
	PageHeader,
} from "../../../../components/app-frame-elements"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import HardCopyListTable from "../hcmgmt-hard-copy/sub-components/HardCopyListTable"
import styled from "styled-components"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

const MyRentalList = observer(
	({
		partialStore,
		fetchingStatus,
		permission,
		i18n,
	}: {
		partialStore: any
		fetchingStatus: ActionStatus
		permission: PermissionAsObjectProps
		i18n: DLI18nProps
	}) => {
		const handleContextMenu = (event: any, rowInfo: any) => {
			event.preventDefault()
			partialStore.setClickPoint({
				mouseX: event.clientX - 2,
				mouseY: event.clientY - 4,
			})
			partialStore.setSelectedId(rowInfo.original.id)
		}
		const clickPointExist = partialStore.clickPoint.mouseX !== null

		return (
			<PageContainer
				pageTools={<div />}
				hasToolbar={false}
				fullWidth
				fixedHeight
				isLoading={fetchingStatus === "LOADING"}
			>
				<StyledMyRentalList>
					<HardCopyListTable
						partialStore={partialStore}
						permission={permission}
						handleContextMenu={handleContextMenu}
						menuId={DLOrgSubMenus.my_rental_list}
					/>
				</StyledMyRentalList>

				{/* response snackbar  */}
				<CommonSnackbarComponent
					i18n={i18n}
					partialStore={partialStore}
				/>
			</PageContainer>
		)
	}
)

export default MyRentalList

const StyledMyRentalList = styled.div`
	padding-right: 1rem;
	padding-left: 1rem;
	.partition {
		margin: 1rem 1rem;
	}
`
