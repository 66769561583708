import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog, DLButton } from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import styled from "styled-components"
import { Icon } from "@mdi/react"
import { mdiArrowLeft } from "@mdi/js"
import { AddHardCopyContent } from "./add-hard-copy-dialog/AddHardCopyContent"
// import AddHardCopyWithoutProject from "./add-hard-copy-dialog/InputTempHardCopyContents"
import { SelectProjectContent } from "./add-hard-copy-dialog/SelectProjectContent"
import { DoesHaveProjectContent } from "./add-hard-copy-dialog/DoesHaveProjectContent"

export default observer(function AddHardCopyDialog({
	i18n,
	partialStore,
	setOpenCreateProjDialog,
}: {
	i18n: DLI18nProps
	partialStore: any
	setOpenCreateProjDialog: any
}) {
	const [haveProject, setHaveProject] = useState<
		"Yes" | "No" | "WithoutProject" | null
	>(null)
	const [selectedProj, setSelectedProj] = useState(null)
	const [inputs, setInputs] = useState({
		title: "",
		description: "",
	})

	const handleInputs = (e: any) => {
		const { name, value } = e.target
		setInputs((inputs) => ({
			...inputs,
			[name]: value,
		}))
	}

	const handleHaveProject = (value: "Yes" | "No" | "WithoutProject") => {
		setHaveProject(value)
		if (value === "No") {
			setOpenCreateProjDialog(true)
			partialStore.setOpenAddHardCopyDialog(false)
		}
	}

	return (
		<DLDialog
			eleTestId="add-hard-copy-dialog"
			isOpen={partialStore.openAddHardCopyDialog}
			setIsOpen={partialStore.setOpenAddHardCopyDialog}
			showCloseBtn={true}
			dialogTitle="Add Hard Copy"
			dialogContents={
				<>
					{!haveProject && (
						<DoesHaveProjectContent
							handleHaveProject={handleHaveProject}
						/>
					)}
					{haveProject === "Yes" && (
						<>
							{selectedProj ? (
								<AddHardCopyContent
									inputs={inputs}
									handleInputs={handleInputs}
								/>
							) : (
								<SelectProjectContent
									partialStore={partialStore}
									setSelectedProj={setSelectedProj}
								/>
							)}
						</>
					)}
					{/* {haveProject === "WithoutProject" && (
						<AddHardCopyWithoutProject />
					)} */}
				</>
			}
			// actionReady={isReady || userEmail === inputs.email.value}
			maxWidth="md"
			actionBtn={
				<>
					{selectedProj && (
						<div className="FR JSB" style={{ width: "100%" }}>
							<DLButton
								eleTestId="back"
								startIcon={
									<Icon path={mdiArrowLeft} size={0.8} />
								}
								clickHandler={() => setSelectedProj(null)}
							>
								Back
							</DLButton>
							<DLButton
								eleTestId="submit-btn"
								color="primary"
								// clickHandler={() => handlePauseMultipleUsers()}
							>
								Submit
							</DLButton>
						</div>
					)}
				</>
			}
			// cancelBtnText={i18n.twCancel || "Cancel"}
			// 			showSpinner={archiveMgmtStore.getActionStatus("getUsers") !== "SUCCESS"}
			// 			cannotUpdate={archiveMgmtStore.getActionStatus("getUsers") !== "SUCCESS"}
		/>
	)
})
