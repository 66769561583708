import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import {
	PageContainer,
	PageHeader,
} from "../../../../components/app-frame-elements"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import HardCopyListTable from "./sub-components/HardCopyListTable"
import HardCopyListCtxMenus from "./sub-components/HardCopyListCtxMenu"
import styled from "styled-components"
import RentHardCopyDialog from "./sub-components/dialogs/RentHardCopyDialog"
import ReturnHardCopyDialog from "./sub-components/dialogs/ReturnHardCopyDialog"
import {
	DLOrgSubMenus,
	DLOrgMenuGroups,
} from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import EditHardCopyDetailDialog from "./sub-components/dialogs/EditHardCopyDetailDialog"
import EditHardCopyStatusDialog from "./sub-components/dialogs/EditHardCopyStatusDialog"
import RentalHistoryDialog from "../hcmgmt-configuration/sub-components/access-control/RentalHistoryDialog"
import AddHardCopyDialog from "./sub-components/dialogs/AddHardCopyDialog"
import CreateProjectDialog from "../../../../components/combined-elements/create-project/CreateProjectDialog"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { CreateProjectMethod } from "../../../../components/combined-elements/create-project/create-project.data-props"
import BulkAddHcWithoutScDialog from "./sub-components/dialogs/bulk-import-hard-copy-dialog/BulkAddHcWithoutScDialog"
import HardCopyHistoryDialog from "./sub-components/dialogs/HardCopyHistoryDialog"
import AddHardCopyWithoutSoftCopyDialog from "./sub-components/dialogs/AddHardCopyWithoutSoftCopyDialog"
import { useHcMgmtStore } from "../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"

const HardCopyList = observer(
	({
		partialStore,
		fetchingStatus,
		permission,
		i18n,
	}: {
		partialStore: any
		fetchingStatus: ActionStatus
		permission: PermissionAsObjectProps
		i18n: DLI18nProps
	}) => {
		const orgStore = useOrgStore()
		const hcMgmtStore = useHcMgmtStore()
		const configStore = hcMgmtStore.config
		const historyStore = hcMgmtStore.history
		const rentalStore = hcMgmtStore.rental

		const handleContextMenu = (event: any, rowInfo: any) => {
			event.preventDefault()
			partialStore.setClickPoint({
				mouseX: event.clientX - 2,
				mouseY: event.clientY - 4,
			})
		}
		const clickPointExist = partialStore.clickPoint.mouseX !== null
		const path = orgStore.groups.selectionPathWithNameAndFormat
		const setIdPath = orgStore.groups.setPathForSelection
		const presetInputs = orgStore.projects.createProjectPresetInputs
		const orgId = orgStore.checkin.orgInfo.orgId.split("Id").join("")

		return (
			<PageContainer
				pageTools={<div />}
				hasToolbar={false}
				fullWidth
				fixedHeight
				isLoading={
					fetchingStatus === "LOADING" ||
					configStore.getActionStatus("getCustomColumns") ===
						"Loading"
				}
			>
				<StyledHardCopyList className="hard-copy-list-table-container">
					<HardCopyListTable
						partialStore={partialStore}
						handleContextMenu={handleContextMenu}
						permission={permission}
						menuId={DLOrgSubMenus.hard_copy}
					/>
				</StyledHardCopyList>
				{/* ---------- context menus ---------- */}
				{clickPointExist && (
					<HardCopyListCtxMenus
						partialStore={partialStore}
						permission={permission}
						i18n={i18n}
					/>
				)}
				{/* response snackbar  */}
				<CommonSnackbarComponent
					i18n={i18n}
					partialStore={partialStore}
				/>

				{rentalStore.openRentHardCopyDialog && (
					<RentHardCopyDialog
						i18n={i18n}
						partialStore={partialStore}
						rentalStore={rentalStore}
					/>
				)}

				{rentalStore.openReturnHardCopyDialog && (
					<ReturnHardCopyDialog
						i18n={i18n}
						partialStore={partialStore}
						rentalStore={rentalStore}
					/>
				)}

				{partialStore.openEditHardCopyDetailDialog && (
					<EditHardCopyDetailDialog
						i18n={i18n}
						partialStore={partialStore}
					/>
				)}
				{partialStore.openEditHardCopyStatusDialog && (
					<EditHardCopyStatusDialog
						i18n={i18n}
						partialStore={partialStore}
					/>
				)}
				{historyStore.openHardCopyHistoryDialog && (
					<HardCopyHistoryDialog 
						i18n={i18n} 
						orgId={orgId} 
						selectedId={partialStore.selectedId} 
						permission={permission}
					/>
				)}

				{historyStore.openRentalHistoryDialog && (
					<RentalHistoryDialog i18n={i18n} />
				)}

				{/* NOTE: WITH soft copy */}
				{partialStore.openAddHardCopyDialog && (
					<AddHardCopyDialog
						i18n={i18n}
						partialStore={partialStore}
						setOpenCreateProjDialog={
							orgStore.projects.setOpenCreateProjDialog
						}
					/>
				)}

				{/* NOTE: WITHOUT soft copy */}
				{partialStore.openAddHardCopyWithoutSoftCopyDialog && (
					<AddHardCopyWithoutSoftCopyDialog
						i18n={i18n}
						partialStore={partialStore}
					/>
				)}
				{/* WARNING: WITHOUT soft copy - BULK */}
				{partialStore.openBulkAddHardCopyWithoutSoftCopyDialog && (
					<BulkAddHcWithoutScDialog
						i18n={i18n}
						partialStore={partialStore}
					/>
				)}

				{orgStore.projects.openCreateProjDialog && (
					<CreateProjectDialog
						open={orgStore.projects.openCreateProjDialog}
						setOpen={orgStore.projects.setOpenCreateProjDialog}
						//
						currentMenu={DLOrgMenuGroups.reports_mgmt}
						createMethod={CreateProjectMethod.byHardCopyOnly}
						//
						path={path}
						setIdPath={setIdPath}
						presetInputs={presetInputs}
					/>
				)}
			</PageContainer>
		)
	}
)

export default HardCopyList

const StyledHardCopyList = styled.div`
	padding-right: 1rem;
	padding-left: 1rem;
	.partition {
		margin: 1rem 1rem;
	}
`
