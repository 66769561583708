import React from "react"
import { DLOrgMenuGroups, DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ProjectStatus } from "../../../common-models/enumerations/project-related-enums"
import styled from "styled-components"

const SourceProjectInfo = ({ partialStore }: { partialStore: any }) => {
    
	const selectedProjInfo = partialStore.rfSourceProjInfo
	
    return (
        <StyledSourceProjectInfo>
			<h4>Source Project Information</h4>
			
			<InfoRow
				label="Group Name"
				value={selectedProjInfo.groupName}
			/>
			<InfoRow
				label="Cabinet Name"
				value={selectedProjInfo.cabinetName}
			/>
			<InfoRow
				label="Client Name"
				value={selectedProjInfo.clientName}
			/>
			<InfoRow
				label="Project Title"
				value={selectedProjInfo.title}
			/>
			<InfoRow
				label="Version"
				value={"v." + selectedProjInfo.version}
			/>
			<InfoRow
				label="Period Type"
				value={selectedProjInfo.periodName}
			/>
			<InfoRow
				label="Year"
				value={selectedProjInfo.financialYear}
			/>
			<InfoRow
				label="Period End Date"
				value={selectedProjInfo.periodEndDate}
			/>
		</StyledSourceProjectInfo>
    )
}

export default SourceProjectInfo

const InfoRow = ({
	label,
	value,
}: {
	label: string
	value: any
}) => {
	return (
		<div className="info-row">
			<div className="label-area">{label}</div>
			<div className="value-area">{value}</div>
		</div>
	)
}

const StyledSourceProjectInfo = styled.div`
    .info-row {
		display: flex;
		padding: 0.3rem 0 0.3rem 0;
		.label-area {
			min-width: 8rem;
		}
	}
`