import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { HardCopyStatusEnum } from "../data-models/hard-copy-list.data-models"

const GetHardCopyList = (self: any) => ({
	getHardCopyList(orgId: string, year: string | number) {
		// 0.
		const actionName = "getHardCopyList"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.request API call
		self.readHardCopyList(orgId, year)
			.then((response: any) => {
				console.log(actionName, "response:", response)
				const correctStatusList = Object.values(HardCopyStatusEnum)
				if (response.status === 200) {
					const items = response.data.data.Items
					console.log("items:", items)

					let convertedList: any = []

					items.map((hc: any) => {
						const status = hc.status
						convertedList.push({
							...hc,
							modifiedBy: hc.updatedBy,
							status: correctStatusList.includes(status)
								? status
								: HardCopyStatusEnum.incorrect,
						})
					})
					console.log("convertedList:", convertedList)

					self.setHardCopyList(convertedList)

					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200, // temp
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetHardCopyList
