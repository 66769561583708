import { AxiosResponse } from "axios"
import { getRequest } from "../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { HardCopyMgmtHistorySourceEnum } from "./data-models/history-model"

export async function readHistories({
	orgId,
	source,
	targetId,
}: {
	orgId: string
	source: HardCopyMgmtHistorySourceEnum
	targetId?: string
}) {
	let url = `/histories`

	const response = await getRequest({
		tokenType: TOKEN_TYPE.hardCopy,
		url,
		params: {
			orgId,
			source,
			...(targetId && { targetId }),
		},
	})
	return response
}

export type HcMgmtHistoryApiProps = {
	apiReadHistories: (params: {
		orgId: string
		source: HardCopyMgmtHistorySourceEnum
		targetId?: string
	}) => Promise<AxiosResponse<any>>
}
