import React from "react"
import {
	singleSelectFilter,
} from "../../../../../library/react-table-utils"

export const columns = (
	partialStore: any,
	handleContextMenu: (event: any, targetInfo: any) => void
) => [
	{
		header: "Source",
		accessorKey: "source",
		filterFn: singleSelectFilter,
	},
	{
		header: "Action Name",
		accessorKey: "actionName",
	},
	{
		header: "Action Summary",
		accessorKey: "actionSummary",
	},
	{
		header: "By",
		accessorKey: "createdBy",
	},
	{
		header: "Date",
		accessorKey: "createdDate",
	},
	// {
	// 	header: "Action",
	// 	accessorKey: "pending",
	// 	enableColumnFilter: false,
	// 	cell: (props: any) => {
	// 		const userId = props.row.original.id
	// 		const cellId = props.cell.id + "-cell"

	// 		return (
	// 			<div data-testid={cellId}>
	// 				<DLIconButton
	// 					variant="iconOnly"
	// 					aria-label="more"
	// 					clickHandler={(event: any) =>
	// 						handleContextMenu(event, props.row)
	// 					}
	// 					eleTestId="hard-copy-ctx-btn-on-row"
	// 				>
	// 					<Icon path={mdiDotsVertical} size={0.8} />
	// 				</DLIconButton>
	// 			</div>
	// 		)
	// 	},
	// },
]

