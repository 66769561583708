import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
import { ReadRentals, MRentHardCopy, MReturnHardCopy, ReadSignedUrl } from "./model-actions"
import { HcMgmtRentalApiProps } from "./hc-mgmt-rental.apis"

const HcMgmtRentalModel = ({
	apiReadRentals,
	apiRentHardCopy,
	apiReturnHardCopy,
	apiReadSignedUrl
}: HcMgmtRentalApiProps) =>
	types
		.model({})
		.actions((self) => ReadRentals(self, apiReadRentals))
		.actions((self) => MRentHardCopy(self, apiRentHardCopy))
		.actions((self) => MReturnHardCopy(self, apiReturnHardCopy))
		.actions((self) => ReadSignedUrl(self, apiReadSignedUrl))
		// model common actions
		.actions(CommonModelActions)

export default HcMgmtRentalModel
