import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { PageContainer } from "../../../../components/app-frame-elements"
import styled from "styled-components"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import ProjArchivedCtxMenus from "../../../../components/combined-elements/projects-table/ProjArchivedCtxMenus"
import AccessMgmtDialog from "./dialogs/AccessMgmtDialog"
import ArchivedProjectDeleteDialog from "../common-dialogs/AmProjectDeleteDialog"
import ArchivedProjectUnarchiveDialog from "./dialogs/ArchivedProjectUnarchiveDialog"
import DigitalSignatureDialog from "./dialogs/DigitalSignatureDialog"
import { DLButton, DLIconButton } from "../../../../components/basic-elements"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { ProjScreenType } from "../../../../components/combined-elements/projects-table/projects-table-props"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import ProjectDetailsDialog from "../../../../components/combined-elements/project-dialogs/ProjectDetailsDialog"
import RepairReplicaDialog from "./dialogs/RepairReplicaDialog"
import PreProcessDialog from "./dialogs/PreProcessDialog"
import ArchiveZipRegenerationDialog from "./dialogs/ArchiveZipRegenerationDialog"
import { requestToken } from "../../../project-side/proj-access-control/store/proj-checkin.apis"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import * as FileSaver from "file-saver"
import { AdminMode } from "./store/am-archived-projects.view-model"
import { mdiCat } from "@mdi/js"
import Icon from "@mdi/react"
import RelayAssignUserDialog from "./dialogs/RelayAssignUserDialog"
import ObjectsToCsv from "objects-to-csv"
import RepairFailedReplicaDialog from "./dialogs/RepairFailedReplicaDialog"
import { saveLatestProjToken } from "../../../../library/token-utils"
import SharedProjectTableV8 from "../../../../components/combined-elements/projects-table/SharedProjectTableV8"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"

export default observer(function AmArchivedProjects({
    partialStore,
    fetchingStatus = ActionStatus.success,
    permission,
    i18n,
}: {
    partialStore: any
    fetchingStatus: ActionStatus
    permission: PermissionAsObjectProps
    i18n: DLI18nProps
}) {
    const orgStore = useOrgStore()

    const [filteredData, setFilteredData] = useState<any>([])

    const userId = orgStore.checkin.orgUserId
    const orgId = orgStore.checkin.orgInfo.orgId

    const userNumId = idToNumber(userId, IdType.user)
    const orgNumId = idToNumber(orgId, IdType.org)

    const [relayDialogOpen, setRelayDialogOpen] = useState(false)
    const [failedRepairReplicaDialogOpen, setFailedRepairReplicaDialogOpen] =
        useState(false)

    const getProjToken = async (projId: number, userId: number) => {
        const result = await requestToken({
            UserId: userId,
            ClientId: projId,
        }).then((response: any) => {
            if (response.data.status.code === 200) {
                // @Noah ? Isn't it required to update the latest proj ID?
                saveLatestProjToken(response.data.data.access_token)

                // console.log("success to get the project token: ", projId)
                return true
            } else {
                console.log("failed to get the project token: ", projId)
                return false
            }
        })

        return result
    }

    const getReplicaIds = () => {
        console.log("Get Replica ID")

        partialStore.resetListWithReplicaId()
        const list = partialStore.pdfStatusList.filter(
            (item: any) => item.pdfStatus
        )
        const updateList = partialStore.pushListWithReplicaId
        orgStore.projects.relayGetRepId(list, 0, updateList)
    }

    const tempGetExportJson = () => {
        console.log("get data to export JSON")
        partialStore.resetListForJsonExport()

        const list = partialStore.replicaTargetList
        const updateList = partialStore.pushListForJsonExport
        orgStore.projects.relayGetProjInfo(list, 0, updateList)
    }

    const tempGetExportJson2 = () => {
        console.log("get user data to export JSON")
        partialStore.resetListForJsonExport2()

        const list = partialStore.replicaTargetList
        const updateList = partialStore.pushListForJsonExport2

        orgStore.projects.relayGetProjUsersAndFileCount(list, 0, updateList)
    }

    const tempExportJson_0 = () => {
        const contents = partialStore.viewReplicaTargetList
        const stringContents = JSON.stringify(contents)

        console.log("JSON contents 0:", contents)

        let proceed = window.confirm(
            "Do you want to download this DEFAULT json?"
        )
        if (!proceed) {
            return
        } else {
            const jsonFile = new Blob([stringContents], {
                type: "text/plain;charset=utf-8",
            })

            FileSaver.saveAs(jsonFile, "Replica-validation-target-list.json")
        }
    }

    async function printCsv(data: any) {
        const csv = new ObjectsToCsv(data)
        console.log(await csv.toString())
    }

    const tempExportJson_1 = (format: string) => {
        const contents = partialStore.viewListForJsonExport

        console.log("JSON contents 1:", contents)

        let proceed = window.confirm(
            `Do you want to download this list with Replica ID as ${format} file?`
        )
        if (!proceed) {
            return
        } else {
            if (format === "csv") {
                printCsv(contents)
            } else {
                const file_x = new Blob([JSON.stringify(contents)], {
                    type: "text/plain;charset=utf-8",
                })

                FileSaver.saveAs(
                    file_x,
                    "Replica-validation-target-list." + format
                )
            }
        }
    }

    const tempExportJson_2 = () => {
        const contents = partialStore.viewListForJsonExport2
        const stringContents = JSON.stringify(contents)

        console.log("JSON contents 2:", contents)

        let proceed = window.confirm(
            "Do you want to download this json with USER and FILE COUNT?"
        )
        if (!proceed) {
            return
        } else {
            const jsonFile = new Blob([stringContents], {
                type: "text/plain;charset=utf-8",
            })

            FileSaver.saveAs(jsonFile, "Replica-validation-target-list.json")
        }
    }

    const validateReplica = () => {
        const replicaIds = partialStore.viewListForJsonExport.map(
            (item: any) => item.replicaId
        )
        if (replicaIds.length)
            partialStore.validateReplicas(getProjToken, userNumId, replicaIds)
    }

    const toggleAdminMode = (mode: AdminMode) => {
        partialStore.updateAdminMode(
            mode === AdminMode.repairReplica
                ? AdminMode.exportZipUrl
                : AdminMode.repairReplica
        )
    }

    const isAppAdminUser = JSON.parse(
        localStorage.getItem("isAdminAppUser") || "false"
    )

    let clientId = partialStore.selectedProj
    let cabinetId = partialStore.selectedCabinetId
    const adminMode = partialStore.adminMode
    const isRepairMode = adminMode === AdminMode.repairReplica
    const clickPointExist = partialStore.clickPoint.mouseX !== null

    return (
        <PageContainer
            pageTools={
                <div className="FR AC" style={{ overflowX: "auto" }}>
                    <DLIconButton
                        eleTestId="mode-change"
                        color={isRepairMode ? "primary" : "warning"}
                        clickHandler={() => toggleAdminMode(adminMode)}
                        tooltipText={
                            isRepairMode ? "Repair Replica" : "Export Zip URL"
                        }
                    >
                        {/* {isRepairMode ? <Icon : "B"} */}
                        <Icon path={mdiCat} size={1} />
                    </DLIconButton>
                    <div className="placeholder" style={{ width: 64 }}></div>
                    {isRepairMode && (
                        <div className="FR AC">
                            <DLButton
                                eleTestId="get-list"
                                color="primary"
                                clickHandler={() =>
                                    partialStore.getReplicaRestorationTargetList(
                                        filteredData
                                    )
                                }
                                tooltipText="Get the filtered projects list for next step"
                            >
                                Get List
                            </DLButton>
                            <DLButton
                                eleTestId="assign-replica-account"
                                clickHandler={() => setRelayDialogOpen(true)}
                                tooltipText="Assign current signed in user to all of the filtered projects"
                            >
                                Assign
                            </DLButton>
                            <DLButton
                                eleTestId="pdf-check"
                                color="primary"
                                clickHandler={() =>
                                    partialStore.preprocessPdfCheck(
                                        getProjToken,
                                        userNumId
                                    )
                                }
                                tooltipText="check the filtered projects if it has pre-process pdfs or not"
                            >
                                PDF Check
                            </DLButton>
                            <DLButton
                                eleTestId="view-list"
                                clickHandler={() =>
                                    console.log(
                                        "pdf status list:",
                                        partialStore.viewPdfStatusList
                                    )
                                }
                                tooltipText="print the current target project list"
                            >
                                view list
                            </DLButton>
                            <DLButton
                                eleTestId="pdf-generate"
                                color="primary"
                                clickHandler={() =>
                                    partialStore.generatePdfs(userNumId)
                                }
                                tooltipText="(PDF check step is required first) Request generate PDFs"
                            >
                                PDF Generate
                            </DLButton>
                            <DLButton
                                eleTestId="get-replica-id"
                                clickHandler={getReplicaIds}
                                tooltipText="(PDF check step is required first) get replica ID of the target projects which have PDFs already"
                            >
                                Replica ID
                            </DLButton>
                            <DLButton
                                eleTestId="repair-replicas"
                                color="primary"
                                clickHandler={() =>
                                    partialStore.repairReplicas(
                                        userNumId,
                                        orgNumId
                                    )
                                }
                                tooltipText="Repair replicas"
                            >
                                Repair Replicas
                            </DLButton>

                            <div
                                className="placeholder"
                                style={{ width: 16 }}
                            ></div>
                            <DLButton
                                eleTestId="get-json-to-validate"
                                variant="outlined"
                                clickHandler={tempGetExportJson}
                                tooltipText="Get replica ID information to export JSON"
                            >
                                Get1
                            </DLButton>
                            <DLButton
                                eleTestId="get-json2-to-validate"
                                variant="outlined"
                                color="warning"
                                clickHandler={tempGetExportJson2}
                                tooltipText="Get project user information to export JSON (WARNING: This requires more time)"
                            >
                                Get2
                            </DLButton>
                            <div
                                className="placeholder"
                                style={{ width: 8 }}
                            ></div>
                            <DLButton
                                eleTestId="export-json-0"
                                variant="outlined"
                                color="primary"
                                clickHandler={tempExportJson_0}
                                tooltipText="Export 0: default only"
                            >
                                EXP0
                            </DLButton>
                            <DLButton
                                eleTestId="export-json-1"
                                variant="outlined"
                                color="primary"
                                clickHandler={() => tempExportJson_1("json")}
                                tooltipText="Export 1: with replica ID & JSON format"
                            >
                                EXP1-1
                            </DLButton>
                            <DLButton
                                eleTestId="export-json-1"
                                variant="outlined"
                                color="primary"
                                clickHandler={() => tempExportJson_1("csv")}
                                tooltipText="Export 1: with replica ID & CSV format"
                            >
                                EXP1-2
                            </DLButton>
                            <DLButton
                                eleTestId="export-json-1"
                                variant="outlined"
                                color="primary"
                                clickHandler={tempExportJson_2}
                                tooltipText="Export 2: with User and File Count"
                            >
                                EXP2
                            </DLButton>
                            <div
                                className="placeholder"
                                style={{ width: 16 }}
                            ></div>
                            <DLButton
                                eleTestId="repair-replicas-by-ids"
                                color="warning"
                                variant="outlined"
                                clickHandler={
                                    () => setFailedRepairReplicaDialogOpen(true)
                                    // partialStore.repairReplicasByIdList(
                                    // 	userNumId,
                                    // 	orgNumId
                                    // )
                                }
                                tooltipText="Repair replicas by Archived project ID List"
                            >
                                Repair Replicas 2
                            </DLButton>
                            <DLButton
                                eleTestId="replica-validation-btn"
                                color="warning"
                                clickHandler={validateReplica}
                                tooltipText="Replica validation by replica ID list (temporary / simple version)"
                            >
                                Replica validation
                            </DLButton>
                            <DLButton
                                eleTestId="x"
                                clickHandler={() => {
                                    console.log(
                                        partialStore.viewReplicaValidationResults
                                    )
                                }}
                                tooltipText="Print the replica validation results on the development pannel console"
                            >
                                View Replica Validation Results
                            </DLButton>
                        </div>
                    )}

                    {!isRepairMode && (
                        <div className="FR AC">
                            <DLButton
                                eleTestId="set-zip-url-targets"
                                variant="outlined"
                                color="warning"
                                clickHandler={() =>
                                    partialStore.getZipUrlTargetList(
                                        filteredData
                                    )
                                }
                                tooltipText="Get zip target list"
                            >
                                Set Targets
                            </DLButton>
                            <DLButton
                                eleTestId="assign-for-zipUrl"
                                color="warning"
                                clickHandler={() => setRelayDialogOpen(true)}
                            >
                                Assign
                            </DLButton>
                            <DLButton
                                eleTestId="get-s3-keys"
                                variant="outlined"
                                color="warning"
                                clickHandler={() => partialStore.getZipUrls()}
                                tooltipText="Get zip URL list"
                            >
                                Get Zip URLs
                            </DLButton>
                            <div
                                className="placeholder"
                                style={{ width: 16 }}
                            ></div>
                            <DLButton
                                eleTestId="download-zip-url"
                                variant="outlined"
                                color="warning"
                                clickHandler={() =>
                                    partialStore.downloadZipUrlList()
                                }
                                tooltipText="Download zip URL list"
                            >
                                Download Zip URL
                            </DLButton>
                            <div
                                className="placeholder"
                                style={{ width: 64 }}
                            ></div>
                            <DLButton
                                eleTestId="get-readonly-id"
                                variant="contained"
                                color="secondary"
                                clickHandler={() =>
                                    partialStore.getReadonlyIds(userNumId)
                                }
                                tooltipText="get readonly ID to unassign / NOTE: Before click this, set targets"
                            >
                                Get Readonly ID
                            </DLButton>
                            <DLButton
                                eleTestId="unassign"
                                variant="outlined"
                                clickHandler={() =>
                                    partialStore.unassignReadonlyUserFromProjects()
                                }
                                tooltipText="Unassign current user from total projects"
                            >
                                Unassign
                            </DLButton>
                        </div>
                    )}
                </div>
            }
            hasToolbar={isAppAdminUser}
            fullWidth
            fixedHeight
            isLoading={fetchingStatus === "LOADING"}
        >
            <StyledAmArchivedProjects>
                <SharedProjectTableV8
                    partialStore={partialStore}
                    i18n={i18n}
                    // common part 2
                    projScreenType={ProjScreenType.amArchived}
                    menuId={DLOrgSubMenus.archived_mgmt}
                    projectStatus={ProjectStatus.archived}
                    permission={permission}
                />

                {clickPointExist && (
                    <ProjArchivedCtxMenus permission={permission} i18n={i18n} />
                )}
                {/* response snackbar */}
                <CommonSnackbarComponent
                    i18n={i18n}
                    partialStore={partialStore}
                />
                {/* dialogs */}
                {partialStore.accessMgmtDialogOpen && (
                    <AccessMgmtDialog i18n={i18n} />
                )}
                {partialStore.projectDetailsDialogOpen && (
                    <ProjectDetailsDialog partialStore={partialStore} />
                )}
                {partialStore.removeArchivedProjectDialogOpen && (
                    <ArchivedProjectDeleteDialog
                        i18n={i18n}
                        isOpen={partialStore.removeArchivedProjectDialogOpen}
                        setIsOpen={
                            partialStore.setRemoveArchivedProjectDialogOpen
                        }
                        clientId={partialStore.selectedProj}
                        cabinetId={partialStore.selectedCabinetId}
                        deleteAction={() =>
                            partialStore.removeAmArchivedProject(
                                clientId,
                                cabinetId
                            )
                        }
                    />
                )}
                {partialStore.archivedProjectUnarchiveDialogOpen && (
                    <ArchivedProjectUnarchiveDialog i18n={i18n} />
                )}
                {partialStore.digitalSignatureDialogOpen && (
                    <DigitalSignatureDialog i18n={i18n} />
                )}

                {partialStore.repairReplicaDialog && <RepairReplicaDialog />}
                {partialStore.preProcessDialog && (
                    <PreProcessDialog partialStore={partialStore} />
                )}
                {partialStore.archiveZipRegenerationDialog && (
                    <ArchiveZipRegenerationDialog />
                )}
                {relayDialogOpen && (
                    <RelayAssignUserDialog
                        open={relayDialogOpen}
                        setOpen={setRelayDialogOpen}
                        title={`Readonly user assign ${
                            isRepairMode ? "(1)" : "(2)"
                        }`}
                        assignUserId={userNumId}
                        repairMode={isRepairMode}
                    />
                )}
                {failedRepairReplicaDialogOpen && (
                    <RepairFailedReplicaDialog
                        open={failedRepairReplicaDialogOpen}
                        setOpen={setFailedRepairReplicaDialogOpen}
                    />
                )}
            </StyledAmArchivedProjects>
        </PageContainer>
    )
})

const StyledAmArchivedProjects = styled.div`
    padding-right: 1rem;
    padding-left: 1rem;
    .counter-container {
        .counter {
            margin: 0 1rem;
            span {
                font-weight: 600;
                color: ${(props) => props.theme.emphasis};
            }
        }
    }
    .partition {
        margin-right: 1rem;
    }
`
