import React, { useState, useEffect } from "react"
import {
    DLDialog,
    DLInputField,
    InputWithLabel,
} from "../../../../../../components/basic-elements"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"

const CreateUpdateCustomColumn = observer(
    ({
        open,
        setOpen,
        configStore,
        dialogType,
        selectedId,
    }: {
        open: boolean
        setOpen: any
        configStore: any
        dialogType: "Create" | "Update"
        selectedId: string | null
    }) => {
        const orgStore = useOrgStore()
        const [inputs, setInputs] = useState({
            label: "",
            description: "",
            helpText: "",
        })

        useEffect(() => {
            if (selectedId) {
                const { label, description, helpText } =
                    configStore.viewCustomColumnById(selectedId)
                setInputs({
                    label,
                    description,
                    helpText,
                })
            } else {
                setInputs({
                    label: "",
                    description: "",
                    helpText: "",
                })
            }
        }, [selectedId])

        const handleInputs = (e: any) => {
            const { name, value } = e.target
            setInputs((inputs: any) => ({
                ...inputs,
                [name]: value,
            }))
        }

        const handleSave = () => {
            const { label, description, helpText } = inputs
            const customerId = process.env.REACT_APP_CUSTOMER_ID
            const orgId = orgStore.checkin.orgInfo.orgId.split("Id").join("")
            const userInfo = {
                id: orgStore.checkin.orgInfo.userId.split("Id").join(""),
                alias: orgStore.checkin.orgInfo.userAliasId,
                name: orgStore.checkin.orgInfo.userName,
            }

            if (selectedId) {
                const { pk, id } = configStore.viewCustomColumnById(selectedId)
                configStore.editCustomColumn({
                    pk,
                    id,
                    by: userInfo,
                    label,
                    description,
                    helpText,
                })
            } else {
                configStore.addCustomColumn(
                    {
                        customerId,
                        orgId,
                        createdBy: userInfo,
                        label,
                        description,
                        helpText,
                    },
                    () => setOpen(false)
                )
            }
        }

        return (
            <DLDialog
                eleTestId="custom-column-setting-dialog"
                isOpen={open}
                setIsOpen={setOpen}
                showCloseBtn={true}
                dialogTitle={`${dialogType} Custom Column`}
                dialogContents={
                    <CreateUpdateCustomColumnContent
                        inputs={inputs}
                        handleInputs={handleInputs}
                    />
                }
                handleAction={handleSave}
                actionReady={inputs.label !== ""}
                maxWidth="sm"
                actionBtn="Save"
                cancelBtnText="Cancel"
                showSpinner={
                    configStore.getActionStatus("addCustomColumn") === "LOADING"
                }
                cannotUpdate={
                    configStore.getActionStatus("addCustomColumn") === "LOADING"
                }
            />
        )
    }
)

const CreateUpdateCustomColumnContent = ({
    inputs,
    handleInputs,
}: {
    inputs: any
    handleInputs: any
}) => (
    <div>
        <div className="input-section FR">
            <InputWithLabel direction="vertical" required label="Label">
                <DLInputField
                    eleTestId="custom-column-label-input"
                    eleFullWidth
                    eleName="label"
                    eleValue={inputs.label}
                    eleHandleChange={handleInputs}
                    eleRequired
                    variant="box"
                />
            </InputWithLabel>
        </div>
        <div className="input-section FR">
            <InputWithLabel direction="vertical" label="Description">
                <DLInputField
                    eleTestId="custom-column-description-input"
                    eleFullWidth
                    eleName="description"
                    eleValue={inputs.description}
                    eleHandleChange={handleInputs}
                    variant="box"
                />
            </InputWithLabel>
        </div>
        <div className="input-section FR">
            <InputWithLabel direction="vertical" label="Help Text">
                <DLInputField
                    eleTestId="custom-column-helpText-input"
                    eleFullWidth
                    eleName="helpText"
                    eleValue={inputs.helpText}
                    eleHandleChange={handleInputs}
                    variant="box"
                />
            </InputWithLabel>
        </div>
    </div>
)

export default CreateUpdateCustomColumn
