import { types, applySnapshot, IModelType } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import { initialStore } from "./hc-mgmt-trash.provider"
import { GetTrashItems } from "./view-model-actions"

const HcMgmtTrashViewModel = types
	.model({
		// trashItems:
		//
		clickPoint: ClickPoint,
		selectedId: "",
		// dialogs

		// common parts
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({}))
	// * API related parts
	.actions(GetTrashItems)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default HcMgmtTrashViewModel
