import React from "react"
import { observer } from "mobx-react-lite"
import {
	DLButton,
	DLIconButton,
} from "../../../../../components/basic-elements"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { Column, Table } from "@tanstack/react-table"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import DLYearpicker from "../../../../../components/basic-elements/date-picker/DLYearpicker"
import { columns } from "./HardCopyListTableColumns"
import { Icon } from "@mdi/react"
import { mdiPlusCircle } from "@mdi/js"
import DLMenusWithMultipleAction from "../../../../../components/basic-elements/menus/DLMenusWithMultipleAction"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { useHcMgmtStore } from "../../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

export default observer(function HardCopyListTable({
	partialStore,
	handleContextMenu,
	permission,
	menuId,
}: {
	partialStore: any
	handleContextMenu: (event: any, targetInfo: any) => void
	permission: PermissionAsObjectProps
	menuId: DLOrgSubMenus
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const hcMgmtStore = useHcMgmtStore()
	const configStore = hcMgmtStore.config
	//
	const isHardCopy = menuId === DLOrgSubMenus.hard_copy
	// const data = isHardCopy
	// 	? partialStore.hardCopy
	// 	: partialStore.myRentalList

	const removableColumns = isHardCopy
		? ["rentalAt", "dueDate"]
		: ["rentalStatus"]
	const filterdColumns = columns(partialStore, handleContextMenu).filter(
		(column: any) => removableColumns.indexOf(column.accessorKey) === -1
	)

	const customColumns = configStore.viewCustomColumns.map((item: any) => ({
		header: item.label,
		// accessorFn: (props: any) => props.customColumns.name,
		accessorKey: item.id,
	}))

	// console.log("filterdColumns", customColumns, filterdColumns)
	// console.log("data:", data)

	const addHardCopyOptions = [
		{
			label: "Add Hard Copy",
			value: "add-hc-with-sc",
			available: permission.create,
			// disabled: true,
			clickEvent: () => {
				partialStore.setOpenAddHardCopyDialog(true)
			},
		},
		{
			label: "Add Hard Copy Without Soft Copy",
			value: "add-hc-without-sc",
			available: permission.create,
			clickEvent: () => {
				partialStore.setOpenAddHardCopyWithoutSoftCopyDialog(true)
			},
		},
		{
			label: "Bulk import Hard Copy",
			value: "bulk-import-hc-with-sc",
			available: permission.create,
			disabled: true,
			clickEvent: () => {
				// TODO:
				console.log("Please add this dialog")
			},
		},
		{
			label: "Bulk import Hard Copy without Soft Copy",
			value: "bulk-import-hc-without-sc",
			available: permission.create,
			clickEvent: () => {
				partialStore.setOpenBulkAddHardCopyWithoutSoftCopyDialog(true)
			},
		},
	]

	const pageHeader = () => {
		return (
			<div className="FR JSB" style={{ width: "100%" }}>
				<div>Total Count: {partialStore.totalHardCopiesCount}</div>
				<DLMenusWithMultipleAction
					remoteButton={
						<DLIconButton
							eleTestId="add-hard-copy"
							size="medium"
							color="primary"
						>
							<Icon path={mdiPlusCircle} size={1} />
						</DLIconButton>
					}
					menuOptions={addHardCopyOptions}
					eleTestId="add-hard-copy-ctx-menu"
				/>
			</div>
		)
	}

	const handleYearChange = (year: string | number) => {
		const orgId = orgStore.checkin.orgInfo.orgId.split("Id").join("")
		partialStore.getHardCopyList(orgId, year)
	}
	
	const handleSelectedRowChange = (selectedRows: any) => {
		const selectedRowIds = selectedRows.map((item: any) => item.original.id)
		partialStore.setSelectedItems(selectedRowIds)
	}
	
	const dntFormat = store.global.getDntFormat	

	return (
		<ReactTableV8
			// TODO: height setting
			height={830}
			tableColumns={[...filterdColumns, ...customColumns]}
			data={partialStore.viewHardCopyList(dntFormat)}
			handleContextMenu={handleContextMenu}
			menuId={menuId}
			pageHeader={pageHeader}
			permission={permission}
			customFilters={(props: any) =>
				filterComponent({ ...props, handleYearChange })
			}
			onSelectedRowChange={handleSelectedRowChange}
		/>
	)
})

const filterComponent = ({
	column,
	table,
	handleYearChange,
}: {
	column: Column<any>
	table: Table<any>
	handleYearChange: (year: string | number) => void
}) => {
	switch (column.id) {
		case "hasSoftCopy":
			return (
				<select
					onChange={(event: any) =>
						column.setFilterValue(event.target.value)
					}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "all") as string}
					data-testid="hasSoftCopy-select"
				>
					<option value="all">All</option>
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select>
			)

		case "status":
			return (
				<select
					onChange={(event: any) =>
						column.setFilterValue(event.target.value)
					}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "all") as string}
					data-testid="status-select"
				>
					{/* HardCopyStatusEnum  */}
					<option value="all">All</option>
					<option value="AVAILABLE">Available</option>
					<option value="UNAVAILABLE">Unavailable</option>
					<option value="STOCKED">Stocked</option>
					<option value="READY_TO_DELETE">Ready to delete</option>
				</select>
			)

		case "rentalStatus":
			return (
				<select
					onChange={(event: any) =>
						column.setFilterValue(event.target.value)
					}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "all") as string}
					data-testid="rentalStatus-select"
				>
					<option value="all">All</option>
					<option value="rented">Rented</option>
					<option value="overdue">Overdue</option>
					<option value="extended">Extended</option>
				</select>
			)

		case "year":
			// console.log(column.getFilterValue())
			return (
				<DLYearpicker
					eleTestId="financial-year-select"
					eleName="financialYear"
					selected={
						column.getFilterValue() || new Date().getFullYear()
					}
					onChange={(value: any) => {
						column.setFilterValue(value)
						handleYearChange(value)
					}}
					showClearIcon
				/>
			)
		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}
