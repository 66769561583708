import { flow } from "mobx-state-tree"
import { HcMgmtRentalApiProps, RentHardCopyProps } from "../hc-mgmt-rental.apis"

const MRentHardCopy = (
	self: any,
	rentHardCopy: HcMgmtRentalApiProps["apiRentHardCopy"]
) => ({
	mRentHardCopy: flow(function* (params: RentHardCopyProps) {
		const actionName = "mRentHardCopy"
		try {
			const response = yield rentHardCopy(params)
			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default MRentHardCopy
