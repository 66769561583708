import React from "react"
import { DLIconButton } from "../../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiPencil, mdiTrashCanOutline } from "@mdi/js"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import { Column } from "@tanstack/react-table"
import { singleSelectFilter } from "../../../../../library/react-table-utils"

export const filterComponent = ({ column }: { column: Column<any> }) => {
	switch (column.id) {
		case "type":
			return (
				<select
					onChange={(event: any) =>
						column.setFilterValue(event.target.value)
					}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "all") as string}
					data-testid="type-select"
				>
					<option value="all">All</option>
					<option value="WP">Workpaper</option>
					<option value="PF">Permanent File</option>
				</select>
			)

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const RationaleFilesColumns = (handleEdit: any, handleDelete: any) => [
	{
		header: "Type",
		accessorKey: "type",
		size: 60,
		filterFn: singleSelectFilter,
	},
	{
		header: "Ref.",
		accessorKey: "reference",
	},
	{
		header: "Description",
		accessorKey: "description",
	},
	{
		header: "Modified On",
		accessorKey: "modifiedDate",
	},
	{
		header: "By",
		accessorKey: "modifiedBy",
	},
	{
		header: "Rationale",
		accessorKey: "rationaleDesc",
	},
	{
		header: "Rationale On",
		accessorKey: "rationaleDate",
	},
	{
		header: "By",
		accessorKey: "rationaleBy",
	},
	{
		header: "Actions",
		accessorKey: "XXX",
		enableColumnFilter: false,
		cell: (props: any) => {
			const rationaleId = props.row.original.rationaleId
			return (
				<div className="FR">
					<DLIconButton
						eleTestId={`restore-wp-${rationaleId}`}
						tooltipText="Update rationale"
					>
						<Icon path={mdiPencil} size={0.8} />
					</DLIconButton>

					<DLIconButton
						eleTestId={`remove-wp-${rationaleId}`}
						tooltipText="Reset the rationale contents"
						disabled={props.row.original.rationaleDesc === ""}
					>
						<Icon path={mdiTrashCanOutline} size={0.8} />
					</DLIconButton>
				</div>
			)
		},
	},
]

export default RationaleFilesColumns
