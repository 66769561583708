import React from "react"
import ProjectInfoDetailsContent from "../../ProjectInfoDetailsContent"
import styled from "styled-components"

const RentHardCopyDialogContentLeftSide = ({ projInfo }: { projInfo: any }) => {
	return (
		<StyledLeftSide className="left-side">
			<div className="hc-info-section">
				<h4>Hard Copy</h4>
				<div>{projInfo.hardCopyName}</div>
			</div>

			<div className="hc-info-section">
				<h4>Hard Copy Type</h4>
				<div>
					{projInfo.hasSoftCopy === "Yes"
						? "With Soft Copy"
						: "Without Soft Copy"}
				</div>
				{/* <ul>
					<li>Connected Project Exists</li>
					<li>Connected Project and Workspaces Exist</li>
					<li>Connected Project Does not exist</li>
				</ul> */}
			</div>

			<div className="hc-info-section">
				<div className="proj-info-section">
					<h4 style={{ marginBottom: 8 }}>Hard Copy Information</h4>
					<ProjectInfoDetailsContent projInfo={projInfo} />
				</div>
			</div>
		</StyledLeftSide>
	)
}

export default RentHardCopyDialogContentLeftSide

const StyledLeftSide = styled.div`
	.hc-info-section {
		margin-bottom: 2rem;
		h4 {
			margin-bottom: 8px;
		}
	}
`
