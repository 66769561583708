import { AxiosResponse } from "axios"
import {
	postRequest,
	deleteRequest,
	getRequest,
	putRequest,
} from "../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { SimpleUserModelType } from "../../../../../service-modules/user-module/user-module-data-models"

export type RentHardCopyProps = {
	customerId: string
	orgId: string
	hardCopyPk: string
	hardCopyId: string
	hardCopy: {
		pk: string
		id: string
		hasSoftCopy: boolean
		title: string
	}
	rentedBy: SimpleUserModelType
	createdBy: SimpleUserModelType
	dueDate: string
	files: { s3Uri: string; name: string; extension: string; size: string }[]
}

export async function readRentals(params: any) {
	let url = `/rentals`

	const response = await getRequest({
		tokenType: TOKEN_TYPE.hardCopy,
		url,
		params,
	})
	return response
}

export async function rentHardCopy(payload: RentHardCopyProps) {
	let url = `/rentals`

	const response = await postRequest({
		tokenType: TOKEN_TYPE.hardCopy,
		url,
		payload,
	})

	return response
}

export type ReturnHardCopyProps = {
	userId: string
	hardCopyId: string
	managerId?: string
	// pk: string
	// id: string
	// by: string
}

export async function returnHardCopy(payload: ReturnHardCopyProps) {
	let url = `/rentals`

	const response = await deleteRequest({
		tokenType: TOKEN_TYPE.hardCopy,
		url,
		payload,
	})

	return response
}

export async function signedUrl(payload: {
	allowedSeconds: number
	fileName: string
	extension: string
	contentType: string
}) {
	let url = `/rentals/presigned-post`

	const response = await postRequest({
		tokenType: TOKEN_TYPE.hardCopy,
		url,
		payload,
	})

	return response
}

export type HcMgmtRentalApiProps = {
	apiReadRentals: (params: any) => Promise<AxiosResponse<any>>
	apiRentHardCopy: (params: RentHardCopyProps) => Promise<AxiosResponse<any>>
	apiReturnHardCopy: (
		params: ReturnHardCopyProps
	) => Promise<AxiosResponse<any>>
	apiReadSignedUrl: (params: {
		allowedSeconds: number
		fileName: string
		extension: string
		contentType: string
	}) => Promise<AxiosResponse<any>>
}
