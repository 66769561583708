import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import {
    DLDialog,
    DLButton,
} from "../../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../../common-models/types/common-props"
import { Icon } from "@mdi/react"
import { mdiPlus, mdiPause } from "@mdi/js"
import { ActionStatus } from "@datalobby/types/lib"
import {
    idToNumber,
    IdType,
} from "../../../../../../../library/converters/id-converter"
import { useOrgStore } from "../../../../../../../stores/org-store/org-store.provider"
import { getTableColumns } from "./get-table-columns"
import { HardCopyStatusEnum } from "../../../store/data-models/hard-copy-list.data-models"
import { BulkAddHcWithoutScDialogContents } from "./BulkAddHcWithoutScDialogContents"
import { useHcMgmtStore } from "../../../../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"
import { monotonicFactory } from "ulid"
const ulid = monotonicFactory()

export default observer(function BulkAddHcWithoutScDialog({
    i18n,
    partialStore,
}: {
    i18n: DLI18nProps
    partialStore: any
}) {
    const orgStore = useOrgStore()
    const hcMgmtStore = useHcMgmtStore()
    const configStore = hcMgmtStore.config
    //
    const actionName = "createMultipleHardCopies"

    const orgIdForHardCopy = orgStore.checkin.orgInfo.orgId.split("Id").join("")
    const customColumns = configStore.getCustomColumnsForBulkImport()

    const handleAddRow = () => {
        const tempId = ulid()

        const newRow = {
            tempId,
            //
            title: "",
            year: 0,
            status: HardCopyStatusEnum.available,
            description: "",
            //
            customColumns: customColumns.columnsEmptyArray,
            //
            isDuplicated: false,
            actionStatus: ActionStatus.standby,
            statusMessage: "",
        }
        partialStore.pushItemToBulkList(newRow)
    }

    const handleBulkAdd = () => {
        const itemList = partialStore.viewBulkHardCopyList.filter(
            (item: any) => item.actionStatus === ActionStatus.standby
        )
        const customerId = process.env.REACT_APP_CUSTOMER_ID
        const orgId = orgStore.checkin.orgInfo.orgId.split("Id").join("") // localStorage.getItem("orgId")?.split("Id").join("")
        const userInfo = {
            id: orgStore.checkin.orgInfo.userId.split("Id").join(""),
            alias: orgStore.checkin.orgInfo.userAliasId,
            name: orgStore.checkin.orgInfo.userName,
        }
        console.log("handleBulkAdd > itemList", itemList)
        partialStore.addHardCopiesWithoutSoftCopies({
            list: itemList,
            otherRequirements: {
                customerId,
                orgId,
                createdBy: userInfo,
            },
            index: 0,
        })
    }

    const handleRemoveRow = (tempId: string) => {
        partialStore.spliceBulkHardCopy(tempId)
    }

    const multipleHardCopiesStatus = partialStore.getActionStatus(actionName)

    // const customColumnsDataForBulkImport = configStore.customColumnsDataForBulkImport
    const customColumnsDataForBulkImport = {
        forTemplate: customColumns,
        forColumns: configStore.viewCustomColumns,
        ccHeadersObjForBulkImport: configStore.revertedCcHeadersObj,
    }

    return (
        <DLDialog
            eleTestId="bulk-add-hc-without-sc-dialog"
            isOpen={partialStore.openBulkAddHardCopyWithoutSoftCopyDialog}
            setIsOpen={partialStore.setOpenBulkAddHardCopyWithoutSoftCopyDialog}
            showCloseBtn={true}
            dialogTitle="Bulk Add Hard Copy without Soft Copy"
            dialogContents={
                <BulkAddHcWithoutScDialogContents
                    partialStore={partialStore}
                    i18n={i18n}
                    handleRemoveRow={handleRemoveRow}
                    orgIdForHardCopy={orgIdForHardCopy}
                    customColumnsDataForBulkImport={
                        customColumnsDataForBulkImport
                    }
                />
            }
            maxWidth="lg"
            actionBtn={
                <div className="FR JSB" style={{ width: "100%" }}>
                    <DLButton
                        eleTestId="add-new-row"
                        startIcon={<Icon path={mdiPlus} size={0.8} />}
                        clickHandler={() => handleAddRow()}
                        // disabled={multipleHardCopiesStatus !== ActionStatus.standby}
                    >
                        Add Row
                    </DLButton>

                    {multipleHardCopiesStatus === ActionStatus.loading ? (
                        <DLButton
                            eleTestId="pause-create-mul-users"
                            startIcon={<Icon path={mdiPause} size={0.8} />}
                            // clickHandler={() => handlePauseMultipleUsers()}
                        >
                            Pause
                        </DLButton>
                    ) : (
                        <div className="FR JSB">
                            {/*{partialStore.viewSubmitStatusCount(
								ActionStatus.success
							) > 0 && (
								<div
									className="FR AC"
									style={{ marginRight: 8 }}
								>
									Submitted:{" "}
									{partialStore.viewSubmitStatusCount(
										ActionStatus.success
									)}
									<span style={{ marginLeft: 8 }}>|</span>
								</div>
							)}
							{partialStore.viewSubmitStatusCount(
								ActionStatus.fail
							) > 0 && (
								<div
									className="FR AC"
									style={{ marginRight: 8 }}
								>
									Failed:{" "}
									{partialStore.viewSubmitStatusCount(
										ActionStatus.fail
									)}
									<span style={{ marginLeft: 8 }}>|</span>
								</div>
							)}
							{standByUsers.length > 0 && (
								<span style={{ padding: 8 }}>
									Submit {standByUsers.length} Users
								</span>
							)}*/}
                            <DLButton
                                eleTestId="proceed-bulk-add"
                                clickHandler={() => handleBulkAdd()}
                                // disabled={
                                // 	standByUsers.length === 0 || isReady()
                                // }
                            >
                                Submit
                            </DLButton>
                        </div>
                    )}
                </div>
            }
            // cancelBtnText={i18n.twCancel || "Cancel"}
            // 			showSpinner={archiveMgmtStore.getActionStatus("getUsers") !== "SUCCESS"}
            // 			cannotUpdate={archiveMgmtStore.getActionStatus("getUsers") !== "SUCCESS"}
        />
    )
})
