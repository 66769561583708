import React, { useEffect, useState } from "react"
import {
	InputWithLabel,
	DLInputField,
	DLRadioGroup,
} from "../../../../../../../components/basic-elements"
import DLYearpicker from "../../../../../../../components/basic-elements/date-picker/DLYearpicker"
import SimpleMDE from "react-simplemde-editor"
import "easymde/dist/easymde.min.css"

const DefaultFields = ({
	input,
	handleInput,
	handleDescription,
	handleHardCopyInputs,
}: {
	input: any
	handleInput: (e: any) => void
	handleDescription: (value: any) => void
	handleHardCopyInputs: (name: string, value: any) => void
}) => {
	return (
		<div>
			<div className="input-section FR">
				<InputWithLabel
					direction="vertical"
					required
					label="Hard Copy Title"
				>
					<DLInputField
						eleTestId="hard-copy-title-input"
						eleFullWidth
						eleName="title"
						eleValue={input.title}
						eleHandleChange={handleInput}
						eleRequired
						variant="box"
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel direction="vertical" required label="Status">
					<DLRadioGroup
						selectedValue={input.status}
						groupName="status"
						items={[
							{ value: "AVAILABLE", label: "Available" },
							{ value: "STOCKED", label: "Stocked" },
							{
								value: "READY_TO_DELETE",
								label: "Ready to delete",
							},
							{ value: "UNAVAILABLE", label: "Unavailable" },
						]}
						eleOnChange={handleInput}
						color="primary"
						size="small"
						itemDirection="row"
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel direction="vertical" required label="Year">
					<DLYearpicker
						eleTestId="hard-copy-year-input"
						eleName="year"
						selected={input.year}
						onChange={(date: any) =>
							handleHardCopyInputs("year", date)
						}
						fullWidth
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel direction="vertical" label="Description">
					<SimpleMDE
						className="md-editor description"
						value={input.description}
						onChange={handleDescription}
					/>
				</InputWithLabel>
			</div>
		</div>
	)
}

export default DefaultFields
