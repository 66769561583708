import { flow } from "mobx-state-tree"

export type ReadSignedUrlProps = {
	allowedSeconds: number
	fileName: string
	extension: string
	contentType: string
}

const ReadSignedUrl = (self: any, _readSignedUrl: any) => ({
	readSignedUrl: flow(function* ({
		allowedSeconds,
		fileName,
		extension,
		contentType,
	}: ReadSignedUrlProps) {
		try {
			const response = yield _readSignedUrl({
				allowedSeconds,
				fileName,
				extension,
				contentType,
			})
			return response
		} catch (err) {
			console.log("err:", err)
			return false
		}
	}),
})

export default ReadSignedUrl
