import { flow } from "mobx-state-tree"
import { HardCopyMgmtApiProps } from "../hg-mgmt-hard-copy.apis"

const CreateHardCopy = (
	self: any,
	addHardCopy: HardCopyMgmtApiProps["apiCreateHardCopy"]
) => ({
	createHardCopy: flow(function* (data: any) {
		const actionName = "createHardCopy"
		try {
			const response = yield addHardCopy(data)
			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default CreateHardCopy
