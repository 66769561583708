import React from "react"
import { Route } from "react-router-dom"
import Workpapers from "./workpaper-files/Workpapers"
import PermanentFiles from "./permanent-files/PermanentFiles"
import { observer } from "mobx-react-lite"
import FinalAnalysisController from "./workpaper-files/sub-components/FinalAnalysisController"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"

export default observer(function ProjectFiles() {
	const projStore = useProjStore()
	const defaultMenuUrl = projStore.checkin.defaultUrl + "/files"
	const wpUrl = defaultMenuUrl + "/workpaper-files"
	const pfUrl = defaultMenuUrl + "/permanent-files"
	return (
		<>
			<Route exact path={defaultMenuUrl} component={Workpapers} />
			<Route exact path={wpUrl} component={Workpapers} />
			<Route
				exact
				path={`${wpUrl}/:parentId`}
				component={FinalAnalysisController}
			/>

			<Route exact path={pfUrl} component={PermanentFiles} />
		</>
	)
})
