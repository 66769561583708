import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import MyRentalList from "./MyRentalList"
import { useHcMgmtStore } from "../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"

export default observer(function MyRentalListController() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const hcMgmtStore = useHcMgmtStore()
	//
	const menuId = DLOrgSubMenus.my_rental_list
	const actionName = "getMyRentalList"
	const i18nSheet = OrgI18n.hardCopyMgmt
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const needRefresh = hcMgmtStore.hardCopy.needRefresh
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	useEffect(() => {
		hcMgmtStore.hardCopy.getMyRentalList()
	}, [])

	const i18n = store.i18n.combineI18n(i18nSheet, "HardCopyMgmt")
	return (
		<>
			{menuAccess ? (
				<MyRentalList
					partialStore={hcMgmtStore.hardCopy}
					fetchingStatus={hcMgmtStore.hardCopy.getActionStatus(
						actionName
					)}
					permission={permission}
					i18n={i18n}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
