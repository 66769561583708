import React from "react"
import { Link } from "react-router-dom"
import {
	DLButton,
	DLIconButton,
} from "../../../../../components/basic-elements"
import Icon from "@mdi/react"
import {
	mdiDotsHorizontal,
	mdiContentPaste,
	mdiFileCheckOutline,
	mdiPlusCircle,
	mdiPlusCircleOutline,
} from "@mdi/js"
import DLDateRangepicker from "../../../../../components/basic-elements/date-picker/DLDateRangePicker"
import DLComboBoxWithCheckbox from "../../../../../components/basic-elements/autocomplete-fields/DLComboBoxWithCheckbox"
import { ProjectStatus } from "../../../../../common-models/enumerations/project-related-enums"
import {
	NormalProjectIcon,
	NormalLockedProjectIcon,
	ReplicaProjectIcon,
	ArchivedProjectIcon,
	UnarchivedProjectIcon,
} from "../../../../../components/basic-elements/icons/common-project-icons"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import { Column, Table } from "@tanstack/react-table"
import {
	dateRangeFilter,
	filterMethodForQCandEP,
	multiSelectFilter,
	singleSelectFilter,
	yearFilter,
} from "../../../../../library/react-table-utils"
import DLYearpicker from "../../../../../components/basic-elements/date-picker/DLYearpicker"

export const filterComponent = ({
	column,
	table,
	dateFormat,
	epList,
	qcList,
	handleYearChange,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
	epList: any
	qcList: any
	handleYearChange: any
}) => {
	switch (column.id) {
		case "statusName":
			return (
				<select
					onChange={(e: any) => {
						column.setFilterValue(e.target.value)
					}}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "all") as string[]}
					data-testid="project-status-select"
				>
					<option value="all">All</option>
					<option value={ProjectStatus.normal}>Normal</option>
					<option value={ProjectStatus.replica}>Replica</option>
					<option value={ProjectStatus.archived}>Archived</option>
					<option value={ProjectStatus.unarchived}>Unarchived</option>
				</select>
			)

		case "periodName":
		case "groupName":
		case "clientName":
		case "engTypeName":
			return (
				<DLComboBoxWithCheckbox
					options={[...column.getFacetedUniqueValues().keys()]}
					getOptionLabel={(option: any) => option}
					onChange={(value: any) => {
						column.setFilterValue(value)
					}}
					eleValue={(column.getFilterValue() ?? []) as string[]}
					eleTestId="periodName-select"
				/>
			)

		case "epUsers":
		case "qcUsers":
			return (
				<DLComboBoxWithCheckbox
					options={column.id === "epUsers" ? epList : qcList}
					getOptionLabel={(option: any) => option}
					onChange={(value: any) => {
						column.setFilterValue(value)
					}}
					eleValue={(column.getFilterValue() ?? []) as string[]}
					eleTestId={`${column.id}-select`}
				/>
			)

		case "periodEndDate":
		case "expectedReportDate":
		case "expectedArchiveDate":
		case "finalReportDate":
		case "finalArchiveDeadlineDate":
		case "lastAccessAt":
		case "createdAt":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		case "financialYear":
			return (
				<DLYearpicker
					eleTestId="financial-year-select"
					eleName="financialYear"
					selected={
						column.getFilterValue()
							? column.getFilterValue()
							: new Date().getFullYear()
					}
					onChange={(value: any) => {
						column.setFilterValue(value)
						handleYearChange(value)
					}}
					showClearIcon
				/>
			)

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const ReportsManagementTableColumns = ({
	partialStore,
	handleContextMenu,
	i18n,
}: {
	partialStore: any
	handleContextMenu: any
	i18n?: DLI18nProps
}) => [
	{
		header: i18n?.twStatus,
		accessorKey: "statusName",
		filterFn: singleSelectFilter,
		size: 20,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			const projStatus = props.getValue()
			return (
				<div data-testid={cellId}>
					{" "}
					{projStatus === ProjectStatus.normal &&
					!props.row.original.isLocked ? (
						<NormalProjectIcon size={1} />
					) : projStatus === ProjectStatus.normal &&
					  props.row.original.isLocked ? (
						<div className="FR AC regular-text">
							<NormalLockedProjectIcon size={1} />
						</div>
					) : projStatus === ProjectStatus.replica ? (
						<div className="FR AC regular-text">
							<ReplicaProjectIcon size={1} />
						</div>
					) : projStatus === ProjectStatus.archived ? (
						<div className="FR AC regular-text">
							<ArchivedProjectIcon size={1} />
						</div>
					) : projStatus === ProjectStatus.unarchived ? (
						<div className="FR AC regular-text">
							<UnarchivedProjectIcon size={1} />
						</div>
					) : (
						<div>UnKnown Status</div>
					)}
				</div>
			)
		},
	},
	{
		header: i18n?.twPeriodType,
		accessorKey: "periodName",
		filterFn: multiSelectFilter,
		cell: (props: any) => {
			return <div data-testid="period-name-cell">{props.getValue()}</div>
		},
	},
	{
		header: i18n?.twFinancialYear || "Financial Year",
		accessorKey: "financialYear",
		size: 82,
		filterFn: yearFilter,
		cell: (row: any) => {
			return <div data-testid="financial-year-cell">{row.getValue()}</div>
		},
	},
	{
		header: i18n?.twGroup || "Group Name",
		accessorKey: "groupName",
		filterFn: multiSelectFilter,
		cell: (row: any) => {
			return <div data-testid="group-cell">{row.getValue()}</div>
		},
	},
	{
		header: i18n?.twClient || "Client",
		accessorKey: "clientName",
		filterFn: multiSelectFilter,
		cell: (row: any) => {
			return <div data-testid="client-cell">{row.getValue()}</div>
		},
	},
	{
		header: i18n?.twReport || "Report",
		accessorKey: "reportId",
		cell: (props: any) => {
			if (props.getValue() !== null) {
				return (
					<DLIconButton
						eleTestId="report-open-icon"
						clickHandler={() => {}}
						tooltipText="Open Report"
					>
						<Icon
							path={mdiContentPaste}
							size={0.8}
							color="#758fff"
						/>
					</DLIconButton>
				)
			} else {
				return (
					<DLIconButton
						eleTestId="report-add-icon"
						clickHandler={() => {}}
						tooltipText="Add Report"
					>
						<Icon path={mdiPlusCircle} size={0.8} />
					</DLIconButton>
				)
			}
		},
	},
	{
		header: i18n?.twChecklist || "Checklist",
		accessorKey: "checklistId",
		cell: (props: any) => {
			if (props.getValue() !== null) {
				return (
					<DLIconButton
						eleTestId="open-checklist-dialog-button"
						clickHandler={() => {
							partialStore.setSelectedProjId(
								props.row.original.id
							)
							partialStore.setOpenChecklistDialog(true)
						}}
						tooltipText="Open Checklist"
					>
						<Icon
							path={mdiFileCheckOutline}
							size={0.8}
							color="#758fff"
						/>
					</DLIconButton>
				)
			} else {
				return (
					<DLIconButton
						eleTestId="add-checklist-button"
						clickHandler={() => {
							partialStore.setSelectedProjId(
								props.row.original.id
							)
							partialStore.setOpenAddChecklistDialog(true)
						}}
						tooltipText="Add Checklist"
					>
						<Icon path={mdiPlusCircleOutline} size={0.8} />
					</DLIconButton>
				)
			}
		},
	},
	{
		header: i18n?.twProjectTitle || "Project Name", // NOTE: Keep "project name" for end user
		accessorKey: "title",
		cell: (props: any) => {
			const projId = props.row.original.id
			const projNumId = idToNumber(projId, IdType.project)
			const projTitle = props.getValue()
			const accessible = props.row.original.accessible
			return (
				<div
					className="project-title-cell"
					data-testid="project-title-cell"
					style={{ fontWeight: 600 }}
				>
					{accessible ? (
						<Link to={`/project/checkin/${projNumId}`}>
							{projTitle}
						</Link>
					) : (
						projTitle
					)}
				</div>
			)
		},
	},
	{
		header: i18n?.twProjectId || "Project ID", // NOTE: Keep "project id" for end user
		accessorKey: "aliasId",
		cell: (row: any) => {
			return (
				<div data-testid="project-aliasId-cell">{row.getValue()}</div>
			)
		},
	},
	{
		header: i18n?.twVersion || "version",
		accessorKey: "version",
		cell: (row: any) => {
			const cellId = row.cell.column.id + "-cell"
			const version = row.getValue() === 0 ? "-" : "v." + row.getValue()
			return <div data-testid={cellId}>{version}</div>
		},
	},
	{
		header: i18n?.twEP || "E.P.",
		accessorKey: "epUsers",
		filterFn: filterMethodForQCandEP,
		cell: (props: any) => {
			return (
				<div data-testid="ep-cell">
					{props.getValue().length > 0 ? (
						<>
							{props.getValue().map((item: any) => {
								return (
									<React.Fragment key={item.name}>
										{item.name}
										{props.getValue().length > 1 &&
											","}{" "}
									</React.Fragment>
								)
							})}
						</>
					) : (
						<span>-</span>
					)}
				</div>
			)
		},
	},
	{
		header: i18n?.twQC || "Q.C.",
		accessorKey: "qcUsers",
		filterFn: filterMethodForQCandEP,
		cell: (row: any) => {
			return (
				<div data-testid="qc-cell">
					{row.getValue().length > 0 ? (
						<>
							{row.getValue().map((item: any) => {
								return (
									<React.Fragment key={item.name}>
										{item.name}
										{row.getValue().length > 1 && ","}{" "}
									</React.Fragment>
								)
							})}
						</>
					) : (
						<span>-</span>
					)}
				</div>
			)
		},
	},
	{
		header: i18n?.twEngType || "Eng. Type",
		accessorKey: "engTypeName",
		filterFn: multiSelectFilter,
		cell: (row: any) => {
			return <div data-testid="engType-cell">{row.getValue()}</div>
		},
	},
	{
		header: i18n?.twPeriodEndDate || "Period End Date",
		accessorKey: "periodEndDate",
		filterFn: dateRangeFilter,
		cell: (row: any) => {
			return (
				<div data-testid="period-end-cell">
					{row.getValue() ? row.getValue() : "-"}
				</div>
			)
		},
	},

	{
		header: i18n?.twExpectedReportDate || "Expected Report Date",
		accessorKey: "expectedReportDate",
		filterFn: dateRangeFilter,
		cell: (row: any) => {
			return (
				<div data-testid="expected-report-date-cell">
					{row.getValue() ? row.getValue() : "-"}
				</div>
			)
		},
	},
	{
		header: i18n?.twExpectedArchiveDate || "Expected Archive Date",
		accessorKey: "expectedArchiveDate",
		filterFn: dateRangeFilter,
		cell: (row: any) => {
			return (
				<div data-testid="expected-report-date-cell">
					{row.getValue() ? row.getValue() : "-"}
				</div>
			)
		},
	},
	{
		header: i18n?.twFinalReportDate || "Final Report Date",
		accessorKey: "finalReportDate",
		filterFn: dateRangeFilter,
		cell: (row: any) => {
			return (
				<div data-testid="final-report-date-cell">
					{row.getValue() ? row.getValue() : "-"}
				</div>
			)
		},
	},
	{
		header:
			i18n?.twFinalArchiveDeadlineDate || "Final Archive Deadline Date",
		accessorKey: "finalArchiveDeadlineDate",
		filterFn: dateRangeFilter,
		cell: (row: any) => {
			return (
				<div data-testid="final-archive-deadline-date-cell">
					{row.getValue() ? row.getValue() : "-"}
				</div>
			)
		},
	},
	{
		header: i18n?.twLastAccessAt || "Last Access At",
		accessorKey: "lastAccessAt",
		filterFn: dateRangeFilter,
		cell: (row: any) => {
			const cellId = row.cell.column.id + "-cell"
			return <div data-testid={`${cellId}-cell`}>{row.getValue()}</div>
		},
	},
	// {
	// 	header: "By",
	// 	accessorKey: "lastAccessBy",
	// 	accessorFn: (props: any) => props.lastAccessBy.name,
	// 	cell: (row: any) => {
	// 		const cellId = row.cell.column.id + "-cell"
	// 		return <div data-testid={`${cellId}-cell`}>{row.getValue()}</div>
	// 	},
	// },
	{
		header: "Created At",
		accessorKey: "createdAt",
		filterFn: dateRangeFilter,
		cell: (row: any) => {
			const cellId = row.cell.column.id + "-cell"
			return <div data-testid={`${cellId}-cell`}>{row.value}</div>
		},
	},
	{
		header: "Created By",
		accessorKey: "createdBy",
		accessorFn: (props: any) => props.createdBy.name,
		cell: (row: any) => {
			const cellId = row.cell.column.id + "-cell"
			return <div data-testid={`${cellId}-cell`}>{row.value}</div>
		},
	},
	{
		header: "Action",
		accessorKey: "xxx",
		cell: (props: any) => {
			const id = props.row.original.id
			const handleClick = (event: any) => {
				partialStore.setSelectedItem({
					id,
				})
				handleContextMenu(event, props.row)
			}
			return (
				<div style={{ width: "100%", textAlign: "center" }}>
					<DLButton
						variant="text"
						eleTestId="project-list-context-btn"
						clickHandler={(event: any) => handleClick(event)}
					>
						<Icon path={mdiDotsHorizontal} size={0.8} />
					</DLButton>
				</div>
			)
		},
	},
]

export default ReportsManagementTableColumns
