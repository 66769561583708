import React from "react"
import styled from "styled-components"

const StyledCreateProjectDialog = styled.div`
	width: 100%;
	overflow: hidden;
	.dialog-section-header {
		background-color: ${(props) => props.theme.shade20};
		padding: 0.2rem 0.6rem 0.2rem 1rem;
	}
	.location-warning {
		color: red;
		margin-bottom: 1rem;
	}
	.input-section {
		width: 100%;
		margin-bottom: 0.6rem;
		border: 1px solid transparent;
		padding: 4px 1rem;
		border-radius: 4px;
		transition: 0.3s;
		&.request {
			color: red;
			border: 1px solid red;
			background-color: rgba(255,0,0, 0.3);
		}
		.with-label{
			width:100%;
			.label {
			min-width: 8rem;
		}
		.input-area {
			width: calc(100% - 8rem);
			/* min-width: 20rem; */
			.request-required-field{
				opacity: 0.4;
			}
		}
		.select-period-type {
		}
		}

	}
	.heading3 {
		/* height: 1rem; */

	}
	.note{
			color: ${(props) => props.theme.warning};
		}

		.divider {
		height: inherit;
		width: 1px;
		margin: 0 0.5rem;
		border-right: 1px solid ${(props) => props.theme.shade20};
	}
	.left-area {
		width: 3rem;
		transition: width 0.2s;
		overflow: hidden;
	}
	.left-area.section-on {
		width: 100%
		padding-left: 0.6rem;
		// overflow: scroll;
	}

	.center-area {
		width: 3rem;
		transition: width 0.2s;
		overflow: hidden;
	}
	.center-area.section-on {
		width: 100%
		.create-btn {
			height: ${(props) => props.theme.shared.baseUnit};
		}
	}
	.right-area {
		width: 3rem;
		transition: width 0.2s;
		overflow: hidden;
	}
	.right-area.section-on {
		width: 100%
	}
`

export default StyledCreateProjectDialog
