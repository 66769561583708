import { flow } from "mobx-state-tree"

const ReadHardCopyUsers = (self: any, read: any) => ({
	readHardCopyUsers: flow(function*() {
		const actionName = "readHardCopyUsers"
		try {
// 			const response = yield read()
            const response = [{
                userId: "userId1",
                role: "Hard Copy Admin",
                userName: "Alpha",
                alias: "alias-a",
                status: "Normal",
                isRentalAvailable: true,
                addedDate: "2022-06-22",
                modifiedDate: "2022-06-23",
                modifiedBy: "Echo"
            },
            {
                userId: "userId2",
                role: "Read Only",
                userName: "Beta",
                alias: "alias-b",
                status: "Suspended",
                isRentalAvailable: false,
                addedDate: "2022-06-22",
                modifiedDate: "2022-06-23",
                modifiedBy: "Echo"
            }]
			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default ReadHardCopyUsers
