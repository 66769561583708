import React from "react"

const ProjInfo: any = {
	clientName: "Client",
	title: "Title",
	projectAlias: "Project Alias",
	version: "Version",
	statusName: "Project Type",
	periodName: "Period Type",
	financialYear: "Year",
	finalReportDate: "Final Report Date",
}

const ProjectInfoDetailsContent = ({ projInfo }: { projInfo: any }) => {
	return (
		<div className="proj-info-details">
			{Object.keys(projInfo).map((key: any) => (
				<>
					{!!ProjInfo[key] ? (
						<RowItem
							key={key}
							label={ProjInfo[key]}
							value={projInfo[key]}
						/>
					) : (
						<RowItem key={key} label={key} value={projInfo[key]} />
					)}
				</>
			))}
		</div>
	)
}

const RowItem = ({ label, value }: { label: string; value: string }) => (
	<div className="FR row-item">
		<div className="label" style={{ minWidth: "10rem" }}>{label}</div>
		{value ? value : "-"}
	</div>
)

export default ProjectInfoDetailsContent
