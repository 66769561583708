import React from "react"
import styled from "styled-components"
import SignOffCell from "./SignOffCell"
import { observer } from "mobx-react-lite"

import {
	SingleSignOffProps,
	SignOffPolicyItemProps,
} from "../../../../service-modules/file-module/data-models/sign-off.data-model"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { DnTFormatProps } from "../../../../common-models/types/common-props"

export default observer(function FileRowSignOffArea({
	// isHovered,
	hoveredColumn,
	width,
	fileId,
	fileAliasId,
	signOff,
	getColumnSignOffs,
	cellType,
	dateFormat,
	signOffPolicy,
	signOffColumnsDisplay,
	permission,
}: {
	hoveredColumn?: any
	width: number
	fileId: string
	fileAliasId: string
	signOff: (props: SingleSignOffProps) => void
	getColumnSignOffs: (
		fileId: string,
		columnId: string,
		dntFormat: DnTFormatProps
	) => any
	cellType: string
	dateFormat: string
	signOffPolicy: SignOffPolicyItemProps[]
	signOffColumnsDisplay: { [x: string]: any }
	permission: PermissionAsObjectProps
}) {
	let divide = signOffPolicy.length
	for (const item in signOffColumnsDisplay) {
		if (!signOffColumnsDisplay[item]) {
			divide = divide - 1
		}
	}

	let cellWidth = width / divide // signOffPolicy.length
	return (
		<StyledFileRowSignOffArea
			className="FR AC signoff-area"
			style={{ width }}
		>
			{signOffPolicy.map((policyItem) => {
				if (signOffColumnsDisplay[policyItem.id]) {
					let isHovered = hoveredColumn === policyItem.id
					return (
						<div key={policyItem.id}>
							<SignOffCell
								isHovered={isHovered}
								condition={policyItem}
								fileId={fileId}
								fileAliasId={fileAliasId}
								signOff={signOff}
								getColumnSignOffs={getColumnSignOffs}
								cellWidth={cellWidth}
								cellType={cellType}
								dateFormat={dateFormat}
								permission={permission}
							/>
						</div>
					)
				}
			})}
		</StyledFileRowSignOffArea>
	)
})

const StyledFileRowSignOffArea = styled.div`
	height: 100%;
	overflow: hidden;
`
