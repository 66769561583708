import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	DLButton,
	InputWithLabel,
	DLInputField,
} from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import styled from "styled-components"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import DLYearpicker from "../../../../../../components/basic-elements/date-picker/DLYearpicker"
import { useHcMgmtStore } from "../../../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"
import SimpleMDE from "react-simplemde-editor"
import "easymde/dist/easymde.min.css"

export default observer(function EditHardCopyDetailDialog({
	i18n,
	partialStore,
}: {
	i18n: DLI18nProps
	partialStore: any
}) {
	const orgStore = useOrgStore()
	const hcMgmtStore = useHcMgmtStore()
	const hcStore = hcMgmtStore.hardCopy
	const configStore = hcMgmtStore.config
	//
	const customFields = configStore.viewCustomColumns

	const [hardCopyDetail, setHardCopyDetail] = useState<any>({})

	useEffect(() => {
		const hardCopy = hcStore.getHardCopyInfo()
		const hardCopyCustomFields = hcStore.getHardCopyCustomFields()
		console.log("hardCopyCustomFields", hardCopyCustomFields)

		if (hardCopy) {
			setHardCopyDetail({
				id: hardCopy.id,
				pk: hardCopy.pk,
				title: hardCopy.title,
				description: hardCopy.description,
				year: hardCopy.year,
				...hardCopyCustomFields,
			})
		} else {
			console.log(
				"Sorry, cannot find the hard copy information from the store"
			)
		}
	}, [])

	const handleHardCopyDetail = (e: any) => {
		const { name, value } = e.target
		setHardCopyDetail((inputs: any) => ({
			...inputs,
			[name]: value,
		}))
	}

	const handleYear = (name: string, value: any) => {
		setHardCopyDetail((inputs: any) => ({
			...inputs,
			[name]: value,
		}))
	}
	
	const handleDescription = (value: any) => {
		setHardCopyDetail((prev: any) => ({
			...prev,
			description: value,
		}))
	}

	const handleSubmit = () => {
		console.log("hardCopyDetail", hardCopyDetail)

		const payload = {
			...hardCopyDetail,
			by: {
				id: orgStore.checkin.orgInfo.userId.split("Id").join(""),
				alias: orgStore.checkin.orgInfo.userAliasId,
				name: orgStore.checkin.orgInfo.userName,
			},
		}
		hcStore.editHcWithoutScDetail(payload)
	}

	return (
		<DLDialog
			eleTestId="edit-hard-copy-detail-dialog"
			isOpen={partialStore.openEditHardCopyDetailDialog}
			setIsOpen={partialStore.setOpenEditHardCopyDetailDialog}
			showCloseBtn={true}
			dialogTitle="Edit Hard Copy Detail"
			dialogContents={
				<EditHardCopyDetailDialogContent
					hardCopyDetail={hardCopyDetail}
					handleHardCopyDetail={handleHardCopyDetail}
					handleYear={handleYear}
					customFields={customFields}
					handleDescription={handleDescription}
				/>
			}
			// actionReady={isReady || userEmail === inputs.email.value}
			actionReady
			maxWidth="sm"
			actionBtn="Update"
			handleAction={handleSubmit}
			cancelBtnText={i18n.twCancel || "Cancel"}
			// 			showSpinner={archiveMgmtStore.getActionStatus("getUsers") !== "SUCCESS"}
			// 			cannotUpdate={archiveMgmtStore.getActionStatus("getUsers") !== "SUCCESS"}
		/>
	)
})

const EditHardCopyDetailDialogContent = ({
	hardCopyDetail,
	handleHardCopyDetail,
	handleYear,
	customFields,
	handleDescription
}: {
	hardCopyDetail: any
	handleHardCopyDetail: any
	handleYear: any
	customFields: any
	handleDescription: any
}) => (
	<StyledHardCopyWithoutProjectDetail>
		<div className="FR">
			<div className="default-fields">
				<h3>1. Default Fields</h3>
				<DefaultFields
					input={hardCopyDetail}
					handleInput={handleHardCopyDetail}
					handleDescription={handleDescription}
					handleYear={handleYear}
				/>
			</div>

			<div className="custom-fields">
				<h3>2. Custom Fields</h3>
				{/* <p>* Custom fields reflect the custom columns.</p> */}
				<CustomFields
					fields={customFields}
					input={hardCopyDetail}
					handleInput={handleHardCopyDetail}
				/>
			</div>
		</div>
	</StyledHardCopyWithoutProjectDetail>
)

const StyledHardCopyWithoutProjectDetail = styled.div`
	.default-fields {
		width: 50%;
	}
	.custom-fields {
		width: 50%;
	}
`

const DefaultFields = ({
	input,
	handleInput,
	handleDescription,
	handleYear,
}: {
	input: any
	handleInput: (e: any) => void
	handleDescription: (value: any) => void
	handleYear: (name: string, value: any) => void
}) => {
	return (
		<div>
			<div className="input-section FR">
				<InputWithLabel
					direction="vertical"
					required
					label="Hard Copy Title"
				>
					<DLInputField
						eleTestId="hard-copy-title-input"
						eleFullWidth
						eleName="title"
						eleValue={input.title}
						eleHandleChange={handleInput}
						eleRequired
						variant="box"
					/>
				</InputWithLabel>
			</div>

			<div className="input-section FR">
				<InputWithLabel direction="vertical" required label="Year">
					{/*<DLInputField
						eleTestId="hard-copy-year-input"
						eleFullWidth
						eleName="year"
						eleValue={input.year}
						eleHandleChange={handleInput}
						eleRequired
						variant="box"
					/>*/}
					<DLYearpicker
						eleTestId="hard-copy-year-input"
						eleName="year"
						selected={input.year}
						onChange={(date: any) => handleYear("year", date)}
						fullWidth
						// showClearIcon
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel direction="vertical" label="Description">
					<SimpleMDE
						className="md-editor"
						value={input.description}
						onChange={handleDescription}
					/>
				</InputWithLabel>
			</div>
		</div>
	)
}

const CustomFields = ({
	fields,
	input,
	handleInput,
}: {
	fields: any[]
	input: any
	handleInput: (e: any) => void
}) => {
	return (
		<div>
			{fields.map((field) => {
				return (
					<div className="input-section FR">
						<InputWithLabel
							direction="vertical"
							label={field.label}
						>
							<DLInputField
								eleTestId={field.label}
								eleFullWidth
								eleName={field.id}
								eleValue={input[field.id]}
								eleHandleChange={handleInput}
								variant="box"
							/>
						</InputWithLabel>
					</div>
				)
			})}
		</div>
	)
}
