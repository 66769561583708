import React from "react"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DLIconButton } from "../../../../../components/basic-elements"
import { mdiDotsVertical } from "@mdi/js"
import { Icon } from "@mdi/react"

const CustomColumnTrash = ({
	handleContextMenu,
}: {
	handleContextMenu: any
}) => {
	return (
		<ReactTableV8
			tableColumns={columns(handleContextMenu)}
			data={[]}
			handleContextMenu={handleContextMenu}
			menuId={DLOrgSubMenus.hard_copy}
			showPageSetting={false}
			// 			customFilters={(props: any) => filterComponent({...props, handleYearChange})}
		/>
	)
}

const columns = (
	// 	partialStore: any,
	handleContextMenu: (event: any, targetInfo: any) => void
) => [
	{
		header: "No",
		accessorKey: "no",
	},
	{
		header: "Type",
		accessorKey: "type",
	},
	{
		header: "Label",
		accessorKey: "label",
	},
	{
		header: "Deleted By",
		accessorKey: "deletedBy",
	},
	{
		header: "Deleted Date",
		accessorKey: "deletedDate",
	},
	{
		header: "Action",
		accessorKey: "pending",
		enableColumnFilter: false,
		cell: (props: any) => {
			const userId = props.row.original.id
			const cellId = props.cell.id + "-cell"

			return (
				<div data-testid={cellId}>
					<DLIconButton
						variant="iconOnly"
						aria-label="more"
						clickHandler={(event: any) =>
							handleContextMenu(event, props.row.original)
						}
						eleTestId="hard-copy-ctx-btn-on-row"
					>
						<Icon path={mdiDotsVertical} size={0.8} />
					</DLIconButton>
				</div>
			)
		},
	},
]

export default CustomColumnTrash
