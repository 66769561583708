import React from "react"
import { DLContextMenu } from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { IconPlaceholder } from "../../../../../components/basic-elements/icons/common-icons"
import { ConsoleLog } from "../../../../../components/basic-elements"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { requestToken } from "../../../../project-side/proj-access-control/store/proj-checkin.apis"
import { saveLatestProjToken } from "../../../../../library/token-utils"

export default observer(function ReportsManagementCtxMenus({
	permission,
	i18n,
}: {
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) {
	const orgStore = useOrgStore()

	const projId = orgStore.reportsMgmt.selectedProjId
	const projNumId = idToNumber(projId, IdType.project)
	const projectInfo = orgStore.reportsMgmt.viewSelectedProj()

	const handleClose = () => {
		orgStore.reportsMgmt.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	const postAction = () => orgStore.projects.setUpdateDialogOpenStatus(true)

	const getProjectInfo = () => {
		const userNumId = idToNumber(orgStore.checkin.orgUserId, IdType.user)

		if (typeof projNumId === "number" && typeof userNumId === "number") {
			requestToken({ UserId: userNumId, ClientId: projNumId }).then(
				(response: any) => {
					if (response.data.status.code === 200) {
						saveLatestProjToken(response.data.data.access_token)
						orgStore.projects.getProjInfo(projId, postAction)
					} else {
						ConsoleLog(
							"Cannot request the project info. Failed to request project token"
						)
					}
				}
			)
		} else {
			ConsoleLog(
				"Cannot request the project info. Project ID or User ID is incorrect."
			)
		}
	}

	const handleOpenEditProjectDialog = () => {
		getProjectInfo()
	}

	const menuOptions = [
		{
			label: "Open",
			value: "open",
			disabled: !projectInfo?.accessible,
			available: projectInfo?.accessible && permission.read,
			isLink: true,
			linkTo: `/project/checkin/${projNumId}`,
			icon: <IconPlaceholder />,
		},
		{
			label: "Project Information",
			value: "project-info",
			disabled: !projectInfo?.accessible,
			available: projectInfo?.accessible && permission.read,
			clickEvent: () =>
				orgStore.projects.setProjectDetailsDialogOpen(true),
			icon: <IconPlaceholder />,
		},
		// {
		// 	label: "Edit Project",
		// 	value: "ctx-edit",
		// 	available: permission.update,
		// 	clickEvent: handleOpenEditProjectDialog,
		// 	icon: (
		// 		<DLIcon
		// 			name={DLIconName.edit}
		// 			size={CommonIconSize.ctxMenuIcon}
		// 		/>
		// 	),
		// },
	]

	return (
		<DLContextMenu
			eleId="reports-mgmt-ctx-menus"
			clickPoint={{
				mouseX: orgStore.reportsMgmt.clickPoint.mouseX,
				mouseY: orgStore.reportsMgmt.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions}
			hasDivider={[0]}
		/>
	)
})
