import React from "react"
import { Route } from "react-router-dom"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import NormalProjectsController from "./NormalProjectsController"
import ReplicaProjectsController from "./ReplicaProjectsController"
import ArchivedProjectsController from "./ArchivedProjectsController"
import UnarchivedProjectsController from "./UnarchivedProjectsController"
import CreateProjectDialog from "../../../components/combined-elements/create-project/CreateProjectDialog"
import { DLOrgMenuGroups } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import ProjectDetailsDialog from "../../../components/combined-elements/project-dialogs/ProjectDetailsDialog"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { OrgI18n } from "../../../common-models/enumerations/translation-sheets"
import CommonSnackbarComponent from "../../../components/combined-elements/snackbar/CommonSnackbarComponent"

export default observer(function AllProjects() {
    const store = useRootStore()
    const orgStore = useOrgStore()

    const path = orgStore.groups.selectionPathWithNameAndFormat
    const setIdPath = orgStore.groups.setPathForSelection
    const presetInputs = orgStore.projects.createProjectPresetInputs
    const i18n = store.i18n.combineI18n(OrgI18n.projects)
    return (
        <>
            <Route
                exact
                path="/organization/projects"
                component={NormalProjectsController}
            />
            <Route
                exact
                path="/organization/projects/normal"
                component={NormalProjectsController}
            />
            <Route
                exact
                path="/organization/projects/replica"
                component={ReplicaProjectsController}
            />
            <Route
                exact
                path="/organization/projects/archived"
                component={ArchivedProjectsController}
            />
            <Route
                exact
                path="/organization/projects/unarchived"
                component={UnarchivedProjectsController}
            />
            <CommonSnackbarComponent
                i18n={i18n}
                partialStore={orgStore.projects}
            />
            {orgStore.projects.openCreateProjDialog && (
                <CreateProjectDialog
                    open={orgStore.projects.openCreateProjDialog}
                    setOpen={orgStore.projects.setOpenCreateProjDialog}
                    //
                    currentMenu={DLOrgMenuGroups.projects}
                    createMethod={orgStore.projects.createProjectMethod}
                    //
                    path={path}
                    setIdPath={setIdPath}
                    presetInputs={presetInputs}
                />
            )}
            {orgStore.projects.projectDetailsDialogOpen && (
                <ProjectDetailsDialog partialStore={orgStore.projects} />
            )}
        </>
    )
})
