import { types } from "mobx-state-tree"


export const HistoryItem = types.model({
	// targetPk: types.string,
	// targetId: types.string,
	// targetKey: types.string,
	actionName: types.string,
	// detail: types.string,
	createdDate: types.string,
	// groupId: types.string,
	source: types.string,
	actionSummary: types.string,
	// "createdBy": {
	// 	"name": "Support",
	// 	"alias": "dl-support",
	// 	"id": "user1"
	// },
	// "createdBy_id": "user1",
	createdBy: types.string,
	service: types.string,
	orgId: types.string,
	eventType: types.string,
	pk: types.string,
	id: types.string,
	// recordType: types.string,
	// gsi1Pk: types.string,
	// customerId: types.string
})

export enum HardCopyMgmtHistorySourceEnum {
	hardCopy = "HARD_COPY",
	rental = "RENTAL",
	customColumn = "CUSTOM_COLUMN",
}