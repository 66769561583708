import sharedTheme from "./sharedTheme"
import {
	transparency as transp,
	shadowPosition as sp,
} from "./shared-variables"

// NOTE: NO GREEN (Its hard to distinguish with red)
const mainColor = "#303441"
const subColor = "#292929"
const emphasis = "#5067cb"

const themeBlack = "#EFF4FF"
const themeShadow = "#000000"

const shade = "#c8c8c8"

const themeRed = "#C71700" // unarchived project icon color
const themeYellow = "#DAA52D" // locked project icon color
const themeOrange = "#E67924"
const themePurple = "#8239CF" // archived project icon color

const darkTheme: { [x: string]: any } = {
	mainColor,
	subColor,
	// ---------- basic variables (sholud be matched with dark theme)
	shared: sharedTheme,
	background: "#121212",

	orgSidebar: mainColor,
	projSidebar: subColor,

	orgSidebarEmphasisTxt: "#6C8FFF",
	projSidebarEmphasisTxt: "#6C8FFF",

	emphasis,
	emphasisDeep: "#2742B8",
	emphasisLight: emphasis + transp.p60,
	//
	txtOnEmphasisColor: "#d6d6d6",

	// ---------- color standard primary
	primaryMainDeep: "#1D2334",
	primaryLight: "#303441",
	primaryLightDeep: "#262934",

	// ---------- color standard secondary
	secondary: subColor + transp.p50,
	secondaryDeep: subColor + transp.p60,

	// ---------- project type color standard
	normalProject: themeBlack + transp.p60,
	normalLockedProject: themeYellow,
	replicaProject: themeBlack + transp.p20,
	archivedProject: themePurple,
	unarchivedProject: themeRed,

	// ---------- warning and dangerous
	warning: themeRed,
	warningDeep: "#881000",

	// ---------- text standard
	textHighEmphasis: themeBlack + transp.p90,
	textMediumEmphasis: themeBlack + transp.p80,
	textDisabled: themeBlack + transp.p35,

	// ---------- shade
	shade05: shade + transp.p05,
	shade10: shade + transp.p10,
	shade20: shade + transp.p20,
	shade30: shade + transp.p30,
	shade40: shade + transp.p40,
	shade60: shade + transp.p60,
	shade90: shade + transp.p90,

	// ---------- shadow
	shadowColorLight: themeShadow + transp.p60,
	shadowColorDeep: themeShadow + transp.p80,
	// stand
	shadowRegularLight: sp.regular + themeShadow + transp.p60,
	shadowRegularDeep: sp.regular + themeShadow + transp.p80,
	// left
	shadowRegularLeftLight: sp.left + themeShadow + transp.p60,
	shadowRegularLeftDeep: sp.left + themeShadow + transp.p80,
	// right
	shadowRegularRightLight: sp.right + themeShadow + transp.p60,
	shadowRegularRightDeep: sp.right + themeShadow + transp.p80,
	//

	themeRed,
	themeYellow,
	themeOrange,

	themePurple,
}

export default darkTheme
