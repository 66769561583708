import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../../components/basic-elements"

const GetRationaleFiles = (self: any) => ({
	getRationaleFiles() {
		// 0.
		const actionName = "getRationaleFiles"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.readRationaleFiles()
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					ConsoleLog(response.data)
					/**
					 * TODO: UPDATE is required
					 */
					self.resetRationaleList()
					const existingWpList = response.data.WorkpaperFilesList
					const existingPfList = response.data.PermanentFilesList
					// 2) Re-organize workpaper list
					existingWpList.map((wp: any) => {
						const reOrganizedWpInfo = {
							description: wp.Description, // ex) "Our-New-World"
							isModifiedAfterRationale:
								wp.IsModifiedAfterRationale, // ex) false
							modifiedBy: wp.ModifiedBy, // ex) "epUserId1"
							modifiedDate: wp.ModifiedDate, // ex) "2020-05-10T18:36:45.562948+00:00"
							rationaleBy: wp.RationaleBY, // ex) ""
							rationaleDate: wp.RationaleDate, // ex) null
							rationaleDesc: wp.RationaleDescription, // ex) ""
							rationaleId: wp.RationaleID.toString(), // ex) 0
							reference: wp.Reference, // ex) "7"
							workpaperId: wp.WorkpaperID.toString(), // ex) 5299
							type: "WP",
						}
						self.pushItemToRationaleList(reOrganizedWpInfo)
					})
					// 3) Re-organize permanent list
					existingPfList.map((pf: any) => {
						const reOrganizedPfInfo = {
							description: pf.Description, // ex) "Our-New-World"
							isModifiedAfterRationale:
								pf.IsModifiedAfterRationale, // ex) false
							modifiedBy: pf.ModifiedBy, // ex) "epUserId1"
							modifiedDate: pf.ModifiedDate, // ex) "2020-05-10T18:36:45.562948+00:00"
							rationaleBy: pf.RationaleBY, // ex) ""
							rationaleDate: pf.RationaleDate, // ex) null
							rationaleDesc: pf.RationaleDescription, // ex) ""
							rationaleId: pf.RationaleID.toString(), // ex) 0
							reference: pf.Reference, // ex) "7"
							workpaperId: pf.WorkpaperID.toString(), // ex) 5299
							type: "PF",
						}
						self.pushItemToRationaleList(reOrganizedPfInfo)
					})
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Succesfully retrived rationale files.",
						open: true,
					})
				} else {
					// set fail responses
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetRationaleFiles
