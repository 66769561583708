import { flow } from "mobx-state-tree"

const UpdateCustomColumn = (self: any, update: any) => ({
	updateCustomColumn: flow(function* (payload: any) {
		const actionName = "updateCustomColumn"
		try {
			const response = yield update(payload)

			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default UpdateCustomColumn
