import React from "react"
// for store
import { observer } from "mobx-react-lite"
// styles
import styled from "styled-components"
import {
	DLDialog,
	InputWithLabel,
	DLInputField,
	DLSystemMsg,
	DLButton,
} from "../../../../components/basic-elements"
import { mdiTrashCan } from "@mdi/js"
import Icon from "@mdi/react"
import { DialogFormProps } from "../../../../common-models/types/dialog.props"
import sharedRegEx from "../../../../library/sharedRegEx"
import useForm from "../../../../library/use-form"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { MessageColorType } from "../../../../common-models/enumerations/common-enums"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"

export default observer(function DeleteProjectDialog({
	projStatus,
	partialStore,
	i18n,
}: {
	projStatus: ProjectStatus
	partialStore: any
	i18n: DLI18nProps
}) {
	const orgStore = useOrgStore()
	const inputsSchema = {
		email: { value: "", error: "", requestInput: false },
	}
	const validationSchema = {
		email: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.email, // TODO: update is required
				error: i18n.tsInvalidFormat || "Invalid format",
			},
		},
	}

	const deleteProject = () => {
		const projectId = partialStore.selectedProj
		const cabinetId = partialStore.selectedCabinetId
		orgStore.projects.removeProject({
			projectId,
			cabinetId,
			handleResponse: partialStore.handleResponse,
			postWorkForSuccess: () => {
				partialStore.popItemFromProjectList(projectId, projStatus)
				partialStore.setOpenDeleteProjDialog(false)
				partialStore.resetSelectedItems()
			},
		})
	}

	const {
		inputs,
		handleOnChange,
		handleOnSubmit,
		isReady,
		// initializeInputs
	} = useForm(inputsSchema, validationSchema, deleteProject)
	const userEmail = localStorage.getItem("lobbyUserId")
	return (
		<DLDialog
			eleTestId="delete-project-dialog"
			isOpen={partialStore.openDeleteProjDialog}
			setIsOpen={partialStore.setOpenDeleteProjDialog}
			handleAction={handleOnSubmit}
			showCloseBtn={true}
			dialogTitle={i18n.twDeleteProject || "Delete Project"}
			dialogContents={
				<DeleteProjectDialogForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
				/>
			}
			actionReady={isReady && userEmail === inputs.email.value}
			maxWidth="xs"
			actionBtn={
				<DLButton
					variant="text"
					eleTestId="delete-proj-btn"
					startIcon={<Icon path={mdiTrashCan} size={0.8} />}
					clickHandler={handleOnSubmit}
					color="warning"
					disabled={!isReady || userEmail !== inputs.email.value}
				>
					Delete
				</DLButton>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
		/>
	)
})

const DeleteProjectDialogForm = observer(
	({
		inputs,
		handleOnChange,
		i18n,
	}: {
		inputs: any
		handleOnChange: any
		i18n: DLI18nProps
	}) => {
		const userEmail = localStorage.getItem("lobbyUserId")
		return (
			<StyledDialog>
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twEmail || "Email"}>
						<DLInputField
							eleTestId="delete_email_input"
							eleFullWidth
							eleName="email"
							eleValue={inputs.email.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={inputs.email.error}
							requestInput={inputs.email.requestInput}
						/>
						{inputs.email.value !== "" &&
							userEmail !== inputs.email.value && (
								<DLSystemMsg
									eleTestId="msg-warning-email-match"
									type={MessageColorType.red}
									msg={
										i18n.twEmailDoesNotMatch ||
										"Email does not match"
									}
								/>
							)}
					</InputWithLabel>
				</div>
			</StyledDialog>
		)
	}
)

const StyledDialog = styled.div`
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 4rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 16rem;
		}
	}
`
