import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog, DLButton, InputWithLabel, DLInputField, DLSingleSelect } from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import styled from "styled-components"

enum Roles {
	hardCopyAdmin = "HardCopyAdmin",
	readonly = "ReadOnly",
}

enum Status {
	normal = "Normal",
	suspended = "Suspended",
}

export default observer(function AddUserDialog({
	i18n,
	partialStore, // hcMgmtStore > config
}: {
	i18n: DLI18nProps
	partialStore: any
}) {
	const actionName = "addUser"
	
	const [inputs, setInputs] = useState({
		userName: "",
		aliasId: "",
		roleId: Roles.hardCopyAdmin,
		status: Status.normal,
		rentalAvailable: true
	})
	
	const handleInputs = () => {
	    
	}

	const handleAction = () => {
		// 		userStore.suspendUser(userStore.selectedUser.id)
	}

	return (
		<DLDialog
			eleTestId="add-user-dialog"
			isOpen={partialStore.openAddUserDialog}
			setIsOpen={partialStore.setOpenAddUserDialog}
			handleAction={handleAction}
			showCloseBtn={true}
			dialogTitle="Add User"
			dialogContents={
                <AddUserDialogContent inputs={inputs} handleInputs={handleInputs}/>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionBtn="Add User"
			maxWidth="sm"
			fullWidth={true}
			actionReady={true}
// 			showSpinner={}
// 			cannotUpdate={userStore.responses.getActionStatus(actionName) === "LOADING"}
		/>
	)
})


const AddUserDialogContent = ({
    inputs,
    handleInputs
}: {
    inputs: any,
    handleInputs: any
}) => {
    return (
		<StyledAddUserDialogContent>
		    <div className="input-section FR">
				<InputWithLabel
					required
					label="Name"
				>
					<DLInputField
						eleTestId="username-input-field"
						eleFullWidth
						eleName="name"
						eleValue={inputs.name}
						eleHandleChange={handleInputs}
						eleRequired
					/>
				</InputWithLabel>
			</div>
		    <div className="input-section FR">
				<InputWithLabel
					required
					label="Alias ID"
				>
					<DLInputField
						eleTestId="alias-input-field"
						eleFullWidth
						eleName="aliasId"
						eleValue={inputs.aliasId}
						eleHandleChange={handleInputs}
						eleRequired
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel required label="Role">
					<DLSingleSelect
						eleTestId="role-selection"
						eleName="role"
						eleValue={inputs.role}
						eleFullWidth
						withLabel={false}
						optionList={[
							{
								value: Roles.hardCopyAdmin,
								name: "Hard Copy Admin",
							},
							{
								value: Roles.readonly,
								name: "Read Only",
							},
						]}
						placeholder="Select Role"
						eleOnChange={handleInputs}
					/>
				</InputWithLabel>
			</div>
		</StyledAddUserDialogContent>
	)
}

const StyledAddUserDialogContent = styled.div`
    .input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`