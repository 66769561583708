import { ActionStatus } from "@datalobby/types/lib"
import * as XLSX from "xlsx"

export function handleFile({
	event,
	setList,
	setFileReadStatus,
	ccHeadersObjForBulkImport,
}: {
	event: any
	setList: any
	setFileReadStatus: any
	ccHeadersObjForBulkImport: { [x: string]: string } // 'label': 'id array of custom columns
}) {
	var file = event.target.files[0]
	var reader = new FileReader()

	reader.onload = function (event) {
		if (event.target) {
			if (
				event.target.result !== null &&
				typeof event.target.result !== "string"
			) {
				var data = new Uint8Array(event.target.result)

				var workbook = XLSX.read(data, { type: "array" })

				const jsonData = XLSX.utils.sheet_to_json(
					workbook.Sheets.Sheet1
				)
				console.log("handleFile > jsonData:", jsonData)

				setList({ list: jsonData, ccHeadersObjForBulkImport })
				setFileReadStatus(ActionStatus.success)

				// partialStore.setBulkHardCopyFileReadStatus(
				//     ActionStatus.success
				// )
				// setDuplicatedItems(duplicatedList)
			}
		} else {
			console.log("event target is null")
		}
	}
	reader.readAsArrayBuffer(file)
}
