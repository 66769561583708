import React from "react"

export type FilterProps = (
	| { id: string; value: number }
	| { id: string; value: string }
)[]

const SharedProjTableInitialFilters = [
	{
		id: "statusName",
		value: "all",
	},
	{
		id: "periodName",
		value: "",
	},
	{
		id: "financialYear",
		value: new Date().getFullYear(), // current year
	},
	{
		id: "groupName",
		value: "",
	},
	{
		id: "clientName",
		value: "",
	},
	{
		id: "title",
		value: "",
	},
	{
		id: "aliasId",
		value: "",
	},
	{
		id: "version",
		value: "",
	},
	{
		id: "epUsers",
		value: "",
	},
	{
		id: "qcUsers",
		value: "",
	},
	{
		id: "engTypeName",
		value: "",
	},
	{
		id: "periodEndDate",
		value: "",
	},
	{
		id: "finalReportDate",
		value: "",
	},
	{
		id: "expectedReportDate",
		value: "",
	},
	{
		id: "finalArchiveDeadlineDate",
		value: "",
	},
	{
		id: "expectedArchiveDate",
		value: "",
	},
	{
		id: "archivedAt",
		value: "",
	},
	{
		id: "unarchivedAt",
		value: "",
	},
	{
		id: "archivedBy.name",
		value: "",
	},
	{
		id: "unarchivedBy.name",
		value: "",
	},
	{
		id: "replicaStatus",
		value: "all",
	},
	{
		id: "unarchiveStatus",
		value: "all",
	},
	{
		id: "archiveZipStatus",
		value: "all",
	},
	{
		id: "lastAccessAt",
		value: "",
	},
	{
		id: "lastAccessBy.name",
		value: "",
	},
	{
		id: "createdAt",
		value: "",
	},
	{
		id: "createdBy.name",
		value: "",
	},
	{
		id: "xxx",
		value: "",
	},
]

export default SharedProjTableInitialFilters
