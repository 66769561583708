import { flow } from "mobx-state-tree"
import { HardCopyMgmtApiProps } from "../hg-mgmt-hard-copy.apis"

const UpdateHardCopyDetail = (
	self: any,
	updateHardCopyDetail: HardCopyMgmtApiProps["apiUpdateHardCopyDetail"]
) => ({
	updateHardCopyDetail: flow(function* (data: any) {
		const actionName = "updateHardCopyDetail"
		try {
			const response = yield updateHardCopyDetail(data)
			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default UpdateHardCopyDetail
