import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog, DLButton } from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import styled from "styled-components"
import { useHcMgmtStore } from "../../../../../../stores/hc-mgmt-store/hc-mgmt-store.provider"

export default observer(function RentalHistoryDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const hcMgmtStore = useHcMgmtStore()
	const historyStore = hcMgmtStore.history

	useEffect(() => {
		// 		archiveMgmtStore.getFilteredUsers("internal")
	}, [])

	return (
		<DLDialog
			eleTestId="rental-history-dialog"
			isOpen={historyStore.openRentalHistoryDialog}
			setIsOpen={historyStore.setOpenRentalHistoryDialog}
			showCloseBtn={true}
			dialogTitle="Rental History"
			dialogContents={<RentalHistoryDialogContent />}
			// actionReady={isReady || userEmail === inputs.email.value}
			maxWidth="sm"
			actionBtn="Proceed"
			cancelBtnText={i18n.twCancel || "Cancel"}
			// 			showSpinner={archiveMgmtStore.getActionStatus("getUsers") !== "SUCCESS"}
			// 			cannotUpdate={archiveMgmtStore.getActionStatus("getUsers") !== "SUCCESS"}
		/>
	)
})

const RentalHistoryDialogContent = () => <>Rental History Content</>
