import React from "react"
import {
	SortingIconHeader,
	DLIconButton,
} from "../../../../../components/basic-elements"
import { DLCheckbox } from "@datalobby/components"
//
import Icon from "@mdi/react"
import { mdiDotsHorizontal } from "@mdi/js"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { ReactTableFilterProps } from "../../../../../common-models/types/react-table.props"
import DLDateRangepicker from "../../../../../components/basic-elements/date-picker/DLDateRangePicker"
import ToggleSelectAllCheckbox from "../../../../../common-models/shared-store-code/selected-items-related/ToggleSelectAllCheckbox"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { dateRangeFilter } from "../../../../../library/react-table-utils"

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	return <div data-testid={cellId}>{props.getValue()}</div>
}

// export const OrgSetupClientsTableColumn = (
// 	handleContextMenu: any,
// 	handleCheckbox: any,
// 	isSelected: any,
// 	i18n: DLI18nProps,
// 	dateFilters: any,
// 	onChangeDate: any,
// 	dateFormat: string,
// 	handleToggleAll: any,
// 	selectedClientsCount: any,
// 	permission: PermissionAsObjectProps
// ) => [
// 	{
// 		Header: (row: any) => {
// 			return <div />
// 			// ConsoleLog(row)
// 			// return (
// 			// 	<ToggleSelectAllCheckbox
// 			// 		selectedItemExist={selectedClientsCount > 0}
// 			// 		toggleAction={handleToggleAll}
// 			// 	/>
// 			// )
// 		},
// 		accessor: "clientId",
// 		width: 48,
// 		filterable: false,
// 		sortable: false,
// 		Cell: (row: any) => {
// 			const id = row.original.clientId
// 			// ConsoleLog("id inside", id)
// 			const handleClick = () => {
// 				handleCheckbox(id)
// 			}

// 			return (
// 				<DLCheckbox
// 					eleTestId={`checkbox-${id}`}
// 					clickHandler={() => handleClick()}
// 					color="primary"
// 					isChecked={isSelected(id)}
// 				/>
// 			)
// 		},
// 	},
// 	{
// 		Header: (
// 			<SortingIconHeader title={i18n.twClientName || "Client Name"} />
// 		),
// 		accessor: "clientName",
// 		Cell: renderCustomCell,
// 	},
// 	{
// 		Header: <SortingIconHeader title={i18n.twClientId || "Client ID"} />,
// 		accessor: "clientAliasId",
// 		Cell: renderCustomCell,
// 	},
// 	{
// 		Header: <SortingIconHeader title={i18n.twCreatedAt || "Created At"} />,
// 		accessor: "createdAt",
// 		Cell: (row: any) => {
// 			const cellId = row.column.id + "-cell"
// 			return <div data-testid={cellId}>{row.value}</div>
// 		},
// 		filterMethod: (filter: any, row: any) => {
// 			return filterDate(
// 				row[filter.id],
// 				dateFilters.createdAtStartDate,
// 				dateFilters.createdAtEndDate
// 			)
// 		},
// 		Filter: ({ filter, onChange }: ReactTableFilterProps) => {
// 			// ConsoleLog(dateFilters)
// 			return (
// 				<DLDateRangepicker
// 					selectedStartDate={dateFilters.createdAtStartDate}
// 					selectedEndDate={dateFilters.createdAtEndDate}
// 					eleStartName="createdAtStartDate"
// 					eleEndName="createdAtEndDate"
// 					onChange={onChangeDate}
// 					format={dateFormat}
// 				/>
// 			)
// 		},
// 	},
// 	{
// 		Header: <SortingIconHeader title={i18n.twCreatedBy || "Created By"} />,
// 		accessor: "createdBy",
// 		Cell: renderCustomCell,
// 	},
// 	{
// 		Header: <div className="FR JC">Actions</div>,
// 		accessor: "xxx",
// 		minWidth: 50,
// 		filterable: false,
// 		sortable: false,
// 		show: permission.update || permission.delete,
// 		Cell: (row: any) => {
// 			const clientId = row.original.clientId
// 			return (
// 				<div className="FR AC JC" style={{ width: "100%" }}>
// 					<DLIconButton
// 						aria-label="more"
// 						clickHandler={(event: any) =>
// 							handleContextMenu(event, clientId)
// 						}
// 						eleTestId="client-ctx-btn-on-row"
// 					>
// 						<Icon path={mdiDotsHorizontal} size={0.8} />
// 					</DLIconButton>
// 				</div>
// 			)
// 		},
// 	},
// ]

export const OrgSetupClientsTableColumn = (
	handleContextMenu: any,
	handleCheckbox: any,
	isSelected: any,
	i18n: DLI18nProps,
	permission: PermissionAsObjectProps
) => [
	{
		header: "",
		accessorKey: "clientId",
		size: 20,
		enableColumnFilter: false,
		cell: (props: any) => {
			const id = props.row.original.clientId
			const handleClick = () => {
				handleCheckbox(id)
			}

			return (
				<DLCheckbox
					eleTestId={`checkbox-${id}`}
					clickHandler={() => handleClick()}
					color="primary"
					isChecked={isSelected(id)}
				/>
			)
		},
	},
	{
		header: i18n.twClientName || "Client Name",
		accessorKey: "clientName",
		cell: renderCustomCell,
	},
	{
		header: i18n.twClientId || "Client ID",
		accessorKey: "clientAliasId",
		cell: renderCustomCell,
	},
	{
		header: i18n.twCreatedAt || "Created At",
		accessorKey: "createdAt",
		filterFn: dateRangeFilter,
		cell: renderCustomCell,
	},
	{
		header: i18n.twCreatedBy || "Created By",
		accessorKey: "createdBy",
		cell: renderCustomCell,
	},
	{
		header: "Actions",
		accessorKey: "xxx",
		enableColumnFilter: false,
		size: 20,
		cell: (props: any) => {
			const clientId = props.row.original.clientId
			return (
				<div className="FR AC JC" style={{ width: "100%" }}>
					<DLIconButton
						aria-label="more"
						clickHandler={(event: any) =>
							handleContextMenu(event, clientId)
						}
						eleTestId="client-ctx-btn-on-row"
					>
						<Icon path={mdiDotsHorizontal} size={0.8} />
					</DLIconButton>
				</div>
			)
		},
	},
]
