import React from "react"
import { observer } from "mobx-react-lite"
import { InputWithLabel } from "../../../../basic-elements"
import { Icon } from "@mdi/react"
import { mdiChevronDown } from "@mdi/js"
import styled from "styled-components"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { FileScreenDialog } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"

export default observer(function FolderSelectField({
	i18n,
	partialStore,
	folderTitle,
	highlight,
}: {
	i18n: DLI18nProps
	partialStore: any
	folderTitle: string | undefined
	highlight: boolean
}) {
	const showFolderTree = () => {
		partialStore.setFileTreeDialogOpen([FileScreenDialog.folderTree])
	}

	return (
		<StyledFolderField className="folder-selector-container input-section FR AC">
			<InputWithLabel
				label={i18n.twFolder || "Folder"}
				required
				highlight={highlight && folderTitle === ""}
			>
				<div
					className="folder-selector FR AC JSB"
					data-testid="add-file-folder-select"
					onClick={() => showFolderTree()}
				>
					<div
						className={`folder-name ${
							folderTitle === "" ? "empty" : ""
						}`}
					>
						{folderTitle || "Please select a folder"}
					</div>
					<Icon path={mdiChevronDown} size={0.7} />
				</div>
			</InputWithLabel>
		</StyledFolderField>
	)
})

const StyledFolderField = styled.div`
	.folder-name.empty {
		color: red;
	}
`
